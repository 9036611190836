import { useEffect } from 'react';

export const useHandleChunkLoadErrors = (onError: VoidFunction) => {
  useEffect(() => {
    const reloadOnError = (error: ErrorEvent) => {
      if (/Loading chunk [\d]+ failed/.test(error.message)) {
        onError();
      }
    };

    window.addEventListener('error', reloadOnError);

    return () => {
      window.removeEventListener('error', reloadOnError);
    };
  }, [onError]);
};
