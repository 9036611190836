import { styled } from './stitches.config';
export const Text = styled('span', {
    variants: {
        variant: {
            title: {
                fontWeight: '$medium',
                fontSize: '$title',
                letterSpacing: '$title',
                lineHeight: '$title',
            },
            textRegular: {
                fontWeight: '$regular',
                fontSize: '$text',
                letterSpacing: '$text',
                lineHeight: '$text',
            },
            textMedium: {
                fontWeight: '$medium',
                fontSize: '$text',
                letterSpacing: '$text',
                lineHeight: '$text',
            },
            caption: {
                fontWeight: '$semibold',
                fontSize: '$caption',
                letterSpacing: '$caption',
                lineHeight: '$caption',
                color: '$grey600',
                textTransform: 'uppercase',
            },
        },
        colorVariant: {
            default: {},
            muted: {
                color: '$grey600',
            },
        },
        truncate: {
            true: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        },
    },
    defaultVariants: {
        variant: 'textRegular',
    },
});
