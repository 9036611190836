import { CSS, keyframes, styled } from '@folkapp/design-system';

const stroke = keyframes({
  '100%': {
    strokeDashoffset: 0,
  },
});

const scale = keyframes({
  '0%, 100%': {
    transform: 'none',
  },
  '50%': {
    transform: 'scale3d(1.2, 1.2, 1)',
  },
});

const fill = keyframes({
  '100%': {
    boxShadow: 'inset 0px 0px 0px 30px rgb(94, 202, 78)',
  },
});

const AnimatedCheckContainer = styled('svg', {
  width: 30,
  height: 30,
  borderRadius: '50%',
  strokeWidth: 2,
  stroke: 'white',
  strokeMiterlimit: 10,
  boxShadow: 'inset 0px 0px 0px rgb(94, 202, 78)',
  animation: `${fill} .4s ease-in-out .4s 1 forwards, ${scale} .3s ease-in-out .9s 1 both`,
});

const AnimatedCheckCircle = styled('circle', {
  strokeDasharray: 166,
  strokeDashoffset: 166,
  strokeWidth: 2,
  strokeMiterlimit: 10,
  stroke: 'rgb(94, 202, 78)',
  animation: `${stroke} .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) 1 forwards`,
});

const AnimatedCheckPath = styled('path', {
  transformOrigin: '50% 50%',
  strokeDasharray: 48,
  strokeDashoffset: 48,
  animation: `${stroke} .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s 1 forwards`,
});

export const AnimatedCheck = ({ css }: { css?: CSS }) => (
  <AnimatedCheckContainer css={css} viewBox="0 0 52 52">
    <AnimatedCheckCircle cx="26" cy="26" r="25" fill="none" />
    <AnimatedCheckPath fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
  </AnimatedCheckContainer>
);
