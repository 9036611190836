import 'inter-ui/inter.css';
import './styles/index.css';

import { App } from 'app/App';
import { registerRedirectionUrl } from 'app/apps/routes/utils';
import { AuthenticatedApolloClientProvider } from 'app/services/graphQLService';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { initalizeSegment } from 'utils/analytics';
import { initalizeDatadogRUM } from 'utils/datadog';
import { initalizeFullStory } from 'utils/fullStory';
import { initalizeIntercom } from 'utils/intercom';

import * as serviceWorker from './serviceWorker';

registerRedirectionUrl();

createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <AuthenticatedApolloClientProvider>
      <App />
    </AuthenticatedApolloClientProvider>
  </BrowserRouter>,
);

initalizeDatadogRUM();
initalizeSegment();
initalizeFullStory();
initalizeIntercom();
serviceWorker.unregister();
