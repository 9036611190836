import { CustomFieldType } from '__generated__/graphql';
import { PopoverOptionsProps } from 'app/Components/Popover';
import { customFieldIconMapping } from 'app/services/local/utils';
import { useCreateCustomField } from 'app/services/remote/customField';
import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { useAddCustomFieldContext } from '../components/context';

// for now, all options are the same whether on the contact panel or not
export const useSections = () => {
  const createCustomField = useCreateCustomField();
  const { dispatch } = useAddCustomFieldContext();
  const onAction = useCallback(
    (customFieldType: CustomFieldType) => {
      const customFieldId = uuid();
      createCustomField(customFieldId, customFieldType);
      dispatch({ type: 'add', customFieldId });
    },
    [createCustomField, dispatch],
  );

  const sections: PopoverOptionsProps['sections'] = useMemo(
    () => [
      {
        options: [
          {
            label: 'Multi-select',
            onAction: () => {
              onAction(CustomFieldType.multipleSelect);
            },
            icon: customFieldIconMapping.multipleSelect,
          },
          {
            label: 'Select',
            onAction: () => {
              onAction(CustomFieldType.singleSelect);
            },
            icon: customFieldIconMapping.singleSelect,
          },
          {
            label: 'Date',
            onAction: () => {
              onAction(CustomFieldType.dateField);
            },
            icon: customFieldIconMapping.dateField,
          },
          {
            label: 'Member',
            onAction: () => {
              onAction(CustomFieldType.userField);
            },
            icon: customFieldIconMapping.userField,
          },
          {
            label: 'Text',
            onAction: () => {
              onAction(CustomFieldType.textField);
            },
            icon: customFieldIconMapping.textField,
          },
          {
            label: 'Contact',
            onAction: () => {
              onAction(CustomFieldType.contactField);
            },
            icon: customFieldIconMapping.contactField,
          },
        ],
      },
    ],
    [onAction],
  );

  return sections;
};
