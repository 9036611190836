import { useMediaQuery } from 'app/hooks/useMediaQuery';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

// cf. https://ishadeed.com/article/flexbox-gap/
const getFlexGapIsSupported = () => {
  // create flex container with row-gap set
  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';

  // create two, elements inside it
  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  const isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
  flex.parentNode?.removeChild(flex);

  return isSupported;
};

const getIsChromeOrFirefox = () => {
  const userAgent = navigator.userAgent;

  return userAgent.includes('Chrome') || userAgent.includes('Firefox');
};

export enum ClientSupportIssue {
  responsivity = 'responsivity',
  browserCompatibility = 'browserCompatibility',
}

const responsivePaths = ['/shared/', '/auth/', '/oauth-redirection/'];

export const useNeedResponsiveMode: () => boolean = () => {
  // 1024px is the Ipad pro regular display
  // Below this value, the contact panel is unusable
  return useMediaQuery('(max-width: 1024px)');
};

export const useClientSupportIssues: () => ClientSupportIssue | null = () => {
  const location = useLocation();
  const currentPathname = location.pathname;
  const needResponsiveMode = useNeedResponsiveMode();

  return useMemo(() => {
    // check for SSR
    if (document === undefined) {
      return null;
    }

    // Desktop detection : mobile is explicitly set to false or it doesn't have a touch screen.
    // User agent data is not supported by all browsers
    // https://caniuse.com/mdn-api_navigator_useragentdata
    const isDesktop =
      // @ts-expect-error
      navigator.userAgentData?.mobile === false ||
      !('ontouchstart' in document.documentElement);

    if (
      !isDesktop &&
      needResponsiveMode &&
      !responsivePaths.some((path) => currentPathname.startsWith(path))
    ) {
      return ClientSupportIssue.responsivity;
    }

    if (getIsChromeOrFirefox()) {
      return null;
    }

    // excludes:
    // 1. versions of Safari older than 14.1
    // 2. IE
    // 3. very old versions of Edge (< 2019)
    if (!getFlexGapIsSupported()) {
      return ClientSupportIssue.browserCompatibility;
    }

    return null;
  }, [currentPathname, needResponsiveMode]);
};
