import { Flex } from '@designSystem';
import { FC } from 'react';

import { ComboBoxProps } from '..';
import { Option } from './Option';
import { ComboBoxOption, OptionDisplay, OptionVariant } from './Option.types';

export interface SelectedOptionsProps
  extends Pick<ComboBoxProps, 'isMulti' | 'actions'> {
  wrapping?: OptionDisplay;
  onInputClick: VoidFunction;
  options: ComboBoxOption[];
  variant?: OptionVariant;
}

export const SelectedOptions: FC<
  React.PropsWithChildren<SelectedOptionsProps>
> = ({
  isMulti,
  onInputClick,
  actions,
  options,
  wrapping,
  variant,
  children,
}) => {
  return (
    <Flex
      align="center"
      css={{
        padding: '0 $2',
      }}
      tabIndex={0}
      role="button"
      onClick={onInputClick}
    >
      <Flex
        align="center"
        css={{
          flexShrink: 0,
          maxWidth: '100%',
          overflow: 'hidden',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          flexWrap: wrapping === 'flex' ? 'wrap' : 'nowrap',
          gap: '$1',
          '.comboboxInputWrapper': {
            width: 'auto',
            height: 'auto',
          },
        }}
      >
        {options.map((option) => (
          <Option
            key={option.id}
            option={option}
            context="selected"
            actions={actions}
            variant={variant}
          />
        ))}
        <Flex
          className="comboboxInputWrapper"
          css={{
            borderRadius: '$md',
          }}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};
