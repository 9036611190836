import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { styled } from './stitches.config';
const InputContainer = styled('div', {
    borderWidth: '$default',
    borderStyle: '$solid',
    borderColor: '$grey300',
    borderRadius: '$lg',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    '&:focus-within': {
        borderColor: '$grey800',
    },
    '&:has(> input[aria-invalid="true"])': {
        borderColor: '$red600',
    },
    variants: {
        leadingIcon: {
            true: {
                paddingBlock: 0,
                paddingInlineStart: 0,
            },
        },
        trailingIcon: {
            true: {
                paddingBlock: 0,
                paddingInlineEnd: 0,
            },
        },
    },
});
const InputBase = styled('input', {
    appearance: 'none',
    width: '100%',
    paddingBlock: '$1_5',
    paddingInline: '$2_5',
    lineHeight: '$4',
    '&::placeholder': {
        color: '$grey500',
    },
    '&:focus': {
        outline: 'none',
    },
    '&[type="search"]::-webkit-search-decoration, &[type="search"]::-webkit-search-cancel-button, &[type="search"]::-webkit-search-results-button, &[type="search"]::-webkit-search-results-decoration': {
        '-webkit-appearance': 'none',
    },
});
const InputItem = styled('span', {
    $$iconSize: '$sizes$4',
    $$itemOuterMargin: '-$space$px',
    $$itemInnerMargin: '-$space$2_5',
    flexShrink: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '30px',
    marginBlock: '-$px',
    '& > svg': {
        color: '$grey600',
        height: '$$iconSize',
        width: '$$iconSize',
    },
});
const InputLeadingItem = styled(InputItem, {
    marginInlineStart: '$$itemOuterMargin',
    marginInlineEnd: '$$itemInnerMargin',
});
const InputTrailingItem = styled(InputItem, {
    marginInlineStart: '$$itemInnerMargin',
    marginInlineEnd: '$$itemOuterMargin',
});
export const Input = forwardRef(({ css, leading, trailing, ...inputProps }, ref) => {
    return (_jsxs(InputContainer, { css: css, children: [leading && _jsx(InputLeadingItem, { children: leading }), _jsx(InputBase, { ref: ref, ...inputProps }), trailing && _jsx(InputTrailingItem, { children: trailing })] }));
});
