import { Box, Button } from '@folkapp/design-system';
import { useIsIntegrationPanelOpen } from 'app/apps/integrationPanel/useIsIntegrationPanelOpen';
import { lazy, Suspense } from 'react';
import onboardingSourceZapier from 'static/images/onboarding_source_zapier.avif';
import onboardingSourceZapierPng from 'static/images/onboarding_source_zapier.png';

import { SingleSourceOption } from './SingleSourceOption';

const LazyIntegrationPanel = lazy(() =>
  import(
    /* webpackChunkName: "IntegrationPanel" */ 'app/apps/integrationPanel'
  ).then((module) => ({
    default: module.IntegrationPanel,
  })),
);

export const ZapierSourceOption = () => {
  const [, openIntegrationPanel] = useIsIntegrationPanelOpen();

  return (
    <>
      <SingleSourceOption
        illustration={
          <picture>
            <source srcSet={onboardingSourceZapier} type="image/avif" />
            <Box
              as="img"
              css={{
                width: 288,
                height: 209,
              }}
              width={288}
              height={209}
              src={onboardingSourceZapierPng}
              title="Zapier"
              alt="Zapier"
            />
          </picture>
        }
        title="Connect with Zapier"
        subtitle="Connect your tools to automatically import your contacts into folk (from Calendly, Typeform, or 500+)."
        action={
          <Button
            fullWidth
            variant="neutral"
            onClick={() => {
              openIntegrationPanel(true);
            }}
          >
            Connect integration
          </Button>
        }
      />

      <Suspense fallback={null}>
        <LazyIntegrationPanel />
      </Suspense>
    </>
  );
};
