import { useMemo } from 'react';

export type AppEnv = 'local' | 'develop' | 'production';

export interface AppConfig {
  apiUrl: string;
  apiWsUrl: string;
  userApiUrl: string;
  apolloCacheVersion: string;
  version: string;
  environment: AppEnv;
  googleMapsApiKey: string;
}

const version = process.env.REACT_APP_VERSION ?? '';
const apolloCacheVersion = '1.3.0';

const configByEnv: Record<AppEnv, AppConfig> = {
  local: {
    apiUrl: 'http://localhost:8005',
    apiWsUrl: 'ws://localhost:8007',
    userApiUrl: 'http://localhost:8002',
    version,
    apolloCacheVersion,
    environment: 'local',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
  },
  develop: {
    apiUrl: 'https://gateways-graphql-api.folk.run',
    apiWsUrl: 'wss://fronts-sockets-ws.folk.run',
    userApiUrl: 'https://users-api-main.folk.run',
    version,
    apolloCacheVersion,
    environment: 'develop',
    googleMapsApiKey: 'AIzaSyDaVH1NT2WyNRf1OiiMyhSHSfzjunIJQvA',
  },
  production: {
    apiUrl: 'https://gateways-graphql-api.folk.app',
    apiWsUrl: 'wss://fronts-sockets-ws.folk.app',
    userApiUrl: 'https://users-api-main.folk.app',
    version,
    apolloCacheVersion,
    environment: 'production',
    googleMapsApiKey: 'AIzaSyDaVH1NT2WyNRf1OiiMyhSHSfzjunIJQvA',
  },
};

export const getAppConfig = (env?: AppEnv): AppConfig => {
  if (env) {
    return configByEnv[env];
  }

  const host = window.location.host;

  if (host === 'app.folk.app') {
    return configByEnv.production;
  }

  if (host === 'app.folk.run' || process.env.REACT_APP_IS_PREVIEW === 'true') {
    return configByEnv.develop;
  }

  // Allow override in development (local machine)
  // Only API URLs are overridden
  if (
    (process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_IS_FOLKX === 'true') &&
    process.env.REACT_APP_APP_ENV
  ) {
    const { apiUrl, apiWsUrl, userApiUrl } =
      configByEnv[process.env.REACT_APP_APP_ENV as AppEnv];

    return {
      ...configByEnv.local,
      apiUrl,
      apiWsUrl,
      userApiUrl,
    };
  }

  return configByEnv.local;
};

export const appConfig = getAppConfig();

export const useAppConfig = () => useMemo(() => getAppConfig(), []);
