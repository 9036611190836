import { styled } from '@folkapp/design-system';

export const ExternalLink = styled('a', {
  color: '$blue600',
  textDecoration: 'none',
  '&:hover, &:focus': {
    textDecoration: 'underline',
  },
  '&:active': {
    color: '$blue700',
    textDecoration: 'underline',
  },
});

ExternalLink.defaultProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};
