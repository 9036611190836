import { Text } from '@folkapp/design-system';
import { FC } from 'react';

import { PopoverSectionHeaderProps } from '../../PopoverOptions';
import classes from './PopoverSectionHeader.module.css';

export const PopoverSectionHeader: FC<PopoverSectionHeaderProps> = ({
  label,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        <Text variant="caption" truncate css={{ paddingInline: '$2' }}>
          {label ?? null}
        </Text>
      </div>
    </div>
  );
};
