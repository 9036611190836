import {
  CompanyFragment,
  ContactCustomFieldFragment,
  ContactListDocument,
  ContactType,
  CustomFieldFragment,
  CustomFieldType,
  CustomFieldValue,
  FilterAttribute,
  GroupByGroupCustomFieldFragment,
  GroupContactPanelCustomFieldFragment,
  GroupContactPanelCustomFieldFragmentDoc,
  GroupContactPanelSettingsFragment,
  GroupCustomFieldInput,
  GroupCustomFieldValueInput,
  GroupPutCustomFieldValueMutation,
  GroupPutCustomFieldValueMutationVariables,
  GroupViewCustomFieldFragment,
  GroupViewCustomFieldFragmentDoc,
  GroupViewSettingsFragment,
  PersonFragment,
  RankedContactsDocument,
  SelectCustomFieldValueInput,
  useGroupDeleteCustomFieldMutation,
  useGroupDeleteCustomFieldValueMutation,
  useGroupPutCustomFieldMutation,
  useGroupPutCustomFieldValueMutation,
  useUpdateCustomFieldDefinitionMutation,
} from '__generated__/graphql';
import {
  ApolloCache,
  makeReference,
  MutationHookOptions,
  Reference,
  StoreObject,
} from '@apollo/client';
import { Modifier } from '@apollo/client/cache/core/types/common';
import { useShouldRefetchAfterFieldValueUpdate } from 'app/apps/contacts/hooks/contactHooks';
import { getRankedContactsQueryVariables } from 'app/apps/contacts/Kanban/useRankedContactsQuery';
import {
  isSelectCustomFieldValue,
  SelectCustomFieldValue,
} from 'app/services/local/utils';
import { NetworkGroupRouteParams } from 'app/types/networkGroupRouteParams';
import { useGetContactMode } from 'app/utils/contacts';
import { isNotNil, removeTypenameRecursively } from 'app/utils/functions';
import uniqBy from 'lodash/uniqBy';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useViewSettings } from 'view-settings/useViewSettings';

import { CacheEntry } from '../graphQLService/utils/cacheEntry';
import { useUpdateContactFragment, useUpdateContacts } from './contact';

type ModifyOptions<TStoreObject extends StoreObject> = {
  id?: string;
  fields: {
    [TKey in keyof Partial<Omit<TStoreObject, '__typename'>>]: Modifier<
      TStoreObject[TKey]
    >;
  };
  optimistic?: boolean;
  broadcast?: boolean;
};

const modifyCache = <TStoreObject extends StoreObject, TSerialized = object>(
  cache: ApolloCache<TSerialized>,
  options: ModifyOptions<TStoreObject>,
) => cache.modify(options);

// @warning: this is used to udpate CustomField AND/OR ContactCustomFieldValues
// In order to update ContactCustomFieldValues, please use `usePutCustomFieldValue`
export const usePutCustomField = () => {
  const { viewId } = useParams<{ viewId?: string }>();
  const updateContacts = useUpdateContacts();
  const [_putCustomField, { loading }] = useGroupPutCustomFieldMutation();
  const updateContactFragment = useUpdateContactFragment();
  const getShouldRefetch = useShouldRefetchAfterFieldValueUpdate();

  const putCustomField = useCallback(
    (
      payload: GroupCustomFieldInput,
      options: NonNullable<CustomFieldFragment['value']> = [],
      shouldUpdateContactList: boolean = false,
    ) => {
      const computeOptimisticCustomFieldOptions = (
        options: NonNullable<CustomFieldFragment['value']>,
      ) => {
        if (!isSelectCustomFieldValue(payload.value)) return options;

        return options.reduce<NonNullable<CustomFieldFragment['value']>>(
          (acc, option) => {
            if (option.id === payload.value?.id) {
              acc.push({
                __typename: 'SelectCustomFieldValue',
                id: payload.value.id,
                label: payload.value.label!,
                color: payload.value.color!,
              });
            } else acc.push(option);

            return acc;
          },
          [],
        );
      };

      return _putCustomField({
        variables: {
          payload: removeTypenameRecursively(payload),
        },
        optimisticResponse: {
          group__putCustomField: {
            ...payload,
            value: computeOptimisticCustomFieldOptions(options),
            __typename: 'GroupCustomField',
          },
        },
        refetchQueries: (result) => {
          if (result.data?.group__putCustomField?.id) {
            return getShouldRefetch({
              value: '',
              attribute: FilterAttribute.customField,
              customFieldId: result.data.group__putCustomField.id,
            })
              ? [RankedContactsDocument, ContactListDocument]
              : [RankedContactsDocument];
          }
          return [RankedContactsDocument];
        },
        update(cache, { data }) {
          if (!data?.group__putCustomField) {
            return;
          }

          // @note: we need to make sure no more than 1 option is selected.
          // Useful when switching from multiple to single select
          if (payload.type === CustomFieldType.singleSelect) {
            updateContacts<PersonFragment | CompanyFragment>({
              modifier: (contacts) =>
                contacts.map((contact) =>
                  getContactWithSingleSelectCustomField(contact, payload.id),
                ),
            });
          }

          if (payload.contactId) {
            updateContactFragment(
              payload.contactId,
              (contact) => ({
                customFields: addCustomField(
                  contact.customFields ?? [],
                  payload,
                ),
              }),
              payload.contactType === ContactType.person,
            );
          }

          if (shouldUpdateContactList) {
            updateContacts<PersonFragment | CompanyFragment>({
              modifier: (contacts) =>
                contacts.map((contact) =>
                  updateCustomFieldValueInContact({
                    contact,
                    value: payload.value,
                    customFieldId: payload.id,
                  }),
                ),
            });
          }

          upsertCustomFieldToSettings({
            cache,
            contactType: payload.contactType,
            customFieldData: data.group__putCustomField,
            groupId: payload.groupId,
            viewId,
          });
        },
      });
    },
    [
      _putCustomField,
      getShouldRefetch,
      updateContactFragment,
      updateContacts,
      viewId,
    ],
  );

  return { putCustomField, loading };
};

export function useDeleteCustomField() {
  const [_deleteCustomField] = useGroupDeleteCustomFieldMutation();

  const deleteCustomField = useCallback(
    ({
      groupId,
      customFieldId,
      contactType,
    }: {
      groupId: string;
      customFieldId: string;
      contactType: ContactType;
    }) => {
      return _deleteCustomField({
        variables: {
          groupId,
          customFieldId,
          contactType,
        },
        optimisticResponse: {
          group__deleteCustomField: {
            id: customFieldId,
            __typename: 'GroupCustomField',
          },
        },
        update(cache, { data }) {
          if (!data) {
            return;
          }

          removeCustomFieldFromSettings({
            cache,
            customFieldId,
          });
        },
      });
    },
    [_deleteCustomField],
  );

  return deleteCustomField;
}

export function useUpdateCustomFieldDefinition() {
  const [mutation] = useUpdateCustomFieldDefinitionMutation();
  const updateContacts = useUpdateContacts();

  const updateCustomFieldDefinition = useCallback(
    ({
      groupId,
      contactType,
      newLabel,
      newColor,
      groupCustomField,
      groupCustomFieldValue,
      networkId,
      viewSettings,
      query,
    }: {
      groupCustomField: GroupByGroupCustomFieldFragment;
      groupCustomFieldValue: SelectCustomFieldValue;
      newLabel?: string;
      newColor?: string;
      groupId: string;
      contactType: ContactType;
      networkId: string;
      viewSettings: GroupViewSettingsFragment;
      query: string;
    }) => {
      mutation({
        variables: {
          input: {
            customFieldId: groupCustomField.id,
            groupId,
            contactType,
            value: groupCustomField.value!.reduce((acc: any, value: any) => {
              const { __typename, ...valueWithoutTypename } = value;
              acc.push(
                value.id === groupCustomFieldValue.id
                  ? {
                      id: value.id,
                      label: newLabel ?? value.label,
                      color: newColor ?? value.color,
                    }
                  : valueWithoutTypename,
              );
              return acc;
            }, [] as any[]),
          },
        },
        optimisticResponse: {
          updateGroupCustomFieldDefinition: {
            id: groupCustomField.id,
            type: groupCustomField.type,
            name: groupCustomField.name,
            value: groupCustomField.value!.reduce((acc: any, value: any) => {
              const { __typename, ...valueWithoutTypename } = value;
              acc.push(
                value.id === groupCustomFieldValue.id
                  ? {
                      id: value.id,
                      label: newLabel ?? value.label,
                      color: newColor ?? value.color,
                    }
                  : valueWithoutTypename,
              );
              return acc;
            }, [] as any[]),
            __typename: 'GroupCustomField',
          },
        },
        update() {
          updateContacts<PersonFragment | CompanyFragment>({
            modifier: (contacts) =>
              contacts.map((contact) =>
                updateCustomFieldValueInContact({
                  contact,
                  value: {
                    id: groupCustomFieldValue.id,
                    label: newLabel ?? groupCustomFieldValue.label,
                    color: newColor ?? groupCustomFieldValue.color,
                  },
                  customFieldId: groupCustomField.id,
                }),
              ),
          });
        },
        refetchQueries: [
          {
            query: RankedContactsDocument,
            variables: getRankedContactsQueryVariables({
              groupId,
              networkId,
              groupCustomField,
              groupCustomFieldValue,
              viewSettings,
              query,
            }),
          },
        ],
      });
    },
    [mutation, updateContacts],
  );

  return { updateCustomFieldDefinition };
}

export function useDeleteCustomFieldValue() {
  const updateContacts = useUpdateContacts();
  const [_deleteCustomFieldValue] = useGroupDeleteCustomFieldValueMutation();

  const deleteCustomFieldValue = useCallback(
    ({
      groupId,
      customFieldId,
      customFieldValueIds,
      contactType,
    }: {
      groupId: string;
      customFieldId: string;
      customFieldValueIds: string[];
      contactType: ContactType;
    }) => {
      return _deleteCustomFieldValue({
        variables: {
          groupId,
          customFieldId,
          customFieldValueIds,
          contactType,
        },
        update(cache) {
          removeCustomFieldValuesFromSettings({
            cache,
            removedCustomFieldValueIds: customFieldValueIds,
          });

          updateContacts<PersonFragment | CompanyFragment>({
            modifier: (contacts) => {
              return contacts.map((contact) =>
                removeCustomFieldValueFromContact({
                  contact,
                  customFieldId,
                  customFieldValueIds,
                }),
              );
            },
          });
        },
      });
    },
    [_deleteCustomFieldValue, updateContacts],
  );

  return deleteCustomFieldValue;
}

export function useKanbanDeleteCustomFieldValue() {
  const [_deleteCustomFieldValue] = useGroupDeleteCustomFieldValueMutation();

  const deleteCustomFieldValue = useCallback(
    ({
      groupId,
      customFieldId,
      customFieldValueIds,
      contactType,
    }: {
      groupId: string;
      customFieldId: string;
      customFieldValueIds: string[];
      contactType: ContactType;
    }) => {
      return _deleteCustomFieldValue({
        variables: {
          groupId,
          customFieldId,
          customFieldValueIds,
          contactType,
        },
        refetchQueries: [RankedContactsDocument],
      });
    },
    [_deleteCustomFieldValue],
  );

  return deleteCustomFieldValue;
}

const updateCustomFieldValueInContact = ({
  contact,
  value,
  customFieldId,
}: {
  contact: PersonFragment | CompanyFragment;
  value: CustomFieldValue | null | undefined;
  customFieldId: string;
}): PersonFragment | CompanyFragment => {
  const updateValues = (values: CustomFieldValue[]) =>
    values.map((v) => (v?.id === value?.id ? value : v));

  return {
    ...contact,
    customFields: (contact.customFields ?? []).map((f) =>
      f?.field.id === customFieldId
        ? {
            ...f,
            values: updateValues(f.values),
          }
        : f,
    ),
  };
};

const removeCustomFieldValueFromContact = ({
  contact,
  customFieldId,
  customFieldValueIds,
}: {
  contact: PersonFragment | CompanyFragment;
  customFieldId: string;
  customFieldValueIds: string[];
}): PersonFragment | CompanyFragment => {
  const removeValues = (values: CustomFieldValue[]) =>
    values.filter((v) => v.id && !customFieldValueIds.includes(v?.id));

  return {
    ...contact,
    customFields:
      contact.customFields?.map((f) =>
        f?.field.id === customFieldId
          ? {
              ...f,
              values: removeValues(f.values),
            }
          : f,
      ) ?? [],
  };
};

const getContactWithSingleSelectCustomField = (
  contact: PersonFragment | CompanyFragment,
  customFieldId: string,
): PersonFragment | CompanyFragment => {
  const newContact = { ...contact };

  newContact.customFields =
    contact.customFields?.map((customField) => ({
      ...customField,
      values:
        customField?.field?.id === customFieldId
          ? customField?.values.slice(0, 1)
          : customField?.values,
    })) || [];

  return newContact;
};

const addCustomFieldValues = (
  fieldValues: CustomFieldValue[],
  fieldInput: GroupCustomFieldInput,
) => {
  if (fieldInput.value === null || fieldInput.value === undefined) {
    return fieldValues;
  }

  if (
    [CustomFieldType.textField, CustomFieldType.dateField].includes(
      fieldInput.type,
    )
  ) {
    return [fieldInput.value.text];
  }

  if (fieldInput.type === CustomFieldType.singleSelect) {
    return [fieldInput.value];
  }

  return uniqBy(
    [
      ...fieldValues.map((v) =>
        v.id === fieldInput.value!.id ? fieldInput.value! : v,
      ),
      fieldInput.value,
    ],
    'id',
  );
};

const addCustomField = (
  existingFields: ContactCustomFieldFragment[],
  fieldInput: GroupCustomFieldInput,
): ContactCustomFieldFragment[] => {
  let modified = false;

  const updatedFields = existingFields.reduce<ContactCustomFieldFragment[]>(
    (acc, curr) => {
      if (curr.field.id === fieldInput.id) {
        modified = true;

        return acc.concat({
          ...curr,
          values: addCustomFieldValues(curr.values, fieldInput),
        });
      }

      return acc.concat(curr);
    },
    [],
  );
  if (modified) {
    return updatedFields;
  }
  return updatedFields.concat({
    field: {
      id: fieldInput.id,
      __typename: 'Field',
      name: fieldInput.name,
      type: fieldInput.type,
    },
    values: [CustomFieldType.textField, CustomFieldType.dateField].includes(
      fieldInput.type,
    )
      ? [fieldInput.value?.text]
      : [fieldInput.value],
    __typename: 'CustomField',
  });
};

const removeCustomFieldFromSettings = ({
  cache,
  customFieldId,
}: {
  cache: ApolloCache<object>;
  customFieldId: string;
}) => {
  ['GroupViewCustomField', 'GroupContactPanelCustomField'].forEach(
    (__typename) => {
      cache.evict({ id: cache.identify({ __typename, id: customFieldId }) });
    },
  );
};

const upsertCustomFieldToSettings = ({
  cache,
  contactType,
  customFieldData,
  groupId,
  viewId,
}: {
  cache: ApolloCache<object>;
  customFieldData: CustomFieldFragment;
  contactType: ContactType;
  groupId: string;
  viewId?: string;
}) => {
  const options = customFieldData.value
    ? customFieldData.value.map((option) => ({
        __typename: 'SelectCustomFieldValue' as const,
        id: option.id,
        color: option.color,
        label: option.label,
      }))
    : null;

  const appendRef = (refs: Reference[], newRef: Reference) =>
    refs.some((ref) => cache.identify(ref) === cache.identify(newRef))
      ? refs
      : [...refs, newRef];

  // Upsert custom field in group contact panel settings
  const groupContactPanelFieldCacheId = `GroupContactPanelCustomField:${customFieldData.id}`;
  cache.updateFragment<GroupContactPanelCustomFieldFragment>(
    {
      id: groupContactPanelFieldCacheId,
      fragment: GroupContactPanelCustomFieldFragmentDoc,
    },
    (data) => ({
      __typename: 'GroupContactPanelCustomField',
      id: customFieldData.id,
      active: true,
      ...data,
      name: customFieldData.name,
      type: customFieldData.type,
      options,
    }),
  );
  const groupContactPanelSettingsModifier: Modifier<Reference[]> = (fields) =>
    appendRef(fields, makeReference(groupContactPanelFieldCacheId));
  modifyCache<CacheEntry<GroupContactPanelSettingsFragment>>(cache, {
    id: cache.identify({
      __typename: 'GroupContactPanelSettings',
      id: groupId,
    }),
    fields:
      contactType === ContactType.person
        ? { person: groupContactPanelSettingsModifier }
        : { company: groupContactPanelSettingsModifier },
  });

  if (viewId) {
    // Upsert custom field in group view settings
    const groupViewFieldCacheId = `GroupViewCustomField:${customFieldData.id}`;
    cache.updateFragment<GroupViewCustomFieldFragment>(
      {
        id: groupViewFieldCacheId,
        fragment: GroupViewCustomFieldFragmentDoc,
      },
      (data) => ({
        __typename: 'GroupViewCustomField',
        id: customFieldData.id,
        active: true,
        columnWidth: 200,
        ...data,
        name: customFieldData.name,
        type: customFieldData.type,
        options,
      }),
    );
    const groupViewSettingsModifier: Modifier<Reference[]> = (fields) =>
      appendRef(fields, makeReference(groupViewFieldCacheId));
    modifyCache<CacheEntry<GroupViewSettingsFragment>>(cache, {
      id: cache.identify({
        __typename: 'GroupViewSettings',
        id: viewId,
      }),
      fields:
        contactType === ContactType.person
          ? { personFields: groupViewSettingsModifier }
          : { companyFields: groupViewSettingsModifier },
    });
  }
};

const removeCustomFieldValuesFromSettings = ({
  cache,
  removedCustomFieldValueIds,
}: {
  cache: ApolloCache<object>;
  removedCustomFieldValueIds: string[];
}) => {
  removedCustomFieldValueIds.forEach((customFieldValueId) => {
    cache.evict({
      id: cache.identify({
        __typename: 'SelectCustomFieldValue',
        id: customFieldValueId,
      }),
    });
  });
};

const CUSTOM_FIELDS_DEFAULT_NAMES_BY_TYPE: Record<CustomFieldType, string> = {
  contactField: 'Relationships',
  dateField: 'Date',
  multipleSelect: 'Tags',
  singleSelect: 'Status',
  textField: 'Label',
  userField: 'Assign',
};

// handles the default name of the new custom field
export const useCreateCustomField = () => {
  const { data } = useViewSettings();
  const getContactMode = useGetContactMode();
  const { putCustomField } = usePutCustomField();
  const { groupId } = useParams<{ groupId: string }>();

  const getDefaultName = useCallback(
    (type: CustomFieldType, index: number = 0): string => {
      if (!data) {
        throw new Error('ViewSettings not loaded yet');
      }
      const { companyFields, personFields } = data.viewSettings;
      const isPersonMode = getContactMode() === ContactType.person;
      const informationFields = isPersonMode ? personFields : companyFields;
      const defaultName = CUSTOM_FIELDS_DEFAULT_NAMES_BY_TYPE[type];
      const getNameByIndex = (i: number | undefined) =>
        i === undefined || i === 0 ? defaultName : `${defaultName} ${i}`;

      const nameExists = informationFields.some(
        (field) =>
          field.__typename === 'GroupViewCustomField' &&
          field.name === getNameByIndex(index),
      );

      if (nameExists) {
        return getDefaultName(type, index + 1);
      }

      return getNameByIndex(index);
    },
    [data, getContactMode],
  );

  return useCallback(
    (id: string, type: CustomFieldType) => {
      const contactType = getContactMode();
      if (groupId) {
        putCustomField({
          id,
          type,
          contactType,
          groupId,
          name: getDefaultName(type),
        });
      }
    },
    [putCustomField, getDefaultName, getContactMode, groupId],
  );
};

export const useUpdateCustomField = (
  customFieldId: string,
  type: CustomFieldType,
) => {
  const { putCustomField } = usePutCustomField();
  const { groupId } = useParams<NetworkGroupRouteParams>();
  const _deleteCustomField = useDeleteCustomField();
  const getContactMode = useGetContactMode();

  const updateTitle = useCallback(
    (title: string) => {
      if (title.trim() === '' || !groupId) {
        return;
      }
      const contactType = getContactMode();
      putCustomField({
        id: customFieldId,
        type,
        contactType,
        groupId: groupId,
        name: title,
      });
    },
    [putCustomField, customFieldId, type, groupId, getContactMode],
  );

  const updateType = useCallback(
    (title: string, type: CustomFieldType) => {
      if (!groupId) {
        return;
      }

      const contactType = getContactMode();
      putCustomField({
        id: customFieldId,
        type,
        contactType,
        groupId: groupId,
        name: title,
      });
    },
    [putCustomField, customFieldId, groupId, getContactMode],
  );

  // @note: one can wonder if this method should be part of the hook.
  // The hook requires the custom field type, which is not used here.
  const deleteCustomField = useCallback(() => {
    if (!groupId) {
      return;
    }

    const contactType = getContactMode();
    _deleteCustomField({
      groupId: groupId,
      customFieldId,
      contactType,
    });
  }, [_deleteCustomField, groupId, customFieldId, getContactMode]);

  return { updateTitle, updateType, deleteCustomField };
};

export function usePutCustomFieldValue(
  options?: MutationHookOptions<
    GroupPutCustomFieldValueMutation,
    GroupPutCustomFieldValueMutationVariables
  >,
) {
  const { viewId } = useParams<{ viewId?: string }>();
  const [mutation] = useGroupPutCustomFieldValueMutation(options);
  const updateContactFragment = useUpdateContactFragment();

  const putCustomFieldValue = useCallback(
    (
      payload: GroupCustomFieldValueInput,
      {
        name,
        type,
        initialValues,
      }: {
        name: string;
        type: CustomFieldType;
        initialValues: SelectCustomFieldValueInput[];
      },
    ) => {
      mutation({
        variables: {
          payload: removeTypenameRecursively(payload),
        },
        optimisticResponse: {
          groupPutCustomFieldValue: {
            id: payload.customFieldId,
            name,
            type,
            value: uniqBy([...initialValues, ...payload.value], 'id').map(
              (customFieldValue) => ({
                __typename: 'SelectCustomFieldValue',
                id: customFieldValue.id,
                label: customFieldValue.label,
                color: customFieldValue.color,
              }),
            ),
            __typename: 'GroupCustomField',
          },
        },
        update(cache, { data }) {
          if (!data?.groupPutCustomFieldValue) {
            return;
          }
          upsertCustomFieldToSettings({
            cache,
            contactType: payload.contactType,
            customFieldData: data.groupPutCustomFieldValue,
            groupId: payload.groupId,
            viewId,
          });
          // Update contact
          const { id, name, type } = data.groupPutCustomFieldValue;
          const { contactId, contactType } = payload;
          const newContactCustomField: ContactCustomFieldFragment = {
            __typename: 'CustomField',
            field: {
              __typename: 'Field',
              name,
              id,
              type,
            },
            values: payload.value,
          };
          updateContactFragment(
            contactId,
            (contact) => ({
              customFields: upsertContactCustomField(
                contact,
                newContactCustomField,
              ),
            }),
            contactType === ContactType.person,
          );
        },
      });
    },
    [mutation, updateContactFragment, viewId],
  );

  return { putCustomFieldValue };

  function upsertContactCustomField(
    contact: PersonFragment | CompanyFragment,
    newContactCustomField: ContactCustomFieldFragment,
  ): ContactCustomFieldFragment[] {
    const customFields = contact.customFields?.filter(isNotNil) || [];
    if (
      customFields?.some(
        ({ field: { id } }) => id === newContactCustomField.field.id,
      )
    ) {
      return customFields.map((cf) =>
        cf?.field.id === newContactCustomField.field.id
          ? newContactCustomField
          : cf,
      );
    }
    return [...customFields, newContactCustomField];
  }
}
