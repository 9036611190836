import { Flex } from '@designSystem';
import { Box, Input, styled } from '@folkapp/design-system';
import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';

export const FormLabel = styled('label', {
  fontWeight: '$medium',
  marginBottom: '$2',
});

export const FormLegend = styled('legend', {
  fontWeight: '$medium',
  marginBottom: '$2',
});

export const StyledUseTypesFieldset = styled('fieldset', {
  width: '100%',
});

export const FormRadio = styled('label', {
  $$textColor: '$colors$grey700',
  $$borderColor: '$colors$grey200',
  $$iconColor: '$colors$grey600',
  $$shadowColor: '$colors$grey200',
  zIndex: 1,

  border: '1px solid $grey200',
  color: '$$textColor',
  svg: {
    color: '$$iconColor',
    width: '$4',
    height: '$4',
    flexShrink: 0,
  },

  alignItems: 'center',
  borderRadius: '$lg',
  cursor: 'pointer',
  display: 'flex',
  flex: 1,
  gap: '$1_5',
  justifyContent: 'start',
  padding: '$1_5 $2',
  textAlign: 'left',
  transitionDuration: '150ms',
  transitionProperty: 'color, border, boxShadow, backgroundColor',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  width: '100%',
  accentColor: '$colors$grey800',

  '&:hover': {
    backgroundColor: '$grey200',
  },
  '&:focus-within': {
    backgroundColor: '$grey200',
  },
  input: {
    cursor: 'pointer',
  },
  variants: {
    selected: {
      true: {
        borderColor: '$grey900',
        '&:focus-within': {
          backgroundColor: '$white',
        },
      },
    },
  },
});

export const UseTypeFields = styled('fieldset', {
  width: '100%',
});

export const PersonalDetailsFields = <
  T extends { firstName: string; lastName: string },
>({
  register,
  errors,
  firstNameFieldPath,
  lastNameFieldPath,
}: {
  firstNameFieldPath: Path<T>;
  lastNameFieldPath: Path<T>;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
}) => {
  return (
    <UseTypeFields>
      <FormLegend>Personal details</FormLegend>
      <Flex align="center" gap="2">
        <Input
          aria-label="First name"
          autoFocus
          placeholder="First name"
          aria-invalid={!!errors.firstName}
          autoComplete="given-name"
          required
          {...register(firstNameFieldPath, { required: 'first name' })}
        />
        <Input
          aria-label="Last name"
          placeholder="Last name"
          aria-invalid={!!errors.lastName}
          autoComplete="family-name"
          required
          {...register(lastNameFieldPath, { required: 'last name' })}
        />
      </Flex>
    </UseTypeFields>
  );
};

export const NetworkNameField = <T extends { networkName?: string }>({
  register,
  errors,
  networkNameFieldPath,
}: {
  register: UseFormRegister<T>;
  networkNameFieldPath: Path<T>;
  errors: FieldErrors<T>;
}) => {
  return (
    <Box css={{ width: '100%' }}>
      <FormLabel htmlFor="networkName">Choose your workspace name</FormLabel>
      <Box css={{ width: '100%', marginTop: '$1' }}>
        <Input
          id="networkName"
          placeholder="Most people choose their name or team name"
          {...register(networkNameFieldPath, { required: 'network name' })}
          aria-invalid={!!errors.networkName}
          required
        />
      </Box>
    </Box>
  );
};
