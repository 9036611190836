import { GroupPreviewFragment, NetworkFragment } from '__generated__/graphql';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';

import { useCollator } from './useCollator';

export const useSortGroups = () => {
  const collator = useCollator();

  const sort = useCallback(
    <T extends GroupPreviewFragment>(groups: T[]) => {
      return [...groups].sort(
        (g1, g2) =>
          collator.compare(g1.name, g2.name) || collator.compare(g1.id, g2.id),
      );
    },
    [collator],
  );

  return sort;
};

export const useSortNetworkGroups = () => {
  const sortGroups = useSortGroups();

  return useCallback(
    (network: NetworkFragment): GroupPreviewFragment[] => {
      return uniqBy(
        [
          ...sortGroups(network.sharedGroups || []),
          ...sortGroups(network.publicGroups || []),
          ...sortGroups(network.privateGroups || []),
        ],
        'id',
      );
    },
    [sortGroups],
  );
};
