import {
  GroupPreviewFragment,
  GroupViewFragment,
  ListNetworkBasicInfosQuery,
  useGroupListViewsQuery,
  useListNetworkBasicInfosQuery,
} from '__generated__/graphql';
import { QueryResult } from '@apollo/client';
import { REDIRECTION_PATHNAME_KEY } from 'app/apps/routes/utils';
import { useSortNetworkGroups } from 'app/hooks/useSortGroups';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { useNetwork } from '../remote/network';

// Louis-Remi offers one beer to whoever finds why importing
// removeRedirectionUrl from app/apps/routes/utils breaks folkx-shared
// tests, while importing REDIRECTION_PATHNAME_KEY works fine
const removeRedirectionUrl = () => {
  window.sessionStorage.removeItem(REDIRECTION_PATHNAME_KEY);
};

type UseStoredId = () => [string | null, (id: string | null) => void];
function createUseStoreId(key: string): UseStoredId {
  return () => {
    const [currentId, setCurrentId] = useState(() => {
      return localStorage.getItem(key);
    });

    const setStoredCurrentId = (id: string | null) => {
      setCurrentId(id);
      id != null ? localStorage.setItem(key, id) : localStorage.removeItem(key);
    };

    return [currentId, setStoredCurrentId];
  };
}

export const CURRENT_NETWORK_ID_KEY = 'current_network_id';
export const CURRENT_GROUP_ID_KEY = 'current_group_id';
export const CURRENT_VIEW_ID_KEY = 'current_view_id';
export const useStoredCurrentNetworkId = createUseStoreId(
  CURRENT_NETWORK_ID_KEY,
);
export const useStoredCurrentGroupId = createUseStoreId(CURRENT_GROUP_ID_KEY);
export const useStoredCurrentViewId = createUseStoreId(CURRENT_VIEW_ID_KEY);

type UseDefaultNetwork = () => Pick<QueryResult, 'loading' | 'error'> & {
  defaultNetwork: ListNetworkBasicInfosQuery['listNetworkBasicInfos'][0] | null;
  isIdInRouteInvalid: boolean;
};
export const useDefaultNetwork: UseDefaultNetwork = () => {
  const { networkId } = useParams<{ networkId?: string }>();
  const [storedCurrentNetworkId, setCurrentNetworkId] =
    useStoredCurrentNetworkId();

  const { data, loading, error } = useListNetworkBasicInfosQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const networkList = data?.listNetworkBasicInfos;

  const defaultNetwork = useMemo(() => {
    if (!networkList) {
      return null;
    }

    const currentNetworkId = networkId ?? storedCurrentNetworkId;
    const currentNetwork =
      currentNetworkId != null &&
      networkList.find(({ id }) => id === currentNetworkId);

    // If the stored network id is in the list of networks, use it.
    if (currentNetwork) {
      if (networkId != null && networkId !== storedCurrentNetworkId) {
        setCurrentNetworkId(networkId);
      }
      return currentNetwork;
    }

    // the id in params is wrong → drop the redirection url
    removeRedirectionUrl();

    // otherwise, use the first network in the list.
    if (networkList[0]) {
      networkList[0].id !== storedCurrentNetworkId &&
        setCurrentNetworkId(networkList[0].id);
      return networkList[0];
    }

    storedCurrentNetworkId != null && setCurrentNetworkId(null);
    return null;
  }, [setCurrentNetworkId, networkList, networkId, storedCurrentNetworkId]);

  return {
    loading,
    error,
    defaultNetwork,
    isIdInRouteInvalid:
      networkId != null && !loading && networkId !== defaultNetwork?.id,
  };
};

type UseDefaultNetworkId = () => Pick<QueryResult, 'loading' | 'error'> & {
  defaultNetworkId: string | null;
  isIdInRouteInvalid: boolean;
};
export const useDefaultNetworkId: UseDefaultNetworkId = () => {
  const { loading, error, defaultNetwork, isIdInRouteInvalid } =
    useDefaultNetwork();

  return {
    loading,
    error,
    defaultNetworkId: defaultNetwork?.id || null,
    isIdInRouteInvalid,
  };
};

type UseDefaultGroup = () => Pick<QueryResult, 'loading' | 'error'> & {
  defaultGroup: GroupPreviewFragment | null;
  isIdInRouteInvalid: boolean;
};
export const useDefaultGroup: UseDefaultGroup = () => {
  const { networkId, groupId } =
    useParams<{ networkId: string; groupId?: string }>();
  const [storedCurrentGroupId, setCurrentGroupId] = useStoredCurrentGroupId();
  const sortNetworkGroups = useSortNetworkGroups();

  const { data, loading, error } = useNetwork(networkId, {
    skip: networkId == null || networkId === '',
  });
  const network = data?.network;

  // If a contact is orphan, we should still be able to display it in our Empty State.
  // N.B: Removing this will cause a redirection loop on orphan contacts.
  const defaultGroup = useMemo(() => {
    if (!network) {
      return null;
    }

    const currentGroupId = groupId ?? storedCurrentGroupId;
    const sortedGroups = sortNetworkGroups(network);
    const currentGroup =
      currentGroupId != null &&
      sortedGroups.find(({ id }) => id === currentGroupId);

    if (currentGroup) {
      groupId != null &&
        groupId !== storedCurrentGroupId &&
        setCurrentGroupId(currentGroup.id);
      return currentGroup;
    }

    // the id in params is wrong → drop the redirection url
    removeRedirectionUrl();

    if (sortedGroups[0]) {
      sortedGroups[0].id !== storedCurrentGroupId &&
        setCurrentGroupId(sortedGroups[0].id);
      return sortedGroups[0];
    }

    storedCurrentGroupId != null && setCurrentGroupId(null);
    return null;
  }, [
    sortNetworkGroups,
    setCurrentGroupId,
    network,
    groupId,
    storedCurrentGroupId,
  ]);

  return {
    loading,
    error,
    defaultGroup,
    isIdInRouteInvalid:
      groupId != null &&
      groupId !== '' &&
      !loading &&
      groupId !== defaultGroup?.id,
  };
};

type UseDefaultGroupId = () => Pick<QueryResult, 'loading' | 'error'> & {
  defaultGroupId: string | null;
  isIdInRouteInvalid: boolean;
};
export const useDefaultGroupId: UseDefaultGroupId = () => {
  const { loading, error, defaultGroup, isIdInRouteInvalid } =
    useDefaultGroup();

  return {
    loading,
    error,
    defaultGroupId: defaultGroup?.id || null,
    isIdInRouteInvalid,
  };
};

type UseDefaultView = () => Pick<QueryResult, 'loading' | 'error'> & {
  defaultView: GroupViewFragment | null;
  isIdInRouteInvalid: boolean;
};
export const useDefaultView: UseDefaultView = () => {
  const { groupId, viewId } = useParams<{ groupId: string; viewId?: string }>();
  const [storedCurrentViewId, setCurrentViewId] = useStoredCurrentViewId();
  const { loading, error, data } = useGroupListViewsQuery({
    variables: {
      groupId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: groupId == null || groupId === '',
  });
  const views = data?.views;

  const defaultView = useMemo(() => {
    if (!views) {
      return null;
    }

    const currentViewId = viewId ?? storedCurrentViewId;
    const currentView =
      currentViewId && views.find(({ id }) => id === currentViewId);

    if (currentView) {
      viewId != null &&
        viewId !== storedCurrentViewId &&
        setCurrentViewId(currentView.id);
      return currentView;
    }

    // the id in params is wrong → drop the redirection url
    removeRedirectionUrl();

    if (views[0]) {
      views[0].id !== storedCurrentViewId && setCurrentViewId(views[0].id);
      return views[0];
    }

    storedCurrentViewId != null && setCurrentViewId(null);
    return null;
  }, [setCurrentViewId, storedCurrentViewId, views, viewId]);

  return {
    loading,
    error,
    defaultView,
    isIdInRouteInvalid:
      viewId != null && viewId !== '' && !loading && viewId !== defaultView?.id,
  };
};

type UseDefaultViewId = () => Pick<QueryResult, 'loading' | 'error'> & {
  defaultViewId: string | null;
  isIdInRouteInvalid: boolean;
};
export const useDefaultViewId: UseDefaultViewId = () => {
  const { loading, error, defaultView, isIdInRouteInvalid } = useDefaultView();

  return {
    loading,
    error,
    defaultViewId: defaultView?.id || null,
    isIdInRouteInvalid,
  };
};

// logas token
const LOGAS_EMAIL_KEY = 'logas_email';

export const getLogasEmail = () => localStorage.getItem(LOGAS_EMAIL_KEY);

export const setLogasEmail = (email: string) =>
  localStorage.setItem(LOGAS_EMAIL_KEY, email);
