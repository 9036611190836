import {
  UpdateGroupContactPanelSettingsInput,
  useUpdateGroupContactPanelSettingsMutation,
} from '__generated__/graphql';
import { useGroupContactPanelSettings } from 'group-contact-panel-settings/useGroupContactPanelSettings';
import { useCallback } from 'react';

import { getToggledSettings } from '../components/ContactDetails/components/VisibleFields/utils';

export const useToggleContactPanelActiveField = ({
  groupId,
  isPerson,
}: {
  groupId?: string;
  isPerson: boolean;
}) => {
  const { data } = useGroupContactPanelSettings();
  const settings = data?.groupContactPanelSettings;

  const [updateGroupContactPanelSettings] =
    useUpdateGroupContactPanelSettingsMutation();

  const handleToggleActive = useCallback(
    (fieldId: string) => {
      if (!groupId) {
        console.error("Can't toggle active field without groupId");
        return;
      }

      if (settings) {
        const groupContactPanelSettings = getToggledSettings(
          fieldId,
          settings,
          isPerson,
        );

        const input: UpdateGroupContactPanelSettingsInput = {
          groupId,
          person: groupContactPanelSettings.person.map((field) => ({
            id: field.id,
            active: field.active,
          })),
          company: groupContactPanelSettings.company.map((field) => ({
            id: field.id,
            active: field.active,
          })),
        };

        updateGroupContactPanelSettings({
          variables: {
            input,
          },
          optimisticResponse: {
            updateGroupContactPanelSettings: groupContactPanelSettings,
          },
        });
      }
    },
    [settings, isPerson, groupId, updateGroupContactPanelSettings],
  );

  return handleToggleActive;
};
