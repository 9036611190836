import {
  ContactType,
  GroupListViewsDocument,
  GroupViewCustomFieldFragment,
  GroupViewNativeFieldFragment,
  GroupViewSettingsFragment,
  useGroupUpdateSettingsMutation,
} from '__generated__/graphql';
import { reducer, UserConfigAction } from 'app/services/local/userConfig';
import { Filter } from 'app/types';
import { NetworkGroupRouteParams } from 'app/types/networkGroupRouteParams';
import { removeTypenameRecursively } from 'app/utils/functions';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useViewSettings } from 'view-settings/useViewSettings';

export const useConfigActions = () => {
  const { groupId } = useParams<NetworkGroupRouteParams>();
  const { data } = useViewSettings();
  const [updateGroupSettings] = useGroupUpdateSettingsMutation();

  /**
   * Function that updates the settings of a group with an action
   * and using the UserConfig reducer.
   */
  const updateSettings = useCallback(
    (action: UserConfigAction, settings: GroupViewSettingsFragment) => {
      const newConfig = reducer(settings, action);
      const payload = removeTypenameRecursively(newConfig);

      updateGroupSettings({
        variables: {
          payload: {
            groupId: groupId,
            id: payload.id,
            filters: payload.filters,
            orderFields: payload.orderFields,
            personFields: payload.personFields
              ? payload.personFields.map((field) => ({
                  id: field.id,
                  active: field.active,
                  columnWidth: field.columnWidth,
                }))
              : undefined,
            companyFields: payload.companyFields
              ? payload.companyFields.map((field) => ({
                  id: field.id,
                  active: field.active,
                  columnWidth: field.columnWidth,
                }))
              : undefined,
          },
        },
        // Changes on filters and display might change the totalCount on the group view
        refetchQueries: [
          { query: GroupListViewsDocument, variables: { groupId } },
        ],
        optimisticResponse: {
          settings: newConfig,
        },
      });
    },
    [updateGroupSettings, groupId],
  );

  const groupSettingsActions = {
    updateContactFields: useCallback(
      (
        fields: (GroupViewNativeFieldFragment | GroupViewCustomFieldFragment)[],
      ) => {
        if (data) {
          updateSettings(
            {
              type:
                data.viewSettings.display === ContactType.company
                  ? 'UPDATE_COMPANY_CONTACT_FIELDS'
                  : 'UPDATE_PERSON_CONTACT_FIELDS',
              payload: fields,
            },
            data.viewSettings,
          );
        }
      },
      [data, updateSettings],
    ),
    updateOrderFields: useCallback(
      (fields: GroupViewSettingsFragment['orderFields']) => {
        if (data) {
          updateSettings(
            {
              type: 'UPDATE_ORDER_FIELDS',
              payload: fields,
            },
            data.viewSettings,
          );
        }
      },
      [data, updateSettings],
    ),
    updateFilters: useCallback(
      (filters: Filter[]) => {
        if (data) {
          updateSettings(
            {
              type: 'UPDATE_FILTERS',
              payload: filters,
            },
            data.viewSettings,
          );
        }
      },
      [data, updateSettings],
    ),
    updatePersonFields: useCallback(
      (
        fields: (GroupViewNativeFieldFragment | GroupViewCustomFieldFragment)[],
      ) => {
        if (data) {
          updateSettings(
            {
              type: 'UPDATE_PERSON_FIELDS',
              payload: fields,
            },
            data.viewSettings,
          );
        }
      },
      [data, updateSettings],
    ),
    updateCompanyFields: useCallback(
      (
        fields: (GroupViewNativeFieldFragment | GroupViewCustomFieldFragment)[],
      ) => {
        if (data) {
          updateSettings(
            {
              type: 'UPDATE_COMPANY_FIELDS',
              payload: fields,
            },
            data.viewSettings,
          );
        }
      },
      [data, updateSettings],
    ),
    toggleVisibleField: useCallback(
      (id: string) => {
        if (data) {
          const fields =
            data.viewSettings.display === ContactType.company
              ? data.viewSettings.companyFields
              : data.viewSettings.personFields;
          const payload = fields.map(
            (
              f: GroupViewNativeFieldFragment | GroupViewCustomFieldFragment,
            ) => ({
              ...f,
              active: f.id === id ? !f.active : f.active,
            }),
          );
          updateSettings(
            {
              type:
                data.viewSettings.display === ContactType.company
                  ? 'UPDATE_COMPANY_CONTACT_FIELDS'
                  : 'UPDATE_PERSON_CONTACT_FIELDS',
              payload,
            },
            data.viewSettings,
          );
        }
      },
      [data, updateSettings],
    ),
  };

  return groupSettingsActions;
};
