import { ContactMultiValueType, ContactType } from '__generated__/graphql';
import { createContext, ReactNode, useContext, useMemo } from 'react';

import {
  BirthdayFormMethods,
  useBirthdayFormMethods,
} from './Fields/Birthday/useBirthdayFormMethods';
import {
  CompaniesFormMethods,
  useCompaniesFormMethods,
} from './Fields/Companies/useCompaniesFormMethods';
import {
  GenderFormMethods,
  useGenderFormMethods,
} from './Fields/Gender/useGenderFormMethods';
import {
  JobTitleFormMethods,
  useJobTitleFormMethods,
} from './Fields/JobTitle/useJobTitleFormMethods';
import {
  NotesFormMethods,
  useNotesFormMethods,
} from './Fields/Notes/useNotesFormMethods';
import {
  MultiValueFormMethods,
  useContactMultiValueFormMethods,
} from './Fields/useContactMultiValueFormMethods';

type ContactFormMethods = {
  emails: MultiValueFormMethods;
  phones: MultiValueFormMethods;
  addresses: MultiValueFormMethods;
  urls: MultiValueFormMethods;
} & GenderFormMethods &
  NotesFormMethods &
  JobTitleFormMethods &
  BirthdayFormMethods &
  CompaniesFormMethods;

export const ContactFormContext = createContext<ContactFormMethods | null>(
  null,
);

export const ContactEditionFormProvider = ({
  children,
  ...context
}: {
  networkId: string;
  contactId: string;
  contactType: ContactType;
  children: ReactNode;
}) => {
  const emailsFormMethods = useContactMultiValueFormMethods({
    ...context,
    type: ContactMultiValueType.email,
  });
  const phonesFormMethods = useContactMultiValueFormMethods({
    ...context,
    type: ContactMultiValueType.phone,
  });
  const urlsFormMethods = useContactMultiValueFormMethods({
    ...context,
    type: ContactMultiValueType.url,
  });
  const addressesFormMethods = useContactMultiValueFormMethods({
    ...context,
    type: ContactMultiValueType.address,
  });
  const genderFormMethods = useGenderFormMethods(context);
  const notesFormMethods = useNotesFormMethods(context);
  const jobTitleFormMethods = useJobTitleFormMethods(context);
  const birthdayFormMethods = useBirthdayFormMethods(context);
  const companiesFormMethods = useCompaniesFormMethods(context);
  const value = useMemo<ContactFormMethods>(
    () => ({
      emails: emailsFormMethods,
      phones: phonesFormMethods,
      addresses: addressesFormMethods,
      urls: urlsFormMethods,
      ...genderFormMethods,
      ...notesFormMethods,
      ...jobTitleFormMethods,
      ...birthdayFormMethods,
      ...companiesFormMethods,
    }),
    [
      emailsFormMethods,
      phonesFormMethods,
      urlsFormMethods,
      addressesFormMethods,
      genderFormMethods,
      notesFormMethods,
      jobTitleFormMethods,
      birthdayFormMethods,
      companiesFormMethods,
    ],
  );
  return (
    <ContactFormContext.Provider value={value}>
      {children}
    </ContactFormContext.Provider>
  );
};

export const useContactFormContext = () => {
  const context = useContext(ContactFormContext);
  if (!context) {
    throw new Error(
      'useContactFormContext must be used within a ContactFormProvider',
    );
  }
  return context;
};
