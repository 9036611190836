import memoizeFormatConstructor from 'intl-format-cache';

import { Locale } from '../types';

const getDateTimeFormat = memoizeFormatConstructor(Intl.DateTimeFormat);
const getRelativeTimeFormat = memoizeFormatConstructor(Intl.RelativeTimeFormat);

export const formatDate = ({
  date,
  locale,
  options,
}: {
  date: Date;
  locale: Locale;
  options?: Intl.DateTimeFormatOptions;
}): string => getDateTimeFormat(locale, options).format(date);

type TimeUnit =
  | 'seconds'
  | 'minutes'
  | 'hours'
  | 'days'
  | 'weeks'
  | 'months'
  | 'years';

// thresholds before going to the next unit
const DIVISIONS: Array<{ amount: number; unit: TimeUnit }> = [
  { amount: 60, unit: 'seconds' },
  { amount: 60, unit: 'minutes' },
  { amount: 24, unit: 'hours' },
  { amount: 7, unit: 'days' },
  { amount: 4.34524, unit: 'weeks' },
  { amount: 12, unit: 'months' },
  { amount: Number.POSITIVE_INFINITY, unit: 'years' },
];

// loosely inspired from https://blog.webdevsimplified.com/2020-07/relative-time-format/
function getDateDurationAndUnit(
  date: Date,
): [duration: number, unit: TimeUnit] {
  let duration = (date.getTime() - new Date().getTime()) / 1000;

  for (const division of DIVISIONS) {
    if (Math.abs(duration) < division.amount) {
      return [Math.round(duration), division.unit];
    }

    duration /= division.amount;
  }

  // just to please TS, this will never be called
  return [duration, 'seconds'];
}

export const formatDateDistance = ({
  date,
  locale,
  options,
}: {
  date: Date;
  locale: Locale;
  options?: Intl.RelativeTimeFormatOptions;
}): string => {
  return getRelativeTimeFormat(locale, {
    numeric: 'auto',
    ...options,
  }).format(...getDateDurationAndUnit(date));
};
