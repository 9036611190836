import {
  UserFragment,
  useTrackSegmentEventMutation,
  useUpdateUserMutation,
} from '__generated__/graphql';
import { Flex } from '@designSystem';
import { Box, Button, useToasts } from '@folkapp/design-system';
import { LayoutOnboarding } from 'app/apps/onboarding/LayoutOnboarding';
import { getStepUrl } from 'app/apps/routes/onboarding/OnboardingRoutingUrls';
import {
  useInviteMember,
  useQueryUserByEmail,
} from 'app/apps/userInvite/hooks';
import { useAddExternalUser } from 'app/apps/userInvite/useAddExternalUser';
import { isValidEmail } from 'app/utils/functions';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import StepTwoBackground from 'static/images/bg_step2.svg';

import { InviteInput } from './components/InviteInput';
import { InviteResults } from './components/InviteResults';
import { ListMembers } from './components/ListMembers';

export const InviteTeammates = ({
  numberOfSteps,
  currentStep,
  nextStep,
  isReferred,
}: {
  numberOfSteps: number;
  currentStep: number;
  nextStep: number;
  isReferred: boolean;
}) => {
  const { networkId } = useParams<{ networkId: string }>();
  const [query, setQuery] = useState('');
  const inviteMember = useInviteMember(networkId);
  const [user, isLoadingUser] = useQueryUserByEmail(query);
  const [updateUser] = useUpdateUserMutation();
  const history = useHistory();
  const [trackEvent] = useTrackSegmentEventMutation();
  const addExternalUser = useAddExternalUser();
  const { addToast } = useToasts();

  const handleInviteMember = useCallback(
    async (user: UserFragment) => {
      setQuery('');
      await inviteMember(user);
    },
    [inviteMember],
  );

  const handleSubmit = useCallback(
    (email: string) => {
      if (user?.id) {
        handleInviteMember(user);
      } else {
        if (isValidEmail(email) && !isLoadingUser) {
          addExternalUser({
            email,
            networkId,
            onCompleted: () => {
              setQuery('');
              addToast(`Invitation sent to ${email}`, {
                variant: 'success',
              });
            },
          });
        }
      }
    },
    [
      addExternalUser,
      handleInviteMember,
      isLoadingUser,
      user,
      networkId,
      addToast,
    ],
  );

  return (
    <LayoutOnboarding
      numberOfSteps={numberOfSteps}
      currentStep={currentStep}
      backgroundImg={StepTwoBackground}
      title="Invite your teammates"
      subTitle="To use folk as a team, invite your teammates and start collaborating on your workspace."
    >
      <Flex
        direction="column"
        justify="between"
        align="center"
        css={{
          position: 'relative',
          height: '100%',
          width: '100%',
          maxWidth: '500px',
        }}
      >
        <Flex
          css={{
            width: '100%',
            flexGrow: 1,
          }}
          direction="column"
          align="stretch"
        >
          <Box
            css={{
              overflow: 'visible',
              width: '100%',
              transition: '0.1s',
              borderRadius: '$lg',
              backgroundColor: '$white',
              padding: '$1',
              marginBottom: '$2',
              '&:focus-within': {
                boxShadow: '$sm',
              },
            }}
          >
            <InviteInput
              autoFocus
              id="user-invite-search-field"
              value={query}
              placeholder="Add your teammate email here..."
              aria-label="Add new members"
              onChange={setQuery}
              onClear={() => setQuery('')}
              onSubmit={handleSubmit}
            />
            <InviteResults
              query={query}
              user={user}
              onSelectUser={handleInviteMember}
              onSelectExternalUser={(email) =>
                addExternalUser({ email, networkId })
              }
              isLoading={isLoadingUser}
              networkId={networkId}
            />
          </Box>
          <ListMembers networkId={networkId} />
        </Flex>

        <Button
          disabled={isLoadingUser}
          onClick={async () => {
            trackEvent({
              variables: {
                payload: {
                  event: 'onboarding_step_3_reached',
                  properties: {
                    networkId,
                  },
                },
              },
            });

            await updateUser({
              variables: {
                payload: {
                  onboardingStep: nextStep,
                },
              },
            });

            history.push(getStepUrl({ networkId, step: nextStep, isReferred }));
          }}
          css={{ padding: '$2 $3' }}
        >
          Next
        </Button>
      </Flex>
    </LayoutOnboarding>
  );
};
