import { useGoogleAddSourceMutation } from '__generated__/graphql';
import { useToasts } from '@folkapp/design-system';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { GoogleSourceConnectTutorial } from './GoogleSourceConnectTutorial';

export type GoogleSourceContext =
  | 'onboarding'
  | 'userSettings'
  | 'commandPanel'
  | 'interactions';

type GoogleSourceOptions = {
  showTutorial: boolean;
};

type GoogleSourceState = {
  context: GoogleSourceContext;
  networkId: string;
  connect: (options?: Partial<GoogleSourceOptions>) => void;
  isConnecting: boolean;
};

const providerContext = createContext<GoogleSourceState | null>(null);

export const GoogleSourceProvider = ({
  context,
  networkId,
  children,
}: {
  networkId: string;
  context: GoogleSourceContext;
  children: ReactNode;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const { addToast } = useToasts();
  const { pathname, search, hash } = useLocation();

  const [connect, { loading: isConnecting }] = useGoogleAddSourceMutation({
    fetchPolicy: 'no-cache',
    variables: {
      networkId,
      redirectUrl:
        context === 'onboarding'
          ? `/onboarding/${networkId}/import`
          : `${pathname}${search}${hash}`,
    },
    onCompleted: (data) => {
      if (data) {
        window.location.assign(data?.url || '/');
      }
    },
    onError: (error) => {
      addToast('Something went wrong', {
        variant: 'error',
      });
      console.error(error);
    },
  });

  const connectCallback = useCallback(
    (options?: Partial<GoogleSourceOptions>) => {
      options?.showTutorial ? setIsOpen(true) : connect();
    },
    [setIsOpen, connect],
  );

  return (
    <providerContext.Provider
      value={{
        context,
        networkId,
        connect: connectCallback,
        isConnecting,
      }}
    >
      <GoogleSourceConnectTutorial
        isOpen={isOpen}
        onClick={connect}
        onOpenChange={setIsOpen}
      />
      {children}
    </providerContext.Provider>
  );
};

export function useGoogleSource(): GoogleSourceState {
  const context = useContext(providerContext);
  if (!context) {
    throw new Error(
      'useGoogleSource must be used within a GoogleSourceProvider',
    );
  }

  return context;
}
