import { Text } from '@folkapp/design-system';
import { FC, LabelHTMLAttributes, ReactNode } from 'react';

export type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  icon?: ReactNode;
};

const Label: FC<React.PropsWithChildren<LabelProps>> = ({
  children,
  icon,
  ...props
}) => {
  return (
    <Text
      as="label"
      css={{
        userSelect: 'none',
        padding: '$1',
        display: 'flex',
        alignItems: 'center',
        svg: {
          width: 16,
          height: 16,
          marginRight: '$1',
          color: '$grey600',
        },
      }}
      variant="textMedium"
      {...props}
    >
      {icon}
      {children}
    </Text>
  );
};

export { Label };
