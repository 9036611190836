import {
  SetPrimaryPersonCompanyMutation,
  SetPrimaryPersonCompanyMutationVariables,
  useSetPrimaryPersonCompanyMutation,
} from '__generated__/graphql';
import { gql, MutationHookOptions } from '@apollo/client';
import { updateContactNativeFieldModifier } from 'app/services/remote/contact/contactNativeFieldModifiers';

gql`
  mutation setPrimaryPersonCompany($input: PersonCompanyInput!) {
    personCompany: setPrimaryPersonCompany(input: $input) {
      personId
      companyId
      person {
        id
      }
      company {
        id
      }
      isPrimary
    }
  }
`;

export const useSetPrimaryPersonCompany = (
  options?: Omit<
    MutationHookOptions<
      SetPrimaryPersonCompanyMutation,
      SetPrimaryPersonCompanyMutationVariables
    >,
    'update'
  >,
) => {
  return useSetPrimaryPersonCompanyMutation({
    ...options,
    update: (cache, { data }) => {
      const personCompany = data?.personCompany;
      if (!personCompany) {
        return;
      }

      cache.modify({
        id: cache.identify({
          __typename: 'Person',
          id: personCompany.personId,
        }),
        fields: {
          companies: updateContactNativeFieldModifier({
            cache,
            nativeField: personCompany,
          }),
        },
      });
    },
  });
};
