import { useUserLoginTokenQuery } from '__generated__/graphql';
import { SkeletonConnectedApp } from 'app/Components/Loaders';
import { useSignInWithAccessToken } from 'app/services/graphQLService';
import { useSearchParams } from 'app/utils/useSearchParams';
import { FC } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

export const OAuthRedirection: FC = () => {
  const { email, token } = useParams<{ email: string; token: string }>();
  const searchParams = useSearchParams();
  const redirectUrl = searchParams.get('redirect_url');
  const history = useHistory();
  const signInWithAccessToken = useSignInWithAccessToken(
    redirectUrl ?? undefined,
  );

  useUserLoginTokenQuery({
    variables: {
      email,
      token,
    },
    onCompleted: (data) => {
      const token = data.infos?.oauthToken;

      if (token === undefined || token === '') {
        console.error(`Expected a token, got: ${token}`);
        return history.push('/auth/login?error=true');
      }

      signInWithAccessToken(token);
    },
    onError: (error) => {
      console.error(error);
      history.push('/auth/login?error=true');
    },
  });

  return <SkeletonConnectedApp />;
};
