import { Button, GmailIcon, LinkedinRoundedIcon } from '@folkapp/design-system';
import onboardingSourceFolkx from 'static/images/onboarding_source_folkx.avif';
import onboardingSourceFolkxPng from 'static/images/onboarding_source_folkx.png';

import { SingleSourceOption } from './SingleSourceOption';

export const FolkXSourceOption = () => (
  <SingleSourceOption
    illustration={
      <picture>
        <source srcSet={onboardingSourceFolkx} type="image/avif" />
        <img
          width={288}
          height={209}
          src={onboardingSourceFolkxPng}
          title="folkX"
          alt="folkX"
        />
      </picture>
    }
    title="Download folkX for Chrome"
    subtitle="Import and edit contacts from any LinkedIn profile page or any Gmail conversation."
    logos={
      <>
        <LinkedinRoundedIcon size={24} />
        <GmailIcon size={24} />
      </>
    }
    action={
      <Button asChild fullWidth variant="neutral">
        <a
          href="https://chrome.google.com/webstore/detail/folkx/akeepikolhaikilagiekmegfhefcbohd"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download extension
        </a>
      </Button>
    }
  />
);
