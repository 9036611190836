import { FC, lazy, Suspense } from 'react';

import { DatepickerProps } from './Datepicker';
import styles from './Datepicker.module.css';

const LazyDatepicker = lazy(() =>
  import(/* webpackChunkName: "Datepicker" */ './Datepicker').then(
    (module) => ({
      default: module.Datepicker,
    }),
  ),
);

export const Datepicker: FC<DatepickerProps> = (props) => (
  <Suspense fallback={<div className={styles.fallback} />}>
    <LazyDatepicker {...props} />
  </Suspense>
);

export { parseIncompleteDate } from './utils';
