import {
  AddCompanyValueMutation,
  AddCompanyValueMutationVariables,
  AddPersonValueMutation,
  AddPersonValueMutationVariables,
  ContactType,
  useAddCompanyValueMutation,
  useAddPersonValueMutation,
} from '__generated__/graphql';
import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  MutationUpdaterFunction,
} from '@apollo/client';
import { useCallback } from 'react';

import { updateContactNativeFieldModifier } from '../contactNativeFieldModifiers';

const contactPropertyByType = {
  address: 'addresses',
  email: 'emails',
  phone: 'phones',
  url: 'urls',
} as const;

export const useContactAddValue = ({
  contactType,
  options,
}: {
  contactType: ContactType;
  options?: MutationHookOptions<
    AddPersonValueMutation | AddCompanyValueMutation,
    AddPersonValueMutationVariables | AddCompanyValueMutationVariables
  >;
}) => {
  const update = useCallback<
    MutationUpdaterFunction<
      AddPersonValueMutation | AddCompanyValueMutation,
      AddPersonValueMutationVariables | AddCompanyValueMutationVariables,
      DefaultContext,
      ApolloCache<any>
    >
  >(
    (cache, { data }, { variables }) => {
      if (!variables || !data?.multiValue) {
        return;
      }

      const contactProperty = contactPropertyByType[variables.input.type];

      cache.modify({
        id: cache.identify({
          __typename: contactType === ContactType.person ? 'Person' : 'Company',
          id: variables?.input.contactId,
        }),
        fields: {
          [contactProperty]: updateContactNativeFieldModifier({
            cache,
            nativeField: data.multiValue,
          }),
        },
      });
    },
    [contactType],
  );

  const addPersonValueMutation = useAddPersonValueMutation({
    update,
    ...options,
  });
  const addCompanyValueMutation = useAddCompanyValueMutation({
    update,
    ...options,
  });

  return contactType === ContactType.person
    ? addPersonValueMutation
    : addCompanyValueMutation;
};
