import { useAppConfig } from 'appConfig';
import { useMemo } from 'react';
import faviconDarkPng from 'static/images/favicon-dark.png';
import faviconDarkSvg from 'static/images/favicon-dark.svg';
import faviconDarkDevelopPng from 'static/images/favicon-dark-develop.png';
import faviconDarkDevelopSvg from 'static/images/favicon-dark-develop.svg';
import faviconDarkLocalPng from 'static/images/favicon-dark-local.png';
import faviconDarkLocalSvg from 'static/images/favicon-dark-local.svg';
import faviconLightPng from 'static/images/favicon-light.png';
import faviconLightSvg from 'static/images/favicon-light.svg';
import faviconLightDevelopPng from 'static/images/favicon-light-develop.png';
import faviconLightDevelopSvg from 'static/images/favicon-light-develop.svg';
import faviconLightLocalPng from 'static/images/favicon-light-local.png';
import faviconLightLocalSvg from 'static/images/favicon-light-local.svg';

const defaultFavicons = {
  dark: {
    svg: faviconDarkSvg,
    png: faviconDarkPng,
  },
  light: {
    svg: faviconLightSvg,
    png: faviconLightPng,
  },
};

const faviconsByEnvironment = {
  local: {
    dark: {
      svg: faviconDarkLocalSvg,
      png: faviconDarkLocalPng,
    },
    light: {
      svg: faviconLightLocalSvg,
      png: faviconLightLocalPng,
    },
  },
  develop: {
    dark: {
      svg: faviconDarkDevelopSvg,
      png: faviconDarkDevelopPng,
    },
    light: {
      svg: faviconLightDevelopSvg,
      png: faviconLightDevelopPng,
    },
  },
  folkrun: {
    dark: {
      svg: faviconDarkDevelopSvg,
      png: faviconDarkDevelopPng,
    },
    light: {
      svg: faviconLightDevelopSvg,
      png: faviconLightDevelopPng,
    },
  },
  production: defaultFavicons,
};

export const useFavicons = () => {
  const appConfig = useAppConfig();
  return useMemo(
    () => faviconsByEnvironment[appConfig.environment] ?? defaultFavicons,
    [appConfig.environment],
  );
};
