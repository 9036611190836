import { Flex, Tooltip } from '@designSystem';
import {
  Box,
  IconButton,
  MdAdd,
  MdDragIndicator,
  styled,
} from '@folkapp/design-system';
import { setFocusToContactPanelField } from 'app/apps/contactPanel/utils';
import {
  forwardRef,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const MainContainer = styled('div', {
  display: 'grid',
  flexDirection: 'column',
  flex: 1,
  maxWidth: '100%',
  padding: '$1',
  marginBottom: '$4',
  border: 'solid transparent 1px',
  borderRadius: '$lg',
  backgroundColor: '$white',
  '&:hover, &:focus-within, &:focus': {
    borderColor: '$grey200',
  },

  variants: {
    isFocused: {
      true: {
        borderColor: '$grey200',
      },
    },
  },

  '.dragged-field &': {
    borderColor: '$grey900',
    boxShadow: '$md',
  },
});

const GripIconContainer = styled(Flex, {
  borderRadius: '$md',
  padding: '$1_5',
  margin: '$2 $1',
  color: '$grey600',
  visibility: 'hidden',
  '&:hover, .dragged-field &': {
    backgroundColor: '$grey200',
  },
  '.container:hover > &, .dragged-field &': {
    visibility: 'visible',
  },
});
const GripIcon = () => {
  return (
    <Tooltip content="Drag to move">
      <GripIconContainer data-testid="drag-handle">
        <MdDragIndicator size={12} />
      </GripIconContainer>
    </Tooltip>
  );
};

const LabelContainer = styled(Box, {
  maxWidth: '100%',
  flexShrink: 0,
  border: 'solid transparent 1px',
});

export const ContactPanelFieldFocusableBox = styled(Flex, {
  flex: 1,
  height: '$7',
  border: 'solid transparent 1px',
  borderRadius: '$lg',
  '&:focus-within': {
    borderColor: '$grey900',
  },
});

const Field = forwardRef<
  HTMLDivElement,
  {
    actions?: ReactNode;
    actionsPlaceholder?: ReactNode;
    areActionsPinned?: boolean;
    children: ReactNode;
  }
>(({ children, actions, actionsPlaceholder, areActionsPinned }, ref) => (
  <Flex
    ref={ref}
    role="listitem"
    className="group"
    css={{
      flex: 1,
      position: 'relative',
      minHeight: '$7',
      overflow: 'hidden',
      lineHeight: '$5',
      color: '$grey900',
      borderRadius: '$lg',
      '&:hover:not(:focus-within)': { backgroundColor: '$grey200' },
    }}
  >
    {/* The field content (e.g.: input, textarea, combobox, etc.) */}
    <Flex
      css={{
        flex: 1,
        overflow: 'hidden',
      }}
    >
      {children}
    </Flex>
    {/* Action placeholder for this field that will be displayed when actions are not */}
    {actionsPlaceholder && (
      <Flex
        align="center"
        css={{
          display: !areActionsPinned ? 'flex' : 'none',
          '.group:hover > &, .group:focus-within > &': {
            display: 'none',
          },
        }}
      >
        {actionsPlaceholder}
      </Flex>
    )}
    {/* Actions for this field that will show/hide when hovering the field or when an action is focused */}
    <Flex
      align="center"
      css={{
        display: areActionsPinned ? 'flex' : 'none',
        flexShrink: 0,
        '.group:hover > &, .group:focus-within > &': {
          display: 'flex',
        },
      }}
    >
      {actions}
    </Flex>
  </Flex>
));

export const ContactPanelField = ({
  label,
  children,
  actions,
  actionsPlaceholder,
  areActionsPinned,
  showAddButton,
  onAdd,
  isFocused,
  isDragDisabled,
}: {
  label?: ReactNode;
  actions?: ReactNode;
  actionsPlaceholder?: ReactNode;
  areActionsPinned?: boolean;
  showAddButton?: boolean;
  onAdd?: () => void;
  isFocused?: boolean;
  children: ReactNode;
  isDragDisabled: boolean;
}) => {
  return (
    <Flex align="start" className="container">
      {!isDragDisabled && <GripIcon />}
      <MainContainer isFocused={isFocused}>
        <Flex justify="between">
          <LabelContainer>{label}</LabelContainer>
          {showAddButton && (
            <Box
              css={{
                display: 'none',
                '.container:hover &': { display: 'block' },
              }}
            >
              <IconButton variant="ghost" onClick={onAdd}>
                <MdAdd />
              </IconButton>
            </Box>
          )}
        </Flex>
        <Field
          actions={actions}
          actionsPlaceholder={actionsPlaceholder}
          areActionsPinned={areActionsPinned}
        >
          {children}
        </Field>
      </MainContainer>
    </Flex>
  );
};

export const ContactPanelMultiValueField = ({
  newValueElementRef,
  label,
  values,
  emptyValueField,
  isFocused,
  isDragDisabled,
}: {
  newValueElementRef?: MutableRefObject<HTMLDivElement | null>;
  label?: ReactNode;
  values: {
    key: string;
    field?: ReactNode;
    areActionsPinned?: boolean;
    actions?: ReactNode;
    actionsPlaceholder?: ReactNode;
  }[];
  emptyValueField: ReactNode;
  isFocused?: boolean;
  isDragDisabled: boolean;
}) => {
  const emptyValueFieldContainerRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null);
  const [isEmptyValueFieldVisible, setIsEmptyValueFieldVisible] = useState(
    values.length === 0,
  );

  useEffect(() => {
    if (values.length === 0) {
      setIsEmptyValueFieldVisible(true);
    }
  }, [values.length]);

  useEffect(() => {
    if (isEmptyValueFieldVisible && values.length > 0) {
      setFocusToContactPanelField(emptyValueFieldContainerRef);
    }
  }, [isEmptyValueFieldVisible, values.length]);

  const handleAddValue = useCallback(() => {
    setIsEmptyValueFieldVisible(true);
    setFocusToContactPanelField(emptyValueFieldContainerRef);
  }, []);

  return (
    <Flex
      align="start"
      className="container"
      css={{
        overflow: 'hidden',
      }}
    >
      {!isDragDisabled && <GripIcon />}
      <MainContainer isFocused={isFocused}>
        <Flex justify="between">
          <LabelContainer>{label}</LabelContainer>
          {values.length > 0 && (
            <Box
              css={{
                display: 'none',
                '.container:hover &': { display: 'block' },
              }}
            >
              <IconButton
                aria-label="Add"
                variant="ghost"
                onClick={handleAddValue}
              >
                <MdAdd />
              </IconButton>
            </Box>
          )}
        </Flex>
        {values.map(
          ({ key, field, actions, areActionsPinned, actionsPlaceholder }) => (
            <Field
              key={key}
              actions={actions}
              actionsPlaceholder={actionsPlaceholder}
              areActionsPinned={areActionsPinned}
            >
              {field}
            </Field>
          ),
        )}
        {isEmptyValueFieldVisible && (
          <Field
            ref={(element) => {
              emptyValueFieldContainerRef.current = element;
              if (newValueElementRef) {
                newValueElementRef.current = element;
              }
            }}
          >
            {emptyValueField}
          </Field>
        )}
      </MainContainer>
    </Flex>
  );
};
