import {
  CompanyFragment,
  ContactType,
  CustomFieldType,
  FilterAttribute,
  GroupContactPanelCustomFieldFragment,
  GroupContactPanelNativeFieldFragment,
  GroupViewCustomFieldFragment,
  GroupViewNativeFieldFragment,
  NativeFieldType,
  PersonFragment,
} from '__generated__/graphql';
import {
  GenderIcon,
  InteractionIcon,
  MdAccountCircle,
  MdBusiness,
  MdCake,
  MdEvent,
  MdFormatListBulleted,
  MdLink,
  MdLocationOn,
  MdMail,
  MdNotes,
  MdPeople,
  MdPerson,
  MdPhone,
  MdTextFields,
  MdWatchLater,
  MdWork,
  SingleSelectIcon,
} from '@folkapp/design-system';
import { isNotNil } from 'app/utils/functions';
import { ReactNode, useEffect, useState } from 'react';

export const nativeFields: Record<
  NativeFieldType,
  Record<string, any> & { name: string; icon: ReactNode }
> = {
  notes: {
    name: 'Notes',
    icon: <MdNotes />,
  },
  pGender: {
    name: 'Gender',
    icon: <GenderIcon />,
  },
  pBirthday: {
    name: 'Birthday',
    icon: <MdCake />,
  },
  pJobTitle: {
    name: 'Job title',
    icon: <MdWork />,
  },
  totalInteractions: {
    name: 'Your total interactions',
    icon: <InteractionIcon />,
  },
  lastInteraction: {
    name: 'Your last interaction',
    icon: <MdWatchLater />,
  },
  addresses: {
    name: 'Addresses',
    icon: <MdLocationOn />,
  },
  emails: {
    name: 'Emails',
    icon: <MdMail />,
  },
  urls: {
    name: 'Urls',
    icon: <MdLink />,
  },
  touchpoint: {
    name: 'Strongest connection',
    icon: <InteractionIcon />,
  },
  phones: {
    name: 'Phone numbers',
    icon: <MdPhone />,
  },
  companies: {
    name: 'Companies',
    icon: <MdBusiness />,
  },
  groups: {
    name: 'Groups',
    icon: <MdPeople />,
  },
};

export const isString = (value: unknown): value is string =>
  typeof value === 'string';

export const hasProperty = <T extends object, K extends string>(
  value: T,
  key: K,
): value is T & Record<K, any> => key in value;

export type SelectCustomFieldValue = {
  id: string;
  label: string;
  color: string;
};

export const isSelectCustomFieldValue = (
  value: unknown,
): value is SelectCustomFieldValue => {
  return (
    typeof value === 'object' &&
    value !== null &&
    hasProperty(value, 'id') &&
    isString(value.id) &&
    hasProperty(value, 'label') &&
    isString(value.label) &&
    hasProperty(value, 'color') &&
    isString(value.color)
  );
};

export type ContactCustomFieldValue = {
  id: string;
  contactType: ContactType;
  label: string;
  picture: null | string;
};

export const isContactCustomFieldValue = (
  value: unknown,
): value is ContactCustomFieldValue => {
  return (
    typeof value === 'object' &&
    value !== null &&
    hasProperty(value, 'id') &&
    isString(value.id) &&
    hasProperty(value, 'label') &&
    isString(value.label)
  );
};

export type UserCustomFieldValue = {
  id: string;
  label: string;
  picture: null | string;
};

export const isUserCustomFieldValue = (
  value: unknown,
): value is UserCustomFieldValue => {
  return (
    typeof value === 'object' &&
    value !== null &&
    hasProperty(value, 'id') &&
    isString(value.id) &&
    hasProperty(value, 'label') &&
    isString(value.label) &&
    hasProperty(value, 'picture') &&
    (isString(value.picture) || value.picture === null)
  );
};

export const getCustomFieldValuesFromContact = ({
  contact,
  attributeId,
}: {
  contact: PersonFragment | CompanyFragment;
  attributeId: string;
}) => {
  const customFields = (contact.customFields ?? []).filter(isNotNil);
  const customField = customFields.find(
    (customField) => customField.field.id === attributeId,
  );
  if (!customField) {
    return [];
  }
  if (!Array.isArray(customField.values)) {
    return [];
  }
  const values = customField.values ?? [];
  // careful: the filter by `isNotNil` is necessary as there are sometimes `null` values.
  // a possible reason is that `null` probably represents a deleted user in the contact combobox
  const nonNilValues = values.filter(isNotNil);

  return nonNilValues;
};

export const customFieldIconMapping: Record<CustomFieldType, ReactNode> = {
  singleSelect: <SingleSelectIcon />,
  multipleSelect: <MdFormatListBulleted />,
  textField: <MdTextFields />,
  userField: <MdAccountCircle />,
  contactField: <MdPerson />,
  dateField: <MdEvent />,
};

export const getFieldIcon = (
  field:
    | GroupViewNativeFieldFragment
    | GroupViewCustomFieldFragment
    | GroupContactPanelNativeFieldFragment
    | GroupContactPanelCustomFieldFragment,
): ReactNode => {
  if (
    field.__typename === 'GroupContactPanelCustomField' ||
    field.__typename === 'GroupViewCustomField'
  ) {
    return customFieldIconMapping[field.type];
  }
  return nativeFields[field.value].icon;
};

export const getFieldName = (
  field:
    | GroupViewNativeFieldFragment
    | GroupViewCustomFieldFragment
    | GroupContactPanelNativeFieldFragment
    | GroupContactPanelCustomFieldFragment,
): string => {
  if (
    field.__typename === 'GroupContactPanelCustomField' ||
    field.__typename === 'GroupViewCustomField'
  ) {
    return field.name;
  }
  return nativeFields[field.value].name;
};

export const getAvailableFilterFields = (isPerson: boolean): string[] => {
  return isPerson
    ? [
        FilterAttribute.emails,
        FilterAttribute.phones,
        FilterAttribute.companies,
        FilterAttribute.urls,
      ]
    : [FilterAttribute.emails, FilterAttribute.phones, FilterAttribute.urls];
};

export const allAvailableNativeFilters = [
  NativeFieldType.emails,
  NativeFieldType.phones,
  NativeFieldType.companies,
  NativeFieldType.urls,
];

export const allAvailableCustomFilterTypes = [
  CustomFieldType.singleSelect,
  CustomFieldType.multipleSelect,
  CustomFieldType.textField,
  CustomFieldType.userField,
];

export const canFilterField = (
  attribute: GroupViewNativeFieldFragment | GroupViewCustomFieldFragment,
) => {
  return (
    (attribute.__typename === 'GroupViewCustomField' &&
      allAvailableCustomFilterTypes.includes(attribute.type)) ||
    (attribute.__typename === 'GroupViewNativeField' &&
      allAvailableNativeFilters.includes(attribute.value))
  );
};

export function useDebounce<T extends unknown>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
