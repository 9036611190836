import { NetworkMemberRole } from '__generated__/graphql';
import { Flex } from '@designSystem';
import { Box } from '@folkapp/design-system';
import { useNetworkMembers } from 'app/services/remote/network';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

import { NetworkMember } from '../NetworkMember';

interface ListInvitesProps {
  networkId: string;
}

export const ListMembers = ({ networkId }: ListInvitesProps) => {
  const queryMembers = useNetworkMembers({ variables: { id: networkId } });
  const members = useMemo(
    () => queryMembers.data?.networkMembers?.members ?? [],
    [queryMembers.data?.networkMembers?.members],
  );

  const sortedMembers = useMemo(
    () =>
      orderBy(
        members,
        [
          (m) => m.role === NetworkMemberRole.owner,
          'user.lastname',
          'user.firstname',
        ],
        ['desc', 'asc', 'asc'],
      ),
    [members],
  );

  if (members.length === 0) {
    return null;
  }

  return (
    <Box
      css={{
        position: 'relative',
        paddingBottom: '$3',
        maxHeight: '$80',
        overflowY: 'overlay',
      }}
    >
      <Flex
        direction="column"
        gap="2"
        css={{ maxHeight: '100%', overflowY: 'auto' }}
      >
        {sortedMembers.map((member) => (
          <NetworkMember
            key={member.user.id}
            id={member.user.id}
            fullname={member.user.fullname}
            email={member.user.email}
            isOwner={member.role === NetworkMemberRole.owner}
          />
        ))}
      </Flex>
    </Box>
  );
};
