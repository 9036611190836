import { ContactType, PersonFragment } from '__generated__/graphql';
import { GenderIcon } from '@folkapp/design-system';
import { contactIsPerson } from 'app/apps/contacts/utils';
import { ComboBox, ComboBoxOption } from 'app/Components/ComboBox';
import { useContains } from 'app/hooks/useContains';
import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FieldProps } from '../../ContactFields/ContactFields';
import { useContactFormContext } from '../../ContactFormContext';
import { ContactPanelField } from '../../ContactPanelField';
import { ContactPanelFieldFocusableBox } from '../../ContactPanelField/ContactPanelField';
import { ContactPanelNativeFieldLabel } from '../../ContactPanelField/ContactPanelFieldLabel';

export const genderOptions = [
  {
    label: 'Male',
    value: 'M',
    highlightColor: '#f7b34c',
  },
  {
    label: 'Female',
    value: 'F',
    highlightColor: '#69d1fa',
  },
  {
    label: 'Other',
    value: 'O',
    highlightColor: '#456ced',
  },
  {
    label: 'Unknown',
    value: 'U',
    highlightColor: '#60ba65',
  },
] as const;

interface GenderProps {
  contact: PersonFragment;
  field: FieldProps['field'];
  isDragDisabled: boolean;
}

export const MaybeGender: FC<FieldProps> = ({
  contact,
  field,
  isDragDisabled,
}) =>
  contactIsPerson(contact) ? (
    <Gender contact={contact} field={field} isDragDisabled={isDragDisabled} />
  ) : null;

export const Gender: FC<GenderProps> = ({ contact, field, isDragDisabled }) => {
  const { groupId } = useParams<{ groupId: string }>();
  const [inputValue, setInputValue] = useState('');
  const gender = contact.pGender;
  const contains = useContains({
    sensitivity: 'base',
  });
  const selectedOptionIds = useMemo(
    () =>
      genderOptions.filter((g) => g.value === gender).map(({ value }) => value),
    [gender],
  );
  const options = useMemo(() => {
    return genderOptions
      .filter(({ label }) => contains(label, inputValue.trim()))
      .map(({ label, value, highlightColor }) => ({
        id: value,
        text: label,
        highlightColor,
      }));
  }, [contains, inputValue]);
  const sections = useMemo(
    () => [
      {
        title: 'Pick one',
        id: 'gender-select',
        options,
      },
    ],
    [options],
  );

  const { onUpdateGender } = useContactFormContext();
  const handleSelect = useCallback(
    (option: ComboBoxOption | null) => {
      onUpdateGender(option?.id ?? null);
    },
    [onUpdateGender],
  );

  const actions = useMemo(
    () => ({
      onRemove: () => handleSelect(null),
      onSelect: handleSelect,
    }),
    [handleSelect],
  );

  const [isLabelMenuOpen, setIsLabelMenuOpen] = useState(false);

  return (
    <ContactPanelField
      isDragDisabled={isDragDisabled}
      isFocused={isLabelMenuOpen}
      label={
        <ContactPanelNativeFieldLabel
          title="Gender"
          icon={<GenderIcon />}
          fieldId={field.id}
          groupId={groupId}
          isPerson={contact.contactType === ContactType.person}
          onMenuOpenChanged={setIsLabelMenuOpen}
        />
      }
    >
      <ContactPanelFieldFocusableBox>
        <ComboBox
          inputValue={inputValue}
          onChange={setInputValue}
          selectedOptionIds={selectedOptionIds}
          actions={actions}
          clearInput={() => setInputValue('')}
          sections={sections}
          placeholder="Select gender"
          filterOutSelectedOptions={false}
          popoverVariant="contactPanel"
        />
      </ContactPanelFieldFocusableBox>
    </ContactPanelField>
  );
};
