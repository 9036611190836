import { styled } from '@folkapp/design-system';

import { FolkXSourceOption } from './FolkXSourceOption';
import { GoogleAccountSourceOption } from './GoogleAccountSourceOption';
import { ImportFileSourceOption } from './ImportFileSourceOption';
import { ZapierSourceOption } from './ZapierSourceOption';

const Container = styled('div', {
  display: 'grid',
  flexDirection: 'row',
  gap: '$6',
  gridTemplateColumns: '$80 $80 $80 $80',

  '@media only screen and (max-width: 1400px)': {
    gridTemplateColumns: '$80 $80',
  },

  '@media only screen and (max-width: 800px)': {
    gridTemplateColumns: '$80',
  },
});

export const SourceOptions = ({ networkId }: { networkId: string }) => {
  return (
    <Container>
      <GoogleAccountSourceOption networkId={networkId} />
      <ImportFileSourceOption networkId={networkId} />
      <FolkXSourceOption />
      <ZapierSourceOption />
    </Container>
  );
};
