import { appConfig } from 'appConfig';

export const initalizeDatadogRUM = () => {
  if (appConfig.environment === 'local') {
    return;
  }

  import('@datadog/browser-rum').then(({ datadogRum }) => {
    datadogRum.init({
      applicationId: '527fb6d6-702b-47b6-a338-271b039d9f01',
      clientToken: 'pub1e1580eb2f4d8662db94c6019a5f291a',
      site: 'datadoghq.eu',
      service: 'front',
      env: appConfig.environment === 'production' ? 'prd' : 'dev',
      version: appConfig.version,
      sampleRate: 5,
      premiumSampleRate: 0,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask',
    });
  });
};
