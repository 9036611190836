// @note: do not go banana with the number of variant
export type OptionVariant = 'material' | 'squareAvatar' | 'highlighted';

export type OptionDisplay = 'flex' | 'inline';
export interface CreateOption {
  id: string;
  label: string;
  text: string;
  highlightColor?: string;
  variant?: OptionVariant;
}

export type OptionActions = {
  onSelect?: (option: ComboBoxOption) => void; // the whole option is needed for companies, e.g. enriched ones
  onEditName?: (optionId: string, name: string) => void;
  onRemove?: (optionId: string) => void;
  onDelete?: (optionId: string) => void;
  onEditColor?: (optionId: string, color: string) => void;
};

export interface HighlightedOption {
  id: string;
  text: string;
  highlightColor: string;
  variant?: OptionVariant;
}

export interface OptionWithEmoji {
  id: string;
  emoji: string | null;
  text: string;
  textRight?: string;
  variant?: OptionVariant;
}

export interface OptionWithAvatar {
  id: string;
  picture: string | null;
  text: string;
  textRight?: string;
  variant?: OptionVariant;
}

export type ComboBoxOption =
  | CreateOption
  | HighlightedOption
  | OptionWithEmoji
  | OptionWithAvatar;

export type ExistingOption = Exclude<ComboBoxOption, CreateOption>;

export const isCreate = (props: ComboBoxOption): props is CreateOption =>
  props.hasOwnProperty('label');

export const isExisting = (props: ComboBoxOption): props is ExistingOption =>
  !isCreate(props);

export const isHighlighted = (
  props: ComboBoxOption,
): props is HighlightedOption =>
  props.hasOwnProperty('highlightColor') && !isCreate(props);

export const isWithEmoji = (props: ComboBoxOption): props is OptionWithEmoji =>
  props.hasOwnProperty('emoji');

export const isWithAvatar = (
  props: ComboBoxOption,
): props is OptionWithAvatar => props.hasOwnProperty('picture');
