import { useCreateGroup } from 'app/services/remote/group';
import { useCreateNetwork } from 'app/services/remote/network';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import { NetworkSetupInput } from './NetworkSetup';
import { defaultUseCases, UseCase } from './utils/useCases';

export const useNetworkSetup = () => {
  const [createNetwork] = useCreateNetwork();
  const [createGroup] = useCreateGroup();

  const getGroups = useCallback((useCases: UseCase[] = []) => {
    // Get all selected useCases, merge and flatten related groups

    const groups = useCases.map((useCase) => useCase.groups);
    const mergedGroups = groups.flat();

    const flatGroups = uniqBy(mergedGroups, 'name');

    return flatGroups;
  }, []);

  return useCallback(
    async (data: NetworkSetupInput) => {
      if (data.networkName) {
        const networkData = await createNetwork({
          variables: {
            payload: {
              name: data.networkName,
              picture: data.picture,
            },
          },
        });
        const createdNetworkId = networkData?.data?.network?.id;
        if (createdNetworkId) {
          // Create groups and attach them to network
          const groups = getGroups(data.useCases ?? defaultUseCases);

          // FIXME: it should be bulked
          await Promise.all(
            groups.map(({ name, icon }) =>
              createGroup({
                variables: {
                  payload: {
                    networkId: createdNetworkId,
                    id: uuid(),
                    name,
                    emoji: icon,
                    openAccess: false,
                  },
                },
              }),
            ),
          );

          return { ...data, id: createdNetworkId };
        }
      }
    },
    [createGroup, createNetwork, getGroups],
  );
};
