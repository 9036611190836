import { useKeyboard } from '@react-aria/interactions';
import { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';

// TODO: consider sharing this util if we adopt it
// cf. https://dev.to/rafi993/roving-focus-in-react-with-custom-hooks-1ln for inspiration
// TODO: have an object as an argument
export const useRoveFocus = ({
  ids,
  popoverIsOpen,
  inputValue,
  onEnter,
  onEscape,
  onSpace,
  onKeyDown,
}: {
  ids: string[];
  popoverIsOpen: boolean;
  inputValue: string;
  onEnter?: (id: string | undefined) => void;
  onSpace?: (id: string | undefined) => void;
  onEscape?: VoidFunction;
  onKeyDown?: (ev: KeyboardEvent<HTMLElement>) => void;
}) => {
  const size = ids.length;
  const previousSize = useRef(ids.length);

  const { keyboardProps } = useKeyboard({
    onKeyDown: (e) => {
      if (!popoverIsOpen) {
        if (e.code === 'Enter') {
          return onEnter?.(undefined);
        }
        return e.continuePropagation();
      }

      if (onKeyDown?.(e)) {
        // Short circuit event handlers if the onKeyDown handler returns true
        return;
      }

      switch (e.code) {
        case 'Escape': {
          onEscape?.();
          return setCurrentFocus(undefined);
        }
        case 'ArrowDown': {
          return setCurrentFocus(
            currentFocusWithDefault === size - 1 ||
              currentFocusWithDefault === undefined
              ? 0
              : currentFocusWithDefault + 1,
          );
        }
        case 'ArrowUp': {
          return setCurrentFocus(
            currentFocusWithDefault === 0 ||
              currentFocusWithDefault === undefined
              ? size - 1
              : currentFocusWithDefault - 1,
          );
        }
        case 'Enter': {
          e.preventDefault();
          if (currentFocusWithDefault === undefined) {
            return onEnter?.(undefined);
          }
          return onEnter?.(ids[currentFocusWithDefault]);
        }
        case 'Space': {
          if (currentFocusWithDefault === undefined) {
            return onSpace?.(undefined);
          }
          return onSpace?.(ids[currentFocusWithDefault]);
        }
        default:
          return;
      }
    },
  });

  const [currentFocus, setCurrentFocus] = useState<number | undefined>(
    popoverIsOpen ? 0 : undefined,
  );

  // @note: this makes it possible to focus a option about to be created
  // but also to lose focus when resetting the `inputValue` back to an empty string
  const currentFocusWithDefault = useMemo(
    () =>
      currentFocus ??
      (inputValue.trim() !== '' && popoverIsOpen ? 0 : undefined),
    [currentFocus, inputValue, popoverIsOpen],
  );

  // picking an option changes the size when selected options are filtered out
  useEffect(() => {
    const diff = previousSize.current - ids.length;
    if (diff > 0 && currentFocus !== undefined) {
      setCurrentFocus(Math.max(0, currentFocus - diff));
    }

    previousSize.current = ids.length;
  }, [currentFocus, ids.length]);

  const result = useMemo(
    () => ({
      currentFocus: currentFocusWithDefault,
      setCurrentFocus,
      keyboardProps,
    }),
    [currentFocusWithDefault, keyboardProps],
  );

  return result;
};
