import { COLORS_AVATARS, COLORS_TAGS } from 'app/utils/constants';
import memoize from 'fast-memoize';
import { FC, useEffect, useState } from 'react';

export type COLOR_TAG = typeof COLORS_TAGS[keyof typeof COLORS_TAGS];

export const generateColor = (name: string, avatar = false): COLOR_TAG => {
  const trimmed = name.trim();
  if (trimmed.length < 3) {
    return avatar ? COLORS_AVATARS.warning : COLORS_TAGS.grey;
  }

  return generateColorUtil(trimmed, avatar);
};

const generateColorUtil = memoize((name: string, avatar = false) => {
  const colors = Object.values(avatar ? COLORS_AVATARS : COLORS_TAGS);
  const magicNumber = name
    .split('')
    .map((letter) => letter.toLowerCase().charCodeAt(0))
    .reduce((acc, curr) => acc + curr, 1);

  const index = magicNumber % colors.length;

  return colors[index]!;
});

export const DifferRender: FC<
  React.PropsWithChildren<{ delayMs?: number }>
> = ({ children, delayMs = 1000 }) => {
  const [preventRender, setPreventRender] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setPreventRender(false);
    }, delayMs);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [delayMs]);

  if (preventRender) {
    return null;
  }

  return <>{children}</>;
};
