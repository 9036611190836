import {
  PersonFieldUpdateField,
  useUpdatePersonFieldMutation,
} from '__generated__/graphql';
import { useCallback } from 'react';

export const useJobTitleFormMethods = ({
  networkId,
  contactId,
}: {
  networkId: string;
  contactId: string;
}) => {
  const [updateField] = useUpdatePersonFieldMutation();

  const onUpdateJobTitle = useCallback(
    (value: string | null) => {
      updateField({
        variables: {
          input: {
            networkId,
            contactId,
            value,
            field: PersonFieldUpdateField.pJobTitle,
          },
        },
      });
    },
    [updateField, networkId, contactId],
  );

  return {
    onUpdateJobTitle,
  };
};

export type JobTitleFormMethods = ReturnType<typeof useJobTitleFormMethods>;
