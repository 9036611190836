import { QueryResult } from '@apollo/client';
import { useEffect } from 'react';

export const useRefetchQueryOnPageVisible = (result: QueryResult) => {
  const { refetch } = result;

  useEffect(() => {
    const triggerRefetch = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    window.addEventListener('visibilitychange', triggerRefetch);

    return () => {
      window.removeEventListener('visibilitychange', triggerRefetch);
    };
  }, [refetch]);
};
