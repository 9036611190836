import { Button, Spinner } from '@folkapp/design-system';
import { appConfig } from 'appConfig';
import { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from 'static/images/logo2.svg';

import styles from './Maintenance.module.css';

export const MaintenanceRoute: FC = () => {
  const history = useHistory();
  const [stateLoading, setStateLoading] = useState(false);
  const [retryLabel, setRetryLabel] = useState('Retry');

  const retry = async () => {
    try {
      setStateLoading(true);
      const response = await fetch(`${appConfig.apiUrl}/maintenance`);
      const isMaintenanceModeActivated = await response.json();
      if (isMaintenanceModeActivated) {
        return;
      }

      const lastLocation = JSON.parse(
        localStorage.getItem('lastLocationBeforeMaintenance') || '',
      );
      if (lastLocation) {
        history.replace(lastLocation);
        return;
      }
    } catch (err) {
      console.error('Retry error', err);
    } finally {
      setRetryLabel(retryLabel + ' again');
      setTimeout(() => {
        setStateLoading(false);
      }, 500);
    }
    history.replace({ pathname: '/' });
  };

  return (
    <div className={styles.root} data-theme={'light'}>
      <div aria-hidden="true" className={styles.partyLights} />
      <div aria-hidden="true" className={styles.backdrop} />
      <main className={styles.container}>
        <Link to="/" className={styles.logo}>
          <img src={logo} alt="folk" />
        </Link>
        <div className={styles.content}>
          <>
            <p className={styles.kicker}>
              This is probably not what you want to see
            </p>
            <h1 className={styles.title}>We are in maintenance mode 😟</h1>
            <p className={styles.description}>
              Sorry folk, but too many people came to this party. We're doing
              everything we can to let you in (i.e kicking over people out of
              the club to make some room). You can try again by hitting the
              button below.
            </p>
          </>
          <div className={styles.actions}>
            <Button
              onClick={retry}
              leading={stateLoading && <Spinner />}
              disabled={stateLoading}
            >
              {retryLabel}
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
};
