import {
  GroupViewCustomFieldFragment,
  GroupViewNativeFieldFragment,
  GroupViewSettingsFragment,
} from '__generated__/graphql';
import { Filter } from 'app/types';

export type UserConfigAction =
  | {
      type:
        | 'UPDATE_PERSON_CONTACT_FIELDS'
        | 'UPDATE_COMPANY_CONTACT_FIELDS'
        | 'UPDATE_PERSON_FIELDS'
        | 'UPDATE_COMPANY_FIELDS';
      payload: (GroupViewNativeFieldFragment | GroupViewCustomFieldFragment)[];
    }
  | {
      type: 'UPDATE_FILTERS';
      payload: Filter[];
    }
  | {
      type: 'UPDATE_ORDER_FIELDS';
      payload: GroupViewSettingsFragment['orderFields'];
    };

export const reducer = <T extends GroupViewSettingsFragment>(
  state: T,
  action: UserConfigAction,
): T => {
  switch (action.type) {
    case 'UPDATE_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case 'UPDATE_PERSON_CONTACT_FIELDS': {
      return {
        ...state,
        personFields: action.payload,
      };
    }
    case 'UPDATE_COMPANY_CONTACT_FIELDS': {
      return {
        ...state,
        companyFields: action.payload,
      };
    }
    case 'UPDATE_ORDER_FIELDS': {
      return {
        ...state,
        orderFields: action.payload,
      };
    }
    case 'UPDATE_PERSON_FIELDS':
      return {
        ...state,
        personFields: action.payload,
      };
    case 'UPDATE_COMPANY_FIELDS':
      return {
        ...state,
        companyFields: action.payload,
      };
    default:
      return state;
  }
};
