import {
  NetworkAddExternalUserMutation,
  NetworkAddExternalUserMutationVariables,
  useNetworkAddExternalUserMutation,
} from '__generated__/graphql';
import { gql, MutationHookOptions, useApolloClient } from '@apollo/client';
import { useToasts } from '@folkapp/design-system';
import { makeUser } from 'app/services/factories/user';
import { updateNetworkMembers } from 'app/services/remote/network';
import { useCallback } from 'react';

gql`
  mutation networkAddExternalUser($payload: ExternalUserInput!) {
    member: network__addExternalUser(payload: $payload) {
      id
      role
      networkId
      userId
    }
  }
`;

export const useAddExternalUser = (
  options?: MutationHookOptions<
    NetworkAddExternalUserMutation,
    NetworkAddExternalUserMutationVariables
  >,
) => {
  const { addToast } = useToasts();
  const [addExternalUser] = useNetworkAddExternalUserMutation({
    onError: (error) => {
      let toastMessage = 'Something went wrong.';

      if (error.message === 'invalid-valid-email') {
        toastMessage = 'The email is invalid.';
      }

      addToast(toastMessage, {
        variant: 'error',
      });
    },
    ...options,
  });
  const client = useApolloClient();

  return useCallback(
    async ({
      email,
      networkId,
      onCompleted,
    }: {
      email: string;
      networkId: string;
      onCompleted?: (datal: NetworkAddExternalUserMutation) => void;
    }) => {
      await addExternalUser({
        onCompleted,
        variables: {
          payload: {
            email,
            networkId,
          },
        },
        update: (_, { data }) => {
          const member = data?.member;

          if (member) {
            updateNetworkMembers({
              networkId,
              client,
              modifier: (members) =>
                members.concat({
                  ...member,
                  user: makeUser({
                    id: member.userId,
                    email,
                    fullname: email,
                    firstname: '',
                    lastname: '',
                    needOnboarding: true,
                  }),
                }),
            });
          }
        },
      });
    },
    [addExternalUser, client],
  );
};
