/* eslint-disable import/no-duplicates */
import longFormatters from 'date-fns/esm/_lib/format/longFormatters';
import isAfter from 'date-fns/isAfter';
import isValidDate from 'date-fns/isValid';
import { enGB, enUS } from 'date-fns/locale';
import parse from 'date-fns/parse';

const longFormattingTokensRegExp = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;

export function parseIncompleteDate(value: string, locale: string) {
  // "02-03-20" and "02.03.20" will be treated as "02/03/20"
  return copyPastedParseIncompleteDate(
    value.replace(/\./g, '/').replace(/-/g, '/'),
    locale,
  );
}

// cf. https://github.com/Hacker0x01/react-datepicker/blob/master/src/date_utils.js
// please do not modify
function copyPastedParseIncompleteDate(value: string, localeString: string) {
  // TODO: find a better solution here
  const locale = localeString.toLowerCase().includes('en-us') ? enUS : enGB;

  // previously arguments
  let dateFormat = 'P';
  // end of previously arguments

  let parsedDate = null;
  const localeObject = getLocaleObject(locale) || getDefaultLocale();
  const strictParsingValueMatch = true;
  if (Array.isArray(dateFormat)) {
    dateFormat.forEach((df) => {
      const tryParseDate = parse(value, df, new Date(), {
        locale: localeObject,
      });
      if (isValid(tryParseDate) && strictParsingValueMatch) {
        parsedDate = tryParseDate;
      }
    });
    return parsedDate;
  }

  parsedDate = parse(value, dateFormat, new Date(), { locale: localeObject });

  if (!isValid(parsedDate)) {
    dateFormat = dateFormat
      .match(longFormattingTokensRegExp)!
      .map(function (substring) {
        const firstCharacter = substring[0];
        if (firstCharacter === 'p' || firstCharacter === 'P') {
          const longFormatter = longFormatters[firstCharacter];
          return localeObject
            ? longFormatter(substring, localeObject.formatLong)
            : firstCharacter;
        }
        return substring;
      })
      .join('');

    if (value.length > 0) {
      parsedDate = parse(value, dateFormat.slice(0, value.length), new Date());
    }

    if (!isValid(parsedDate)) {
      parsedDate = new Date(value);
    }
  }

  return isValid(parsedDate) && strictParsingValueMatch ? parsedDate : null;
}

function getDefaultLocale() {
  const scope = typeof window !== 'undefined' ? window : global;

  // @ts-expect-error
  return scope.__localeId__;
}

function getLocaleObject(localeSpec: any) {
  if (typeof localeSpec === 'string') {
    // Treat it as a locale name registered by registerLocale
    const scope = typeof window !== 'undefined' ? window : global;
    // @ts-expect-error
    return scope.__localeData__ ? scope.__localeData__[localeSpec] : null;
  } else {
    // Treat it as a raw date-fns locale object
    return localeSpec;
  }
}

function isValid(date: any) {
  return isValidDate(date) && isAfter(date, new Date('1/1/1000'));
}
