import { CustomFieldValue } from '__generated__/graphql';

export const GroupCustomField = {
  fields: {
    value: {
      merge(_: CustomFieldValue[], next: CustomFieldValue[]) {
        return next;
      },
    },
  },
};
