import { Input } from '@folkapp/design-system';
import { useHover } from '@react-aria/interactions';
import { useTextField } from '@react-aria/textfield';
import { mergeProps } from '@react-aria/utils';
import { usePopoverContext } from 'app/Components/Popover';
import cx from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';

import styles from './PopoverEditOption.module.css';

export interface PopoverEditOptionProps {
  onChange?: (value: string) => void;
  onEnter: (value: string) => void;
  value: string;
  label: string;
  hasFocus?: boolean;
  onHover?: VoidFunction;
}

export const PopoverEditOption: FC<PopoverEditOptionProps> = ({
  onChange,
  onEnter,
  value,
  label,
  hasFocus,
  onHover,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { close } = usePopoverContext();
  // useful to handle back tab and escape behaviours
  const tabIndex = useRef<0 | undefined>(0);
  const [localTitle, setLocalTitle] = useState(value);
  const blurIsEscape = useRef(true);

  useEffect(() => {
    setLocalTitle(value);
  }, [value]);

  const { inputProps, labelProps } = useTextField(
    {
      type: 'text',
      value: localTitle,
      onChange: (value) => {
        setLocalTitle(value);
        onChange?.(value);
      },
      'aria-label': label,
      placeholder: label,
      onFocus: () => {
        blurIsEscape.current = true;
        tabIndex.current = 0;
      },
      onKeyDown: (e) => {
        if (e.key === 'Enter') {
          onEnter(localTitle);
          close?.();
        }

        // tab or back tab
        if (e.key === 'Tab') {
          blurIsEscape.current = false;
        }

        if (e.key === 'Tab' && e.shiftKey) {
          tabIndex.current = undefined;
        }
      },
      onBlur: () => {
        onEnter(localTitle);
        setLocalTitle(localTitle);

        if (blurIsEscape.current) {
          containerRef.current?.focus();
        }
      },
    },
    inputRef,
  );

  const { hoverProps } = useHover({
    onHoverStart: () => {
      onHover?.();

      inputRef.current?.focus();
    },
  });

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, []);

  return (
    <div
      {...mergeProps(labelProps, hoverProps)}
      role="none"
      className={cx(styles.root)}
      tabIndex={tabIndex.current}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          inputRef.current?.focus();
        }
      }}
      ref={containerRef}
    >
      <Input ref={inputRef} {...inputProps} />
    </div>
  );
};
