import {
  useTrackSegmentEventMutation,
  useUpdateUserMutation,
} from '__generated__/graphql';
import { Flex } from '@designSystem';
import { Box, Button } from '@folkapp/design-system';
import { LayoutOnboarding } from 'app/apps/onboarding/LayoutOnboarding';
import { UserStatus } from 'app/services/graphQLService';
import { setUserStatus } from 'app/services/graphQLService/graphQLService';
import { useHistory, useParams } from 'react-router-dom';
import StepThreeBackground from 'static/images/bg_step3.svg';

import { getStepUrl } from '../../../routes/onboarding/OnboardingRoutingUrls';
import { SourceOptions } from './SourceOptions';

export const Sources = ({
  numberOfSteps,
  currentStep,
  nextStep,
  isReferred,
}: {
  numberOfSteps: number;
  currentStep: number;
  nextStep: number;
  isReferred: boolean;
}) => {
  const { networkId } = useParams<{ networkId: string }>();
  const [updateUser] = useUpdateUserMutation();
  const [trackEvent] = useTrackSegmentEventMutation();
  const history = useHistory();

  return (
    <LayoutOnboarding
      numberOfSteps={numberOfSteps}
      currentStep={currentStep}
      backgroundImg={StepThreeBackground}
      title="Create live groups of contacts"
      subTitle="folk lets you create live groups of contacts in four different ways."
    >
      <Flex
        css={{ flex: 1 }}
        align="center"
        direction="column"
        justify="between"
      >
        <Box css={{ flexGrow: 1 }} />

        <SourceOptions networkId={networkId} />

        <Box css={{ flexGrow: 1, marginBottom: '$3' }} />

        <Button
          onClick={async () => {
            trackEvent({
              variables: {
                payload: {
                  event: 'onboarding_completed',
                  properties: {
                    networkId,
                  },
                },
              },
            });

            await updateUser({
              variables: {
                payload: {
                  onboardingStep: nextStep,
                  needOnboarding: false,
                },
              },
            });

            // Once user finishes this step, set needOnboarding to false, and redirect to main page
            setUserStatus(UserStatus.CONNECTED);

            history.push(getStepUrl({ networkId, step: nextStep, isReferred }));
          }}
          css={{ padding: '$2 $3' }}
        >
          Next
        </Button>
      </Flex>
    </LayoutOnboarding>
  );
};
