import {
  ContactType,
  RemoveCompanyValueMutation,
  RemoveCompanyValueMutationVariables,
  RemovePersonValueMutation,
  RemovePersonValueMutationVariables,
  useRemoveCompanyValueMutation,
  useRemovePersonValueMutation,
} from '__generated__/graphql';
import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  MutationUpdaterFunction,
} from '@apollo/client';
import { useCallback } from 'react';

import { deleteContactNativeFieldModifier } from '../contactNativeFieldModifiers';

const contactPropertyByType = {
  address: 'addresses',
  email: 'emails',
  phone: 'phones',
  url: 'urls',
} as const;

export const useContactRemoveValue = ({
  contactType,
  options,
}: {
  contactType: ContactType;
  options?: MutationHookOptions<
    RemovePersonValueMutation | RemoveCompanyValueMutation,
    RemovePersonValueMutationVariables | RemoveCompanyValueMutationVariables
  >;
}) => {
  const update = useCallback<
    MutationUpdaterFunction<
      RemovePersonValueMutation | RemoveCompanyValueMutation,
      RemovePersonValueMutationVariables | RemoveCompanyValueMutationVariables,
      DefaultContext,
      ApolloCache<any>
    >
  >(
    (cache, { data }, { variables }) => {
      if (!variables || !data?.multiValue) {
        return;
      }

      const contactProperty = contactPropertyByType[variables.input.type];

      cache.modify({
        id: cache.identify({
          __typename: contactType === ContactType.person ? 'Person' : 'Company',
          id: variables.input.contactId,
        }),
        fields: {
          [contactProperty]: deleteContactNativeFieldModifier({
            cache,
            nativeField: data.multiValue,
          }),
        },
      });
    },
    [contactType],
  );

  const removePersonValueMutation = useRemovePersonValueMutation({
    update,
    ...options,
  });
  const removeCompanyValueMutation = useRemoveCompanyValueMutation({
    update,
    ...options,
  });

  return contactType === ContactType.person
    ? removePersonValueMutation
    : removeCompanyValueMutation;
};
