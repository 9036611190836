import { CSSReset, ToastProvider } from '@folkapp/design-system';
import { OverlayProvider } from '@react-aria/overlays';
import { useAppConfig } from 'appConfig';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { LocalizationProvider } from './@localization/Provider';
import { useHandleChunkLoadErrors } from './App.utils';
import { CommandPanelProvider } from './apps/commandPanel/CommandPanelContext';
import { IntegrationPanelProvider } from './apps/integrationPanel/useIsIntegrationPanelOpen';
import { FolkRoutes } from './apps/routes';
import { NotSupported } from './Components/NotSupported';
import { useMediaQuery } from './hooks/useMediaQuery';
import { useFavicons } from './useFavicons';
import { useClientSupportIssues } from './utils/browser';
import { FeatureFlagManager } from './utils/featureFlag';

const onChunkLoadError = () => {
  window.location.reload();
};

const FEATURE_FLAGS: string[] = [];

export const AppHead = () => {
  const appConfig = useAppConfig();
  const favicons = useFavicons();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return (
    <Helmet>
      {/*
    Once support is good enough for [SVG favicons](https://caniuse.com/link-icon-svg)
    and `prefers-color-scheme` style is respected by the browser, we could remove the
    following code.
  */}
      {prefersDarkMode ? (
        <>
          <link
            rel="icon"
            type="image/png"
            href={favicons.dark.png}
            data-testid="favicon-dark-png"
          />
          <link
            rel="icon"
            type="image/svg+xml"
            href={favicons.dark.svg}
            data-testid="favicon-dark-svg"
          />
        </>
      ) : (
        <>
          <link
            rel="icon"
            type="image/png"
            href={favicons.light.png}
            data-testid="favicon-light-png"
          />
          <link
            rel="icon"
            type="image/svg+xml"
            href={favicons.light.svg}
            data-testid="favicon-light-svg"
          />
        </>
      )}

      {appConfig.environment !== 'production' && (
        <title>folk - {appConfig.environment}</title>
      )}
    </Helmet>
  );
};

export const App = () => {
  useHandleChunkLoadErrors(onChunkLoadError);

  const clientSupportIssues = useClientSupportIssues();
  if (clientSupportIssues) {
    return <NotSupported issue={clientSupportIssues} />;
  }

  return (
    <HelmetProvider>
      <AppHead />
      <OverlayProvider>
        <LocalizationProvider locale={window.navigator.language}>
          <ToastProvider>
            <CommandPanelProvider>
              <IntegrationPanelProvider>
                <FolkRoutes />
                <FeatureFlagManager featureFlags={FEATURE_FLAGS} />
                <CSSReset />
              </IntegrationPanelProvider>
            </CommandPanelProvider>
          </ToastProvider>
        </LocalizationProvider>
      </OverlayProvider>
    </HelmetProvider>
  );
};
