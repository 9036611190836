import { createStitches } from '@stitches/react';
export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config, prefix, reset, } = createStitches({
    theme: {
        borderStyles: {
            solid: 'solid',
            dashed: 'dashed',
            dotted: 'dotted',
            double: 'double',
            hidden: 'hidden',
            none: 'none',
        },
        borderWidths: {
            default: '1px',
            '0': '0px',
            '2': '2px',
            '4': '4px',
            '8': '8px',
        },
        colors: {
            transparent: 'transparent',
            current: 'currentColor',
            black: '#000',
            white: '#fff',
            folk100: '#EFECFF',
            folk200: '#CDC4FF',
            folk300: '#AB9CFF',
            folk400: '#8A74FF',
            folk500: '#684CFF',
            folk600: '#5738FF',
            folk700: '#4B33D3',
            folk800: '#3F2EA6',
            folk900: '#34297A',
            grey100: '#F4F5F5',
            grey200: '#EBEBEC',
            grey300: '#D6D7D7',
            grey400: '#C2C3C4',
            grey500: '#989A9C',
            grey600: '#6F7275',
            grey700: '#464A4D',
            grey800: '#313539',
            grey900: '#181A1B',
            red100: '#FEEDEE',
            red200: '#FDDCDC',
            red300: '#FBB8B9',
            red400: '#F99596',
            red500: '#F77173',
            red600: '#F54E50',
            red700: '#CA4547',
            red800: '#9E3B3D',
            red900: '#733234',
            blue100: '#E9F1FD',
            blue200: '#D4E2FB',
            blue300: '#A9C6F7',
            blue400: '#7DA9F3',
            blue500: '#528DEF',
            blue600: '#2770EC',
            blue700: '#2560C3',
            blue800: '#23509B',
            blue900: '#203F72',
            green100: '#E9FAF6',
            green200: '#D2F5EE',
            green300: '#A6ECDD',
            green400: '#79E2CC',
            green500: '#4DD8BA',
            green600: '#20CEA9',
            green700: '#1FAB8E',
            green800: '#1F8873',
            green900: '#1D423C',
            yellow100: '#FEF8EB',
            yellow200: '#FCF1D6',
            yellow300: '#FAE3AE',
            yellow400: '#F7D585',
            yellow500: '#F5C75D',
            yellow600: '#F2B934',
            yellow700: '#C79A30',
            yellow800: '#9D7B2C',
            yellow900: '#725D29',
        },
        fontSizes: {
            title: '24px',
            text: '13px',
            caption: '11px',
        },
        fontWeights: {
            thin: '100',
            extralight: '200',
            light: '300',
            regular: '400',
            medium: '500',
            semibold: '600',
            bold: '700',
            extrabold: '800',
            black: '900',
        },
        fonts: {
            sans: '"Inter var", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            serif: 'ui-serif, Georgia, Cambria, "Times New Roman", Times, serif',
            mono: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
        },
        letterSpacings: {
            title: '-0.019em',
            text: '-0.002em',
            caption: '0.5px',
        },
        lineHeights: {
            // Fixed line-heights
            '3': '.75rem',
            '4': '1rem',
            '5': '1.25rem',
            '6': '1.5rem',
            '7': '1.75rem',
            '8': '2rem',
            '9': '2.25rem',
            '10': '2.5rem',
            title: '28px',
            text: '18px',
            caption: '16px',
        },
        radii: {
            none: '0px',
            sm: '0.125rem',
            default: '0.25rem',
            md: '0.375rem',
            lg: '0.5rem',
            xl: '0.75rem',
            '2xl': '1rem',
            '3xl': '1.5rem',
            full: '9999px',
        },
        shadows: {
            sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            default: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
            md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
            lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
            xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
            '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
            inner: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
            none: '0 0 #0000',
        },
        sizes: {
            '0': '0px',
            px: '1px',
            '0_5': '0.125rem',
            '1': '0.25rem',
            '1_5': '0.375rem',
            '2': '0.5rem',
            '2_5': '0.625rem',
            '3': '0.75rem',
            '3_5': '0.875rem',
            '4': '1rem',
            '5': '1.25rem',
            '6': '1.5rem',
            '7': '1.75rem',
            '8': '2rem',
            '9': '2.25rem',
            '10': '2.5rem',
            '11': '2.75rem',
            '12': '3rem',
            '14': '3.5rem',
            '16': '4rem',
            '20': '5rem',
            '24': '6rem',
            '28': '7rem',
            '32': '8rem',
            '36': '9rem',
            '40': '10rem',
            '44': '11rem',
            '48': '12rem',
            '52': '13rem',
            '56': '14rem',
            '60': '15rem',
            '64': '16rem',
            '72': '18rem',
            '80': '20rem',
            '96': '24rem',
            full: '100%',
            min: 'min-content',
            max: 'max-content',
            fit: 'fit-content',
            xs: '20rem',
            sm: '24rem',
            md: '28rem',
            lg: '32rem',
            xl: '36rem',
            '2xl': '42rem',
            '3xl': '48rem',
            '4xl': '56rem',
            '5xl': '64rem',
            '6xl': '72rem',
            '7xl': '80rem',
            prose: '65ch',
            'screen-sm': '640px',
            'screen-md': '768px',
            'screen-lg': '1024px',
            'screen-xl': '1280px',
            'screen-2xl': '1536px',
        },
        space: {
            '0': '0px',
            px: '1px',
            '0_5': '0.125rem',
            '1': '0.25rem',
            '1_5': '0.375rem',
            '2': '0.5rem',
            '2_5': '0.625rem',
            '3': '0.75rem',
            '3_5': '0.875rem',
            '4': '1rem',
            '5': '1.25rem',
            '6': '1.5rem',
            '7': '1.75rem',
            '8': '2rem',
            '9': '2.25rem',
            '10': '2.5rem',
            '11': '2.75rem',
            '12': '3rem',
            '14': '3.5rem',
            '16': '4rem',
            '20': '5rem',
            '24': '6rem',
            '28': '7rem',
            '32': '8rem',
            '36': '9rem',
            '40': '10rem',
            '44': '11rem',
            '48': '12rem',
            '52': '13rem',
            '56': '14rem',
            '60': '15rem',
            '64': '16rem',
            '72': '18rem',
            '80': '20rem',
            '96': '24rem',
        },
        transitions: {},
        zIndices: {},
    },
    media: {
        sm: '(min-width: 640px)',
        md: '(min-width: 768px)',
        lg: '(min-width: 1024px)',
        xl: '(min-width: 1280px)',
        '2xl': '(min-width: 1536px)',
    },
    utils: {},
});
