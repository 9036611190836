import { Box, keyframes, styled } from '@folkapp/design-system';
import {
  Content,
  Root,
  TooltipContentProps,
  TooltipProps,
  Trigger,
} from '@radix-ui/react-tooltip';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { isMac } from '@react-aria/utils';
import { FC, Fragment, ReactNode } from 'react';

const TooltipContent = styled(Content, {
  borderRadius: '0.25rem',
  padding: '0.375rem',
  color: '#ffffff',
  backgroundColor: '$grey800',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  animationTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
  animationDuration: '150ms',
  maxWidth: '$56',

  '@media (prefers-reduced-motion: no-preference)': {
    $$translateX: 0,
    $$translateY: 0,
    '&[data-state="delayed-open"]': {
      animationName: keyframes({
        '0%': {
          opacity: 0,
          transform: 'translate($$translateX, $$translateY)',
        },
        '100%': {
          opacity: 1,
          transform: 'translate(0, 0)',
        },
      }),
    },
  },

  '&[data-side="top"]': {
    $$translateY: '-0.25rem',
  },
  '&[data-side="right"]': {
    $$translateX: '0.25rem',
  },
  '&[data-side="bottom"]': {
    $$translateY: '0.25rem',
  },
  '&[data-side="left"]': {
    $$translateX: '-0.25rem',
  },
});

export const Tooltip: FC<
  React.PropsWithChildren<
    TooltipProps & TooltipContentProps & { content: ReactNode }
  >
> = ({
  children,
  content,
  open,
  defaultOpen,
  delayDuration = 375,
  onOpenChange,
  ...props
}) => {
  return (
    <Root
      open={open}
      defaultOpen={defaultOpen}
      delayDuration={delayDuration}
      onOpenChange={onOpenChange}
    >
      <Trigger asChild>{children}</Trigger>
      <TooltipContent side="bottom" align="center" sideOffset={4} {...props}>
        {content}
      </TooltipContent>
    </Root>
  );
};

export const TooltipShortcut: FC<
  React.PropsWithChildren<{ shortcut: string }>
> = ({ shortcut, children }) => {
  const tokens = shortcut.split('+').map((token) => {
    switch (token) {
      case 'Mod':
        return isMac() ? '⌘' : 'Ctrl';
      default:
        return token;
    }
  });
  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '0.25rem',
      }}
    >
      <VisuallyHidden.Root>Keyboard shortcut: </VisuallyHidden.Root>
      <Box>
        {tokens.map((token, index) => (
          <Fragment key={index}>
            <Box
              as="kbd"
              css={{
                fontFamily: 'inherit',
                backgroundColor: '#1F2937',
                padding: '0.125rem 0.375rem',
                borderRadius: '0.25rem',
                boxShadow:
                  '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
              }}
            >
              {token}
            </Box>
            {index < tokens.length - 1 && (
              <Box as="span" css={{ margin: '0 0.125rem' }}>
                +
              </Box>
            )}
          </Fragment>
        ))}
      </Box>
      <VisuallyHidden.Root>, </VisuallyHidden.Root>
      {children}
    </Box>
  );
};
