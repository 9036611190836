import { Flex } from '@designSystem';
import { Avatar, AvatarProps, Box, Text } from '@folkapp/design-system';
import { Emoji } from 'app/Components/Emoji';
import { hexToLighterColor } from 'app/utils/colors';
import cx from 'classnames';
import { FC, memo, useCallback } from 'react';

import styles from './Option.module.css';
import {
  ComboBoxOption,
  CreateOption,
  HighlightedOption,
  isCreate,
  isHighlighted,
  isWithEmoji,
  OptionActions,
  OptionVariant,
  OptionWithAvatar,
  OptionWithEmoji,
} from './Option.types';
import { PopoverActions } from './PopoverActions';
import { RemoveOption } from './RemoveOption';

interface OptionProps {
  option: ComboBoxOption;
  variant?: OptionVariant;
  actions?: OptionActions; // not needed for readonly options
  context: 'selected' | 'readonly' | 'popover'; // @note: we could handle `popoverSelected` but it's not useful for now
  hideRemoveIcon?: boolean; // whether to display the "x" icon. Undefined everywhere for now
}

let Option: FC<OptionProps> = ({
  option,
  actions,
  context,
  variant,
  hideRemoveIcon,
}) => {
  // @note: this remove action is only the one related to the "x" icon.
  // The one related to the backspace is implemented in the ComboBox
  const onRemove =
    context === 'selected' && !hideRemoveIcon ? actions?.onRemove : undefined;
  let popoverActions = {};

  if (actions) {
    const { onRemove: _onRemove, onSelect: _onSelect, ...rest } = actions;
    popoverActions = rest;
  }

  if (isCreate(option)) {
    return (
      <div className={styles.container}>
        <OptionCreate {...option} />
      </div>
    );
  }

  return (
    <div
      className={cx(
        styles.container,
        (context === 'selected' || context === 'readonly') &&
          styles.containerInline,
      )}
    >
      <Text
        css={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '$0_5 $1_5',
          borderRadius: '$md',
          overflow: 'hidden',
          ...(isHighlighted(option) && {
            backgroundColor: hexToLighterColor(option.highlightColor),
            fontWeight: '$medium',
          }),
          ...(variant === 'material' && { border: '1px solid $grey200' }),
          ...(variant === 'highlighted' && { backgroundColor: '$grey200' }),
        }}
      >
        {isHighlighted(option) ? (
          <OptionHighlighted option={option} onRemove={onRemove} />
        ) : (
          <OptionWithAvatarOrEmojiLeft
            option={option}
            variant={variant}
            onRemove={onRemove}
          />
        )}
      </Text>

      {context === 'popover' && (
        <PopoverActions actions={popoverActions} option={option} />
      )}

      {
        // there can't be a text on the right if there are actions
        context === 'popover' &&
          !isHighlighted(option) &&
          Object.keys(popoverActions).length === 0 &&
          option.textRight && (
            <div className={styles.containerTextRight}>
              <div className={styles.textRight}>{option.textRight}</div>
            </div>
          )
      }
    </div>
  );
};

Option = memo(Option);

export { Option };

const OptionHighlighted: FC<{
  option: HighlightedOption;
  onRemove?: (optionId: string) => void;
}> = ({ option: { id, text }, onRemove }) => {
  const handleRemove = useCallback(() => {
    onRemove?.(id);
  }, [id, onRemove]);
  return (
    <Flex
      css={{
        display: 'inline-flex',
        alignItems: 'center',
        flex: 1,
        minWidth: 0,
        color: '$grey800',
      }}
    >
      <Box
        css={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </Box>
      {onRemove && <RemoveOption onRemove={handleRemove} />}
    </Flex>
  );
};

const OptionCreate: FC<CreateOption> = ({ label, text }) => (
  <div className={styles.containerCreate}>
    {label && <div className={styles.labelCreate}>{label}</div>}

    <div className={styles.textCreate}>{text}</div>
  </div>
);

export const resolveOptionAvatarVariant = (
  option: OptionWithAvatar,
  variant?: OptionVariant,
): AvatarProps['variant'] => {
  const isAvatarSquared =
    variant === 'squareAvatar' || option.variant === 'squareAvatar';
  if (isAvatarSquared) {
    return 'company';
  }
  return variant === 'highlighted' ? 'user' : 'person';
};

const OptionWithAvatarOrEmojiLeft: FC<{
  option: OptionWithAvatar | OptionWithEmoji;
  variant: OptionProps['variant'];
  onRemove?: (optionId: string) => void;
}> = ({ option, variant, onRemove }) => {
  const handleRemove = useCallback(() => {
    onRemove?.(option.id);
  }, [option.id, onRemove]);

  return (
    <>
      <div className={styles.media}>
        {isWithEmoji(option) ? (
          <Emoji emoji={option.emoji} size={16} />
        ) : (
          <Avatar
            size="xs"
            id={option.id}
            name={option.textRight ?? option.text}
            src={option.picture}
            variant={resolveOptionAvatarVariant(option, variant)}
          />
        )}
      </div>

      <div className={styles.textLeft}>{option.text}</div>

      {onRemove && <RemoveOption onRemove={handleRemove} />}
    </>
  );
};
