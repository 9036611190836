import { makeVar, ReactiveVar, useReactiveVar } from '@apollo/client';
import union from 'lodash/union';
import { useCallback, useMemo } from 'react';

import { contactSearchQuery } from './contactSearcQuery';
import { AppStateSelectionInterface } from './types/appState';

export const initialSelection = {
  all: false,
  selected: [],
  unselected: [],
};

export const selection: ReactiveVar<AppStateSelectionInterface> =
  makeVar<AppStateSelectionInterface>(initialSelection);

type SelectionAction =
  | {
      type: 'RESET_CONTACT_SELECTION';
    }
  | {
      type: 'SELECT_ALL_CONTACTS';
    }
  | {
      type: 'SELECT_CONTACTS';
      payload: string[];
    }
  | {
      type: 'UNSELECT_CONTACTS';
      payload: string[];
    }
  | {
      type: 'TOGGLE_CONTACT';
      payload: string;
    };

// TODO: improve the types to narrow them down by action
function selectionReducer(
  state: AppStateSelectionInterface = initialSelection,
  action: SelectionAction,
) {
  switch (action.type) {
    case 'RESET_CONTACT_SELECTION': {
      return { ...initialSelection };
    }
    case 'SELECT_ALL_CONTACTS': {
      return {
        ...state,
        all: true,
        unselected: [],
        selected: [],
      };
    }
    case 'SELECT_CONTACTS': {
      const { all, selected, unselected } = state;

      let _selected: string[] = selected || [];
      let _unselected: string[] = unselected || [];

      const selection = action.payload;

      _unselected = _unselected.filter((i) => !selection.includes(i));
      _selected = union(_selected, selection).filter((i) => i !== undefined);

      return {
        ...state,
        selected: all ? [] : _selected,
        unselected: all ? _unselected : [],
      };
    }
    case 'UNSELECT_CONTACTS': {
      const { all, selected, unselected } = state;
      let _selected: string[] = selected || [];
      let _unselected: string[] = unselected || [];

      const selection = action.payload;

      _selected = _selected.filter((i) => !selection.includes(i));
      _unselected = union(_unselected, selection);

      return {
        ...state,

        selected: all ? [] : _selected,
        unselected: all ? _unselected : [],
      };
    }
    case 'TOGGLE_CONTACT': {
      const id = action.payload;
      if (!id) return state;
      const { all, selected, unselected } = state;
      const isSelected = all ? unselected.includes(id) : !selected.includes(id);
      const newSelected = isSelected
        ? selected.concat(id).filter((i) => i !== undefined)
        : selected.filter((i) => i !== id);
      const newUnselected = isSelected
        ? unselected.filter((i) => i !== id)
        : unselected.concat(id);

      return {
        ...state,
        selected: all ? [] : newSelected,
        unselected: all ? newUnselected : [],
      };
    }
    default:
      return state;
  }
}

export const updateAppStateSelection = (action: SelectionAction) => {
  try {
    const newState = selectionReducer(selection(), action);
    return selection(newState);
  } catch (error) {
    console.error('app-state', error);
    return error;
  }
};

export const useAppStateActions = () => {
  const resetSelection = useCallback(() => {
    updateAppStateSelection({
      type: 'RESET_CONTACT_SELECTION',
    });
  }, []);

  const select = useCallback((ids: string[]) => {
    updateAppStateSelection({
      type: 'SELECT_CONTACTS',
      payload: ids,
    });
  }, []);

  const unselect = useCallback((ids: string[]) => {
    updateAppStateSelection({
      type: 'UNSELECT_CONTACTS',
      payload: ids,
    });
  }, []);

  const toggleSelection = useCallback((id: string) => {
    updateAppStateSelection({
      type: 'TOGGLE_CONTACT',
      payload: id,
    });
  }, []);

  const selectAll = useCallback(() => {
    updateAppStateSelection({
      type: 'SELECT_ALL_CONTACTS',
    });
  }, []);

  const actions = useMemo(
    () => ({ toggleSelection, selectAll, resetSelection, select, unselect }),
    [resetSelection, select, selectAll, toggleSelection, unselect],
  );

  return actions;
};

export const useAppStateSelectionQuery = () => {
  return useReactiveVar(selection);
};

export const useSelection = () => {
  return useReactiveVar(selection);
};

export const getSelection = (): AppStateSelectionInterface => {
  return selection();
};

export function useContactSearchQuery() {
  return useReactiveVar(contactSearchQuery);
}

export function updateContactSearchQuery(query: string = '') {
  return contactSearchQuery(query);
}
