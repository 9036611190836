import { RefObject } from 'react';

export const setFocusToContactPanelField = (
  emptyValueFieldContainerRef: RefObject<HTMLDivElement | null>,
) => {
  const button = emptyValueFieldContainerRef.current?.querySelector('button');
  const input = emptyValueFieldContainerRef.current?.querySelector('input');

  if (button) {
    button.click();
  } else if (input) {
    input.focus();
  } else if (emptyValueFieldContainerRef.current) {
    emptyValueFieldContainerRef.current.click();
  }
};
