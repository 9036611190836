import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';

const AddCustomFieldContext = createContext<
  State & {
    dispatch: Dispatch<Action>;
  }
>({ added: undefined, editing: undefined, dispatch: () => {} });

export function useAddCustomFieldContext() {
  const context = useContext(AddCustomFieldContext);
  if (context === undefined) {
    throw new Error(
      'useAddCustomFieldContext must be used within a AddCustomFieldContext',
    );
  }

  return context;
}

interface AddCustomFieldProviderProps {
  children: ReactNode;
}

type State = { added: string | undefined; editing: string | undefined };
type Action =
  | { type: 'add'; customFieldId: string }
  | { type: 'edit'; customFieldId: string }
  | { type: 'clear' };

const initialState: State = { added: undefined, editing: undefined };

const addCustomFieldReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'add':
      return { ...state, added: action.customFieldId };
    case 'edit':
      return { ...state, editing: action.customFieldId };
    case 'clear':
      return initialState;
    default:
      return state;
  }
};

export const AddCustomFieldProvider = ({
  children,
}: AddCustomFieldProviderProps) => {
  const [state, dispatch] = useReducer(addCustomFieldReducer, initialState);

  const value = useMemo(
    () => ({
      ...state,
      dispatch,
    }),
    [state, dispatch],
  );

  return (
    <AddCustomFieldContext.Provider value={value}>
      {children}
    </AddCustomFieldContext.Provider>
  );
};
