import { ContactCustomFieldFragment } from '__generated__/graphql';
import { FieldMergeFunction } from '@apollo/client';

import { CacheEntry } from './cacheEntry';

const uniqBy = <T>(isEqual: (item: T, otherItem: T) => boolean, array: T[]) => {
  return array.filter((item, index, array) => {
    return array.findIndex((otherItem) => isEqual(item, otherItem)) === index;
  });
};

export const mergeCustomFields: FieldMergeFunction<
  CacheEntry<ContactCustomFieldFragment>[]
> = (existing, incoming, { cache }) => {
  if (!existing) {
    return incoming;
  }

  const merged = uniqBy(
    (customField, otherCustomField) => {
      return (
        cache.identify(customField.field) ===
        cache.identify(otherCustomField.field)
      );
    },
    [...incoming, ...existing],
  );

  return merged;
};
