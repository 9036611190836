import { GroupFragment, NetworkMemberFragment } from '__generated__/graphql';

export const Network = {
  fields: {
    privateGroups: {
      merge(_prev: GroupFragment[], next: GroupFragment[]) {
        return next;
      },
    },
    members: {
      merge(_prev: NetworkMemberFragment[], next: NetworkMemberFragment[]) {
        return next;
      },
    },
    sharedGroups: {
      merge(_prev: GroupFragment[], next: GroupFragment[]) {
        return next;
      },
    },
    publicGroups: {
      merge(_prev: GroupFragment[], next: GroupFragment[]) {
        return next;
      },
    },
  },
};
