import { config, MdCheck } from '@folkapp/design-system';
import { useRoveFocus } from 'app/utils/lists';
import { FC, Fragment, ReactNode, useCallback, useMemo, useState } from 'react';

import { Flex } from '../../../../../@designSystem';
import { PopoverOption, usePopoverContext } from '../..';
import { PopoverEditOption } from '../PopoverEditOption';
import { PopoverNestedOption } from '../PopoverNestedOption';
import { PopoverSectionHeader } from './components/PopoverSectionHeader/PopoverSectionHeader';
import { SectionsSeparator } from './components/PopoverSectionSeparator';
import styles from './PopoverOptions.module.css';

// this is weak but it works and remains simple
export interface PopoverOptionsSingleOptionProp {
  // nested option
  sections?: PopoverOptionsSection[];
  // edit option
  onChange?: (value: string) => void;
  onEnter?: (value: string) => void;
  value?: string;
  // single option
  id?: string;
  label?: string;
  onAction?: VoidFunction;
  icon?: ReactNode;
  emoji?: string | null;
  closeOnAction?: boolean;
  hasFocus?: boolean;
  onHover?: VoidFunction;
  children?: JSX.Element;
  variant?: 'error' | 'switch' | 'success';
  activeSwitch?: boolean;
  selected?: boolean;
}

export interface PopoverSectionHeaderProps {
  label?: string;
}

export type PopoverOptionsSection = {
  options: PopoverOptionsSingleOptionProp[];
  header?: PopoverSectionHeaderProps | null;
  separator?: boolean;
};

export interface PopoverOptionsProps {
  sections: PopoverOptionsSection[];
  closeOnAction?: boolean;
  isNested?: boolean;
  onClose?: VoidFunction;
}

export const PopoverOptions: FC<PopoverOptionsProps> = ({
  sections,
  closeOnAction,
  isNested,
  onClose,
}) => {
  const { close } = usePopoverContext();
  const allOptions = useMemo(
    () => sections.map(({ options }) => options).flat(),
    [sections],
  );
  const headerIndexes = getHeaderIndexes(sections);
  const [labelEntered, setLabelEntered] = useState<string | undefined>(
    undefined,
  );

  const handleEnter = useCallback(
    (label: string | undefined) => {
      setLabelEntered(label);
      const option = allOptions.find((o) => o.label === label);

      option?.onAction?.();

      if (option?.closeOnAction || closeOnAction) {
        close?.();
        onClose?.();
      }
    },
    [allOptions, closeOnAction, close, onClose],
  );

  const { currentFocus, keyboardProps, setCurrentFocus } = useRoveFocus({
    ids: allOptions.map((o) => o?.label ?? ''),
    popoverIsOpen: true,
    inputValue: '',
    onEnter: handleEnter,
    onEscape: () => {
      close?.();
      onClose?.();
    },
  });

  const getHeader = useCallback(
    (index: any) => {
      return sections[headerIndexes.indexOf(index)]?.header;
    },
    [sections, headerIndexes],
  );

  const separatorMapping = useMemo(() => {
    return sections.reduce<boolean[]>((acc, section) => {
      section.options.forEach((_, index) =>
        acc.push(!!section.separator && index === section.options.length - 1),
      );
      return acc;
    }, []);
  }, [sections]);

  return (
    <div
      {...keyboardProps}
      className={styles.container}
      tabIndex={isNested ? undefined : 0}
    >
      {allOptions.map((option, index) => {
        return (
          <Fragment key={option?.id || option.label}>
            {headerIndexes.includes(index) && (
              <PopoverSectionHeader {...getHeader(index)} />
            )}

            {/* TODO: get rid of the ternaaaaaary */}
            {option.sections ? (
              <PopoverNestedOption
                key={option.label}
                sections={option.sections}
                label={option.label!}
                icon={option.icon}
                hasFocus={currentFocus === index}
                onHover={() => {
                  setCurrentFocus(index);
                  setLabelEntered(undefined);
                  option.onHover?.();
                }}
                triggerOpen={labelEntered === option.label}
              />
            ) : option.onEnter ? (
              <PopoverEditOption
                key={option.label}
                label={option.label!}
                value={option.value!}
                onEnter={option.onEnter}
                onChange={option.onChange}
                hasFocus={currentFocus === index}
                onHover={() => {
                  setCurrentFocus(index);
                  option.onHover?.();
                }}
              />
            ) : (
              <PopoverOption
                {...option}
                closeOnAction={option?.closeOnAction || closeOnAction}
                key={option.label}
                hasFocus={currentFocus === index}
                onHover={() => {
                  setCurrentFocus(index);
                  option.onHover?.();
                }}
                isNested={isNested}
              >
                {option.selected ? (
                  <Flex
                    css={{
                      flex: 1,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <MdCheck
                      color={config.theme.colors.grey900}
                      size={14}
                      style={{ display: 'inline-block' }}
                    />
                  </Flex>
                ) : undefined}
              </PopoverOption>
            )}

            {separatorMapping[index] && <SectionsSeparator />}
          </Fragment>
        );
      })}
    </div>
  );
};

function getHeaderIndexes(sections: PopoverOptionsSection[]) {
  let t = 0;
  const indexes: (number | null)[] = [];
  sections.forEach((s) => {
    if (s.header) {
      indexes.push(t);
    } else {
      indexes.push(null);
    }
    t += s.options.length;
  });
  return indexes;
}
