import { UserFragment } from '__generated__/graphql';
import { v4 as uuid } from 'uuid';

export function makeUser({
  id = uuid(),
  email = '',
  fullname = '',
  firstname = '',
  lastname = '',
  picture = null,
  needOnboarding = false,
  onboardingStep = 0,
  acquisitionTags = null,
  ...props
}: Partial<UserFragment> = {}): UserFragment {
  return {
    __typename: 'User',
    id,
    email,
    fullname,
    firstname,
    lastname,
    picture,
    needOnboarding,
    onboardingStep,
    acquisitionTags,
    ...props,
  };
}
