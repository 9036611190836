import { GroupViewSettingsFragment } from '__generated__/graphql';

export const GroupSettings = {
  fields: {
    filters: {
      merge(
        _ignored: GroupViewSettingsFragment['filters'],
        incoming: GroupViewSettingsFragment['filters'],
      ) {
        return incoming;
      },
    },
    companyFields: {
      merge(_ignored: any[], incoming: any[]) {
        return incoming;
      },
    },
    personFields: {
      merge(_ignored: any[], incoming: any[]) {
        return incoming;
      },
    },
  },
};
