import {
  CompanyFragment,
  ContactContextInput,
  ContactListDocument,
  ContactListQuery,
  ContactListQueryVariables,
  ContactPaginationInput,
  ContactType,
  GetContactQuery,
  GetContactQueryVariables,
  GetPrimaryPeopleQuery,
  GetPrimaryPeopleQueryVariables,
  PersonFragment,
  useContactListLazyQuery,
  useContactListQuery,
  useGetContactLazyQuery,
  useGetContactQuery,
  useGetPrimaryPeopleQuery,
} from '__generated__/graphql';
import {
  ApolloClient,
  LazyQueryHookOptions,
  QueryHookOptions,
  useApolloClient,
} from '@apollo/client';
import {
  CompanyFragments,
  PersonFragments,
} from 'app/operations/remote/fragments/contact';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useContextInput, useGetQueryVariables, usePagination } from './utils';

export enum SortField {
  LAST_NAME = 'lastname',
  FIRST_NAME = 'firstname',
  LAST_INTERACTION = 'lastInteraction',
  NUMBER_INTERACTIONS = 'totalInteractions',
}

export function useContact(
  { contactId, contactType }: { contactId: string; contactType: ContactType },
  options?: QueryHookOptions<GetContactQuery, GetContactQueryVariables>,
) {
  const { networkId, groupId } =
    useParams<{ networkId: string; groupId?: string }>();

  return useGetContactQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        contactId,
        networkId,
        contactType,
      },
      groupId,
    },
    ...options,
  });
}

export function useLazyContact(
  options?: QueryHookOptions<GetContactQuery, GetContactQueryVariables>,
) {
  return useGetContactLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options,
  });
}

export function usePrimaryPeople(
  options?: QueryHookOptions<
    GetPrimaryPeopleQuery,
    GetPrimaryPeopleQueryVariables
  >,
) {
  return useGetPrimaryPeopleQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    ...options,
  });
}

export function useContacts(
  options?: QueryHookOptions<ContactListQuery, ContactListQueryVariables>,
  {
    page,
    limit,
    sortField,
    contactType,
  }: Pick<ContactContextInput, 'contactType'> &
    Pick<ContactPaginationInput, 'page' | 'limit' | 'sortField'> = {},
) {
  const context = useContextInput();
  const pagination = usePagination();

  const variables = {
    context: {
      ...context,
      contactType: contactType || context.contactType,
    },
    pagination: {
      ...pagination,
      // Sometimes we don’t want our query to be reloaded
      // when page changes.
      page: page ?? (pagination.page || 0),
      limit: limit || pagination.limit,
      sortField: sortField || pagination.sortField,
    },
    selection: {},
    groupId: context.groupId ?? undefined,
  };
  return useContactListQuery({
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options,
  });
}

export function useLazyContacts(
  options: LazyQueryHookOptions<ContactListQuery, ContactListQueryVariables>,
  { page }: Pick<ContactPaginationInput, 'page'> = {},
) {
  const context = useContextInput();
  const pagination = usePagination();

  return useContactListLazyQuery({
    variables: {
      context,
      pagination: {
        ...pagination,
        // Sometimes we don’t want our query to be reloaded
        // when page changes.
        page: page ?? (pagination.page || 0),
      },
      selection: {},
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options,
  });
}

export const useUpdateContacts = () => {
  const client = useApolloClient();
  const getQueryVariables = useGetQueryVariables();

  return useCallback(
    <T extends PersonFragment | CompanyFragment>({
      modifier,
      totalModifier,
    }: {
      modifier?: (contacts: T[]) => T[];
      totalModifier?: (total: number) => number;
    }) => {
      const variables = getQueryVariables();

      const queryResult = client.readQuery<
        ContactListQuery,
        ContactListQueryVariables
      >({
        query: ContactListDocument,
        variables: {
          ...variables,
          selection: {},
          groupId: variables.context.groupId ?? undefined,
        },
      });

      const contacts = queryResult?.contacts;

      if (contacts) {
        const newContacts =
          modifier?.((contacts.contacts || []) as T[]) || contacts.contacts;

        client.writeQuery<ContactListQuery, ContactListQueryVariables>({
          query: ContactListDocument,
          variables: {
            ...variables,
            selection: {},
            groupId: variables.context.groupId ?? undefined,
          },
          data: {
            contacts: {
              ...contacts,
              total: totalModifier?.(contacts.total ?? 0) ?? contacts.total,
              contacts: newContacts,
            },
          },
        });
      }
    },
    [client, getQueryVariables],
  );
};

export function getContact<T extends CompanyFragment | PersonFragment>(
  client: ApolloClient<object>,
  id: string | null,
  isPeopleType: boolean = true,
): T | null {
  if (!id) return null;
  let typename = isPeopleType ? 'Person' : 'Company';
  let contact = client.readFragment<T>({
    id: `${typename}:${id}`,
    fragmentName: typename,
    fragment: isPeopleType ? PersonFragments.person : CompanyFragments.company,
  });

  // If we failed to find the contact, we try the other contact type.
  if (!contact) {
    typename = typename === 'Person' ? 'Company' : 'Person';
    contact = client.readFragment<T>({
      id: `${typename}:${id}`,
      fragmentName: typename,
      fragment:
        typename === 'Person'
          ? PersonFragments.person
          : CompanyFragments.company,
    });
  }

  return contact;
}

export const useGetContact = () => {
  const client = useApolloClient();
  const { groupId } = useParams<{ groupId?: string }>();

  return useCallback(
    <T extends CompanyFragment | PersonFragment>(
      id: string | null,
      isPeopleType: boolean = true,
    ): T | null => {
      if (!id) return null;
      let typename = isPeopleType ? 'Person' : 'Company';

      let contact = client.readFragment<T>({
        id: `${typename}:${id}`,
        fragmentName: typename,
        fragment: isPeopleType
          ? PersonFragments.person
          : CompanyFragments.company,
        variables: {
          groupId,
        },
      });

      // If we failed to find the contact, we try the other contact type.
      if (!contact) {
        typename = typename === 'Person' ? 'Company' : 'Person';
        contact = client.readFragment<T>({
          id: `${typename}:${id}`,
          fragmentName: typename,
          fragment:
            typename === 'Company'
              ? PersonFragments.person
              : CompanyFragments.company,
          variables: {
            groupId,
          },
        });
      }

      return contact;
    },
    [client, groupId],
  );
};

export const useUpdateContactFragment = () => {
  const client = useApolloClient();
  const getContact = useGetContact();
  const { groupId } = useParams<{ groupId?: string }>();

  return useCallback(
    <T extends CompanyFragment | PersonFragment>(
      id: string,
      modifier: (contact: T) => Partial<T>,
      isPeopleType: boolean = true,
    ) => {
      const storedContact = getContact<T>(id, isPeopleType);

      if (storedContact) {
        const typename =
          storedContact.__typename === 'Person' ? 'Person' : 'Company';

        client.writeFragment<T, T>({
          id: `${typename}:${id}`,
          fragmentName: typename,
          fragment: isPeopleType
            ? PersonFragments.person
            : CompanyFragments.company,
          data: {
            ...storedContact,
            ...modifier(storedContact),
          },
          variables: {
            groupId,
          } as any,
        });
      }
    },
    [client, getContact, groupId],
  );
};
