import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export const IntegrationPanelContext = createContext({
  isOpen: false,
  setIsOpen: (_: boolean) => {},
});

export const IntegrationPanelProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const providerValue = useMemo(
    () => ({
      isOpen,
      setIsOpen,
    }),
    [isOpen],
  );

  return (
    <IntegrationPanelContext.Provider value={providerValue}>
      {children}
    </IntegrationPanelContext.Provider>
  );
};

export const useIsIntegrationPanelOpen: () => [
  boolean,
  (isOpen: boolean) => void,
] = () => {
  const { isOpen, setIsOpen } = useContext(IntegrationPanelContext);
  return [isOpen, setIsOpen];
};
