import {
  ContactType,
  UpdateCompanyValueMutation,
  UpdateCompanyValueMutationVariables,
  UpdatePersonValueMutation,
  UpdatePersonValueMutationVariables,
  useUpdateCompanyValueMutation,
  useUpdatePersonValueMutation,
} from '__generated__/graphql';
import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  MutationUpdaterFunction,
} from '@apollo/client';
import { useCallback } from 'react';

import { updateContactNativeFieldModifier } from '../contactNativeFieldModifiers';

const contactPropertyByType = {
  address: 'addresses',
  email: 'emails',
  phone: 'phones',
  url: 'urls',
} as const;

export const useContactUpdateValue = ({
  contactType,
  options,
}: {
  contactType: ContactType;
  options?: MutationHookOptions<
    UpdatePersonValueMutation | UpdateCompanyValueMutation,
    UpdatePersonValueMutationVariables | UpdateCompanyValueMutationVariables
  >;
}) => {
  const update = useCallback<
    MutationUpdaterFunction<
      UpdatePersonValueMutation | UpdateCompanyValueMutation,
      UpdatePersonValueMutationVariables | UpdateCompanyValueMutationVariables,
      DefaultContext,
      ApolloCache<any>
    >
  >(
    (cache, { data }, { variables }) => {
      if (!variables || !data?.multiValue) {
        return;
      }

      const contactProperty = contactPropertyByType[variables.input.type];

      cache.modify({
        id: cache.identify({
          __typename: contactType === ContactType.person ? 'Person' : 'Company',
          id: variables.input.contactId,
        }),
        fields: {
          [contactProperty]: updateContactNativeFieldModifier({
            cache,
            nativeField: data.multiValue,
          }),
        },
      });
    },
    [contactType],
  );

  const updatePersonValueMutation = useUpdatePersonValueMutation({
    update,
    ...options,
  });
  const updateCompanyValueMutation = useUpdateCompanyValueMutation({
    update,
    ...options,
  });

  return contactType === ContactType.person
    ? updatePersonValueMutation
    : updateCompanyValueMutation;
};
