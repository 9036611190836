import { useLogasMutation } from '__generated__/graphql';
import { useToasts } from '@folkapp/design-system';
import { useSignInWithAccessToken } from 'app/services/graphQLService';
import { useLogout } from 'app/services/graphQLService/graphQLService';
import { setLogasEmail } from 'app/services/local/user';
import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';

export const LogasRoute: FC = () => {
  const { token } = useParams<{ token: string }>();
  const signInWithAccessToken = useSignInWithAccessToken();
  const { addToast } = useToasts();
  const logout = useLogout();

  const [logas] = useLogasMutation({
    variables: {
      token,
    },
  });

  const setLogas = useCallback(async () => {
    await logout();

    try {
      const userLogin = await logas();

      if (!userLogin.data) {
        throw new Error('[LogasRoute.setLogas] No data found');
      }

      const { token: logasToken, userForest: logasEmail } =
        userLogin.data?.logas;

      await signInWithAccessToken(logasToken);
      setLogasEmail(logasEmail);
    } catch (error: any) {
      console.error({ error });
      const errorMessage =
        error.message === 'invalid-token'
          ? 'The token is invalid'
          : 'Check the error in the console!';
      addToast('Cannot log as', {
        description: errorMessage,
        variant: 'error',
      });
    }
  }, [logas, signInWithAccessToken, addToast, logout]);

  useEffect(() => {
    setLogas();
  }, [setLogas]);

  return null;
};
