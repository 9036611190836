import {
  DeleteGroupMutation,
  DeleteGroupMutationVariables,
  GetGroupQuery,
  GetGroupQueryVariables,
  GroupCreateMutation,
  GroupCreateMutationVariables,
  GroupDuplicateMutation,
  GroupDuplicateMutationVariables,
  GroupFragment,
  GroupListViewsDocument,
  GroupListViewsQuery,
  GroupListViewsQueryVariables,
  GroupViewFragment,
  useDeleteGroupMutation,
  useGetGroupLazyQuery,
  useGetGroupQuery,
  useGroupCreateMutation,
  useGroupDuplicateMutation,
  useNetworkGroupUpdatesSubscription,
} from '__generated__/graphql';
import {
  ApolloClient,
  MutationHookOptions,
  QueryHookOptions,
  useApolloClient,
} from '@apollo/client';
import { GroupFragments } from 'app/operations/remote/fragments/group';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import type { NetworkGroupRouteParams } from '../../types/networkGroupRouteParams';
import { getAccessToken } from '../graphQLService';
import { updateNetwork } from './network';
import { useUser } from './user';

/**
 * fetches a group given its id
 */
export const useGroup = (
  groupId: string,
  options?: QueryHookOptions<GetGroupQuery, GetGroupQueryVariables>,
) => {
  return useGetGroupQuery({
    nextFetchPolicy: 'cache-first',
    variables: { id: groupId },
    ...options,
  });
};

/**
 * Fetches a group from the url groupId.
 */
export const useGroupFromUrl = () => {
  const { groupId } = useParams<NetworkGroupRouteParams>();
  const [fetch, { data }] = useGetGroupLazyQuery();
  useEffect(() => {
    if (groupId) {
      fetch({
        variables: {
          id: groupId,
        },
      });
    }
  }, [groupId, fetch]);
  return data?.group;
};

/**
 * Lazy fetches a group given its id.
 */
export const useLazyGroup = (
  options?: QueryHookOptions<GetGroupQuery, GetGroupQueryVariables>,
) => {
  return useGetGroupLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options,
  });
};

/**
 * get group given its ids from the cache
 */
export function getGroup(client: ApolloClient<any>, id: string | null) {
  if (id === null) return null;
  return client.readFragment<GroupFragment>({
    id: `Group:${id}`,
    fragmentName: 'Group',
    fragment: GroupFragments.group,
  });
}

/**
 * create a group
 */
export function useCreateGroup(
  options?: MutationHookOptions<
    GroupCreateMutation,
    GroupCreateMutationVariables
  >,
) {
  const client = useApolloClient();
  return useGroupCreateMutation({
    update(_, { data }) {
      const group = data?.group;

      if (group) {
        updateNetwork(client, group.networkId, (network) => ({
          ...network,
          privateGroups: group.openAccess
            ? network.privateGroups || []
            : [group, ...(network.privateGroups || [])],
          publicGroups: group.openAccess
            ? [group, ...(network.publicGroups || [])]
            : network.publicGroups || [],
        }));
      }
    },
    ...options,
  });
}

/**
 * duplicate a group
 */
export const useDuplicateGroup = (
  options?: MutationHookOptions<
    GroupDuplicateMutation,
    GroupDuplicateMutationVariables
  >,
) => {
  const [duplicateGroup, result] = useGroupDuplicateMutation(options);
  const client = useApolloClient();
  const { networkId } = useParams<{ networkId: string }>();
  const duplicate = useCallback(
    ({ group, newGroupId }: { group: GroupFragment; newGroupId: string }) => {
      return duplicateGroup({
        variables: {
          networkId,
          groupId: group.id,
          newGroupId,
        },
        optimisticResponse: {
          group: {
            ...group,
            createdBy: '',
            id: newGroupId,
            name: 'Copy of ' + group.name,
            openAccess: false,
          },
        },
        update(_, { data }) {
          const newGroup = data?.group;
          if (networkId && newGroup) {
            updateNetwork(client, networkId, (network) => {
              return {
                ...network,
                privateGroups: network.privateGroups.concat(newGroup),
              };
            });
          }
        },
      });
    },
    [client, networkId, duplicateGroup],
  );
  return { duplicate, result };
};

/**
 * remove a group
 */
export const useRemoveGroup = (
  options?: MutationHookOptions<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >,
) => {
  const [mutation, result] = useDeleteGroupMutation(options);
  const client = useApolloClient();
  const { networkId } = useParams<{ networkId: string }>();
  const remove = useCallback(
    (group: GroupFragment) => {
      return mutation({
        variables: {
          networkId,
          groupId: group.id,
        },
        optimisticResponse: {
          deleted: true,
        },
        update(_, { data }) {
          const deleted = data?.deleted;
          if (deleted) {
            updateNetwork(client, networkId, (network) => ({
              ...network,
              publicGroups:
                network.publicGroups?.filter((g) => g?.id !== group.id) || [],
              sharedGroups:
                network.sharedGroups?.filter((g) => g?.id !== group.id) || [],
              privateGroups:
                network.privateGroups?.filter((g) => g?.id !== group.id) || [],
            }));
          }
        },
      });
    },
    [mutation, client, networkId],
  );

  return { remove, result };
};

export type GenericGroupModifier = (groups: GroupFragment[]) => GroupFragment[];

export const updateGroup = (
  client: ApolloClient<object>,
  id: string,
  modifier: (group: GroupFragment) => GroupFragment,
) => {
  const group = getGroup(client, id);
  if (group) {
    const typename = 'Group';
    client.writeFragment<GroupFragment>({
      id: `${typename}:${id}`,
      fragment: GroupFragments.group,
      fragmentName: 'Group',
      data: modifier(group),
    });
  }
};

export function useGroupUpdatesSubscription({
  networkId,
}: {
  networkId: string;
}) {
  const userId = useUser()?.id ?? '';
  const client = useApolloClient();
  return useNetworkGroupUpdatesSubscription({
    onSubscriptionData({ subscriptionData }) {
      const group = subscriptionData.data?.group;
      if (group) {
        updateGroup(client, group.id, (oldGroup) => ({
          ...oldGroup,
          name: group.name ?? oldGroup.name,
          emoji: group.emoji ?? oldGroup.emoji,
          openAccess:
            typeof group.openAccess === 'boolean'
              ? group.openAccess
              : oldGroup.openAccess,
          totalPeople: group.totalPeople ?? oldGroup.totalPeople,
          totalCompanies: group.totalCompanies ?? oldGroup.totalCompanies,
          __typename: 'Group',
        }));
      }
    },
    variables: {
      authorization: getAccessToken()!,
      userId,
      networkId,
    },
  });
}

function getGroupQueryViews(client: ApolloClient<object>, groupId: string) {
  return client.readQuery<GroupListViewsQuery, GroupListViewsQueryVariables>({
    query: GroupListViewsDocument,
    variables: { groupId },
  });
}

export function updateGroupViews(
  client: ApolloClient<object>,
  groupId: string,
  modifier: (views: GroupViewFragment[]) => GroupViewFragment[],
) {
  const views = getGroupQueryViews(client, groupId)?.views;
  if (views) {
    return client.writeQuery<GroupListViewsQuery, GroupListViewsQueryVariables>(
      {
        query: GroupListViewsDocument,
        variables: { groupId },
        data: {
          views: modifier(views),
        },
      },
    );
  }
  return null;
}
