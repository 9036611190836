import { Box, keyframes, styled } from '@folkapp/design-system';

export const Skeleton = styled(Box, {
  borderRadius: '$md',
  animationName: keyframes({
    '0%, 100%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
    },
  }).toString(),
  animationDuration: '2s',
  animationTimingFunction: 'cubic-bezier(0.4, 0, 0.6, 1)',
  animationIterationCount: 'infinite',
});
