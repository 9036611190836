import { Box, keyframes, Spinner } from '@folkapp/design-system';

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

export const ContactImporterLoader = () => {
  return (
    <Box
      role="alert"
      aria-live="assertive"
      aria-label="Loading CSV importer"
      css={{
        position: 'fixed',
        inset: 0,
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
          content: '""',
          position: 'absolute',
          inset: 0,
          // Same backdrop color as flatfile
          background: 'rgba(0,0,0,0.5)',
          zIndex: -1,
          animationName: fadeIn.toString(),
          animationTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
          animationDuration: '375ms',
          animationFillMode: 'both',
        },
      }}
    >
      <Spinner size="sm" css={{ color: '$white' }} />
    </Box>
  );
};
