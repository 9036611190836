export type UseCase = {
  value: string;
  label: string;
  groups: { name: string; icon: string }[];
};

export const defaultUseCases = [
  {
    value: 'Default',
    label: 'Default',
    groups: [
      {
        name: 'Colleagues',
        icon: ':people_holding_hands:',
      },
      {
        name: 'Clients',
        icon: ':handshake:',
      },
      {
        name: 'Alumni',
        icon: ':house:',
      },
    ],
  },
];

export const useCases: UseCase[] = [
  {
    value: 'Real Estate',
    label: 'Real Estate',
    groups: [
      {
        name: 'Estate agents',
        icon: ':house:',
      },
      {
        name: 'Contractors',
        icon: ':page_facing_up:',
      },
      {
        name: 'Partners',
        icon: ':handshake:',
      },
    ],
  },
  {
    value: 'Fundraising',
    label: 'Fundraising',
    groups: [
      {
        name: 'Business angels',
        icon: ':money_with_wings:',
      },
      {
        name: 'VCs',
        icon: ':bar_chart:',
      },
    ],
  },
  {
    value: 'Investing',
    label: 'Investing',
    groups: [
      {
        name: 'Investors',
        icon: ':moneybag:',
      },
      {
        name: 'Portfolio company',
        icon: ':chart_with_upwards_trend:',
      },
      {
        name: 'Founders',
        icon: ':house:',
      },
    ],
  },
  {
    value: 'PR/Medias',
    label: 'PR/Medias',
    groups: [
      {
        name: 'Journalists',
        icon: ':newspaper:',
      },
      {
        name: 'Influencers',
        icon: ':iphone:',
      },
      {
        name: 'Agencies',
        icon: ':house:',
      },
    ],
  },
  {
    value: 'Partnerships',
    label: 'Partnerships',
    groups: [
      {
        name: 'Partners',
        icon: ':handshake:',
      },
      {
        name: 'Suppliers',
        icon: ':gear:',
      },
      {
        name: 'Distributors',
        icon: ':cyclone:',
      },
    ],
  },
  {
    value: 'Recruiting',
    label: 'Recruiting',
    groups: [
      {
        name: 'Talents',
        icon: ':hand:',
      },
      {
        name: 'Recruiting agencies',
        icon: ':mag_right:',
      },
      {
        name: 'Candidates',
        icon: ':mag_right:',
      },
    ],
  },
  {
    value: 'Sales',
    label: 'Sales',
    groups: [
      {
        name: 'Clients',
        icon: ':money_mouth_face:',
      },
      {
        name: 'Prospects',
        icon: ':male-detective:',
      },
    ],
  },
  {
    value: 'Consulting/Freelancing',
    label: 'Consulting/Freelancing',
    groups: [
      {
        name: 'Community',
        icon: ':family:',
      },
      {
        name: 'Clients',
        icon: ':money_mouth_face:',
      },
      {
        name: 'Partners',
        icon: ':handshake:',
      },
    ],
  },
  {
    value: 'Personal CRM',
    label: 'Personal CRM',
    groups: [
      {
        name: 'Friends',
        icon: ':people_holding_hands:',
      },
      {
        name: 'Family',
        icon: ':family:',
      },
      {
        name: 'Networking',
        icon: ':snowflake:',
      },
    ],
  },
];
