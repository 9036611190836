import { config } from '@folkapp/design-system';
import { generateColor } from 'app/utils/ui';
import { StylesConfig } from 'react-select';

import { UseCase } from './useCases';

export const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    borderWidth: 1,
    background: 'transparent',
    borderColor: state.hasValue
      ? config.theme.colors.grey800
      : config.theme.colors.grey200,
    padding: 0,
    borderRadius: config.theme.radii.lg,
    boxShadow: 'none',
    minHeight: 'none',
    '&:focus': {
      borderColor: config.theme.colors['grey400'],
    },
    '&:hover': {
      borderColor: config.theme.colors['grey400'],
    },
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 100_000, // needed in the context of a modal
  }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
    backgroundColor: '#ffffff',
    borderRadius: config.theme.radii.md,
    boxShadow:
      'rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: `${config.theme.space[1]} 0`,
    backgroundColor: '#fff',
    zIndex: 3,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingTop: config.theme.space[1],
    paddingBottom: config.theme.space[1],
    paddingLeft: config.theme.space[2],
    paddingRight: config.theme.space[2],
    maxWidth: '100%',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  multiValue: (provided, { data }) => {
    const { value } = data;
    return {
      ...provided,
      borderRadius: config.theme.radii.md,
      backgroundColor: `${generateColor(value)}22`,
    };
  },
  multiValueLabel: (provided) => ({
    ...provided,
    color: config.theme.colors.grey900,
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  option: (provided, state) => {
    return {
      ...provided,
      position: 'relative',
      padding: `${config.theme.space[1]} ${config.theme.space[2]}`,
      backgroundColor: state.isFocused
        ? config.theme.colors.grey200
        : 'transparent',
      cursor: 'pointer',
      '&:not(:first-of-type)': {
        borderTopWidth: 0,
        borderTopStyle: 'solid',
        borderTopColor: config.theme.colors.grey200,
      },
      '&:hover': {
        backgroundColor: config.theme.colors.grey200,
      },
    };
  },
  noOptionsMessage: (provided) => ({
    ...provided,
    paddingTop: config.theme.space[2],
    paddingBottom: config.theme.space[2],
    paddingLeft: config.theme.space[3],
    paddingRight: config.theme.space[3],
    textAlign: 'left',
  }),
  loadingMessage: (provided) => ({
    ...provided,
    paddingTop: config.theme.space[2],
    paddingBottom: config.theme.space[2],
    paddingLeft: config.theme.space[3],
    paddingRight: config.theme.space[3],
    textAlign: 'left',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: `${config.theme.space[1]} ${config.theme.space[2]}`,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    display: 'none',
    padding: `${config.theme.space[1]} ${config.theme.space[2]}`,
    marginLeft: config.theme.space[2],
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: config.theme.colors.grey400,
    marginLeft: config.theme.space[1],
    '&:hover': {
      backgroundColor: 'transparent',
      color: config.theme.colors.grey800,
    },
  }),
} as StylesConfig<UseCase, true>;
