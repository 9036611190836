import { UserFragment } from '__generated__/graphql';
import { Flex } from '@designSystem';
import { Box, MdAdd, MdMail, styled } from '@folkapp/design-system';
import { useMemberIds } from 'app/apps/userInvite/hooks';
import { COLORS } from 'app/utils/constants';
import { isValidEmail } from 'app/utils/functions';
import { FC, useMemo, useRef } from 'react';

import { InviteOption } from '../InviteOption';

const ListItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$2',
  padding: '$3 $2',
  border: '1px solid $grey200',
  borderRadius: '$lg',
  color: '$grey800',
  width: '100%',
});

export const InviteResults: FC<{
  user: UserFragment | null;
  query: string;
  onSelectUser: (user: UserFragment) => void;
  onSelectExternalUser: (email: string) => void;
  isLoading: boolean;
  networkId: string;
}> = ({
  user,
  query,
  onSelectUser,
  onSelectExternalUser,
  isLoading,
  networkId,
}) => {
  const ref = useRef(null);
  const memberIds = useMemberIds(networkId);
  const canInviteMember = useMemo(
    () => !!user && query.trim().toLowerCase() === user.email.toLowerCase(),
    [query, user],
  );

  if (!isValidEmail(query)) {
    return null;
  }

  if (!!user && memberIds.includes(user.id)) {
    return (
      <ListItemContainer
        css={{ borderColor: 'transparent', color: '$grey600' }}
      >
        {query} has already been invited
      </ListItemContainer>
    );
  }

  if (!user || !canInviteMember) {
    return (
      <Container>
        <ListItemContainer
          as="button"
          disabled={isLoading}
          onClick={() => onSelectExternalUser(query)}
          ref={ref}
        >
          <Flex align="center" gap="2">
            <MdMail color={COLORS.grey} />

            <span>{query}</span>
          </Flex>

          <MdAdd color={COLORS.grey} />
        </ListItemContainer>
      </Container>
    );
  }

  return (
    <Container>
      <InviteOption
        name={user.fullname}
        email={user.email}
        picture={user.picture ?? null}
        onPress={() => onSelectUser(user)}
        id={user.id}
      />
    </Container>
  );
};

const Container = styled(Box, {
  width: '100%',
  marginTop: '$1',
  borderRadius: '$lg',
  overflow: 'hidden',
  ':hover': {
    backgroundColor: '$grey200',
  },
  '&:focus': {
    border: '1px solid $grey600',
  },
});
