import {
  useTrackSegmentEventMutation,
  useUpdateUserMutation,
} from '__generated__/graphql';
import { Flex, Spinner } from '@designSystem';
import { Box, Button, Input } from '@folkapp/design-system';
import { LayoutOnboarding } from 'app/apps/onboarding/LayoutOnboarding';
import { getStepUrl } from 'app/apps/routes/onboarding/OnboardingRoutingUrls';
import { useGetMe } from 'app/services/remote/user';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import StepOneBackground from 'static/images/bg_step1.svg';

import { FormLegend, UseTypeFields } from './components';
import { UseCase } from './utils/useCases';

type NetworkSetupInput = {
  firstName: string;
  lastName: string;
  networkName: string | undefined;
  useCases: UseCase[] | undefined;
  useType: string[] | undefined;
};

export const Profile = ({
  numberOfSteps,
  currentStep,
  nextStep,
  isReferred,
}: {
  numberOfSteps: number;
  currentStep: number;
  nextStep: number;
  isReferred: boolean;
}) => {
  const { networkId } = useParams<{ networkId: string }>();
  const user = useGetMe({
    fetchPolicy: 'cache-first',
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<NetworkSetupInput>({
    mode: 'onSubmit',
    defaultValues: {
      firstName: user?.firstname ?? '',
      lastName: user?.lastname ?? '',
    },
  });
  const history = useHistory();
  const [updateUser] = useUpdateUserMutation();
  const [trackEvent] = useTrackSegmentEventMutation();

  const handleData: SubmitHandler<NetworkSetupInput> = async (data) => {
    trackEvent({
      variables: {
        payload: {
          event: 'onboarding_step_2_reached',
          properties: {
            networkId,
          },
        },
      },
    });

    await updateUser({
      variables: {
        payload: {
          firstname: data.firstName,
          lastname: data.lastName,
          onboardingStep: nextStep,
        },
      },
    });

    history.push(getStepUrl({ networkId, step: nextStep, isReferred }));
  };

  /**
   * Could `updateUser` and `trackEvent` be moved where the onboarding
   * happens the first time?
   */
  useEffect(() => {
    trackEvent({
      variables: {
        payload: {
          event: 'onboarding_step_1_reached',
          properties: { networkId },
        },
      },
    });
  }, [trackEvent, networkId]);

  return (
    <LayoutOnboarding
      numberOfSteps={numberOfSteps}
      currentStep={currentStep}
      backgroundImg={StepOneBackground}
      title="Tell us about yourself"
      subTitle="folk is designed for managing both teams and individuals contacts. A workspace is where you and your team can manage contacts and collaborate."
    >
      <Flex
        as="form"
        direction="column"
        align="center"
        css={{ height: '100%' }}
        onSubmit={handleSubmit(handleData)}
        aria-label="Network setup"
      >
        <Flex
          css={{
            maxWidth: '$96',
            margin: '0 auto',
          }}
          justify="center"
          direction="column"
          gap="2"
        >
          <Flex
            align="center"
            gap="6"
            direction="column"
            css={{
              padding: '$4',
              width: '22rem',
              justifyContent: 'space-between',
              backgroundColor: '$white',
              borderRadius: '$md',
            }}
          >
            <UseTypeFields>
              <FormLegend>Personal details</FormLegend>
              <Flex align="center" gap="2">
                <Input
                  aria-label="First name"
                  autoFocus
                  placeholder="First name"
                  aria-invalid={!!errors.firstName}
                  autoComplete="given-name"
                  required
                  {...register('firstName', { required: 'first name' })}
                />
                <Input
                  aria-label="Last name"
                  placeholder="Last name"
                  aria-invalid={!!errors.lastName}
                  autoComplete="family-name"
                  required
                  {...register('lastName', { required: 'last name' })}
                />
              </Flex>
            </UseTypeFields>
          </Flex>
        </Flex>

        <Box css={{ flexGrow: 1, marginBottom: '$8' }} />

        <Button
          type="submit"
          aria-label="Submit network setup"
          disabled={isSubmitting}
        >
          {isSubmitting ? <Spinner /> : 'Next'}
        </Button>
      </Flex>
    </LayoutOnboarding>
  );
};
