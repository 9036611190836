import { gql } from '@apollo/client';

import { UserFragments } from './user';

export const GroupMemberFragments = {
  member: gql`
    fragment GroupMember on GroupMember {
      id
      role
      user {
        ...User
      }
      expirationDate
    }
    ${UserFragments.user}
  `,
};

export const GroupFragments = {
  group: gql`
    fragment Group on Group {
      id
      name
      emoji
      totalPeople
      totalCompanies
      openAccess
      createdBy
      networkId
    }
  `,
  groupPreview: gql`
    fragment GroupPreview on Group {
      id
      name
      emoji
    }
  `,
};

export const SharedViewFragments = {
  sharedViewSettings: gql`
    fragment SharedViewSettings on SharedViewSettings {
      sharedViewId
      url
      isShared
      isIndexed
      title
      description
    }
  `,
};

export const ViewsFragments = {
  view: gql`
    fragment GroupView on GroupView {
      id
      name
      type
      display
      totalCount
      sharedViewSettings {
        ...SharedViewSettings
      }
    }
    ${SharedViewFragments.sharedViewSettings}
  `,
};
