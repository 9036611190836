import { Avatar, Box } from '@folkapp/design-system';
import { FC } from 'react';

import { Invite } from '../Invite/Invite';

export const InviteOption: FC<{
  name: string;
  email: string;
  picture: string | null;
  onPress: VoidFunction;
  id: string;
}> = ({ name, email, picture, onPress, id }) => {
  return (
    <Box
      as="button"
      onClick={() => onPress()}
      css={{
        backgroundColor: '#fff',
        width: '100%',
        textAlign: 'left',
        '&:hover': {
          backgroundColor: '$grey200',
        },
      }}
    >
      <Invite
        avatar={
          <Avatar id={id} name={name} src={picture} size="lg" variant="user" />
        }
        title={name}
        description={email}
      />
    </Box>
  );
};
