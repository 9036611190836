import { useCommentLocationQuery } from 'app/services/remote/comment';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

export const useGroupContactCommentRedirectionEffect = ({
  commentId,
  redirect,
}: {
  commentId: string;
  redirect: (path: string) => void;
}) => {
  const { data, error } = useCommentLocationQuery(commentId);

  useEffect(() => {
    if (error) {
      redirect('/');
    } else if (data) {
      const { networkId, groupId, contactId, contactType } = data.result;

      const contactTypeKey =
        contactType === 'person' ? 'personId' : 'companyId';
      const redirectionUrl = `/apps/contacts/network/${networkId}/groups/${groupId}#${contactTypeKey}=${contactId}&tab=comments`;

      redirect(redirectionUrl);
    }
  }, [data, error, redirect]);
};

export const GroupContactCommentRedirection = () => {
  const { commentId } = useParams<{ commentId: string }>();
  const history = useHistory();

  useGroupContactCommentRedirectionEffect({
    commentId,
    redirect: (path) => history.replace(path),
  });

  return null;
};
