import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as AvatarPrimitives from '@radix-ui/react-avatar';
import { useMemo } from 'react';
import runes from 'runes';
import { styled } from './stitches.config';
const AvatarFallbackBackground = styled('div', {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'inherit',
});
const AvatarRoot = styled(AvatarPrimitives.Root, {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    userSelect: 'none',
    lineHeight: '$none',
    variants: {
        variant: {
            user: {
                borderRadius: '$full',
                [`${AvatarFallbackBackground}`]: {
                    color: '$white',
                    backgroundColor: '$$fallbackColor',
                },
            },
            person: {
                borderRadius: '$full',
                [`${AvatarFallbackBackground}`]: {
                    color: '$grey700',
                    backgroundColor: '$white',
                    boxShadow: 'inset 0 0 0 1px $colors$grey700',
                },
                [`[data-theme='dark'] & ${AvatarFallbackBackground}`]: {
                    color: '$grey300',
                    backgroundColor: '$transparent',
                    boxShadow: 'inset 0 0 0 1px $colors$grey300',
                },
            },
            company: {
                borderRadius: '$$avatarBorderRadius',
                [`${AvatarFallbackBackground}`]: {
                    color: '$grey700',
                    backgroundColor: '$white',
                    boxShadow: 'inset 0 0 0 1px $colors$grey400',
                },
                [`[data-theme='dark'] & ${AvatarFallbackBackground}`]: {
                    color: '$grey300',
                    backgroundColor: '$transparent',
                    boxShadow: 'inset 0 0 0 1px $colors$grey600',
                },
            },
            network: {
                borderRadius: '$$avatarBorderRadius',
                [`${AvatarFallbackBackground}`]: {
                    color: '$white',
                    backgroundColor: '$$fallbackColor',
                },
            },
        },
        size: {
            xs: {
                width: '$4',
                height: '$4',
                fontSize: 9,
                fontWeight: '$semibold',
                $$avatarBorderRadius: '$radii$default',
            },
            sm: {
                width: '$5',
                height: '$5',
                fontSize: 12,
                fontWeight: '$medium',
                $$avatarBorderRadius: '$radii$default',
            },
            md: {
                width: '$6',
                height: '$6',
                fontSize: 12,
                fontWeight: '$medium',
                $$avatarBorderRadius: '$radii$default',
            },
            lg: {
                width: '$8',
                height: '$8',
                fontSize: 14,
                fontWeight: '$medium',
                $$avatarBorderRadius: '$radii$md',
            },
            xl: {
                width: '$14',
                height: '$14',
                fontSize: 20,
                fontWeight: '$medium',
                $$avatarBorderRadius: '$radii$lg',
            },
        },
    },
    defaultVariants: {
        variant: 'circle',
        size: 'sm',
    },
});
const AvatarImage = styled(AvatarPrimitives.Image, {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 'inherit',
    backgroundColor: '#fff',
});
// Seed generating function, see https://github.com/bryc/code/blob/master/jshash/PRNGs.md#addendum-a-seed-generating-functions
const xmur3 = (str) => {
    let h = 1779033703 ^ str.length;
    for (let i = 0; i < str.length; i++) {
        h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
        h = (h << 13) | (h >>> 19);
    }
    return () => {
        h = Math.imul(h ^ (h >>> 16), 2246822507);
        h = Math.imul(h ^ (h >>> 13), 3266489909);
        return (h ^= h >>> 16) >>> 0;
    };
};
// Seedable PRNG, see https://github.com/bryc/code/blob/master/jshash/PRNGs.md#mulberry32
const mulberry32 = (seed) => {
    let a = seed;
    return () => {
        a |= 0;
        a = (a + 0x6d2b79f5) | 0;
        let t = Math.imul(a ^ (a >>> 15), 1 | a);
        t = (t + Math.imul(t ^ (t >>> 7), 61 | t)) ^ t;
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    };
};
const avatarStyles = [
    { $$fallbackColor: '#F56741' },
    { $$fallbackColor: '#5079E4' },
    { $$fallbackColor: '#DA5992' },
    { $$fallbackColor: '#FFC700' },
];
const getAvatarStyle = (value) => {
    const seed = xmur3(value);
    const rand = mulberry32(seed());
    return avatarStyles[Math.floor(rand() * avatarStyles.length)];
};
const AvatarFallback = ({ id = '', name = '', ...props }) => {
    const avatarStyle = useMemo(() => getAvatarStyle(id), [id]);
    // Handles emoji and compound emoji correctly. e.g. `👨‍👩‍👧‍👦`
    const [initial = ''] = runes(name);
    return (_jsx(AvatarPrimitives.Fallback, { asChild: true, ...props, children: _jsx(AvatarFallbackBackground, { role: "img", "aria-label": name, css: avatarStyle, children: initial.toLocaleUpperCase() }) }));
};
export const Avatar = ({ id, name, src, delayMs, onLoadingStatusChange, ...props }) => {
    return (_jsxs(AvatarRoot, { ...props, children: [src && (_jsx(AvatarImage, { src: src, onLoadingStatusChange: onLoadingStatusChange })), _jsx(AvatarFallback, { id: id, name: name !== null && name !== void 0 ? name : undefined, delayMs: delayMs })] }));
};
