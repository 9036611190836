import { FC } from 'react';

import styles from './CircularLoader.module.css';

interface CircularLoaderProps {
  size?: number;
  spinnerColor?: string;
}

export const CircularLoader: FC<CircularLoaderProps> = ({
  spinnerColor,
  size,
}) => {
  return (
    <div className={styles.root}>
      <div
        className={styles.container}
        data-testid="circular-loader"
        style={
          size
            ? {
                height: size,
              }
            : undefined
        }
      >
        <div className={styles.content}>
          <div
            className={styles.dot}
            style={
              spinnerColor
                ? {
                    background: spinnerColor,
                  }
                : {}
            }
          />

          <div className={styles.dot} />

          <div
            className={styles.dot}
            style={
              spinnerColor
                ? {
                    background: spinnerColor,
                  }
                : {}
            }
          />
        </div>
      </div>
    </div>
  );
};
