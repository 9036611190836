import {
  NetworkMemberRole,
  useNetworkAddMemberMutation,
  useUserSearchQuery,
} from '__generated__/graphql';
import { useApolloClient } from '@apollo/client';
import { useToasts } from '@folkapp/design-system';
import { makeUser } from 'app/services/factories/user';
import { useDebounce } from 'app/services/local/utils';
import {
  updateNetworkMembers,
  useNetworkMembers,
} from 'app/services/remote/network';
import { isValidEmail } from 'app/utils/functions';
import uniq from 'lodash/uniq';
import { useCallback, useMemo } from 'react';

export const useInviteMember = (networkId: string) => {
  const client = useApolloClient();
  const { addToast } = useToasts();

  const [addMember] = useNetworkAddMemberMutation({
    update: (_, { data }) => {
      const member = data?.member;

      if (member) {
        updateNetworkMembers({
          networkId,
          client,
          modifier: (members) => members.concat(member),
        });
      }
    },
    onCompleted: (data) => {
      if (data.member?.user) {
        addToast('Member added', {
          variant: 'success',
        });
      }
    },
  });

  const handleAddMember = useCallback(
    async ({
      id,
      email,
      fullname,
      firstname,
      lastname,
    }: {
      id: string;
      email: string;
      fullname: string;
      firstname?: string | null;
      lastname?: string | null;
    }) => {
      await addMember({
        variables: {
          payload: {
            userId: id,
            networkId,
            email,
          },
        },
        optimisticResponse: {
          member: {
            __typename: 'NetworkMember',
            id,
            networkId,
            role: NetworkMemberRole.admin,
            user: makeUser({
              id,
              email,
              fullname,
              firstname,
              lastname,
            }),
          },
        },
      });
    },
    [networkId, addMember],
  );

  return handleAddMember;
};

export const useMemberIds = (networkId: string) => {
  const members = useNetworkMembers({
    variables: { id: networkId },
  }).data?.networkMembers?.members;

  return useMemo(
    () => uniq(members?.map((member) => member.user.id) ?? []),
    [members],
  );
};

export const useQueryUserByEmail = (query: string) => {
  const debouncedQuery = useDebounce(query, 300);
  const { loading, data } = useUserSearchQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      email: debouncedQuery,
    },
    skip: !isValidEmail(debouncedQuery),
  });

  return [data?.user ?? null, loading] as const;
};
