import sheet from 'emoji-datasource-apple/img/apple/sheets-256/32.png';
import { BackgroundImageFn } from 'emoji-mart';
import shuffle from 'lodash/shuffle';
import { v4 as uuid } from 'uuid';

export const groupsEmojis = shuffle([
  {
    id: uuid(),
    title: 'Family',
    emoji: ':family:',
    color: '87,56,255',
  },
  {
    id: uuid(),
    title: 'Colleagues',
    emoji: ':briefcase:',
    color: '32,206,169',
  },
  {
    id: uuid(),
    title: 'Employees',
    emoji: ':male-technologist:',
    color: '245,78,80',
  },
  {
    id: uuid(),
    title: 'Friends',
    emoji: ':call_me_hand:',
    color: '242,185,52',
  },
  {
    id: uuid(),
    title: 'Suppliers',
    emoji: ':truck:',
    color: '99,195,217',
  },
  {
    id: uuid(),
    title: 'Investors',
    emoji: ':money_with_wings:',
    color: '39,112,236',
  },
  {
    id: uuid(),
    title: 'Competitors',
    emoji: ':facepunch:',
    color: '222,74,150',
  },
  {
    id: uuid(),
    title: 'Advisors',
    emoji: ':male-detective:',
    color: '242,114,68',
  },
  {
    id: uuid(),
    title: 'Clients',
    emoji: ':crown:',
    color: '235,112,91',
  },
  {
    id: uuid(),
    title: 'Contractors',
    emoji: ':bookmark_tabs:',
    color: '32,206,169',
  },
  {
    id: uuid(),
    title: 'Journalists',
    emoji: ':rolled_up_newspaper:',
    color: '217,75,151',
  },
  {
    id: uuid(),
    title: 'Candidates',
    emoji: ':memo:',
    color: '121,80,242',
  },
  {
    id: uuid(),
    title: 'Alumni',
    emoji: ':mortar_board:',
    color: '135,154,171',
  },
  {
    id: uuid(),
    title: 'Experts',
    emoji: ':microscope:',
    color: '222,74,150',
  },
  {
    id: uuid(),
    title: 'Service providers',
    emoji: ':bellhop_bell:',
    color: '242,185,52',
  },
  {
    id: uuid(),
    title: 'Portfolio companies',
    emoji: ':moneybag:',
    color: '32,206,169',
  },
  {
    id: uuid(),
    title: 'In my city',
    emoji: ':cityscape:',
    color: '69,42,219',
  },
]);

export function getRandomEmoji() {
  return groupsEmojis[Math.floor(Math.random() * groupsEmojis.length)]!.emoji;
}

export const backgroundImageFn: BackgroundImageFn = () => sheet;
