import { keyframes, styled } from '@folkapp/design-system';
import { ComponentProps, FC } from 'react';

const spin = keyframes({
  '100%': {
    transform: 'rotate(1turn)',
  },
});

const SpinnerRoot = styled('svg', {
  animationName: spin.toString(),
  animationDuration: '1s',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
  color: '$grey500',
  width: '$5',
  height: '$5',
});
const SpinnerCircle = styled('circle', {
  opacity: 0.25,
  strokeWidth: 2,
  stroke: 'currentColor',
});
const SpinnerArc = styled('path', {
  opacity: 0.75,
  strokeWidth: 2,
  stroke: 'currentColor',
});

export const Spinner: FC<ComponentProps<typeof SpinnerRoot>> = (props) => {
  return (
    <SpinnerRoot
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <SpinnerCircle cx={12} cy={12} r={10} />
      <SpinnerArc d="M 20.66025403784439 17 A 10 10 0 0 0 12 2" />
    </SpinnerRoot>
  );
};
