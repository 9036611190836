import {
  useGetEnrichedCompanyDataQuery,
  useTrackSegmentEventMutation,
  useUpdateUserMutation,
} from '__generated__/graphql';
import { Flex, Spinner } from '@designSystem';
import { Box, Button, Text } from '@folkapp/design-system';
import { LayoutOnboarding } from 'app/apps/onboarding/LayoutOnboarding';
import { getStepUrl } from 'app/apps/routes/onboarding/OnboardingRoutingUrls';
import { Emoji } from 'app/Components/Emoji';
import { useGetMe } from 'app/services/remote/user';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import StepOneBackground from 'static/images/bg_step1.svg';

import {
  FormLabel,
  FormLegend,
  FormRadio,
  NetworkNameField,
  PersonalDetailsFields,
  StyledUseTypesFieldset,
} from './components';
import { useNetworkSetup } from './hook';
import { UseCaseOption } from './UseCaseOption';
import { selectStyle } from './utils/selectStyle';
import { UseCase, useCases } from './utils/useCases';

export type NetworkSetupInput = {
  firstName: string;
  lastName: string;
  networkName: string | undefined;
  useCases: UseCase[] | undefined;
  useType: string[] | undefined;
  picture?: string;
};
export const NetworkSetup = ({
  numberOfSteps,
  currentStep,
  nextStep,
  isReferred,
}: {
  numberOfSteps: number;
  currentStep: number;
  nextStep: number;
  isReferred: boolean;
}) => {
  const user = useGetMe({
    fetchPolicy: 'cache-first',
  });
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<NetworkSetupInput>({
    mode: 'onSubmit',
    defaultValues: {
      firstName: user?.firstname ?? '',
      lastName: user?.lastname ?? '',
    },
  });
  const history = useHistory();
  const setupNetwork = useNetworkSetup();
  const [updateUser] = useUpdateUserMutation();
  const [trackEvent] = useTrackSegmentEventMutation();

  const { data: enrichmentCompanyData } = useGetEnrichedCompanyDataQuery({
    skip: !user?.email,
    variables: {
      email: user?.email ?? '',
    },
    onCompleted: (data) => {
      if (data?.enrichedCompanyData?.fullname) {
        setValue('networkName', data.enrichedCompanyData.fullname);
      }
    },
  });

  const handleData: SubmitHandler<NetworkSetupInput> = async (data) => {
    // Setup new network
    const networkData = await setupNetwork({
      ...data,
      picture: enrichmentCompanyData?.enrichedCompanyData?.logoUrl ?? undefined,
    });

    if (!networkData) {
      return;
    }

    trackEvent({
      variables: {
        payload: {
          event: 'onboarding_step_2_reached',
          properties: {
            networkId: networkData.id,
          },
        },
      },
    });

    await updateUser({
      variables: {
        payload: {
          firstname: networkData.firstName,
          lastname: networkData.lastName,
          // FIXME: we should not have `false` values to filter out...
          usageMode: networkData.useType?.filter(Boolean) ?? [],
          useCases: networkData.useCases?.map((useCase) => useCase.value) ?? [],
          onboardingStep: nextStep,
        },
      },
    });

    history.push(
      getStepUrl({ networkId: networkData.id, isReferred, step: nextStep }),
    );
  };

  /**
   * Could `updateUser` and `trackEvent` be moved where the onboarding
   * happens the first time?
   */
  useEffect(() => {
    trackEvent({
      variables: {
        payload: {
          event: 'onboarding_step_1_reached',
          properties: {},
        },
      },
    });
  }, [trackEvent]);

  return (
    <LayoutOnboarding
      numberOfSteps={numberOfSteps}
      currentStep={currentStep}
      backgroundImg={StepOneBackground}
      title="Tell us about yourself"
      subTitle="folk is designed for managing both teams and individuals contacts. A workspace is where you and your team can manage contacts and collaborate."
    >
      <Flex
        as="form"
        direction="column"
        align="center"
        css={{ height: '100%' }}
        onSubmit={handleSubmit(handleData)}
        aria-label="Network setup"
      >
        <Flex
          css={{
            maxWidth: '$96',
            margin: '0 auto',
          }}
          justify="center"
          direction="column"
          gap="2"
        >
          <Flex
            align="center"
            gap="6"
            direction="column"
            css={{
              padding: '$4',
              width: '22rem',
              justifyContent: 'space-between',
              backgroundColor: '$white',
              borderRadius: '$md',
            }}
          >
            <PersonalDetailsFields
              register={register}
              errors={errors}
              firstNameFieldPath="firstName"
              lastNameFieldPath="lastName"
            />

            <StyledUseTypesFieldset>
              <FormLegend>How do you plan to use folk?</FormLegend>
              <Flex align="center" gap="2">
                <FormRadio
                  htmlFor="use-type-team"
                  selected={!!watch('useType.0')}
                >
                  <input
                    {...register('useType.0')}
                    id="use-type-team"
                    type="checkbox"
                    aria-label="Team"
                    value="Team"
                  />
                  <Emoji size={16} emoji=":briefcase:" />
                  Team
                </FormRadio>

                <FormRadio
                  htmlFor="use-type-single"
                  selected={!!watch('useType.1')}
                >
                  <input
                    {...register('useType.1')}
                    id="use-type-single"
                    type="checkbox"
                    aria-label="Personal CRM"
                    value="Personal CRM"
                  />
                  <Emoji size={16} emoji=":computer:" />
                  Personal CRM
                </FormRadio>
              </Flex>
            </StyledUseTypesFieldset>

            <NetworkNameField
              register={register}
              errors={errors}
              networkNameFieldPath="networkName"
            />

            <Box css={{ width: '100%' }}>
              <FormLabel>What do you want to use folk for</FormLabel>
              <Box css={{ width: '100%', marginTop: '$1' }}>
                <Controller
                  name="useCases"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      aria-label="What do you want to use folk for"
                      styles={selectStyle}
                      isMulti
                      menuPlacement="auto"
                      placeholder="Select my use case(s)"
                      options={[
                        {
                          label: 'select any use cases you want',
                          options: useCases,
                        },
                      ]}
                      closeMenuOnSelect={false}
                      components={{
                        Option: UseCaseOption,
                        GroupHeading: ({ children }) => {
                          return (
                            <Text
                              variant="caption"
                              as="div"
                              css={{
                                paddingInline: '$3',
                                paddingBlock: '$1',
                              }}
                            >
                              {children}
                            </Text>
                          );
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Flex>
        </Flex>

        <Box css={{ flexGrow: 1, marginBottom: '$8' }} />

        <Button
          type="submit"
          aria-label="Submit network setup"
          disabled={isSubmitting}
        >
          {isSubmitting ? <Spinner /> : 'Next'}
        </Button>
      </Flex>
    </LayoutOnboarding>
  );
};
