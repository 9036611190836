import { Button } from '@folkapp/design-system';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import background from 'static/images/bkg--signup.avif';
import backgroundJpg from 'static/images/bkg--signup.jpg';
import logo from 'static/images/logo2.svg';

import styles from './NotFound.module.css';

export const NotFound: FC = () => {
  const [isPartyTime, setIsPartyTime] = useState(false);

  return (
    <div className={styles.root} data-theme={isPartyTime ? 'dark' : 'light'}>
      {!isPartyTime && (
        <picture>
          <source type="image/avif" srcSet={background} />
          <img
            src={backgroundJpg}
            alt=""
            className={styles.background}
            width="100%"
            height="100%"
          />
        </picture>
      )}
      <div aria-hidden="true" className={styles.partyLights} />
      <div aria-hidden="true" className={styles.backdrop} />
      <main className={styles.container}>
        <Link to="/" className={styles.logo}>
          <img src={logo} alt="folk" />
        </Link>
        <div className={styles.content}>
          {isPartyTime ? (
            <>
              <p className={styles.kicker}>
                <a
                  className={styles.link}
                  href="https://www.youtube.com/watch?v=uURB-vo9rZ4"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  909 error
                </a>
              </p>
              <h1 className={styles.title}>
                It's <span className={styles.textGlow}>never</span> too early to
                party!
              </h1>
              <p className={styles.description}>
                Right on! We too believe a good 909 is better than a 404. One
                should never miss an opportunity to party 🎉
              </p>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/uURB-vo9rZ4?autoplay=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ display: 'none' }}
              />
            </>
          ) : (
            <>
              <p className={styles.kicker}>404 error</p>
              <h1 className={styles.title}>You're too early for this party!</h1>
              <p className={styles.description}>
                Sorry folk, but no one came to this party. You can either go
                back to the homepage or party anyway.
              </p>
            </>
          )}

          <div className={styles.actions}>
            <Button asChild>
              <Link to="/">Go back home</Link>
            </Button>

            {!isPartyTime && (
              <>
                <span className={styles.actionSeparator}>or</span>

                <Button
                  leading={'💃'}
                  onClick={() => setIsPartyTime((isPartyTime) => !isPartyTime)}
                  variant="neutral"
                >
                  Party anyway
                </Button>
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
