import { Box, MdDelete, MdMoreHoriz, Text } from '@folkapp/design-system';
import { useButton } from '@react-aria/button';
import {
  Popover,
  PopoverElement,
  PopoverOptions,
  PopoverOptionsSection,
} from 'app/Components/Popover';
import cx from 'classnames';
import { FC, useMemo, useRef } from 'react';

import { Flex } from '../../../../@designSystem';
import { hexToLighterColor } from '../../../utils/colors';
import { COLORS_TAGS, COLORS_TAGS_TRANSLATION } from '../../../utils/constants';
import {
  HighlightedOption,
  isHighlighted,
  OptionActions,
  OptionWithAvatar,
  OptionWithEmoji,
} from './Option.types';
import styles from './PopoverActions.module.css';

interface PopoverActionsProps {
  actions: Omit<OptionActions, 'onRemove' | 'onSelect'>;
  option: OptionWithAvatar | OptionWithEmoji | HighlightedOption;
}

export const ColorIcon: FC<{ colorKey: keyof typeof COLORS_TAGS }> = ({
  colorKey,
}) => (
  <Flex
    css={{
      borderRadius: '$md',
      alignItems: 'center',
      justifyContent: 'center',
      width: '18px',
      height: '18px',
      backgroundColor: hexToLighterColor(COLORS_TAGS[colorKey]),
    }}
  >
    <Text variant="caption">A</Text>
  </Flex>
);

// TODO: implement action for `editColor`
export const PopoverActions: FC<PopoverActionsProps> = ({
  actions,
  option,
}) => {
  const ref = useRef(null);
  const popoverRef = useRef<PopoverElement>(null);
  const { buttonProps } = useButton(
    {
      // @note: is there a better way to open the popover
      // without triggering external events?
      onPress: () => {
        popoverRef.current?.open();
      },
    },
    ref,
  );

  const sections: PopoverOptionsSection[] = useMemo(() => {
    const sections: PopoverOptionsSection[] = [];

    if (actions.onEditName !== undefined) {
      sections.push({
        options: [
          {
            label: 'Tag name',
            value: option.text,
            onEnter: (value: string) => actions.onEditName!(option.id, value),
          },
        ],
      });
    }

    if (actions.onEditColor) {
      sections.push({
        separator: true,
        header: { label: 'Colors' },
        options: (Object.keys(COLORS_TAGS) as (keyof typeof COLORS_TAGS)[]).map(
          (key) => ({
            label: COLORS_TAGS_TRANSLATION[key],
            closeOnAction: true,
            selected:
              isHighlighted(option) &&
              option.highlightColor === COLORS_TAGS[key],
            icon: <ColorIcon colorKey={key} />,
            onAction: () => actions.onEditColor!(option.id, COLORS_TAGS[key]),
          }),
        ),
      });
    }

    if (actions.onDelete !== undefined) {
      sections.push({
        options: [
          {
            label: 'Delete tag',
            closeOnAction: true,
            icon: <MdDelete />,
            variant: 'error',
            onAction: () => actions.onDelete!(option.id),
          },
        ],
      });
    }

    return sections;
  }, [actions.onEditName, actions.onEditColor, actions.onDelete, option]);

  // TODO: see if it's the correct logic
  if (
    !isHighlighted(option) ||
    Object.keys(actions).length === 0 ||
    (actions.onEditName === undefined && actions.onDelete === undefined)
  ) {
    return null;
  }

  return (
    <div className={cx(styles.root)}>
      <Popover
        ref={popoverRef}
        variant="nestedClick"
        content={<PopoverOptions sections={sections} />}
        placement="top right"
      >
        <Box
          ref={ref}
          {...(buttonProps as any)}
          aria-label="More actions"
          css={{
            alignSelf: 'stretch',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '$1',
            borderRadius: '$md',
            '&:hover': {
              backgroundColor: '$grey300',
            },
          }}
        >
          <MdMoreHoriz color={'var(--colors-grey600)'} size={16} />
        </Box>
      </Popover>
    </div>
  );
};
