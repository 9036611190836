import { ContactType } from '__generated__/graphql';
import { useSetPrimaryPersonCompany } from 'app/apps/contactPanel/hooks/useSetPrimaryPersonCompany';
import {
  useAddCompanyToPerson,
  useAddNewCompanyToPerson,
} from 'app/Components/CompanyComboBox/peopleCompanyApi';
import { CompanyOption } from 'app/Components/CompanyComboBox/useComboBox';
import { useRemovePersonCompany } from 'app/services/remote/contact/removeFromCompany';
import { useCallback } from 'react';

export const useCompaniesFormMethods = ({
  networkId,
  contactId,
}: {
  networkId: string;
  contactId: string;
}) => {
  const [setPersonCompanyAsPrimary] = useSetPrimaryPersonCompany();
  const [removeContactFromCompany] = useRemovePersonCompany();

  const onRemoveCompany = useCallback(
    ({ companyId, isPrimary }: { companyId: string; isPrimary: boolean }) => {
      removeContactFromCompany({
        variables: {
          input: {
            networkId,
            personId: contactId,
            companyId,
          },
        },
        optimisticResponse: {
          personCompany: {
            __typename: 'PeopleCompany',
            companyId,
            personId: contactId,
            isPrimary,
            company: null,
          },
        },
      });
    },
    [networkId, contactId, removeContactFromCompany],
  );

  const onSetCompanyAsPrimary = useCallback(
    (companyId: string) => {
      setPersonCompanyAsPrimary({
        variables: {
          input: {
            networkId,
            personId: contactId,
            companyId,
          },
        },
        optimisticResponse: {
          personCompany: {
            personId: contactId,
            companyId,
            __typename: 'PeopleCompany',
            isPrimary: true,
            person: {
              __typename: 'Person',
              id: contactId,
            },
            company: {
              __typename: 'Company',
              id: companyId,
            },
          },
        },
      });
    },
    [networkId, contactId, setPersonCompanyAsPrimary],
  );

  const [addNewCompanyToPerson] = useAddNewCompanyToPerson();
  const [addCompanyToPerson] = useAddCompanyToPerson();
  const onSelectCompany = useCallback(
    ({
      company,
      isPrimary,
      isCreation,
    }: {
      company: CompanyOption;
      isPrimary: boolean;
      isCreation: boolean;
    }) => {
      if (isCreation) {
        addNewCompanyToPerson({
          variables: {
            input: {
              networkId,
              personId: contactId,
              companyId: company.id,
              companyName: company.cName,
              companyDomain: company.cDomain,
              companyPicture: company.picture,
              companyUrl: company.url,
            },
          },
          optimisticResponse: {
            personCompany: {
              __typename: 'PeopleCompany',
              personId: contactId,
              companyId: company.id,
              isPrimary,
              company: {
                __typename: 'Company',
                id: company.id,
                contactType: ContactType.company,
                cName: company.cName,
                fullname: company.cName,
                picture: company.picture ?? null,
              },
            },
          },
        });
      } else {
        addCompanyToPerson({
          variables: {
            input: {
              networkId,
              personId: contactId,
              companyId: company.id,
            },
          },
          optimisticResponse: {
            personCompany: {
              __typename: 'PeopleCompany',
              personId: contactId,
              companyId: company.id,
              isPrimary,
              company: {
                __typename: 'Company',
                id: company.id,
                contactType: ContactType.company,
                cName: company.cName,
                fullname: company.cName,
                picture: company.picture ?? null,
              },
            },
          },
        });
      }
    },
    [networkId, addCompanyToPerson, contactId, addNewCompanyToPerson],
  );

  return {
    onSetCompanyAsPrimary,
    onRemoveCompany,
    onSelectCompany,
  };
};

export type CompaniesFormMethods = ReturnType<typeof useCompaniesFormMethods>;
