import { Button, keyframes, styled, Text } from '@folkapp/design-system';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { ComponentProps, ReactNode } from 'react';

import { ModalFooter } from './components/ModalFooter';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: 'rgba(49, 53, 57, 0.2)',
  position: 'fixed',
  zIndex: 2,
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

const StyledContent = styled(DialogPrimitive.Content, {
  backgroundColor: '$white',
  borderRadius: '$lg',
  zIndex: 3,
  boxShadow: '$xl',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 480,
  maxHeight: '85vh',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&:focus': { outline: 'none' },
  variants: {
    size: {
      sm: {
        maxWidth: 320,
      },
      lg: {
        maxWidth: 800,
      },
      /**
       * Variant exclusively used for the manual merge of contacts.
       * We should probably not use it elsewhere.
       *  */
      auto: {
        maxWidth: 900,
        width: 'auto',
      },
    },
  },
});

const Dialog = DialogPrimitive.Root;
const DialogTitle = ({ children }: { children: ReactNode }) => {
  return (
    <Text variant="title" as={DialogPrimitive.Title}>
      {children}
    </Text>
  );
};
const DialogDescription = ({ children }: { children: ReactNode }) => {
  return <Text as={DialogPrimitive.Description}>{children}</Text>;
};

export interface FooterAction
  extends Pick<ComponentProps<typeof Button>, 'disabled' | 'onClick'> {
  onClick?: NonNullable<ComponentProps<typeof Button>['onClick']>;
  label: string;
  testId?: string;
  icon?: ReactNode;
  type: 'button' | 'submit' | 'reset';
  variant?: 'default' | 'neutral' | 'ghost' | 'danger';
}

export type ModalProps = {
  actions?: FooterAction[];
  children?: ReactNode;
  description?: string;
  formId?: string;
  isDismissable?: boolean;
  noBackground?: boolean;
  onRequestClose: VoidFunction;
  open: boolean;
  size?: 'sm' | 'lg' | 'auto';
  title?: string;
} & DialogPrimitive.DialogContentProps;

export const Modal = ({
  actions,
  children,
  description,
  isDismissable = true,
  noBackground = false,
  onRequestClose,
  formId,
  open,
  size,
  title,
  ...props
}: ModalProps) => (
  <Dialog open={open}>
    <DialogPrimitive.Portal>
      {!noBackground && <StyledOverlay />}
      <StyledContent
        data-testid="modal"
        onEscapeKeyDown={() => onRequestClose()}
        onPointerDownOutside={() => onRequestClose()}
        onInteractOutside={() => onRequestClose()}
        size={size}
        {...props}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        {description && <DialogDescription>{description}</DialogDescription>}
        {children}
        {/* TODO: rework the close button, it doesn't work well on the AddPersonModal and not at all on the reminder form */}
        {/* {isDismissable && (
          <DialogClose asChild onClick={onRequestClose}>
            <IconButton align="center" justify="center">
              <Icon name="cross" />
            </IconButton>
          </DialogClose>
        )} */}

        {actions && actions.length > 0 && formId && (
          <ModalFooter actions={actions} formId={formId} />
        )}
      </StyledContent>
    </DialogPrimitive.Portal>
  </Dialog>
);
