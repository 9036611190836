import { useToasts } from '@folkapp/design-system';
import {
  getUserStatus,
  useAuthenticate,
  useGetIsConsistentUser,
  useRenewToken,
  UserStatus,
} from 'app/services/graphQLService';
import { getLocationSearchParams } from 'app/utils/functions';
import { useCallback, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

export const REDIRECTION_PATHNAME_KEY = 'redirection_pathname';

export const registerRedirectionUrl = () => {
  // keep search and hash so that we handle redirections to the contact panel
  const urlWithoutOrigin = window.location.href.replace(
    window.location.origin,
    '',
  );

  if (
    urlWithoutOrigin.startsWith('/apps/contacts/network/') ||
    urlWithoutOrigin.startsWith('/comments/')
  ) {
    window.sessionStorage.setItem(REDIRECTION_PATHNAME_KEY, urlWithoutOrigin);
  }
};

// /!\ this function has been duplicated in app/services/local/user.ts
// otherwise folkx-shared tests would break inexplicably
export const removeRedirectionUrl = () => {
  window.sessionStorage.removeItem(REDIRECTION_PATHNAME_KEY);
};

const getRedirectionAfterLogin = () => {
  const redirectionPathname = window.sessionStorage.getItem(
    REDIRECTION_PATHNAME_KEY,
  );

  if (redirectionPathname) {
    removeRedirectionUrl();
    return redirectionPathname;
  }

  return '/apps/contacts/all';
};

export const useRenewTokenPeriodically = () => {
  const renewToken = useRenewToken();

  useEffect(() => {
    const intervalId = setInterval(() => {
      renewToken();
    }, 5 * 60 * 1_000); // 5min

    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [renewToken]);
};

export const redirectOnFocus = ({
  userStatus,
  expectedUserStatus,
  warnInvitedUserAlreadyConnected,
  authenticate,
  redirectTo,
  getIsConsistentUser,
}: {
  userStatus: UserStatus;
  expectedUserStatus: UserStatus;
  warnInvitedUserAlreadyConnected: VoidFunction;
  authenticate: VoidFunction;
  redirectTo: (userStatus: UserStatus) => void;
  getIsConsistentUser: () => boolean;
}) => {
  switch (expectedUserStatus) {
    case UserStatus.NOT_CONNECTED:
      if (expectedUserStatus !== userStatus) {
        // notify someone already logged in who clicked an external user invitation link.
        const { email, email_token: emailToken } = getLocationSearchParams(
          window.location.href,
        );

        if (email && emailToken) {
          warnInvitedUserAlreadyConnected();
        }

        authenticate();
      }

      break;

    case UserStatus.CONNECTED:
      if (userStatus === UserStatus.NOT_CONNECTED) {
        redirectTo(UserStatus.NOT_CONNECTED);
      }

      if (userStatus === UserStatus.ONBOARDING) {
        redirectTo(UserStatus.ONBOARDING);
      }

      /*
        Steps to reproduce this case:
        1. user A is connected on tab 1
        2. go to tab 2, logout and connect with user B
        3. come back to tab A
        => it will reload tab A and user B will appear, as expected
        @note: we could be more subtle than that and avoid a page reload
        but that's simple enough and robust for such an edge case
      */
      if (userStatus === UserStatus.CONNECTED && !getIsConsistentUser()) {
        window.location.reload();
      }

      break;

    case UserStatus.ONBOARDING:
      if (userStatus === UserStatus.NOT_CONNECTED) {
        redirectTo(UserStatus.NOT_CONNECTED);
      }

      if (userStatus === UserStatus.CONNECTED) {
        redirectTo(UserStatus.CONNECTED);
      }

      break;
  }
};

export const useRedirectionOnFocus = (expectedUserStatus: UserStatus) => {
  const authenticate = useAuthenticate();
  const redirectTo = useRedirectTo();
  const { addToast } = useToasts();
  const getIsConsistentUser = useGetIsConsistentUser();

  const handleRedirectOnFocus = useCallback(() => {
    redirectOnFocus({
      userStatus: getUserStatus(),
      expectedUserStatus,
      warnInvitedUserAlreadyConnected: () => {
        addToast(
          "Please log out first before joining the workspace you've been invited to.",
          {
            variant: 'warning',
          },
        );
      },
      authenticate,
      redirectTo,
      getIsConsistentUser,
    });
  }, [
    expectedUserStatus,
    addToast,
    authenticate,
    redirectTo,
    getIsConsistentUser,
  ]);

  useEffect(() => {
    window.addEventListener('focus', handleRedirectOnFocus);

    return () => window.removeEventListener('focus', handleRedirectOnFocus);
  }, [handleRedirectOnFocus]);
};

// Do not use directly, this is only for the graphQLService.
// It's not convenient to have to pass `history`
export const redirectTo = (
  userStatus: UserStatus,
  history: RouteComponentProps['history'],
) => {
  switch (userStatus) {
    case UserStatus.CONNECTED:
      const redirectionAfterLogin = getRedirectionAfterLogin();
      return history.replace(redirectionAfterLogin);
    case UserStatus.ONBOARDING:
      return history.replace('/onboarding');
    case UserStatus.NOT_CONNECTED:
      return history.replace('/auth/login');
  }
};

export const useRedirectTo = () => {
  const history = useHistory();

  return useCallback(
    (userStatus: UserStatus) => redirectTo(userStatus, history),
    [history],
  );
};

export const assertUserStatusBasedOnUrl = (
  userStatus: UserStatus,
  pathname = window.location.pathname,
) => {
  switch (userStatus) {
    case UserStatus.CONNECTED:
      return (
        pathname.startsWith('/apps/') ||
        pathname.startsWith('/comments/') ||
        pathname.startsWith('/settings/') ||
        pathname.startsWith('/shared')
      );
    case UserStatus.ONBOARDING:
      return pathname.startsWith('/onboarding');
    case UserStatus.NOT_CONNECTED:
      return pathname.startsWith('/auth');
  }
};
