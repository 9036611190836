/* eslint-disable import/no-duplicates */
import isValidDateFns from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import cloneDeepWith from 'lodash/cloneDeepWith';

export function isNotNil<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export const areSameDay = (
  date1: Date | undefined,
  date2: Date | undefined,
) => {
  if (date1 === undefined || date2 === undefined) {
    return date1 === date2;
  }

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

// same regex as the backend
export const isValidEmail = (email: string) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
};

// useful to get params while avoiding the following caveats:
// -> `+` sign replaced with a space when using `URLSearchParams`
// -> `=` sign replaced with its hex symbol `&#x3D;` by the email provider
export const getLocationSearchParams = (locationHref: string) =>
  decodeURI(new URL(locationHref.replace(/&#x3D;/g, '=')).search)
    .replace('?', '')
    .split('&')
    .map((param) => param.split('='))
    .reduce<Record<string, string>>((values, [key, value]) => {
      values[key!] = value!;
      return values;
    }, {});

export function removeTypename<T extends { __typename?: unknown }>(obj: T) {
  const { __typename, ...objWithoutTypename } = obj;
  return objWithoutTypename;
}

export const removeTypenameRecursively = <T extends object>(obj: T): T =>
  cloneDeepWith(obj, (value: unknown) => {
    if (
      typeof value === 'object' &&
      value !== null &&
      value.hasOwnProperty('__typename')
    ) {
      const objWithoutTypename = removeTypename(
        value as {
          __typename: unknown;
        },
      );

      return typeof objWithoutTypename === 'object'
        ? removeTypenameRecursively(objWithoutTypename)
        : objWithoutTypename;
    }

    return undefined;
  });

export const range = (n: number) => Array.from(Array(n).keys());

// matched the behaviour on the server
export const getNameFromEmail = (email: string) => {
  const emailRoot = email.split('@')[0]?.split('.');

  return {
    firstname: capitalizeFirstLetter(emailRoot?.[0] ?? ''),
    lastname: emailRoot?.slice(1, 2).join(' ').toUpperCase() ?? '',
  };
};

const capitalizeFirstLetter = (text: string) => {
  return (text[0]?.toUpperCase() ?? '') + text.slice(1);
};

export const isValidDate = (d: any) =>
  typeof d === 'string' ? isValidDateFns(parseISO(d)) : isValidDateFns(d);
