import { styled } from '@folkapp/design-system';
import React, { FC, useCallback, useRef } from 'react';
import AutoSizeInputPrimitive, {
  AutosizeInputProps as AutoSizeInputPrimitiveProps,
} from 'react-input-autosize';

type AutoSizerInputProps = Omit<
  AutoSizeInputPrimitiveProps,
  'onChange' | 'ref'
> & {
  onChange: (value: string) => void;
  onBlurIfChanged?: (value: string) => void;
  testId?: string;
};

const StyledAutosizeInput = styled(AutoSizeInputPrimitive, {
  input: {
    fontWeight: "inherit",
    '&::placeholder': {
      color: '$grey600',
    },
    '&:focus::placeholder': {
      color: '$grey400',
    },
  },
});

export const AutoSizeInput: FC<AutoSizerInputProps> = ({
  onChange,
  onBlurIfChanged,
  testId,
  onFocus,
  onBlur,
  ...inputProps
}) => {
  const initialValueRef = useRef(inputProps.value ?? '');
  const handleFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (event) => {
      initialValueRef.current = event.target.value;
      onFocus?.(event);
    },
    [onFocus],
  );

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => onChange(event.target.value),
    [onChange],
  );

  const handleBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (event) => {
      const value = event.target.value;

      if (value !== initialValueRef.current) {
        onBlurIfChanged?.(value);
      }
      onBlur?.(event);
    },
    [onBlur, onBlurIfChanged],
  );

  return (
    <StyledAutosizeInput
      {...inputProps}
      data-testid={testId}
      onFocus={handleFocus}
      onChange={handleChange}
      onBlur={handleBlur}
      autoComplete="off"
      data-lpignore="true"
      data-form-type="other"
    />
  );
};
