import { Box } from '@folkapp/design-system';

export const SectionsSeparator = () => (
  <Box css={{ padding: '$1_5 $3' }}>
    <Box
      role="separator"
      css={{
        height: '$px',
        background: '$grey200',
      }}
    />
  </Box>
);
