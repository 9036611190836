import { CustomFieldValue } from '__generated__/graphql';

export const CustomField = {
  fields: {
    values: {
      merge(_: CustomFieldValue[], next: CustomFieldValue[]) {
        return next;
      },
    },
  },
};
