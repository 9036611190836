import { Flex } from '@designSystem';
import { Box } from '@folkapp/design-system';
import { ReactNode } from 'react';

interface InviteProps {
  avatar: ReactNode;
  title: ReactNode;
  description: ReactNode;
}

export const Invite = ({ avatar, title, description }: InviteProps) => {
  return (
    <Flex
      align="center"
      justify="between"
      gap="2"
      css={{
        padding: '$3',
        flexShrink: 0,
        border: '1px solid $grey200',
        borderRadius: '$lg',
        backgroundColor: '$white',
        color: '$grey800',
        maxWidth: '100%',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      {avatar}
      <Box css={{ flex: 1, minWidth: 0 }}>
        <Box
          css={{
            color: '$grey800',
            fontWeight: '$medium',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {title}
        </Box>
        <Box
          css={{
            color: '$grey500',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {description}
        </Box>
      </Box>
    </Flex>
  );
};
