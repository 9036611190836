export const COLORS = {
  black: '#313539' as const,
  blackLight: '#696c6f' as const,
  white: '#fff' as const,
  accent: '#ffbb69' as const,
  warningAlt: '#f27244' as const,
  ocean: '#2770ec' as const,
  // Used in COLOR_TAGS
  primary: '#5738FF' as const,
  success: '#20cea9' as const,
  error: '#f54e50' as const,
  warning: '#F2B934' as const,
  grey: '#879AAB' as const,
  candy: '#DE4A96' as const,
};

export const COLORS_TAGS = {
  primary: COLORS.primary,
  success: COLORS.success,
  error: COLORS.error,
  warning: COLORS.warning,
  grey: COLORS.grey,
  candy: COLORS.candy,
};

export const COLORS_TAGS_TRANSLATION: Record<keyof typeof COLORS_TAGS, string> =
  {
    primary: 'Purple',
    success: 'Green',
    error: 'Red',
    warning: 'Yellow',
    grey: 'Grey',
    candy: 'Pink',
  };

const { grey, ...colorsAvatars } = COLORS_TAGS;

export const COLORS_AVATARS = colorsAvatars;

export const KEYBOARD_KEYS = {
  UP: 38,
  DOWN: 40,
  RIGHT: 39,
  LEFT: 37,
  ENTER: 13,
  ESCAPE: 27,
};
