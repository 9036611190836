import { UseDisclosure, useDisclosure } from 'app/hooks/useDisclosure';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type CommandPanelState = ReturnType<UseDisclosure> & {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  onClearSearch: () => void;
};

const CommandPanelContext = createContext<CommandPanelState | null>(null);

export const CommandPanelProvider = ({ children }: { children: ReactNode }) => {
  const [search, setSearch] = useState('');
  const onClearSearch = useCallback(() => setSearch(''), [setSearch]);
  const disclosure = useDisclosure(false);

  const value = useMemo(
    () => ({
      search,
      setSearch,
      onClearSearch,
      ...disclosure,
    }),
    [onClearSearch, disclosure, search],
  );

  return (
    <CommandPanelContext.Provider value={value}>
      {children}
    </CommandPanelContext.Provider>
  );
};

export const useCommandPanel = (): CommandPanelState => {
  const context = useContext(CommandPanelContext);
  if (!context) {
    throw new Error(
      'useCommandPanel must be used within a CommandPanelProvider',
    );
  }
  return context;
};
