import { Popover, PopoverOptions } from 'app/Components/Popover';
import { PopoverElement } from 'app/Components/Popover/Popover';
import { FC, useRef } from 'react';

import { useSections } from '../hooks/useSections';

interface AddCustomFieldPopoverProps {
  fromContactPanel?: boolean;
}

export const AddCustomFieldPopover: FC<
  React.PropsWithChildren<AddCustomFieldPopoverProps>
> = ({ children, fromContactPanel = false }) => {
  const popoverRef = useRef<PopoverElement>(null);
  const options = useSections();

  return (
    <Popover
      placement={fromContactPanel ? 'bottom left' : undefined}
      ref={popoverRef}
      content={<PopoverOptions closeOnAction sections={options} />}
    >
      {children}
    </Popover>
  );
};
