import {
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
  RenewTokenDocument,
  RenewTokenMutation,
  RenewTokenMutationVariables,
  UserFragment,
} from '__generated__/graphql';
import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  HttpLink,
  split,
  useApolloClient,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { datadogRum } from '@datadog/browser-rum';
import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist';
import { assertUserStatusBasedOnUrl, redirectTo } from 'app/apps/routes/utils';
import { AppConfig } from 'appConfig';
import { createClient } from 'graphql-ws';
import { History } from 'history';
import jwtDecode from 'jwt-decode';
import localForage from 'localforage';
import { useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { analytics } from '../../../utils/analytics';
import { CURRENT_GROUP_ID_KEY, CURRENT_NETWORK_ID_KEY } from '../local/user';
import { getUser } from '../remote/user';
import { makeCache } from './cache';
import { buildAnalyticsUserTraits } from './utils';
import { sha256 } from './utils/sha256';

// user status
export enum UserStatus {
  NOT_CONNECTED = 'notConnected',
  ONBOARDING = 'onboarding',
  CONNECTED = 'connected',
}

const MAINTENANCE_MODE = 'maintenance-mode';

export const initializeApolloClient = async (
  appConfig: AppConfig,
  // FIXME: coupling with routing and authentication is not ideal.
  // We should extract authentication management to a separate provider,
  // routing should only react to authentication state changes.
  //
  // apollo client should be authenticated using state instead of retrieving the token from local storage.
  //
  // ```ts
  // <BrowserRouter>
  //   <AuthProvider> <-- store authentication state (i.e.: token) and expose authentication methods (e.g.: login, logout, renew token, etc.)
  //     <AuthenticatedApolloClientProvider> <-- can access authentication state to authenticate requests with `setContext`
  //                                             can call logout / renew token methods if necessary,
  //                                             client used for authentication-specific requests won't need to access cache and can be a simpler client
  //       <App />
  //     </AuthenticatedApolloClientProvider>
  //   </AuthProvider>
  // </BrowserRouter>
  // ```
  history: History,
) => {
  // Making a new InMemoryCache with reactiveVars and
  // typePolicies all set.
  const cache = makeCache();

  // Main link for HTTP(S) requests.
  const httpLink = split(
    (operation) => operation.operationName === 'contactList',
    new HttpLink({
      uri: `${appConfig.apiUrl}/graphql`,
    }),
    new BatchHttpLink({
      uri: `${appConfig.apiUrl}/graphql`,
    }),
  );

  // WebSocket link.
  const wsLink =
    // FIXME: fix WebSocket link in Zoom App instead of disabling it
    process.env.REACT_APP_IS_FOLKX !== 'true' &&
    new GraphQLWsLink(
      createClient({
        url: `${appConfig.apiWsUrl}/graphql`,
        connectionParams: {
          authorization: `Bearer ${getAccessToken()}`,
          operationName: 'use',
        },
      }),
    );

  const link = wsLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink,
      )
    : httpLink;

  const maintenanceLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((result) => {
      const { pathname, hash, search } = window.location;

      if (pathname !== '/maintenance' && result.errors) {
        const maintenanceError = result.errors.find(
          (err) => err.message === MAINTENANCE_MODE,
        );
        if (maintenanceError) {
          localStorage.setItem(
            'lastLocationBeforeMaintenance',
            JSON.stringify({ pathname, hash, search }),
          );
          window.location.assign('/maintenance');
        }
      }
      return result;
    });
  });

  // Used to persist cached data event after a page reload.
  // Note that user’s cache is flushed if the key changes.
  await persistCache({
    cache,
    // type issue: https://github.com/apollographql/apollo-cache-persist/issues/399
    storage: new LocalForageWrapper(localForage) as any,
    maxSize: false,
    key: `apollo-cache-persist-${appConfig.apolloCacheVersion || '0.0.0'}`,
    // TODO: discuss having a debounce in https://gitlab.com/folk-application/app/-/merge_requests/1936
    // Debounce interval between persists (in ms).
    // Defaults to 0 for 'background' and 1000 for 'write' and custom triggers.
    // debounce: 60_000
  });

  const client = new ApolloClient({
    assumeImmutableResults: true,
    link: createPersistedQueryLink({ sha256 }).concat(
      ApolloLink.from([
        maintenanceLink,
        onError(({ graphQLErrors, operation }) => {
          if (appConfig.environment !== 'local') {
            datadogRum.addError(graphQLErrors, {
              operationName: operation.operationName,
              variables: operation.variables,
            });
          }

          // TODO: handle errors
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, extensions }) => {
              if (
                extensions?.code === 'UNAUTHENTICATED' ||
                message === 'authentication-failed' ||
                message === 'unauthorized-missing-user'
              ) {
                logout(client).then(() => {
                  redirectTo(UserStatus.NOT_CONNECTED, history);
                });
              }
            });
          }
        }),
        setContext(() => {
          return {
            credentials: 'include',
            headers: {
              authorization: `Bearer ${getAccessToken()}`,
            },
          };
        }),
        link,
      ]),
    ),
    cache,
    name: process.env.REACT_APP_IS_FOLKX === 'true' ? 'folkx' : 'folk-front',
    version: appConfig.version,
  });

  // do *not* await this one as it would slow down
  // the app initialization in `startApp`
  if (window?.location.pathname !== '/logout') {
    authenticate(client, history);
  }

  return client;
};

export const logout = async (client: ApolloClient<object>) => {
  // @note: do NOT clear session storage, otherwise redirecting to
  // the URL won't work
  client.stop();
  await client.clearStore();
  await localForage.clear().catch(console.error);

  const currentNetworkId = localStorage.getItem(CURRENT_NETWORK_ID_KEY);
  const currentGroupId = localStorage.getItem(CURRENT_GROUP_ID_KEY);
  localStorage.clear();
  // this makes it possible to keep the current network on next login
  if (currentNetworkId) {
    localStorage.setItem(CURRENT_NETWORK_ID_KEY, currentNetworkId);
    if (currentGroupId) {
      localStorage.setItem(CURRENT_GROUP_ID_KEY, currentGroupId);
    }
  }
};

export const useLogout = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    await logout(client);
  }, [client]);
};

// access token
const ACCESS_TOKEN_KEY = 'access_token';

export const setAccessToken = (token: string) => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const getAccessToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const removeAccessToken = () => {
  return window.localStorage.removeItem(ACCESS_TOKEN_KEY);
};

const USER_STATUS_KEY = 'user_status';

export const setUserStatus = (status: UserStatus) => {
  window.localStorage.setItem(USER_STATUS_KEY, status);
};

export const getUserStatus = () => {
  return (
    (window.localStorage.getItem(USER_STATUS_KEY) as UserStatus | null) ??
    UserStatus.NOT_CONNECTED
  );
};

const USER_ID_KEY = 'user_id';

const setUserId = (userId: string) => {
  window.localStorage.setItem(USER_ID_KEY, userId);
};

const getUserId = () => {
  return window.localStorage.getItem(USER_ID_KEY);
};

const removeUserId = () => {
  window.localStorage.removeItem(USER_ID_KEY);
};

const setUserInfos = (user: UserFragment | undefined) => {
  if (user) {
    setUserId(user.id);

    return setUserStatus(
      user.needOnboarding ? UserStatus.ONBOARDING : UserStatus.CONNECTED,
    );
  }

  removeAccessToken();
  removeUserId();
  setUserStatus(UserStatus.NOT_CONNECTED);
};

export const useGetIsConsistentUser = () => {
  const client = useApolloClient();

  return useCallback(() => {
    const userIdInCache = getUser(client)?.id;
    const userIdInLocalStorage = getUserId();

    return (
      !userIdInCache ||
      !userIdInLocalStorage ||
      userIdInCache === userIdInLocalStorage
    );
  }, [client]);
};

// sign in
const signInWithAccessToken = async ({
  client,
  accessToken,
  history,
  redirectUrl,
}: {
  client: ApolloClient<object>;
  accessToken: string;
  history: RouteComponentProps['history'];
  redirectUrl?: string;
}) => {
  setAccessToken(accessToken);

  const {
    data: { user },
  } = await client.query<GetMeQuery, GetMeQueryVariables>({
    query: GetMeDocument,
  });

  const userNeedsOnboarding = user.needOnboarding;
  setUserInfos(user);
  analytics?.identify(user.id, buildAnalyticsUserTraits(user));

  if (redirectUrl) {
    history.replace(redirectUrl);
    return;
  }

  if (
    userNeedsOnboarding === false &&
    // This is important not to redirect if you reload the page on a group for example.
    // You do *not* want to be redirected to AllContacts
    !assertUserStatusBasedOnUrl(UserStatus.CONNECTED)
  ) {
    redirectTo(UserStatus.CONNECTED, history);
  }

  if (
    userNeedsOnboarding === true &&
    // Same here, no need to redirect if the user is already on the onboarding
    !assertUserStatusBasedOnUrl(UserStatus.ONBOARDING)
  ) {
    redirectTo(UserStatus.ONBOARDING, history);
  }
};

export const useSignInWithAccessToken = (redirectUrl?: string) => {
  const client = useApolloClient();
  const history = useHistory();

  return useCallback(
    (accessToken: string) =>
      signInWithAccessToken({ client, accessToken, history, redirectUrl }),
    [client, history, redirectUrl],
  );
};

const renewToken = async (
  client: ApolloClient<object>,
  history: RouteComponentProps['history'],
) => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return;
  }

  try {
    const { data } = await client.mutate<
      RenewTokenMutation,
      RenewTokenMutationVariables
    >({
      mutation: RenewTokenDocument,
      variables: {
        token: accessToken,
      },
    });

    if (data?.accessToken) {
      setAccessToken(data.accessToken);

      return data.accessToken;
    }
  } catch (e) {
    const isMaintenanceMode =
      e instanceof ApolloError &&
      e.graphQLErrors.find((err: any) => err?.message === MAINTENANCE_MODE);

    if (!isMaintenanceMode) {
      logout(client).then(() => {
        redirectTo(UserStatus.NOT_CONNECTED, history);
      });
    }
  }
};

export const useRenewToken = () => {
  const client = useApolloClient();
  const history = useHistory();

  return useCallback(() => renewToken(client, history), [client, history]);
};

const isAuthTokenValid = (accessToken: string) => {
  const decoded: { exp: number } | null = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return !!decoded && decoded.exp >= currentTime;
};

const authenticate = async (
  client: ApolloClient<object>,
  history: RouteComponentProps['history'],
) => {
  const accessToken = await renewToken(client, history);

  if (!accessToken) {
    return;
  }

  if (isAuthTokenValid(accessToken)) {
    await signInWithAccessToken({ client, accessToken, history });
  } else {
    removeAccessToken();
  }
};

export const useAuthenticate = () => {
  const client = useApolloClient();
  const history = useHistory();

  return useCallback(() => authenticate(client, history), [client, history]);
};
