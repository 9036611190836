import {
  CompanyListItemFragmentDoc,
  PeopleCompanyFragmentDoc,
  useAddNewCompanyToPersonMutation,
  useAddPersonCompanyMutation,
} from '__generated__/graphql';
import { gql } from '@apollo/client';
import { updateContactNativeFieldModifier } from 'app/services/remote/contact/contactNativeFieldModifiers';

gql`
  mutation addPersonCompany($input: PersonCompanyInput!) {
    personCompany: addPersonCompany(input: $input) {
      ...PeopleCompany
    }
    
    ${PeopleCompanyFragmentDoc}
  }
`;

export const useAddCompanyToPerson = () => {
  return useAddPersonCompanyMutation({
    update: (cache, { data }) => {
      const personCompany = data?.personCompany;
      if (!personCompany) {
        return;
      }

      cache.modify({
        id: cache.identify({
          __typename: 'Person',
          id: personCompany.personId,
        }),
        fields: {
          companies: updateContactNativeFieldModifier({
            cache,
            nativeField: personCompany,
          }),
        },
      });
    },
  });
};

gql`
  mutation addNewCompanyToPerson($input: AddNewCompanyToPersonInput!) {
    personCompany: addNewCompanyToPerson(input: $input) {
      ...PeopleCompany
    }
  }

  ${PeopleCompanyFragmentDoc}
`;

export const useAddNewCompanyToPerson = () => {
  return useAddNewCompanyToPersonMutation({
    update(cache, { data }) {
      const personCompany = data?.personCompany;
      if (!personCompany) {
        return;
      }

      cache.modify({
        id: cache.identify({
          __typename: 'Person',
          id: personCompany.personId,
        }),
        fields: {
          companies: updateContactNativeFieldModifier({
            cache,
            nativeField: personCompany,
          }),
        },
      });
    },
  });
};

gql`
  fragment CompanyListItem on Company {
    id
    fullname
    picture
    urls {
      id
      value
    }
  }
`;

gql`
  query Companies($networkId: ID!, $query: String) {
    companies(
      networkId: $networkId
      limit: 5
      orderBy: { field: NAME, direction: ASC }
      query: $query
    ) {
      totalCount
      items {
        ...CompanyListItem
      }
    }
  }

  ${CompanyListItemFragmentDoc}
`;
