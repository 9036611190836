import { Box, MdClose } from '@folkapp/design-system';
import { FC } from 'react';

interface RemoveOptionProps {
  onRemove: VoidFunction;
}

export const RemoveOption: FC<RemoveOptionProps> = ({ onRemove }) => {
  return (
    <Box
      as="button"
      onClick={onRemove}
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '$4',
        height: '$4',
        marginLeft: '$1',
      }}
      aria-label="Remove option"
    >
      <Box
        as={MdClose}
        css={{
          opacity: 0.5,
          width: '$3_5',
          height: '$3_5',
          '&:hover, &:active': {
            opacity: 0.4,
          },
        }}
      />
    </Box>
  );
};
