import { styled } from '@folkapp/design-system';

export const SkeletonConnectedApp = ({
  isLightSidebar = false,
}: {
  isLightSidebar?: boolean;
}) => (
  <Container>
    <SkeletonSidebar isLightSidebar={isLightSidebar} />
    <SkeletonContent />
  </Container>
);

const Container = styled('div', {
  display: 'flex',
  backgroundColor: 'var(--color-background-sidebar)',
});

const SkeletonSidebar = styled('div', {
  width: 'var(--sidebar-width)',
  flexShrink: 0,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',

  variants: {
    isLightSidebar: {
      true: {
        backgroundColor: '$white',
      },
      false: {
        backgroundColor: 'var(--color-background-sidebar)',
      },
    },
  },
});

const SkeletonContent = styled('div', {
  display: 'flex',
  borderRadiusTopLeft: '$lg',
  borderRadiusBottomLeft: '$lg',
  boxShadow:
    'inset -1px 0 0 0 rgb(61 62 61 / 0%), 0 1px 1px 0 rgb(0 0 0 / 20%), 0 0 10px 0 rgb(0 0 0 / 5%)',
  flexGrow: 1,
  backgroundColor: '$white',
});
