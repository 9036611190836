import { Avatar, styled, Text } from '@folkapp/design-system';
import { FC } from 'react';

import { Flex } from './Flex';
import { Skeleton } from './Skeleton';
import { Tooltip } from './Tooltip';

export const AvatarGroupSkeleton = () => (
  <Flex>
    <Flex css={{ gap: '$1_5' }}>
      {Array.from({ length: 3 }, (_, i) => (
        <AvatarContainer key={i}>
          <Skeleton
            css={{
              width: '$6',
              height: '$6',
              backgroundColor: '$grey200',
              borderRadius: '$full',
              border: 'solid white 1px',
            }}
          />
        </AvatarContainer>
      ))}
    </Flex>

    <AdditionalText>
      <Flex align="center" css={{ height: '100%' }}>
        <Skeleton
          css={{ width: '$8', height: '$4', backgroundColor: '$grey200' }}
        />
      </Flex>
    </AdditionalText>
  </Flex>
);

interface AvatarGroupProps {
  items: Array<{
    id?: string;
    src?: string | null;
    name?: string | null;
  }>;
  maxItemsDisplayed?: number;
  testId?: string;
}

export const AvatarGroup: FC<AvatarGroupProps> = ({
  items,
  maxItemsDisplayed = 3,
  testId,
}) => {
  const additionalItems = items.slice(maxItemsDisplayed);

  return (
    <Flex data-testid={testId}>
      <Flex css={{ gap: '$1_5' }}>
        {items.slice(0, maxItemsDisplayed).map((item) => (
          <Tooltip key={item.id} content={item.name} side="bottom" align="end">
            <AvatarContainer>
              <Avatar
                id={item.id}
                name={item.name}
                src={item.src}
                size="md"
                variant="user"
              />
            </AvatarContainer>
          </Tooltip>
        ))}
      </Flex>

      {additionalItems.length > 0 && (
        <Tooltip
          content={additionalItems.map((item) => item.name).join(', ')}
          side="bottom"
          align="end"
        >
          <AdditionalText>
            <Text variant="caption">+{additionalItems.length}</Text>
          </AdditionalText>
        </Tooltip>
      )}
    </Flex>
  );
};

const AdditionalText = styled('div', {
  backgroundColor: '$grey200',
  width: '$6',
  height: '$6',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$full',
  marginLeft: '$1',
});

const AvatarContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
