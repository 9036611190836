import {
  CSSReset,
  EmptyState,
  ErrorIllustration,
  styled,
} from '@folkapp/design-system';
import { ClientSupportIssue } from 'app/utils/browser';
import { FC } from 'react';
import logo from 'static/images/logo2.svg';

const messageMapping: Record<ClientSupportIssue, string> = {
  browserCompatibility:
    'Sorry for the inconvenience, folk does not support your browser. We support the latest versions of modern browsers, you can try to update your browser version or to use a different one.',
  responsivity:
    'Sorry for the inconvenience, folk does not support small mobile screens. If you are on a tablet, try rotating your screen in landscape mode and reload the page.',
};

const Container = styled('div', {
  padding: '$4',
  margin: '0 auto',
  maxWidth: 400,
});

const ContentContainer = styled('div', {
  width: ' 100%',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const NotSupported: FC<{
  issue: ClientSupportIssue;
}> = ({ issue }) => (
  <Container>
    <CSSReset />
    <img width={75} src={logo} title="folk logo" alt="folk logo" />

    <ContentContainer>
      <EmptyState
        illustration={<ErrorIllustration />}
        title="Not supported"
        description={messageMapping[issue]}
      />
    </ContentContainer>
  </Container>
);
