import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const CommentIllustration = (props) => {
    return (_jsxs("svg", { width: "121", height: "120", viewBox: "0 0 121 120", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props, children: [_jsx("path", { d: "M98.5 69V112L38.5 69H98.5Z", fill: "#FFE999" }), _jsx("rect", { width: "95.9921", height: "64.5464", transform: "matrix(1 0 0.000131699 1 12.5 25)", fill: "#FFC700" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M98.5 89.5464H67.1694L38.5 69H98.5V89.5464Z", fill: "#FFB100" })] }));
};
export const SenderIllustration = (props) => {
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "121", height: "120", fill: "none", ...props, children: [_jsx("path", { fill: "#FFE999", d: "M12.5 40.001h95.9996l.00711175 54h-95.9996z" }), _jsx("path", { d: "M60.4997 64 16.4953 28h87.9997L60.4997 64Z", fill: "#FFC700" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M31.165 40.001 60.5001 64l29.3292-23.999H31.165Z", fill: "#FFB100" })] }));
};
export const ReminderIllustration = (props) => {
    return (_jsxs("svg", { width: "121", height: "120", viewBox: "0 0 121 120", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props, children: [_jsx("path", { d: "M75.7688 25.801C74.9617 31.5436 69.6522 35.5446 63.9096 34.7375C58.1671 33.9304 54.1661 28.6209 54.9731 22.8784C55.7802 17.1358 61.0897 13.1348 66.8323 13.9419C72.5748 14.7489 76.5758 20.0584 75.7688 25.801Z", fill: "#E48CB3" }), _jsx("circle", { cx: "56.116", cy: "90.1926", r: "14", transform: "rotate(8 56.116 90.1926)", fill: "#F5D4E3" }), _jsx("path", { d: "M25.3534 57.5937C28.0436 38.4519 45.742 25.1152 64.8839 27.8054C84.0257 30.4956 97.3624 48.194 94.6722 67.3359L90.4506 97.374L21.1318 87.6319L25.3534 57.5937Z", fill: "#DA5992" }), _jsx("path", { d: "M73.9756 30.3609C71.7822 33.4899 67.9533 35.3056 63.9096 34.7373C59.866 34.169 56.6859 31.3683 55.4399 27.7559C58.5047 27.3539 61.674 27.3543 64.8839 27.8054C68.0937 28.2565 71.1404 29.1297 73.9756 30.3609Z", fill: "#CA3C73" })] }));
};
export const InteractionIllustration = (props) => {
    return (_jsxs("svg", { width: "121", height: "120", viewBox: "0 0 121 120", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props, children: [_jsx("path", { d: "M66.9867 67.8248L21.5002 67.8248L66.9867 12.0005L66.9867 67.8248Z", fill: "#FFE999" }), _jsx("path", { d: "M52.5135 53.3515L98 53.3515L52.5135 109.176L52.5135 53.3515Z", fill: "#FFC700" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M52.5137 67.8238L66.9864 67.8238L66.9864 53.3516H52.5137V67.8238Z", fill: "#FFB100" })] }));
};
export const EmptyGroupIllustration = (props) => {
    return (_jsxs("svg", { width: "121", height: "120", viewBox: "0 0 121 120", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props, children: [_jsx("circle", { cx: "64.5", cy: "60", r: "35", fill: "#DDE5FA" }), _jsx("path", { d: "M16.5 60C16.5 86.5097 37.9903 108 64.5 108L64.5 12C37.9903 12 16.5 33.4903 16.5 60Z", fill: "#5079E4" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M64.5 25C45.17 25 29.5 40.67 29.5 60C29.5 79.33 45.17 95 64.5 95V25Z", fill: "#3A60DA" })] }));
};
export const NoMembersIllustration = (props) => {
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "120", height: "120", viewBox: "0 0 120 120", fill: "none", ...props, children: [_jsx("path", { d: "M95.444 53.4915C100.156 35.9071 89.5186 17.7784 71.6854 13L54.6227 76.6788C72.4559 81.4573 90.7323 71.0759 95.444 53.4915Z", fill: "#FFC700" }), _jsx("rect", { x: "34.0665", y: "84.9859", width: "31.8691", height: "18.8415", rx: "9.42077", fill: "#F56741" }), _jsx("path", { d: "M51.3504 88.4257L73.6613 88.4257L51.3504 46.9687L51.3504 88.4257Z", fill: "#5079E4" }), _jsx("path", { d: "M50.8283 31.2674C27.7272 37.4573 14.0676 61.3879 20.3188 84.7178L103.976 62.302C97.7243 38.9721 73.9295 25.0775 50.8283 31.2674Z", fill: "#FC6A45", fillOpacity: "0.15" }), _jsx("circle", { cx: "75.498", cy: "56.9481", r: "7.66679", fill: "#CA216B", fillOpacity: "0.4" }), _jsx("path", { d: "M44.0059 25.7082C39.0381 22.661 32.5407 24.2179 29.4935 29.1856L47.4833 40.2206C50.5305 35.2528 48.9736 28.7554 44.0059 25.7082Z", fill: "black" })] }));
};
export const DuplicateIllustration = (props) => {
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "120", height: "120", viewBox: "0 0 120 120", fill: "none", ...props, children: [_jsx("path", { d: "M48 60C48 86.5097 69.4903 108 96 108L96 12C69.4903 12 48 33.4903 48 60Z", fill: "#FFE999" }), _jsx("path", { d: "M16 60C16 86.5097 37.4903 108 64 108L64 12C37.4903 12 16 33.4903 16 60Z", fill: "#FFC700" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M64 95.7771C54.18 86.988 48 74.2154 48 59.9994C48 45.7834 54.18 33.0108 64 24.2217L64 95.7771Z", fill: "#FFB100" })] }));
};
export const ErrorIllustration = (props) => {
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "120", height: "120", viewBox: "0 0 120 120", fill: "none", ...props, children: [_jsx("rect", { x: "15.7705", y: "20.6077", width: "116", height: "24", transform: "rotate(30 15.7705 20.6077)", fill: "#FEE8E2" }), _jsx("circle", { cx: "60", cy: "60", r: "44", fill: "#F56741" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M29.3393 28.4419L102.67 70.7793C100.647 78.8112 96.4165 85.9654 90.6606 91.5586L17.3298 49.221C19.3525 41.1891 23.5834 34.035 29.3393 28.4419Z", fill: "#F04829" })] }));
};
export const SearchIllustration = (props) => {
    return (_jsxs("svg", { width: "120", height: "120", viewBox: "0 0 120 120", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props, children: [_jsx("circle", { cx: "51", cy: "51", r: "32", fill: "#F56741" }), _jsx("rect", { x: "46", y: "64.9648", width: "26.8203", height: "52.3634", transform: "rotate(-45 46 64.9648)", fill: "#FEE8E2" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M62.069 81.0339L46 64.9648L64.9648 46L81.0339 62.069C77.8011 70.8378 70.8378 77.801 62.069 81.0339Z", fill: "#F04829" })] }));
};
