import { createContext, ReactNode } from 'react';

import { Locale } from './types';

interface LocalizationProviderProps {
  children: ReactNode;
  locale: Locale;
}

export const LocalizationContext = createContext<null | Locale>(null);

// the app is not translated so it would be weird to
// have fully localised dates such as "1er septembre 2021"
const restrictLocaleToEnglish = (locale: Locale) => {
  const { language } = new Intl.Locale(locale);

  if (language !== 'en') {
    return 'en-GB';
  }

  // avoid confusion for date display. TO BE CONFIRMED
  if (locale === 'en') {
    return 'en-GB';
  }

  return locale;
};

export const LocalizationProvider = ({
  children,
  locale,
}: LocalizationProviderProps) => {
  return (
    <LocalizationContext.Provider value={restrictLocaleToEnglish(locale)}>
      {children}
    </LocalizationContext.Provider>
  );
};
