import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Slot } from '@radix-ui/react-slot';
import { forwardRef } from 'react';
import { styled } from './stitches.config';
import { renderSlotChildren } from './util/renderSlotChildren';
const ButtonIcon = styled('span', {
    $$iconSize: '$sizes$4',
    $$iconOuterMargin: '-$space$0_5',
    $$iconInnerMargin: '$space$1_5',
    height: '$$iconSize',
    width: '$$iconSize',
    flexShrink: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
        width: '100%',
        height: '100%',
    },
});
const ButtonLeadingIcon = styled(ButtonIcon, {
    marginInlineStart: '$$iconOuterMargin',
    marginInlineEnd: '$$iconInnerMargin',
});
const ButtonTrailingIcon = styled(ButtonIcon, {
    marginInlineStart: '$$iconInnerMargin',
    marginInlineEnd: '$$iconOuterMargin',
});
const ButtonBase = styled('button', {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingInline: '$2_5',
    paddingBlock: '$1_5',
    borderWidth: 1,
    textDecoration: 'none',
    fontSize: '$text',
    lineHeight: '$4',
    fontWeight: '$medium',
    borderRadius: '$md',
    whiteSpace: 'nowrap',
    flexShrink: 0,
    transitionProperty: 'background-color',
    transitionDuration: '150ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    '&:focus': {
        outline: 'none',
    },
    '&:disabled': {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    variants: {
        variant: {
            default: {
                backgroundColor: '$grey900',
                color: '$white',
                borderColor: '$transparent',
                svg: {
                    color: '$white',
                },
                '&:hover': {
                    backgroundColor: '$grey800',
                },
                '&:active': {
                    backgroundColor: '$grey700',
                },
                '&:focus-visible': {
                    boxShadow: '$sm, 0 0 0 1px $colors$white, 0 0 0 3px $colors$grey600',
                },
            },
            neutral: {
                backgroundColor: '$white',
                color: '$grey800',
                borderColor: '$grey300',
                svg: {
                    color: '$grey600',
                },
                '&:hover': {
                    backgroundColor: '$grey200',
                    svg: {
                        color: '$grey700',
                    },
                },
                '&:active': {
                    backgroundColor: '$grey200',
                },
                '&:focus-visible': {
                    boxShadow: '$sm, 0 0 0 1px $colors$white, 0 0 0 3px $colors$grey300',
                },
            },
            ghost: {
                backgroundColor: '$transparent',
                color: '$grey600',
                borderColor: '$transparent',
                mixBlendMode: 'multiply',
                svg: {
                    color: '$grey600',
                },
                '&:hover': {
                    backgroundColor: '$grey200',
                    svg: {
                        color: '$grey700',
                    },
                },
                '&:focus': {
                    backgroundColor: '$grey200',
                },
                '&:active': {
                    backgroundColor: '$grey200',
                },
                '&:focus-visible': {
                    boxShadow: '0 0 0 1px $colors$white, 0 0 0 3px $colors$grey200',
                },
            },
            danger: {
                backgroundColor: '$red100',
                color: '$red800',
                borderColor: '$transparent',
                svg: {
                    color: '$red700',
                },
                '&:hover': {
                    backgroundColor: '$red200',
                },
                '&:active': {
                    backgroundColor: '$red300',
                },
                '&:focus-visible': {
                    boxShadow: '0 0 0 1px $colors$white, 0 0 0 3px $colors$red400',
                },
            },
        },
        fullWidth: {
            true: {
                width: '100%',
                [`& > ${ButtonTrailingIcon}`]: {
                    marginInlineStart: 'auto',
                },
            },
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});
export const Button = forwardRef(({ leading, trailing, asChild, children, ...props }, ref) => {
    const renderChildren = (children) => (_jsxs(_Fragment, { children: [leading && _jsx(ButtonLeadingIcon, { children: leading }), children, trailing && _jsx(ButtonTrailingIcon, { children: trailing })] }));
    return (_jsx(ButtonBase, { as: asChild ? Slot : undefined, ref: ref, ...props, children: asChild
            ? renderSlotChildren(children, renderChildren)
            : renderChildren(children) }));
});
const IconButtonBase = styled(ButtonBase, {
    svg: {
        flexShrink: 0,
        width: '$4',
        height: '$4',
    },
    variants: {
        size: {
            lg: {
                padding: '$1_5',
            },
            md: {
                width: '$6',
                height: '$6',
                padding: '$0_5',
            },
        },
    },
    defaultVariants: {
        size: 'lg',
    },
});
export const IconButton = forwardRef(({ asChild, children, ...props }, ref) => {
    return (_jsx(IconButtonBase, { as: asChild ? Slot : undefined, ref: ref, ...props, children: children }));
});
