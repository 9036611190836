import { ApolloCache, Reference } from '@apollo/client';
import { Modifier } from '@apollo/client/cache/core/types/common';

const warnAboutUnresolvedReference = (entity: object) => {
  if (process.env.NODE_ENV !== 'production') {
    console.warn(
      `Could not resolve reference for the following entity: cache updates will not be applied.
- Make sure that a "__typename" property is present on the entity.
- Make sure that the entity primary key is present ("id" by default)
- If the entity primary key is not "id", make sure that a cache type policy defines the entity primary key or composite primary key (see InMemoryCache.typePolicies)
`,
      entity,
    );
  }
};

export const updateContactNativeFieldModifier =
  <
    TNativeField extends {
      isPrimary?: boolean | null | undefined;
    },
  >({
    cache,
    nativeField,
  }: {
    cache: ApolloCache<object>;
    nativeField: TNativeField;
  }): Modifier<Reference[] | null> =>
  (refs: Reference[] | null, { toReference }) => {
    const entityRef = toReference(nativeField);
    if (!entityRef) {
      warnAboutUnresolvedReference(nativeField);
      return refs;
    }
    if (!refs) {
      return [entityRef];
    }

    if (nativeField.isPrimary) {
      refs.forEach((ref) => {
        if (ref.__ref !== entityRef.__ref) {
          cache.modify({
            id: ref.__ref,
            fields: { isPrimary: () => false },
          });
        }
      });
    }

    const exists = refs.some((ref) => ref.__ref === entityRef.__ref);

    return exists ? refs : [...refs, entityRef];
  };

export const deleteContactNativeFieldModifier =
  <
    TNativeField extends {
      isPrimary?: boolean | null | undefined;
    },
  >({
    cache,
    nativeField,
  }: {
    cache: ApolloCache<object>;
    nativeField: TNativeField;
  }): Modifier<Reference[] | null> =>
  (refs: Reference[] | null, { toReference }) => {
    const entityRef = toReference(nativeField);
    if (!entityRef) {
      warnAboutUnresolvedReference(nativeField);
      return refs;
    }
    const updatedRefs =
      refs?.filter((ref) => ref.__ref !== entityRef.__ref) ?? [];

    if (nativeField.isPrimary) {
      const newPrimaryRef = updatedRefs[0];
      if (newPrimaryRef) {
        cache.modify({
          id: newPrimaryRef.__ref,
          fields: { isPrimary: () => true },
        });
      }
    }

    return updatedRefs;
  };
