// @ts-nocheck
/* eslint no-var: 0 */
/* eslint prefer-rest-params: 0 */

import { appConfig } from 'appConfig';

import { THIRD_PARTY_LOADING_DELAY_MS } from './constants';

export const initalizeIntercom = () => {
  if (appConfig.environment === 'local') {
    return;
  }

  const intercomAppId =
    appConfig.environment === 'production' ? 'rn8e78eq' : 'va1fk8br';

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + intercomAppId;
        var x = d.getElementsByTagName('script')[0];

        setTimeout(() => {
          x.parentNode.insertBefore(s, x);
        }, THIRD_PARTY_LOADING_DELAY_MS);
      };
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};
