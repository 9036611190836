import { useCallback, useContext, useMemo } from 'react';

import { formatDate, formatDateDistance } from '../functions';
import { LocalizationContext } from '../Provider';

export const useFormatters = () => {
  const locale = useContext(LocalizationContext);

  if (!locale) {
    throw new Error('@localization context missing');
  }

  const handleFormatDate = useCallback(
    (date: Date, options?: Intl.DateTimeFormatOptions) => {
      return formatDate({ date, locale, options });
    },
    [locale],
  );

  const handleFormatDateDistance = useCallback(
    (date: Date, options?: Intl.RelativeTimeFormatOptions) => {
      return formatDateDistance({ date, locale, options });
    },
    [locale],
  );

  return useMemo(
    () => ({
      formatDate: handleFormatDate,
      formatDateDistance: handleFormatDateDistance,
    }),
    [handleFormatDate, handleFormatDateDistance],
  );
};

export const useLocale = () => {
  const locale = useContext(LocalizationContext);

  if (!locale) {
    throw new Error('@localization context missing');
  }

  return locale;
};
