import { useApolloClient } from '@apollo/client';
import { logout } from 'app/services/graphQLService';
import { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

export const LogoutRoute: FC = () => {
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const client = useApolloClient();

  useEffect(() => {
    logout(client);
    setIsLoggedOut(true);
  }, [client]);

  if (!isLoggedOut) {
    return <div>Logging out..</div>;
  }

  return <Redirect to="/auth/login" />;
};
