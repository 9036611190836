import { Box, styled, Text } from '@folkapp/design-system';
import { useButton } from '@react-aria/button';
import { useHover } from '@react-aria/interactions';
import { CircularLoader } from 'app/Components/Loaders/CircularLoader';
import { PopoverProps } from 'app/Components/Popover/Popover';
import cx from 'classnames';
import {
  FC,
  Fragment,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { Section } from '../ComboBox';
import styles from './ComboBoxPopoverContent.module.css';
import { Option } from './Option';
import { ComboBoxOption, OptionActions, OptionVariant } from './Option.types';

export interface ComboBoxPopoverContentProps {
  loading: boolean;
  sections: Section[];
  onSelect: (option: ComboBoxOption) => void;
  currentFocus: number | undefined;
  setCurrentFocus: (index: number | undefined) => void;
  blockingMessage?: string;
  actions: OptionActions;
  variant?: OptionVariant;
  popoverVariant?: PopoverProps['variant'];
}

const RootBox = styled(Box, {
  display: 'block',
  width: 240,
  maxHeight: 415,
  padding: '5px 0',
  lineHeight: '28px',
  color: 'rgba(49, 53, 57, 1)',
  outline: 0,
  overflowY: 'auto',

  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },
});

let ComboBoxPopoverContent: FC<ComboBoxPopoverContentProps> = ({
  loading,
  sections,
  onSelect,
  currentFocus,
  setCurrentFocus,
  blockingMessage,
  actions,
  variant,
  popoverVariant,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  /**
   * Scrolls to focused item if it is not visible.
   */
  const handleScrollToOption = useCallback(
    (
      { top, bottom }: { top: number; bottom: number },
      itemRef: RefObject<HTMLDivElement>,
    ) => {
      const containerBoundingClientRect = ref.current?.getBoundingClientRect();
      if (containerBoundingClientRect) {
        const { top: containerTop, bottom: containerBottom } =
          containerBoundingClientRect;
        if (containerTop >= top) {
          itemRef.current?.scrollIntoView();
        } else if (containerBottom <= bottom) {
          itemRef.current?.scrollIntoView(false);
        }
      }
    },
    [],
  );

  const Title = styled(Text, {
    display: 'flex',
    alignItems: 'center',
    paddingInline: '$2',
    variants: {
      loading: {
        true: {
          opacity: 0.5,
        },
      },
    },
  });
  Title.defaultProps = {
    variant: 'caption',
    truncate: true,
  };

  // TODO: agree on a good UX regarding loading with designers
  if (loading && sections.length === 0) {
    return (
      <RootBox fullWidth={popoverVariant === 'contactPanel'} tabIndex={0}>
        <Title>loading...</Title>
        <div className={styles.loader}>
          <CircularLoader />
        </div>
      </RootBox>
    );
  }

  if (blockingMessage) {
    return (
      <RootBox fullWidth={popoverVariant === 'contactPanel'} tabIndex={0}>
        <Title>{blockingMessage}</Title>
      </RootBox>
    );
  }

  return (
    <RootBox ref={ref} fullWidth={popoverVariant === 'contactPanel'}>
      {sections.map((section, sectionIndex) => (
        <Fragment key={section.id}>
          <Title loading={loading}>{section.title}</Title>

          {section.options.map((option, optionIndex) => {
            const prevSectionsLength = sections
              .slice(0, sectionIndex)
              .map((section) => section.options)
              .flat().length;
            const computedIndex = prevSectionsLength + optionIndex;
            return (
              <SingleOption
                onFocus={handleScrollToOption}
                key={option.id}
                option={option}
                onSelect={onSelect}
                focus={currentFocus === computedIndex}
                index={computedIndex}
                setCurrentFocus={setCurrentFocus}
                actions={actions}
                variant={variant}
              />
            );
          })}
        </Fragment>
      ))}
    </RootBox>
  );
};

ComboBoxPopoverContent = memo(ComboBoxPopoverContent);

export { ComboBoxPopoverContent };

let SingleOption: FC<
  Pick<
    ComboBoxPopoverContentProps,
    'onSelect' | 'setCurrentFocus' | 'actions' | 'variant'
  > & {
    option: ComboBoxOption;
    focus: boolean;
    onFocus: (
      position: {
        top: number;
        bottom: number;
      },
      ref: RefObject<HTMLDivElement>,
    ) => void;
    index: number;
  }
> = ({
  option,
  onSelect,
  focus,
  setCurrentFocus,
  onFocus,
  actions,
  variant,
  index,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { buttonProps } = useButton(
    {
      onPress: () => {
        // the unfocus is needed to be able to focus the input
        setCurrentFocus(undefined);
        onSelect(option);
      },
      // can't have a `button` because we use the parent ref, which is applied on a `div`
      elementType: 'span',
    },
    ref,
  );
  const { hoverProps } = useHover({
    onHoverStart: () => {
      setCurrentFocus(index);
    },
  });

  useEffect(() => {
    const boundingClientRect = ref.current?.getBoundingClientRect();
    if (focus && boundingClientRect) {
      const { top, bottom } = boundingClientRect;
      onFocus({ top, bottom }, ref);
    }
  }, [focus, onFocus]);

  return (
    <span
      data-testid={`option-${option.id}`}
      {...buttonProps}
      {...hoverProps}
      ref={ref}
      className={cx(styles.option, focus && styles.optionFocused)}
    >
      <Option
        option={option}
        actions={actions}
        variant={option.variant || variant}
        context="popover"
      />
    </span>
  );
};

SingleOption = memo(SingleOption);
