import {
  CampaignsQuery,
  CampaignsQueryVariables,
  ContactListQueryVariables,
  ContactSearchQueryVariables,
  DuplicatesQuery,
  DuplicatesQueryVariables,
  GetPrimaryPeopleQuery,
  GetPrimaryPeopleQueryVariables,
  PaginatedContactsFragment,
  RankedContactsQuery,
  RankedContactsQueryVariables,
  SharedViewContactsQuery,
  SharedViewContactsQueryVariables,
} from '__generated__/graphql';
import { FieldMergeFunction, InMemoryCache } from '@apollo/client';

import { Company } from './fields/company';
import { CustomField } from './fields/customField';
import { GroupCustomField } from './fields/groupCustomField';
import { GroupSettings } from './fields/groupSettings';
import { Network } from './fields/network';
import { Person } from './fields/person';

const createMergePaginatedContacts =
  (offset: number): FieldMergeFunction<PaginatedContactsFragment> =>
  (existing, incoming) => {
    if (!existing) {
      return incoming;
    }
    if (existing.total !== incoming.total) {
      // Invalidate results when total changes.
      return incoming;
    }
    if (offset === 0) {
      // Invalidate results when fetching first page.
      // This allows contact list pages to be refetched when user comes back to the list.
      return incoming;
    }

    // Merge contacts arrays. The `incoming` array might not be the next page after `incoming`.
    // The resulting merged array might overlap with `prev` array or might have holes.
    const merged = [...existing.contacts];
    incoming.contacts.forEach((contact, index) => {
      merged[offset + index] = contact;
    });

    for (let i = 0; i < merged.length; i++) {
      if (!merged[i]) {
        // @ts-expect-error Set holes to null to normalize cache as apollo does not seem to allow undefined
        merged[i] = null;
      }
    }

    return {
      __typename: 'PaginatedContacts',
      contacts: merged,
      total: incoming.total,
    };
  };

const mergeSearchContactsPaginatedContacts: FieldMergeFunction<
  PaginatedContactsFragment
> = (existing, incoming, options) => {
  const args = options.args as ContactSearchQueryVariables;
  const page = args.page ?? 0;
  const pageSize = args.limit ?? 50;
  const offset = page * pageSize;
  const mergePaginatedContacts = createMergePaginatedContacts(offset);
  return mergePaginatedContacts(existing, incoming, options);
};

const mergeContactListPaginatedContacts: FieldMergeFunction<
  PaginatedContactsFragment
> = (existing, incoming, options) => {
  const args = options.args as ContactListQueryVariables;
  const page = args.pagination?.page ?? 0;
  const pageSize = args.pagination?.limit ?? 100;
  const offset = page * pageSize;
  const mergePaginatedContacts = createMergePaginatedContacts(offset);
  return mergePaginatedContacts(existing, incoming, options);
};

export function makeCache() {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          contact__list: {
            keyArgs: [
              'context',
              'pagination',
              ['sortField', 'filters', 'sortType', 'query', 'limit'],
              'selection',
            ],
            merge: mergeContactListPaginatedContacts,
          },
          sharedViewContacts: {
            keyArgs: ['id'],
            merge(
              existing:
                | SharedViewContactsQuery['sharedViewContacts']
                | undefined,
              incoming: SharedViewContactsQuery['sharedViewContacts'],
              options,
            ): SharedViewContactsQuery['sharedViewContacts'] {
              if (!existing) {
                return incoming;
              }
              if (existing.totalCount !== incoming.totalCount) {
                return incoming;
              }
              const offset =
                (options.args as SharedViewContactsQueryVariables).offset ?? 0;

              const items = [...existing.items];
              incoming.items.forEach((item, index) => {
                items[offset + index] = item;
              });
              return {
                __typename: 'PaginatedSharedViewContacts',
                items,
                totalCount: incoming.totalCount,
              };
            },
          },
          rankedContacts: {
            keyArgs: [
              'networkId',
              'groupId',
              'groupViewId',
              'contactType',
              'filters',
              'query',
              'limit',
            ],
            merge: (
              existing: RankedContactsQuery['rankedContacts'] | undefined,
              incoming: RankedContactsQuery['rankedContacts'],
              options,
            ): RankedContactsQuery['rankedContacts'] => {
              if (!existing) {
                return incoming;
              }
              if (existing.totalCount !== incoming.totalCount) {
                // Invalidate results when total changes.
                return incoming;
              }
              const args = options.args as RankedContactsQueryVariables;
              const offset = args.offset ?? 0;
              if (offset === 0) {
                // Invalidate results when fetching first page.
                // This allows contact list pages to be refetched when user comes back to the list.
                return incoming;
              }

              // Merge contacts arrays. The `incoming` array might not be the next page after `incoming`.
              // The resulting merged array might overlap with `prev` array or might have holes.
              const merged = [...existing.items];
              incoming.items.forEach((contact, index) => {
                merged[offset + index] = contact;
              });

              for (let i = 0; i < merged.length; i++) {
                if (!merged[i]) {
                  // @ts-expect-error Set holes to null to normalize cache as apollo does not seem to allow undefined
                  merged[i] = null;
                }
              }

              return {
                __typename: 'RankedContactListResult',
                items: merged,
                totalCount: incoming.totalCount,
              };
            },
          },
          campaigns: {
            keyArgs: ['networkId', 'limit'],
            merge: (
              existing: CampaignsQuery['campaigns'] | undefined,
              incoming: CampaignsQuery['campaigns'],
              options,
            ): CampaignsQuery['campaigns'] => {
              if (!existing) {
                return incoming;
              }
              if (existing.totalCount !== incoming.totalCount) {
                // Invalidate results when total changes.
                return incoming;
              }
              const args = options.args as CampaignsQueryVariables;
              const offset = args.input.offset ?? 0;
              if (offset === 0) {
                // Invalidate results when fetching first page.
                // This allows the list to be refetched when user comes back to the list.
                return incoming;
              }

              // Merge arrays. The `incoming` array might not be the next page after `incoming`.
              // The resulting merged array might overlap with `prev` array or might have holes.
              const merged = [...existing.items];
              incoming.items.forEach((contact, index) => {
                merged[offset + index] = contact;
              });

              for (let i = 0; i < merged.length; i++) {
                if (!merged[i]) {
                  // @ts-expect-error Set holes to null to normalize cache as apollo does not seem to allow undefined
                  merged[i] = null;
                }
              }

              return {
                __typename: 'CampaignListResult',
                items: merged,
                totalCount: incoming.totalCount,
              };
            },
          },
          searchContacts: {
            keyArgs: ['search', 'networkId', 'limit', 'contactType'],
            merge: mergeSearchContactsPaginatedContacts,
          },
          getPrimaryPeople: {
            keyArgs: ['companyId'],
            merge(
              existing: GetPrimaryPeopleQuery['primaryPeople'] | undefined,
              incoming: GetPrimaryPeopleQuery['primaryPeople'],
              options,
            ) {
              const merged = existing ? existing.slice(0) : [];
              const args = options.args as GetPrimaryPeopleQueryVariables;
              const offset = args.offset ?? 0;
              if (offset === 0) {
                // Invalidate results when fetching first page.
                // This allows contact list pages to be refetched when user comes back to the list.
                return incoming;
              }

              // If offset is 0, list must be reseted.
              if (offset === 0) {
                return incoming;
              }
              incoming.forEach((item, index) => {
                merged[offset + index] = item;
              });
              return merged;
            },
          },
          group__listViews: {
            merge(_, next) {
              return next;
            },
          },
          group__members: {
            merge(_, next) {
              return next;
            },
          },
          listReminders: {
            merge(_, next) {
              return next;
            },
          },
          listCampaignTemplates: {
            merge(cache, next) {
              return next;
            },
          },
          duplicates: {
            keyArgs: ['networkId'],
            merge(
              existing: DuplicatesQuery['duplicates'] | undefined,
              incoming: DuplicatesQuery['duplicates'],
              options,
            ): DuplicatesQuery['duplicates'] {
              if (!existing) {
                return incoming;
              }
              if (existing.totalCount !== incoming.totalCount) {
                return incoming;
              }
              const { offset = 0 } = options.args as DuplicatesQueryVariables;
              const items = [...existing.items];
              incoming.items.forEach((item, index) => {
                items[offset + index] = item;
              });
              return {
                __typename: 'DuplicateListResult',
                items,
                totalCount: incoming.totalCount,
              };
            },
          },
        },
      },
      GroupSettings,
      Network,
      Person,
      Company,
      CustomField,
      GroupCustomField,
      UserContactInteractionMetadata: {
        keyFields: ['contactId'],
      },
      Group: {
        fields: {
          members: {
            merge(_, next) {
              return next;
            },
          },
        },
      },
      Campaign: {
        fields: {
          messages: {
            merge(_, next) {
              return next;
            },
          },
          recipients: {
            merge(_, next) {
              return next;
            },
          },
        },
      },
      Filter: {
        keyFields: [
          'attribute',
          'condition',
          'value',
          'values',
          'customFieldId',
        ],
      },
      ImportStatus: {
        keyFields: ['userId', 'networkId', 'sourceName', 'importType'],
      },
      GroupContactPanelSettings: {
        fields: {
          person: {
            merge: (existing, incoming) => incoming,
          },
          company: {
            merge: (existing, incoming) => incoming,
          },
        },
      },
      PeopleCompany: {
        keyFields: ['personId', 'companyId'],
      },
    },
  });
}
