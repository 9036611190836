import {
  ContactContextInput,
  ContactPaginationInput,
  ContactSelectionInput,
  ContactType,
  SortType,
  ViewSettingsQuery,
} from '__generated__/graphql';
import { selection, useContactSearchQuery } from 'app/services/local/appState';
import { contactSearchQuery } from 'app/services/local/contactSearcQuery';
import { useUrlContactIdAndType } from 'app/utils/contacts';
import { removeTypename } from 'app/utils/functions';
import memoize from 'fast-memoize';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useViewSettings } from 'view-settings/useViewSettings';

import type { NetworkGroupRouteParams } from '../../../types/networkGroupRouteParams';

interface QueryVariables {
  context: ContactContextInput;
  pagination: ContactPaginationInput;
  selection: ContactSelectionInput;
  groupId?: string;
}

export const useContextInput = () => {
  const { data } = useViewSettings();
  const { networkId, groupId } = useParams<NetworkGroupRouteParams>();

  const contextInput = useMemo(() => {
    const contactType = data ? data.viewSettings.display : ContactType.person;
    return {
      contactType,
      networkId,
      groupId,
    };
  }, [data, groupId, networkId]);

  return contextInput;
};

const getSortDirection = (sortField: string): SortType => {
  switch (sortField) {
    case 'totalInteractions':
    case 'lastInteraction':
      return SortType.DESC;
    default:
      return SortType.ASC;
  }
};

const getPaginationFromConfig = memoize(
  (
    viewSettingsQuery: ViewSettingsQuery | undefined,
    query: string,
  ): ContactPaginationInput => {
    const defaultSortField = 'lastInteraction';
    if (!viewSettingsQuery) {
      return {
        filters: [],
        page: 0,
        limit: 100,
        sortField: defaultSortField,
        sortType: getSortDirection(defaultSortField),
        query,
      };
    }
    const filters = viewSettingsQuery.viewSettings.filters.map(removeTypename);
    const isPeopleType =
      viewSettingsQuery.viewSettings.display === ContactType.person;
    const orderFieldsType = viewSettingsQuery.viewSettings.orderFields;
    const orderFields = isPeopleType
      ? orderFieldsType.person
      : orderFieldsType.company;
    // TODO: use a getIsCustomField method instead to take into account custom field values
    const _sortField = orderFields?.find((f) => f.active)?.value;
    const sortField =
      (typeof _sortField === 'string' && _sortField) || defaultSortField;

    return {
      filters,
      page: 0,
      limit: 100,
      sortField,
      sortType: getSortDirection(sortField),
      query,
    };
  },
);

export const usePagination = (): ContactPaginationInput => {
  const { data } = useViewSettings();
  const query = useContactSearchQuery();
  const pagination = useMemo(
    () => getPaginationFromConfig(data, query),
    [data, query],
  );

  return pagination;
};

export const useGetPagination = () => {
  const { data } = useViewSettings();

  return useCallback(() => {
    return getPaginationFromConfig(data, contactSearchQuery());
  }, [data]);
};

export const useGetContext = () => {
  const { data } = useViewSettings();
  const { id, contactType } = useUrlContactIdAndType();
  const { networkId, groupId } =
    useParams<{ networkId: string; groupId: string }>();

  return useCallback((): ContactContextInput => {
    // FIXME: we should not rely on the contact panel contact type to resolve the context.
    const viewSettingsContactType =
      data?.viewSettings.display ?? ContactType.person;
    const isPeopleType =
      contactType === ContactType.person ||
      (!id && viewSettingsContactType === ContactType.person);

    return {
      // TODO: update config’s currentDisplay to match api.
      contactType: isPeopleType ? ContactType.person : ContactType.company,
      networkId,
      groupId,
    };
  }, [data?.viewSettings.display, contactType, id, groupId, networkId]);
};

export const useGetQueryVariables = () => {
  const getPagination = useGetPagination();
  const getContext = useGetContext();

  return useCallback((): QueryVariables => {
    const context = getContext();
    const pagination = getPagination();

    return {
      context,
      pagination,
      selection: selection(),
      groupId: context.groupId || undefined,
    };
  }, [getContext, getPagination]);
};
