import { AnimatedCheck } from '@designSystem/AnimatedCheck';
import { MdCheck, styled } from '@folkapp/design-system';
import { useEffect, useState } from 'react';
import cursorAnimation from 'static/images/cursor-animation.gif';

export const GrantAccessAnimation = () => {
  const [keyIndex, setKeyIndex] = useState(0);

  /**
   * Indefinitely re-render the SVG.
   * Feel free to implement a CSS-only solution: https://css-tricks.com/css-keyframe-animation-delay-iterations/
   * */
  useEffect(() => {
    const intervalId = setInterval(() => {
      setKeyIndex((key) => key + 1);
    }, 2.5 * 1_000);

    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, []);

  return (
    <Container>
      <Row percentage={79} />
      <Row percentage={63} />
      <Row percentage={92} />
      <Row percentage={79} animation="successAndCursor" keyIndex={keyIndex} />
      <Row percentage={63} animation="success" keyIndex={keyIndex} />
      <Row percentage={86} animation="success" keyIndex={keyIndex} />
      <Row percentage={94} animation="success" keyIndex={keyIndex} />
    </Container>
  );
};

const Row = ({
  animation,
  percentage,
  keyIndex,
}: {
  animation?: 'success' | 'successAndCursor';
  percentage: number;
  keyIndex?: number;
}) => {
  return (
    <RowContainer>
      <ProgressBarContainer>
        <ProgressBar
          css={{
            width: `${percentage}%`,
          }}
        />
      </ProgressBarContainer>

      {animation ? (
        <>
          <AnimatedCheck key={keyIndex} />
          {animation === 'successAndCursor' && (
            <AnimatedCursorContainer>
              <img
                key={(keyIndex ?? 0) - 999}
                width={50}
                src={cursorAnimation}
                title="Cursor animation"
                alt="Cursor"
              />
            </AnimatedCursorContainer>
          )}
        </>
      ) : (
        <IconContainer>
          <MdCheck size={18} color="white" />
        </IconContainer>
      )}
    </RowContainer>
  );
};

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '$lg',
  backgroundColor: '$grey100',
  borderRadius: '$lg',
  gap: '$6',
  padding: '$6',
});

const ProgressBarContainer = styled('div', {
  display: 'flex',
  flexGrow: 1,
});

const ProgressBar = styled('div', {
  backgroundColor: '$grey400',
  height: '$3',
  borderRadius: '$lg',
});

const IconContainer = styled('div', {
  height: 30,
  width: 30,
  backgroundColor: '$grey400',
  borderRadius: '$full',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
});

const AnimatedCursorContainer = styled('div', {
  position: 'absolute',
  top: 15,
  right: -15,
});

const RowContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'between',
  gap: '$8',
  position: 'relative',
});
