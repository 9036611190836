import { Flex } from '@designSystem';
import { styled } from '@folkapp/design-system';
import { useSearchField } from '@react-aria/searchfield';
import { useSearchFieldState } from '@react-stately/searchfield';
import { AriaSearchFieldProps } from '@react-types/searchfield';
import { FC, useRef } from 'react';

const StyledInviteInput = styled('input', {
  width: '100%',
  padding: '$2',
  '&::-webkit-search-cancel-button': {
    display: 'none',
  },
});

export const InviteInput: FC<
  AriaSearchFieldProps & {
    value: string;
    label?: string;
    placeholder: string;
    onChange: (text: string) => void;
    onClear: VoidFunction;
    onBlur?: VoidFunction;
  }
> = (props) => {
  const ref = useRef(null);
  const state = useSearchFieldState(props);
  const { inputProps } = useSearchField(
    {
      ...props,
      onBlur: () => {
        props.onBlur?.();
      },
    },
    state,
    ref,
  );

  return (
    <Flex direction="column" css={{ flex: 3, maxWidth: '500px' }}>
      <Flex
        align="center"
        gap="2"
        css={{
          width: '100%',
          gap: '$2',
          padding: '$1 $1 $1 $2',
          transition: '0.1s',
          border: '1px solid $grey200',
          borderRadius: '$lg',
          '&:hover': {
            borderColor: '$grey300',
          },
          '&:focus-within': {
            borderColor: '$grey900',
          },
        }}
      >
        <StyledInviteInput
          autoFocus
          className="addTeammate"
          {...inputProps}
          ref={ref}
          autoComplete="off"
        />
      </Flex>
    </Flex>
  );
};
