import { styled } from '@folkapp/design-system';

const StyledInput = styled('input', {
  border: '2px solid $grey200',
  width: '100%',
  transition: '0.1s',
  borderRadius: '$lg',
  padding: '$1 $2',
  variants: {
    variation: {
      default: {
        backgroundColor: '#fff',
        border: '1px solid $grey200',
        '&:hover': {
          border: '1px solid $grey200',
        },
        '&:active': {
          border: '1px solid $grey600',
        },
        '&:focus': {
          boxShadow: '0 0 0 3px #DDD7FF',
        },
      },
      flat: {
        backgroundColor: 'transparent',
        border: '2px solid transparent',
        color: '$grey800',
        '&:focus': {
          border: '2px solid $grey200',
        },
      },
      new: {
        border: '1px solid transparent',
        outline: 0,
        backgroundColor: 'white',
        '&::placeholder': {
          color: '$grey400',
        },
        '&:placeholder-shown': {
          backgroundColor: '$grey200',
        },
        '&:hover': {
          backgroundColor: '$grey200',
        },
        '&:focus': {
          backgroundColor: 'transparent',
          border: '1px solid $grey600',
        },
      },
    },
    width: {
      default: {
        maxWidth: '$80',
      },
      fullWidth: {
        width: '100%',
      },
    },
  },
  defaultVariants: {
    variation: 'default',
    width: 'default',
  },
});

export { StyledInput };
