import { styled } from '@folkapp/design-system';
import * as SwitchPrimitive from '@radix-ui/react-switch';
import React, { ComponentProps } from 'react';

const StyledSwitch = styled(SwitchPrimitive.Root, {
  all: 'unset',
  backgroundColor: '$grey300',
  borderRadius: '9999px',
  position: 'relative',
  WebkitTapHighlightColor: '$grey600',
  '&:focus': {
    boxShadow: '0 0 0 3px $colors$grey300',
  },
  transition: 'box-shadow 100ms',
  '&[data-state="checked"]': { backgroundColor: '$grey900' },

  variants: {
    size: {
      small: {
        width: 18,
        height: 10,
      },
      medium: {
        width: 28,
        height: 16,
      },
    },
  },

  defaultVariants: {
    size: 'medium',
  },
});

const StyledThumb = styled(SwitchPrimitive.Thumb, {
  display: 'block',
  backgroundColor: 'white',
  borderRadius: '9999px',
  transition: 'transform 100ms',
  transform: 'translateX(1px)',
  willChange: 'transform',

  variants: {
    size: {
      small: {
        width: 8,
        height: 8,
        '&[data-state="checked"]': { transform: 'translateX(9px)' },
      },
      medium: {
        width: 14,
        height: 14,
        '&[data-state="checked"]': { transform: 'translateX(13px)' },
      },
    },
  },

  defaultVariants: {
    size: 'medium',
  },
});

export type SwitchProps = Omit<ComponentProps<typeof StyledSwitch>, 'css'>;

export const Switch = (props: SwitchProps) => (
  <StyledSwitch {...props}>
    <StyledThumb size={props.size} />
  </StyledSwitch>
);
