// sha256 digest from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
export async function sha256(message: string): Promise<string> {
  const msgUint8 = new globalThis.TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await globalThis.crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
}
