import { FilterAttribute, FilterCondition } from '__generated__/graphql';
import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import { useRedirectUpon403 } from 'app/services/graphQLService/utils';
import {
  SortField,
  useContacts as useContactList,
} from 'app/services/remote/contact';
import { useCallback } from 'react';
import { useViewSettings } from 'view-settings/useViewSettings';

export { useConfigActions } from './config';
interface ContactsHookOptions {
  limit?: number;
  sortField?: SortField;
  fetchPolicy?: WatchQueryFetchPolicy;
  nextFetchPolicy?: WatchQueryFetchPolicy;
}

export function useEagerContacts({
  limit,
  sortField,
  fetchPolicy = 'cache-and-network',
  nextFetchPolicy = 'cache-first',
}: ContactsHookOptions = {}) {
  const redirectUpon403 = useRedirectUpon403();
  return useContactList(
    {
      fetchPolicy,
      nextFetchPolicy,
      // This callback was memoized during the Apollo 3 migration.
      // TODO: unmemoize this when the following issue is fixed:
      // https://github.com/apollographql/apollo-client/issues/6301
      onError: useCallback(
        (err: ApolloError) => {
          try {
            redirectUpon403({ error: err });
          } catch (error) {
            console.error(error);
          }
        },
        [redirectUpon403],
      ),
    },
    { limit, sortField },
  );
}

export const useShouldRefetchAfterFieldValueUpdate = () => {
  const { data } = useViewSettings();

  const filters = data?.viewSettings.filters;

  return useCallback(
    ({
      value,
      attribute,
      customFieldId,
    }: {
      value: string | null;
      attribute: FilterAttribute;
      customFieldId?: string;
    }) => {
      if (!filters) {
        return false;
      }

      return filters.some((filter) => {
        // If filters are active, check wether updated field is affected by said filters
        // If not, no need to refetch.
        if (filter.attribute !== attribute) return false;

        // if updating a custom field included in filters, refetch

        if (
          attribute === FilterAttribute.customField &&
          customFieldId === filter.customFieldId
        )
          return true;

        switch (filter.condition) {
          case FilterCondition.CONTAINS:
            return value && !value.includes(filter.value);
          case FilterCondition.NOT_CONTAINS:
            return value && value.includes(filter.value);
          case FilterCondition.IS:
            return value && value !== filter.value;
          case FilterCondition.IS_NOT:
            return value && value === filter.value;
          case FilterCondition.IS_EMPTY:
            return !!value;
          case FilterCondition.IS_NOT_EMPTY:
            return value === '' || !!!value;
          default:
            return false;
        }
      });
    },
    [filters],
  );
};
