import { useOnboardingGetStepQuery } from '__generated__/graphql';
import { InviteTeammates } from 'app/apps/onboarding/screens/InviteTeammates';
import { NetworkSetup } from 'app/apps/onboarding/screens/NetworkSetup';
import { Profile } from 'app/apps/onboarding/screens/NetworkSetup/Profile';
import { Sources } from 'app/apps/onboarding/screens/Sources';
import { useDefaultNetworkId } from 'app/services/local/user';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { getStepUrl, OnboardingStep } from './OnboardingRoutingUrls';

export const OnboardingRoute = () => {
  const match = useRouteMatch();
  const { defaultNetworkId } = useDefaultNetworkId();

  const { data: onboardingData } = useOnboardingGetStepQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  if (!onboardingData) {
    return null;
  }

  if (!onboardingData.onboardingGetStep.needOnboarding) {
    return <Redirect to="/" />;
  }

  const { isReferred, onboardingStep: currentStep } =
    onboardingData.onboardingGetStep;

  return (
    <Switch>
      <Route exact path={`${match.path}/network-setup`}>
        {defaultNetworkId ? (
          <Redirect
            to={getStepUrl({
              networkId: defaultNetworkId,
              step: OnboardingStep.INVITE_TEAM,
              isReferred,
            })}
          />
        ) : (
          <NetworkSetup
            numberOfSteps={isReferred ? 2 : 3}
            currentStep={0}
            nextStep={OnboardingStep.INVITE_TEAM}
            isReferred={isReferred}
          />
        )}
      </Route>

      <Route exact path={`${match.path}/:networkId/profile`}>
        <Profile
          numberOfSteps={isReferred ? 2 : 3}
          currentStep={0}
          nextStep={OnboardingStep.IMPORT}
          isReferred={isReferred}
        />
      </Route>

      <Route exact path={`${match.path}/:networkId/invite-team`}>
        <InviteTeammates
          numberOfSteps={3} // not available for referred users so 3 steps
          currentStep={OnboardingStep.INVITE_TEAM}
          nextStep={OnboardingStep.IMPORT}
          isReferred={isReferred}
        />
      </Route>

      <Route exact path={`${match.path}/:networkId/import`}>
        <Sources
          numberOfSteps={isReferred ? 2 : 3}
          currentStep={isReferred ? 1 : 2}
          nextStep={OnboardingStep.END}
          isReferred={isReferred}
        />
      </Route>

      <Route path="*">
        <Redirect
          to={getStepUrl({
            networkId: defaultNetworkId || undefined,
            step: currentStep,
            isReferred,
          })}
        />
      </Route>
    </Switch>
  );
};
