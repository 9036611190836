import { useEffect, useState } from 'react';

import { useSearchParams } from './useSearchParams';

const LOCAL_STORAGE_PREFIX = 'ff_';

export const FeatureFlagManager = ({
  featureFlags,
}: {
  featureFlags: string[];
}) => {
  const query = useSearchParams();

  useEffect(() => {
    for (const featureFlag of featureFlags) {
      const value = query.get(featureFlag);

      if (value === 'true' || value === 'false') {
        localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${featureFlag}`, value);
      }

      if (value === 'true') {
        console.info('⚠️⚠️⚠️ feature enabled ⚠️⚠️⚠️', featureFlag);
      }

      if (value === 'false') {
        console.info('⚠️⚠️⚠️ feature disabled ⚠️⚠️⚠️', featureFlag);
      }
    }
  });

  return null;
};

export const useFeatureFlagIsActive = (featureFlag: string) => {
  const [isActive, setIsActive] = useState(
    () =>
      localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${featureFlag}`) === 'true',
  );

  useEffect(() => {
    const onLocalStorageChange = () => {
      setIsActive(
        localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${featureFlag}`) ===
          'true',
      );
    };

    window.addEventListener('storage', onLocalStorageChange);

    return () => window.removeEventListener('storage', onLocalStorageChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlag]);

  return isActive;
};
