import { Flex } from '@designSystem';
import { styled } from '@folkapp/design-system';

export const StepDot = styled('button', {
  height: '$2',
  width: '$2',
  backgroundColor: '$grey300',
  boxSizing: 'border-box',
  borderRadius: '$2xl',
  margin: '0px 4px',
  transition: 'border-color 150ms ease-in-out',
  cursor: 'initial',
  variants: {
    isActive: {
      true: {
        backgroundColor: '$grey700',
      },
    },
  },
});

export const StepDots = ({
  stepCount,
  stepIndex,
}: {
  stepCount: number;
  stepIndex: number;
}) => (
  <Flex align="end" css={{ flex: 1, margin: '$10' }}>
    {Array.from({ length: stepCount }, (_, i) => (
      <StepDot key={i} disabled isActive={stepIndex === i} />
    ))}
  </Flex>
);
