import './Emoji.css';

import { backgroundImageFn } from 'app/utils/emoji';
// Not lazy-loaded on purpose because emojis are everywhere
import type { CustomEmoji } from 'emoji-mart';
import { Emoji as EmojiComponent } from 'emoji-mart';
import { FC, memo, useMemo } from 'react';
import folkLogo from 'static/images/folk.svg';

const FOLK_EMOJI: CustomEmoji = {
  id: 'folk',
  colons: ':folk:',
  emoticons: [],
  imageUrl: folkLogo,
  name: 'folk',
  short_names: ['folk'],
};

const getEmoji = (name?: string | null) =>
  name === 'folk' ? FOLK_EMOJI : name;

interface EmojiProps {
  emoji?: string | null;
  size: 12 | 16 | 20;
}

let Emoji: FC<EmojiProps> = ({ emoji, size }) => {
  const computedEmoji = getEmoji(emoji);

  const fallback = useMemo(
    () => <div style={{ width: size, height: size }} />,
    [size],
  );

  if (!computedEmoji) {
    return fallback;
  }

  return (
    <EmojiComponent
      emoji={computedEmoji}
      size={size}
      backgroundImageFn={backgroundImageFn}
    />
  );
};

Emoji = memo(Emoji);

export { Emoji };
