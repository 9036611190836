import {
  RemovePersonCompanyMutation,
  RemovePersonCompanyMutationVariables,
  useRemovePersonCompanyMutation,
} from '__generated__/graphql';
import { MutationHookOptions } from '@apollo/client';

import { deleteContactNativeFieldModifier } from './contactNativeFieldModifiers';

export const useRemovePersonCompany = (
  options?: Omit<
    MutationHookOptions<
      RemovePersonCompanyMutation,
      RemovePersonCompanyMutationVariables
    >,
    'update'
  >,
) => {
  return useRemovePersonCompanyMutation({
    update(cache, { data }) {
      const personCompany = data?.personCompany;
      if (!personCompany) {
        return;
      }
      cache.modify({
        id: cache.identify({
          __typename: 'Person',
          id: personCompany.personId,
        }),
        fields: {
          companies: deleteContactNativeFieldModifier({
            cache,
            nativeField: personCompany,
          }),
        },
      });
    },
    ...options,
  });
};
