import { CustomFieldType } from '__generated__/graphql';
import { ConfirmationModal } from '@designSystem';
import {
  MdContentCopy,
  MdDelete,
  MdVisibilityOff,
  styled,
  Text,
} from '@folkapp/design-system';
import { useToggleContactPanelActiveField } from 'app/apps/contactPanel/hooks/useToggleContactPanelActiveField';
import { CustomFieldContainer } from 'app/apps/customFields';
import { Popover, PopoverElement, PopoverOption } from 'app/Components/Popover';
import { useDisclosure } from 'app/hooks/useDisclosure';
import { customFieldIconMapping } from 'app/services/local/utils';
import { FC, ForwardedRef, forwardRef, ReactNode, useRef } from 'react';

export const ContactPanelLabel = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  paddingLeft: '$2',
  paddingRight: '$2',
  paddingTop: '$1',
  paddingBottom: '$1',
  borderRadius: '$lg',
  overflow: 'hidden',
  columnGap: '$1_5',
  minHeight: '$7',
  '&:hover': {
    backgroundColor: '$grey200',
  },
  variants: {
    disabled: {
      true: { cursor: 'not-allowed' },
      false: { cursor: 'pointer' },
    },
  },
});

export const ContactPanelLabelText = forwardRef(
  (
    { children }: { children: ReactNode },
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    return (
      <Text variant="caption" truncate css={{ userSelect: 'none' }} ref={ref}>
        {children}
      </Text>
    );
  },
);

export const ContactPanelLabelIcon = styled('div', {
  color: '$grey600',
  flexShrink: 0,
  svg: {
    width: 16,
    height: 16,
    fill: 'currentColor',
  },
});

export interface ContactPanelCustomFieldLabelProps {
  type: CustomFieldType;
  customFieldId: string;
  title: string;
  groupId: string;
  isPerson: boolean;
  onMenuOpenChanged?: (isOpen: boolean) => void;
}

export const ContactPanelCustomFieldLabel: FC<
  ContactPanelCustomFieldLabelProps
> = ({ type, customFieldId, title, groupId, isPerson, onMenuOpenChanged }) => {
  const handleToggleActive = useToggleContactPanelActiveField({
    groupId,
    isPerson,
  });

  const icon = customFieldIconMapping[type];
  return (
    <CustomFieldContainer
      title={title}
      customFieldId={customFieldId}
      type={type}
      extraOptions={[
        {
          icon: <MdVisibilityOff />,
          label: 'Hide',
          closeOnAction: true,
          onAction: () => handleToggleActive(customFieldId),
        },
      ]}
      onMenuOpen={() => onMenuOpenChanged?.(true)}
      onMenuClose={() => onMenuOpenChanged?.(false)}
    >
      <ContactPanelLabel>
        {icon && <ContactPanelLabelIcon>{icon}</ContactPanelLabelIcon>}
        <ContactPanelLabelText>{title}</ContactPanelLabelText>
      </ContactPanelLabel>
    </CustomFieldContainer>
  );
};

export interface ContactPanelNativeFieldLabelProps {
  icon?: ReactNode;
  title: string;
  fieldId: string;
  groupId?: string;
  isPerson: boolean;
  onDuplicate?: VoidFunction | null;
  onDelete?: VoidFunction | null;
  onMenuOpenChanged?: (isOpen: boolean) => void;
}

export const ContactPanelNativeFieldLabel: FC<
  ContactPanelNativeFieldLabelProps
> = ({
  icon,
  title,
  fieldId,
  groupId,
  isPerson,
  onDuplicate,
  onDelete,
  onMenuOpenChanged,
}) => {
  const popoverRef = useRef<PopoverElement>(null);
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure(false);

  const enablePopover = Boolean(onDuplicate || onDelete || groupId);

  const handleToggleActive = useToggleContactPanelActiveField({
    groupId,
    isPerson,
  });

  return (
    <>
      <Popover
        onOpen={() => onMenuOpenChanged?.(true)}
        onClose={() => onMenuOpenChanged?.(false)}
        ref={popoverRef}
        placement="bottom left"
        testId={`contact-panel-label-${title}`}
        content={
          enablePopover ? (
            <>
              {onDuplicate && (
                <PopoverOption
                  label="Duplicate"
                  icon={<MdContentCopy />}
                  closeOnAction
                  onAction={onDuplicate}
                />
              )}
              {onDelete && (
                <PopoverOption
                  label="Delete value"
                  icon={<MdDelete />}
                  closeOnAction
                  onAction={onOpenDeleteModal}
                  variant="error"
                />
              )}
              {groupId && (
                <PopoverOption
                  label="Hide"
                  icon={<MdVisibilityOff />}
                  closeOnAction
                  onAction={() => {
                    handleToggleActive(fieldId);
                  }}
                />
              )}
            </>
          ) : null
        }
      >
        <ContactPanelLabel disabled={!enablePopover}>
          {icon && <ContactPanelLabelIcon>{icon}</ContactPanelLabelIcon>}
          <ContactPanelLabelText>{title}</ContactPanelLabelText>
        </ContactPanelLabel>
      </Popover>
      <ConfirmationModal
        open={isDeleteModalOpen}
        onRequestClose={onCloseDeleteModal}
        confirmButtonText="Yes"
        onConfirm={() => {
          onDelete?.();
        }}
        title="Are you sure you want to delete this field?"
        description="This action cannot be undone."
      />
    </>
  );
};
