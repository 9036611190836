import { Avatar, Text } from '@folkapp/design-system';

import { Invite } from '../Invite';

export const NetworkMember = ({
  email,
  fullname,
  id,
  isOwner,
}: {
  email: string;
  fullname: string;
  id: string;
  isOwner: boolean;
}) => {
  return (
    <Invite
      avatar={<Avatar id={id} name={email} size="lg" variant="user" />}
      title={
        <>
          {fullname ?? email}{' '}
          {isOwner ? <Text variant="caption">Owner</Text> : null}
        </>
      }
      description={email}
    />
  );
};
