import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from './stitches.config';
import { Text } from './Text';
const EmptyStateRoot = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginInline: 'auto',
    maxWidth: '$lg',
    textAlign: 'center',
    flex: 1,
    variants: {
        variant: {
            default: {
                padding: '$16',
            },
            compact: {
                padding: '$8',
            },
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});
const EmptyStateIllustration = styled('div', { marginBlockEnd: '$6' });
export const EmptyStateTitle = ({ children }) => {
    return (_jsx(Text, { as: "h3", variant: "title", children: children }));
};
export const EmptyStateDescription = ({ children, }) => {
    return (_jsx(Text, { as: "p", css: { marginBlockStart: '$2' }, colorVariant: "muted", children: children }));
};
export const EmptyStateActions = styled('div', {
    marginBlockStart: '$6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '$3',
});
export const EmptyState = ({ illustration, title, description, actions, variant = 'default', }) => {
    return (_jsxs(EmptyStateRoot, { variant: variant, children: [illustration && (_jsx(EmptyStateIllustration, { children: illustration })), title && _jsx(EmptyStateTitle, { children: title }), description && (_jsx(EmptyStateDescription, { children: description })), actions && _jsx(EmptyStateActions, { children: actions })] }));
};
