import { keyframes, styled, Text } from '@folkapp/design-system';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { forwardRef, ReactNode } from 'react';

export const DropdownMenuContent = styled(DropdownMenuPrimitive.Content, {
  minWidth: '$48',
  maxHeight: '$120',
  backgroundColor: '#ffffff',
  borderRadius: '$md',
  padding: '$1',
  marginTop: '$2',
  boxShadow:
    '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.05)',
  overflow: 'auto',
  animationTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
  animationDuration: '150ms',

  '@media (prefers-reduced-motion: no-preference)': {
    $$translateX: 0,
    $$translateY: 0,
    '&[data-state="open"]': {
      animationName: keyframes({
        '0%': {
          opacity: 0,
          transform: 'translate($$translateX, $$translateY)',
        },
        '100%': {
          opacity: 1,
          transform: 'translate(0, 0)',
        },
      }),
    },
  },

  '&[data-side="top"]': {
    $$translateY: '-$sizes$1',
  },
  '&[data-side="right"]': {
    $$translateX: '-$sizes$1',
  },
  '&[data-side="bottom"]': {
    $$translateY: '$sizes$1',
  },
  '&[data-side="left"]': {
    $$translateX: '$sizes$1',
  },
});

const itemStyle = {
  '&:not([data-disabled])': {
    cursor: 'pointer',
  },
  userSelect: 'none',
  position: 'relative',
  padding: '$1_5 $3',
  borderRadius: '$default',
  display: 'flex',
  alignItems: 'center',

  variants: {
    variant: {
      default: {
        '&:focus': {
          backgroundColor: '$grey200',
        },
        svg: {
          color: '$grey600',
        },
        '&:focus svg': {
          color: '$grey700',
        },
      },
      danger: {
        color: '$red700',
        '&:focus': {
          backgroundColor: '$red100',
        },
        svg: {
          color: '$red500',
        },
        '&:focus svg': {
          color: '$red600',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'default',
  },
};

export const DropdownMenuItem = styled(DropdownMenuPrimitive.Item, itemStyle);

export const DropdownMenuCheckboxItem = styled(
  DropdownMenuPrimitive.CheckboxItem,
  itemStyle,
);

export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuRadioItem = styled(
  DropdownMenuPrimitive.RadioItem,
  itemStyle,
);

export const DropdownMenuItemIcon = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$5',
  height: '$5',
  marginLeft: '-$1',
  marginRight: '$2',
  svg: {
    width: '$4',
    height: '$4',
  },
});

export const DropdownMenuRoot = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuItemIndicator = DropdownMenuPrimitive.ItemIndicator;

export const DropdownMenu = forwardRef<
  HTMLButtonElement,
  DropdownMenuPrimitive.DropdownMenuProps & {
    content?: ReactNode;
  }
>(
  (
    {
      children,
      content,
      dir,
      open,
      defaultOpen,
      onOpenChange,
      modal,
      ...props
    },
    ref,
  ) => {
    return (
      <DropdownMenuRoot
        dir={dir}
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        modal={modal}
      >
        <DropdownMenuTrigger asChild ref={ref} {...props}>
          {children}
        </DropdownMenuTrigger>
        {content}
      </DropdownMenuRoot>
    );
  },
);

export const DropdownMenuLabel = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      variant="caption"
      as={DropdownMenuPrimitive.Label}
      css={{ padding: '$1_5 $3' }}
    >
      {children}
    </Text>
  );
};

export const DropdownMenuSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: '$grey200',
  margin: '$1 $0_5',
});
