import { ExternalLink } from '@designSystem';
import {
  Button,
  MdEvent,
  MdMail,
  MdPerson,
  Text,
} from '@folkapp/design-system';
import * as Dialog from '@radix-ui/react-dialog';

import {
  Form,
  GoogleImportTutorialDialogContent,
  GoogleImportTutorialDialogOverlay,
  List,
  ListItem,
  Paragraph,
} from '../onboarding/screens/Sources/GoogleAccountSourceOption';
import { GrantAccessAnimation } from '../onboarding/screens/Sources/GrantAccessAnimation';

export const GoogleSourceConnectTutorial = ({
  isOpen,
  onOpenChange,
  onClick,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onClick: VoidFunction;
}) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <GoogleImportTutorialDialogOverlay />

        <GoogleImportTutorialDialogContent>
          <Form
            onSubmit={(event: any) => {
              event.preventDefault();
              onClick();
            }}
          >
            <Text as="h3" variant="title" css={{ marginBottom: '$2' }}>
              Grant access to set up your CRM data
            </Text>

            <GrantAccessAnimation />

            <Paragraph>
              Check all permissions boxes on the next screen to access your:
            </Paragraph>

            <List>
              <ListItem aria-label="Contacts">
                <MdPerson size={16} />
                Contacts
              </ListItem>

              <ListItem aria-label="Emails">
                <MdMail size={16} />
                Emails
              </ListItem>

              <ListItem aria-label="Calendar events">
                <MdEvent size={16} />
                Calendar events
              </ListItem>
            </List>

            <ExternalLink
              // TODO: verify href
              href="https://intercom.help/folk-app/en/articles/4970606-import-your-contacts"
            >
              Learn how permissions are used at folk
            </ExternalLink>

            <Button css={{ marginTop: '$2' }}>Synchronise my data</Button>
          </Form>
        </GoogleImportTutorialDialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
