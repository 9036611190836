import { CustomFieldType } from '__generated__/graphql';
import { ConfirmationModal } from '@designSystem';
import { MdDelete, MdEdit } from '@folkapp/design-system';
import {
  Popover,
  PopoverOptionProps,
  PopoverOptions,
  PopoverOptionsSection,
  usePopoverContext,
} from 'app/Components/Popover';
import { customFieldIconMapping } from 'app/services/local/utils';
import { useUpdateCustomField } from 'app/services/remote/customField';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useAddCustomFieldContext } from '../context';

interface CustomFieldContainerProps {
  title: string;
  customFieldId: string;
  type: CustomFieldType;
  resizingIndex?: number;
  extraOptions?: PopoverOptionProps[];
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}

export const CustomFieldContainer: FC<
  React.PropsWithChildren<CustomFieldContainerProps>
> = ({
  children,
  title,
  customFieldId,
  type,
  resizingIndex,
  extraOptions,
  onMenuOpen,
  onMenuClose,
}) => {
  const { close: closeParentPopover } = usePopoverContext();
  const { dispatch, added: addedCustomFieldId } = useAddCustomFieldContext();
  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [customFieldLabelToDelete, setCustomFieldLabelToDelete] = useState<
    string | null
  >(null);
  const { updateTitle, updateType, deleteCustomField } = useUpdateCustomField(
    customFieldId,
    type,
  );
  const handleUpdateType = useCallback(
    (type: CustomFieldType) => {
      // @note: it is possible to update both the name and the type at once
      updateType(updatedTitle, type);
    },
    [updateType, updatedTitle],
  );
  const handleEnter = useCallback(
    (newTitle: string) => {
      dispatch({ type: 'clear' });
      if (newTitle !== title) {
        updateTitle(newTitle);
      }
    },
    [dispatch, title, updateTitle],
  );

  const isSelect =
    type === CustomFieldType.singleSelect ||
    type === CustomFieldType.multipleSelect;

  const changeTypeSections: PopoverOptionsSection[] = useMemo(
    () =>
      isSelect
        ? [
            {
              options: [
                {
                  icon: customFieldIconMapping.multipleSelect,
                  closeOnAction: true,
                  label: 'Multi select',
                  onAction: () => {
                    handleUpdateType(CustomFieldType.multipleSelect);
                    // we don't have an elegant API for closing the parent nested popover
                    closeParentPopover?.();
                  },
                },
                {
                  icon: customFieldIconMapping.singleSelect,
                  closeOnAction: true,
                  label: 'Select',
                  onAction: () => {
                    handleUpdateType(CustomFieldType.singleSelect);
                    // we don't have an elegant API for closing the parent nested popover
                    closeParentPopover?.();
                  },
                },
              ],
            },
          ]
        : [],
    [closeParentPopover, handleUpdateType, isSelect],
  );

  const sections: PopoverOptionsSection[] = useMemo(
    () => [
      {
        options: [
          {
            label: 'Column name',
            value: title,
            onEnter: handleEnter,
            onChange: setUpdatedTitle,
          },
          ...(isSelect
            ? [
                {
                  icon: <MdEdit />,
                  label: 'Change type',
                  sections: changeTypeSections,
                } as const,
              ]
            : []),
          ...(extraOptions ?? []),
          {
            icon: <MdDelete />,
            label: 'Delete',
            closeOnAction: true,
            onAction: () => setCustomFieldLabelToDelete(updatedTitle),
            variant: 'error',
          },
        ],
      },
    ],
    [
      setCustomFieldLabelToDelete,
      updatedTitle,
      extraOptions,
      handleEnter,
      setUpdatedTitle,
      title,
      changeTypeSections,
      isSelect,
    ],
  );

  useEffect(() => {
    setUpdatedTitle(title);
  }, [title]);

  return (
    <>
      <Popover
        placement="bottom left"
        disableOpen={resizingIndex !== undefined}
        triggerOpen={addedCustomFieldId === customFieldId}
        content={<PopoverOptions sections={sections} />}
        onOpen={() => {
          dispatch({ type: 'edit', customFieldId });
          onMenuOpen?.();
        }}
        onClose={() => {
          dispatch({ type: 'clear' });
          // ignore the updated title
          setUpdatedTitle(title);
          onMenuClose?.();
        }}
      >
        {children}
      </Popover>

      <ConfirmationModal
        open={customFieldLabelToDelete !== null}
        onRequestClose={() => setCustomFieldLabelToDelete(null)}
        confirmButtonText="Yes"
        onConfirm={deleteCustomField}
        title={`Are you sure you want to delete the custom field ${customFieldLabelToDelete}?`}
        description="This action cannot be undone."
      />
    </>
  );
};
