import {
  CompanyFieldUpdateField,
  ContactType,
  PersonFieldUpdateField,
  useUpdateCompanyFieldMutation,
  useUpdatePersonFieldMutation,
} from '__generated__/graphql';
import { useCallback } from 'react';

export const useNotesFormMethods = ({
  networkId,
  contactId,
  contactType,
}: {
  networkId: string;
  contactId: string;
  contactType: ContactType;
}) => {
  const [updatePersonField] = useUpdatePersonFieldMutation();
  const [updateCompanyField] = useUpdateCompanyFieldMutation();

  const onUpdateNotes = useCallback(
    (value: string | null) => {
      if (contactType === ContactType.person) {
        updatePersonField({
          variables: {
            input: {
              networkId,
              contactId,
              value,
              field: PersonFieldUpdateField.notes,
            },
          },
        });
      } else {
        updateCompanyField({
          variables: {
            input: {
              networkId,
              contactId,
              value,
              field: CompanyFieldUpdateField.notes,
            },
          },
        });
      }
    },
    [contactType, updatePersonField, updateCompanyField, networkId, contactId],
  );

  return {
    onUpdateNotes,
  };
};

export type NotesFormMethods = ReturnType<typeof useNotesFormMethods>;
