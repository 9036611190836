import { MdInfo, styled } from '@folkapp/design-system';
import { ComponentProps } from 'react';

const Container = styled('div', {
  padding: '$4',
  display: 'flex',
  alignItems: 'flex-start',
  textAlign: 'left',
  borderRadius: '$lg',
  gap: '$2',
  color: '$grey800',
  svg: {
    marginTop: 2, // hacky compensation
  },

  variants: {
    variation: {
      warning: {
        // TODO: use token
        backgroundColor: '#FBEAC2',
        svg: {
          // TODO: use token
          color: '#DDAA32',
        },
      },
    },
  },

  defaultVariants: {
    variation: 'info',
  },
});

type ContainerProps = Omit<ComponentProps<typeof Container>, 'css'>;

type BannerProps = Pick<ContainerProps, 'variation'> & {
  // @note: ideally, we would only text with possibly markdow to
  // be parsed here but it's simpler for now to accept text with links.
  content: string | JSX.Element;
};

export const Banner = ({ content, ...bannerProps }: BannerProps) => (
  <Container role="alert" {...bannerProps}>
    <MdInfo size={16} />
    <p>{content}</p>
  </Container>
);
