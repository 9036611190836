export enum OnboardingStep {
  INITIAL_SETUP = 0,
  INVITE_TEAM = 1,
  IMPORT = 2,
  END = 3,
}

export const getStepUrl = ({
  networkId,
  step,
  isReferred = false,
}: {
  networkId: string | undefined;
  step: number;
  isReferred?: boolean;
}) => {
  if (!networkId) {
    return '/onboarding/network-setup';
  }

  switch (step) {
    case OnboardingStep.INITIAL_SETUP:
      return isReferred
        ? `/onboarding/${networkId}/profile`
        : '/onboarding/network-setup';
    case OnboardingStep.INVITE_TEAM:
      return `/onboarding/${networkId}/invite-team`;
    case OnboardingStep.IMPORT:
      return `/onboarding/${networkId}/import`;
    default:
      return '/';
  }
};
