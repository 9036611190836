import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { keyframes, styled } from './stitches.config';
const SpinnerRoot = styled('svg', {
    color: '$colors$grey500',
    variants: {
        size: {
            sm: {
                width: '$5',
                height: '$5',
            },
            md: {
                width: '$10',
                height: '$10',
            },
        },
    },
    defaultVariants: {
        size: 'sm',
    },
});
const spin = keyframes({
    '100%': {
        transform: 'rotate(1turn)',
    },
});
const SpinnerGroup = styled('g', {
    animationName: spin.toString(),
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    transformOrigin: 'center',
});
const SpinnerCircle = styled('circle', {
    opacity: 0.25,
    stroke: 'currentColor',
    variants: {
        size: {
            sm: {
                strokeWidth: 2,
            },
            md: {
                strokeWidth: 1,
            },
        },
    },
    defaultVariants: {
        size: 'sm',
    },
});
const SpinnerArc = styled('path', {
    opacity: 0.75,
    stroke: 'currentColor',
    variants: {
        size: {
            sm: {
                strokeWidth: 2,
            },
            md: {
                strokeWidth: 1,
            },
        },
    },
    defaultVariants: {
        size: 'sm',
    },
});
const SpinnerLogo = styled('path', {
    opacity: 0.75,
    fill: 'currentColor',
});
export const Spinner = ({ size = 'sm', ...props }) => {
    return (_jsxs(SpinnerRoot, { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", size: size, role: "status", "aria-atomic": "true", ...props, children: [_jsxs(SpinnerGroup, { children: [_jsx(SpinnerCircle, { cx: 12, cy: 12, r: 10, size: size }), _jsx(SpinnerArc, { d: "M 20.66025403784439 17 A 10 10 0 0 0 12 2", size: size })] }), size === 'md' && _jsx(SpinnerLogo, { d: "M 5 12 A 7 7 0 0 0 19 12" })] }));
};
