import {
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
  useGetMeQuery,
  UserFragment,
} from '__generated__/graphql';
import { ApolloClient, QueryHookOptions } from '@apollo/client';
import { appConfig } from 'appConfig';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { getAccessToken } from '../graphQLService';

/**
 * Fetches remote connected user.
 */
export function useGetMe(
  options?: QueryHookOptions<GetMeQuery, GetMeQueryVariables>,
): UserFragment | undefined {
  const history = useHistory();
  const userQueryResult = useGetMeQuery({
    onError: useCallback(
      (error: any) => {
        if (error.message === 'unauthorized-missing-user') {
          history.push('/logout');
        }
      },
      [history],
    ),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options,
  });

  // FIXME: find a better way, maybe intializing the test cache?
  if (process.env.NODE_ENV === 'test' && userQueryResult.data === undefined) {
    return {
      __typename: 'LocalUser',
    } as any;
  }

  if (userQueryResult.data?.user === undefined) {
    history.push('/logout');
  }

  return userQueryResult.data?.user;
}

/**
 * Gets current user.
 */
export function getUser(client: ApolloClient<object>) {
  return client.readQuery<GetMeQuery, GetMeQueryVariables>({
    query: GetMeDocument,
  })?.user;
}

/**
 * Gets current user from cache.
 */
export function useUser(
  options?: QueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const { data } = useGetMeQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    ...options,
  });
  return data?.user;
}

/**
 * Uploads an avatar
 */
export const uploadAvatar = async (file: File) => {
  const data = new FormData();

  data.append('file', file);

  return fetch(`${appConfig.userApiUrl}/avatars/upload`, {
    method: 'POST',
    body: data,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  })
    .then((response) => response.json())
    .then((response) => response.url);
};
