import { Switch } from '@designSystem';
import { Box, config } from '@folkapp/design-system';
import { useButton } from '@react-aria/button';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { Emoji } from 'app/Components/Emoji';
import { COLORS } from 'app/utils/constants';
import cx from 'classnames';
import { FC, ReactNode, useEffect, useRef } from 'react';

import { usePopoverContext } from '../../Popover';
import styles from './PopoverOption.module.css';

export interface PopoverOptionProps {
  id?: string;
  label?: string;
  onAction?: VoidFunction;
  icon?: ReactNode;
  emoji?: string | null;
  picture?: string | null;
  closeOnAction?: boolean;
  hasFocus?: boolean;
  onHover?: VoidFunction;
  children?: JSX.Element;
  nestedHover?: boolean;
  variant?: 'error' | 'switch' | 'success';
  activeSwitch?: boolean;
  isNested?: boolean;
  isDisabled?: boolean;
  selected?: boolean;
}

export const PopoverOption: FC<PopoverOptionProps> = ({
  label,
  onAction,
  icon,
  closeOnAction,
  onHover,
  nestedHover,
  hasFocus = false,
  children = null,
  variant,
  activeSwitch,
  emoji,
  isNested,
  isDisabled,
  selected,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { close } = usePopoverContext();
  const { buttonProps } = useButton(
    {
      elementType: 'div',
      isDisabled,
      onPress: () => {
        if (closeOnAction) {
          close?.();
        }

        onAction?.();
      },
    },
    ref,
  );

  const { hoverProps, isHovered } = useHover({
    onHoverStart: () => {
      if (onAction) {
        onHover?.();
      }
    },
  });

  useEffect(() => {
    if (hasFocus && isNested) {
      ref.current?.focus();
    }
  }, [hasFocus, isNested]);

  return (
    <div
      {...mergeProps(hoverProps, buttonProps)}
      ref={ref}
      className={cx(
        styles.root,
        isDisabled && styles.rootDisabled,
        (isHovered || hasFocus) && onAction && styles.rootHovered,
        variant === 'error' && styles.error,
      )}
      tabIndex={nestedHover ? undefined : 0}
    >
      <div className={styles.mainContainer}>
        <LeftContent isError={variant === 'error'} icon={icon} emoji={emoji} />

        <span
          className={styles.label}
          style={{
            fontWeight: selected
              ? config.theme.fontWeights.medium
              : config.theme.fontWeights.regular,
          }}
        >
          {label ?? null}
        </span>
        {children}
      </div>

      {variant === 'switch' && (
        <div className={styles.switchContainer}>
          <Switch checked={activeSwitch} size="small" />
        </div>
      )}
    </div>
  );
};

const LeftContent: FC<{
  isError: boolean;
  icon?: ReactNode;
  emoji: string | null | undefined;
}> = ({ isError, icon, emoji }) => {
  if (icon) {
    return (
      <Box
        className={styles.iconContainer}
        css={{ color: isError ? COLORS.error : undefined }}
      >
        {icon}
      </Box>
    );
  }

  if (emoji) {
    return (
      <div className={styles.iconContainer}>
        <div className={styles.icon}>
          <Emoji emoji={emoji} size={16} />
        </div>
      </div>
    );
  }

  return null;
};
