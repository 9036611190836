import {
  ContactMultiValueFragment,
  GroupPreviewFragment,
} from '__generated__/graphql';

import { mergeCustomFields } from '../utils/mergeCustomFields';

export const Company = {
  fields: {
    groups: {
      merge(_: GroupPreviewFragment[], next: GroupPreviewFragment[]) {
        return next;
      },
    },
    emails: {
      merge(
        prev: ContactMultiValueFragment[],
        next: ContactMultiValueFragment[],
      ) {
        return next;
      },
    },
    phones: {
      merge(
        prev: ContactMultiValueFragment[],
        next: ContactMultiValueFragment[],
      ) {
        return next;
      },
    },
    addresses: {
      merge(
        prev: ContactMultiValueFragment[],
        next: ContactMultiValueFragment[],
      ) {
        return next;
      },
    },
    urls: {
      merge(
        prev: ContactMultiValueFragment[],
        next: ContactMultiValueFragment[],
      ) {
        return next;
      },
    },
    customFields: {
      merge: mergeCustomFields,
    },
  },
};
