import {
  CommentAuthorFragmentDoc,
  CommentFragmentDoc,
  GroupContactCommentListInput,
  useGroupContactCommentLocationQuery,
  useListGroupContactCommentsQuery,
} from '__generated__/graphql';
import { gql } from '@apollo/client';

gql`
  fragment CommentAuthor on User {
    id
    fullname
    picture
  }
`;

gql`
  fragment Comment on GroupContactComment {
    id
    groupId
    contactId
    content
    authorId
    author {
      ...CommentAuthor
    }
    createdAt
    updatedAt
    deletedAt
    parentComment {
      id
      contactId
      content
      author {
        ...CommentAuthor
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
  ${CommentAuthorFragmentDoc}
`;

gql`
  mutation CreateGroupContactComment($input: GroupContactCommentCreateInput!) {
    comment: createGroupContactComment(input: $input) {
      ...Comment
    }
  }
  ${CommentFragmentDoc}
`;

gql`
  mutation UpdateGroupContactComment($input: GroupContactCommentUpdateInput!) {
    comment: updateGroupContactComment(input: $input) {
      ...Comment
    }
  }
  ${CommentFragmentDoc}
`;

gql`
  mutation RemoveGroupContactComment($input: GroupContactCommentRemoveInput!) {
    deletedCommentId: removeGroupContactComment(input: $input)
  }
`;

gql`
  query ListGroupContactComments($input: GroupContactCommentListInput!) {
    result: listGroupContactComments(input: $input) {
      totalCount
      items {
        ...Comment
      }
    }
  }
  ${CommentFragmentDoc}
`;

export const useCommentsQuery = (input: GroupContactCommentListInput) => {
  return useListGroupContactCommentsQuery({
    variables: { input },
    skip: !input.groupId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
};

gql`
  query GroupContactCommentLocation($commentId: ID!) {
    result: groupContactCommentLocation(commentId: $commentId) {
      contactId
      contactType
      groupId
      networkId
    }
  }
`;

export const useCommentLocationQuery = (commentId: string) => {
  return useGroupContactCommentLocationQuery({
    variables: { commentId },
    // We don't want to cache the result of this query
    fetchPolicy: 'network-only',
  });
};
