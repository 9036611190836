import {
  Button,
  GmailIcon,
  GoogleCalendarIcon,
  GoogleColorIcon,
  GoogleContactsIcon,
  keyframes,
  styled,
} from '@folkapp/design-system';
import * as Dialog from '@radix-ui/react-dialog';
import {
  GoogleSourceProvider,
  useGoogleSource,
} from 'app/apps/provider/GoogleSourceProvider';
import onboardingSourceGoogle from 'static/images/onboarding_source_google.avif';
import onboardingSourceGooglePng from 'static/images/onboarding_source_google.png';

import { SingleSourceOption } from './SingleSourceOption';

export const GoogleImportTutorialDialogOverlay = styled(Dialog.Overlay, {
  position: 'fixed',
  inset: 0,
  zIndex: 3,
  backgroundColor: '$grey800',
  opacity: 0.16,
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translateY(40px) scale(.9)' },
  '100%': { opacity: 1, transform: 'translateY(0) scale(1)' },
});

export const GoogleImportTutorialDialogContent = styled(Dialog.Content, {
  position: 'fixed',
  zIndex: 3,
  top: 0,
  left: 0,
  right: 0,
  margin: '10vh auto',
  padding: '$6 $12',
  maxHeight: '90vh',
  overflowY: 'overlay',
  width: '100%',
  maxWidth: '$xl',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  backgroundColor: '$white',
  borderRadius: '$md',
});

export function GoogleAccountSourceOption({
  networkId,
}: {
  networkId: string;
}) {
  return (
    <GoogleSourceProvider networkId={networkId} context="onboarding">
      <GoogleAccountSourceOptionInternal />
    </GoogleSourceProvider>
  );
}

const GoogleAccountSourceOptionInternal = () => {
  const { connect, isConnecting } = useGoogleSource();

  return (
    <SingleSourceOption
      illustration={
        <picture>
          <source srcSet={onboardingSourceGoogle} type="image/avif" />
          <img
            width={288}
            height={209}
            src={onboardingSourceGooglePng}
            title="Google account"
            alt="Google account"
          />
        </picture>
      }
      title="Connect Google account"
      subtitle="Connect your Google account and automatically get all your contacts, emails, and calendar events imported into folk."
      logos={
        <>
          <GoogleContactsIcon size={24} />
          <GmailIcon size={24} />
          <GoogleCalendarIcon size={24} />
        </>
      }
      action={
        <Button
          leading={<GoogleColorIcon />}
          fullWidth
          variant="neutral"
          disabled={isConnecting}
          onClick={() => connect({ showTutorial: true })}
        >
          Connect Google
        </Button>
      }
    />
  );
};

export const Form = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  gap: '$4',
});

export const List = styled('ul', {
  display: 'flex',
  gap: '$4',
});

export const ListItem = styled('li', {
  display: 'inline-flex',
  gap: '$1',
  alignItems: 'center',
});

export const Paragraph = styled('p', {
  color: '$grey900',
});
