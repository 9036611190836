import { AnalyticsBrowser } from '@segment/analytics-next';

import { appConfig } from '../appConfig';
import { THIRD_PARTY_LOADING_DELAY_MS } from './constants';

let analytics: AnalyticsBrowser | undefined;

const initalizeSegment = () => {
  setTimeout(() => {
    if (appConfig.environment === 'local') {
      return;
    }

    import('@segment/analytics-next').then(({ AnalyticsBrowser }) => {
      AnalyticsBrowser.load({
        writeKey:
          appConfig.environment === 'production'
            ? 'uutfOhVVAoXS1r0mo83p88xbqBVAdeHI'
            : 'MKyO55lgVCek71dAdloHl2aoYmCQplQG',
      });
    });
  }, THIRD_PARTY_LOADING_DELAY_MS);
};

export { analytics, initalizeSegment };
