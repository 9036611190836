import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { useAppConfig } from 'appConfig';
import { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { initializeApolloClient } from './graphQLService';

export const AuthenticatedApolloClientProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [client, setClient] =
    useState<ApolloClient<NormalizedCacheObject> | null>(null);

  const appConfig = useAppConfig();
  const history = useHistory();
  useEffect(() => {
    initializeApolloClient(appConfig, history).then((client) =>
      setClient(client),
    );
  }, [appConfig, history]);

  return client ? (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  ) : null;
};
