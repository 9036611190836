import {
  CompanyFragment,
  ContactType,
  PersonFragment,
} from '__generated__/graphql';
import { OptionWithAvatar } from 'app/Components/ComboBox';
import * as H from 'history';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useViewSettings } from 'view-settings/useViewSettings';

export function formatContactGender(gender?: string | null) {
  switch (gender) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    case 'O':
      return 'Other';
    case 'U':
    default:
      return 'Unknown';
  }
}
export const fromCompanyToOption = (company: {
  id: string;
  fullname?: string | null;
  picture?: string | null;
  urls?: { value?: string | null }[] | null;
}): OptionWithAvatar => ({
  id: company.id,
  text: company.fullname ?? '-',
  picture: company.picture ?? null,
  variant: 'squareAvatar',
  textRight:
    'urls' in company ? company.urls?.[0]?.value ?? undefined : undefined,
});

export const fromContactToOption = (
  contact: CompanyFragment | PersonFragment,
): OptionWithAvatar => ({
  id: contact.id,
  text: contact.fullname!,
  picture: contact.picture ?? null,
  textRight: contact.urls?.[0]?.value ?? undefined,
  variant: contact.contactType === 'company' ? 'squareAvatar' : undefined,
});

function getUrlContactIdAndTypeFromLocation(location: Location | H.Location) {
  let contactType: ContactType | null = null;
  const urlParams = new URLSearchParams(
    location.hash ? location.hash.replace('#', '') : '',
  );
  const contactId = urlParams.get('personId');
  const companyId = urlParams.get('companyId');
  if (contactId) {
    contactType = ContactType.person;
  } else if (companyId) {
    contactType = ContactType.company;
  }
  return {
    contactType,
    id: contactId || companyId,
  };
}

export function useUrlContactIdAndType() {
  return getUrlContactIdAndTypeFromLocation(useLocation());
}

/**
 * Function that provides current contact mode:
 * - From the url if it is provided when the contact panel is opened.
 * - From the current settings otherwise.
 */
export const useGetContactMode = () => {
  const { data } = useViewSettings();

  return useCallback(() => {
    return (
      getUrlContactIdAndTypeFromLocation(window.location).contactType ??
      data?.viewSettings.display ??
      ContactType.person
    );
  }, [data]);
};
