import { useDialog } from '@react-aria/dialog';
import { FocusRing, FocusScope } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import {
  AriaPositionProps,
  DismissButton,
  OverlayContainer,
  useModal,
  useOverlay,
  useOverlayPosition,
} from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import { animated, useSpring } from '@react-spring/web';
import cx from 'classnames';
import {
  Children,
  createContext,
  CSSProperties,
  forwardRef,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import styles from './Popover.module.css';

const getPopoverContentLayout = ({
  width,
  variant,
  placement,
  offset,
}: {
  width: number;
  variant?: PopoverVariant;
  placement: AriaPositionProps['placement'];
  offset: AriaPositionProps['offset'];
}): {
  width?: number;
  marginLeft: number | undefined;
  placement: AriaPositionProps['placement'];
  offset: AriaPositionProps['offset'];
} => {
  if (variant === 'nested') {
    return {
      marginLeft: undefined,
      placement: placement ?? 'right top',
      offset: offset ?? 2,
    };
  }

  if (variant === 'nestedClick') {
    return {
      marginLeft: undefined,
      placement: placement ?? 'top',
      offset: offset ?? -82,
    };
  }

  // because of cell padding
  if (variant === 'withinTable') {
    return {
      marginLeft:
        width <= 200 ? undefined : Math.max((200 - width) / 2, -8) - 8,
      placement: placement ?? (width <= 200 ? 'bottom' : 'bottom left'),
      offset: offset ?? 8,
    };
  }

  if (variant === 'contactPanel') {
    return {
      marginLeft: undefined,
      placement,
      offset: 8,
      width,
    };
  }

  return {
    marginLeft: undefined,
    placement,
    offset: offset ?? 2,
  };
};

// TODO: this should not be here
type PopoverVariant =
  | 'nested'
  | 'nestedClick'
  | 'withinTable'
  | 'datepicker'
  | 'contactPanel';

interface PopoverContentProps {
  isOpen: boolean;
  onClose: VoidFunction;
  style?: CSSProperties | undefined;
  children: JSX.Element | null;
  variant?: PopoverVariant;
  autoFocus: boolean;
  marginLeft: number | undefined;
  width?: number;
  offset: AriaPositionProps['offset'];
  openOnHover?: boolean;
}

const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  (
    {
      children,
      isOpen,
      onClose,
      style,
      autoFocus,
      variant,
      marginLeft,
      offset,
      openOnHover,
      width,
      ...otherProps
    },
    ref,
  ) => {
    const { x: scale } = useSpring({
      from: { x: 0.9 },
      x: isOpen ? 1 : 0.9,
      config: { tension: 300, friction: 50 },
    });

    const { x: opacity } = useSpring({
      from: { x: 0.33 },
      x: isOpen ? 1 : 0.33,
      config: { tension: 300, friction: 50 },
    });

    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal.
    const { overlayProps, underlayProps } = useOverlay(
      {
        onClose,
        isOpen,
        isDismissable: true,
      },
      ref as RefObject<HTMLDivElement>,
    );

    // Hide content outside the modal from screen readers.
    const { modalProps } = useModal();

    // Get props for the dialog and its title
    const { dialogProps } = useDialog({}, ref as RefObject<HTMLDivElement>);

    if (!isOpen || !Children.count(children)) {
      return null;
    }

    return (
      <OverlayContainer
        style={{
          // radix uses this z-index for its ovelay portal
          // while we are still using radix and ract-aria, we need to
          // make sure their z-index do not collide
          zIndex: 2147483647,
          position: 'absolute',
          top: 0,
          left: 0,
          minWidth: 'max-content',
        }}
      >
        <div
          className={variant !== 'nested' ? styles.overlay : undefined}
          {...(variant !== 'nested' && underlayProps)}
          // here out of precaution in addition to the CSS
        >
          <FocusScope autoFocus={autoFocus}>
            <div
              ref={ref}
              {...mergeProps(overlayProps, dialogProps, otherProps, modalProps)}
              style={{
                ...style,
                zIndex:
                  variant === 'nested' || variant === 'nestedClick'
                    ? 100_002
                    : 100_001,
              }}
            >
              <animated.div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={cx(
                  styles.content,
                  (variant === 'nested' ||
                    variant === 'nestedClick' ||
                    variant === 'datepicker') &&
                    styles.contentNested,
                )}
                style={{
                  opacity: opacity.to(String),
                  transform: scale.to((x: number) => `scale(${x})`),
                  maxHeight: style?.maxHeight,
                  // @note: to be refined depending on all use cases
                  marginLeft,
                  width,
                }}
              >
                {children}
              </animated.div>
            </div>

            <DismissButton onDismiss={onClose} />
          </FocusScope>
        </div>
      </OverlayContainer>
    );
  },
);

PopoverContent.displayName = 'PopoverContent';

export interface PopoverElement {
  close: VoidFunction;
  open: VoidFunction;
}

export interface PopoverProps {
  children: ReactNode;
  content: JSX.Element | null;
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
  triggerOpen?: boolean;
  autoFocus?: boolean;
  variant?: PopoverVariant;
  placement?: AriaPositionProps['placement'];
  defaultCursor?: boolean;
  offset?: AriaPositionProps['offset'];
  disableOpen?: boolean;
  testId?: string;
}

const Popover = forwardRef<PopoverElement, PopoverProps>(
  (
    {
      children,
      content,
      onOpen,
      onClose,
      triggerOpen,
      variant,
      placement: forcedPlacement,
      autoFocus = true,
      defaultCursor = false,
      offset: forcedOffset,
      disableOpen: initialDisableOpen = false,
      testId,
    },
    forwardedRef,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [closeWithDelay, setCloseWithDelay] = useState(false);
    const [openWithDelay, setOpenWithDelay] = useState(false);

    const [disableOpen, setDisableOpen] = useState(initialDisableOpen);
    // when we prevent the popover from opening, we want the condition to
    // remain for at least one more render. This is used at the end of resizing a
    // custom field column to prevent from opening the edit popover.
    useEffect(() => {
      let timeoutId: ReturnType<typeof setTimeout> | null = null;

      if (!initialDisableOpen && disableOpen) {
        timeoutId = global.setTimeout(() => {
          setDisableOpen(false);
        }, 0);
      } else {
        setDisableOpen(initialDisableOpen);
      }

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [initialDisableOpen, disableOpen]);

    const open = useCallback(() => {
      if (disableOpen) {
        return;
      }
      setIsOpen(true);
      onOpen?.();
    }, [disableOpen, onOpen]);
    const close = useCallback(() => {
      onClose?.();
      setIsOpen(false);
    }, [onClose]);
    const toggle = useCallback(() => {
      if (isOpen) {
        close();
      } else {
        open();
      }
    }, [close, isOpen, open]);

    useEffect(() => {
      if (triggerOpen && !isOpen) {
        open();
      }
    }, [triggerOpen, isOpen, open]);

    const triggerRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef(null);

    const {
      marginLeft: popoverContentMarginLeft,
      placement,
      offset,
      width,
    } = getPopoverContentLayout({
      width: triggerRef?.current?.clientWidth ?? 200,
      variant,
      placement: forcedPlacement,
      offset: forcedOffset,
    });

    // Get popover positioning props relative to the trigger
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { overlayProps, updatePosition } = useOverlayPosition({
      targetRef: triggerRef,
      overlayRef,
      placement,
      offset,
      isOpen,
    });

    useEffect(() => {
      const element = triggerRef.current;
      if (!element) return;
      if (!('ResizeObserver' in window)) return;
      const resizeObserver = new window.ResizeObserver(updatePosition);
      resizeObserver.observe(element);
      return () => resizeObserver.disconnect();
    }, [updatePosition]);

    const { hoverProps: nestedHoverProps } = useHover({
      onHoverStart: () => {
        setCloseWithDelay(false);
        setOpenWithDelay(true);
      },
      onHoverEnd: () => {
        onClose?.(); // @note: check that it makes sense to call it twice
        setCloseWithDelay(true);
        setOpenWithDelay(false);
      },
    });

    const handleKeyDown = useCallback(
      (e: { key: string }) => {
        if (e.key === 'Enter') {
          if (variant === 'nested' || variant === 'nestedClick') {
            open();
          } else {
            toggle();
          }
        }
      },
      [open, toggle, variant],
    );

    useEffect(() => {
      let timeoutId: NodeJS.Timeout | undefined;
      let active = true;
      if (closeWithDelay) {
        timeoutId = global.setTimeout(() => {
          if (active) {
            close();
          }
        }, 250);

        return () => {
          active = false;
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      }
    }, [close, closeWithDelay]);

    useEffect(() => {
      let timeoutId: NodeJS.Timeout | undefined;
      let active = true;
      if (openWithDelay) {
        timeoutId = global.setTimeout(() => {
          if (active) {
            open();
          }
        }, 150);

        return () => {
          active = false;
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      }
    }, [open, openWithDelay]);

    // @note: exporting `isOpen` doesn't work as intended - there are sync issues
    useImperativeHandle(forwardedRef, () => ({ open, close }));

    return (
      <PopoverContext.Provider value={{ close, isOpen }}>
        <div
          className={styles.container}
          {...(variant === 'nested' ? nestedHoverProps : {})}
          // @note: the event is stopped to prevent side effects when detecting
          // a table cell becoming inactive
          onBlur={(event) => {
            event.stopPropagation();
          }}
        >
          <FocusRing
            focusRingClass={cx(variant !== 'nested' && styles.focusRing)}
          >
            <div
              // not using a react-aria interaction so that the event
              // is dispatched, e.g. for ComboBox keyboard interactions
              ref={triggerRef}
              data-testid={testId}
              onClick={
                variant === 'nested' || variant === 'nestedClick'
                  ? open
                  : toggle
              }
              onKeyDown={handleKeyDown}
              className={cx(
                styles.button,
                defaultCursor && styles.defaultCursor,
              )}
              role="button"
              tabIndex={0}
            >
              {children}
            </div>
          </FocusRing>

          <PopoverContent
            {...overlayProps}
            ref={overlayRef}
            isOpen={isOpen && !!content}
            onClose={close}
            autoFocus={autoFocus}
            variant={variant}
            marginLeft={popoverContentMarginLeft}
            offset={offset}
            width={width}
          >
            {content}
          </PopoverContent>
        </div>
      </PopoverContext.Provider>
    );
  },
);

Popover.displayName = 'Popover';

export { Popover };

export const PopoverContext = createContext<{
  close: VoidFunction | null;
  isOpen: boolean;
}>({ close: null, isOpen: false });

export function usePopoverContext() {
  const context = useContext(PopoverContext);
  if (context === undefined) {
    throw new Error('usePopoverContext must be used within a PopoverContext');
  }

  return context;
}
