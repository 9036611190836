import { Box, styled } from '@folkapp/design-system';
import { ReactNode } from 'react';

const SingleSourceOptionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '$4',
  backgroundColor: '$grey100',
  borderRadius: '$lg',
  flexShrink: 0,
  height: 'fit-content',
  minHeight: 430,
});

const SingleSourceOptionSubContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  marginBottom: '$6',
});

const SingleSourceOptionLogoContainer = styled('div', {
  display: 'flex',
  gap: '$2',
  marginTop: '$4',
});

const Title = styled('h3', {
  color: '$grey900',
  fontWeight: '$medium',
  fontSize: '1.25rem',
  letterSpacing: '$tight',
  marginBottom: '$2',
});

const Subtitle = styled('p', {
  color: '$grey600',
});

const IllustrationContainer = styled('div', {
  backgroundColor: 'white',
  borderRadius: '$lg',
  overflow: 'hidden',
  boxShadow: '$sm',
});

export const SingleSourceOption = ({
  illustration,
  title,
  subtitle,
  logos,
  action,
}: {
  illustration: ReactNode;
  title: string;
  subtitle: string;
  logos?: ReactNode;
  action: ReactNode;
}) => (
  <SingleSourceOptionContainer>
    <SingleSourceOptionSubContainer>
      <IllustrationContainer>{illustration}</IllustrationContainer>

      <Box>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>

        <SingleSourceOptionLogoContainer>
          {logos}
        </SingleSourceOptionLogoContainer>
      </Box>
    </SingleSourceOptionSubContainer>

    {action}
  </SingleSourceOptionContainer>
);
