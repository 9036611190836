import { keyframes, styled } from '@folkapp/design-system';
import * as PopoverPrimitive from '@radix-ui/react-popover';

// Exports
export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverAnchor = PopoverPrimitive.Anchor;
export const PopoverContent = styled(PopoverPrimitive.Content, {
  position: 'relative',
  minWidth: '$48',
  backgroundColor: '#ffffff',
  borderRadius: '$md',
  padding: '$1',
  marginTop: '$2',
  boxShadow:
    '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.05)',
  overflow: 'auto',
  animationTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
  animationDuration: '150ms',

  '@media (prefers-reduced-motion: no-preference)': {
    $$translateX: 0,
    $$translateY: 0,
    '&[data-state="open"]': {
      animationName: keyframes({
        '0%': {
          opacity: 0,
          transform: 'translate($$translateX, $$translateY)',
        },
        '100%': {
          opacity: 1,
          transform: 'translate(0, 0)',
        },
      }),
    },
  },

  '&[data-side="top"]': {
    $$translateY: '-$sizes$1',
  },
  '&[data-side="right"]': {
    $$translateX: '-$sizes$1',
  },
  '&[data-side="bottom"]': {
    $$translateY: '$sizes$1',
  },
  '&[data-side="left"]': {
    $$translateX: '$sizes$1',
  },
});
export const PopoverArrow = styled(PopoverPrimitive.Arrow, {
  fill: '#fff',
});
export const PopoverClose = styled(PopoverPrimitive.Close, {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: '$3',
  width: '$3',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$grey600',
  position: 'absolute',
  top: '$1_5',
  right: '$1_5',

  '&:hover': { backgroundColor: '$grey200' },
  '&:focus': { boxShadow: `0 0 0 2px $grey600` },
});
