import { Flex } from '@designSystem';
import { Button } from '@folkapp/design-system';

import { FooterAction } from '../Modal';

interface Props {
  actions: FooterAction[];
  formId: string;
}

export const ModalFooter = ({ actions, formId }: Props) => {
  return (
    <Flex align="center" justify="end" css={{ padding: '$2' }} gap="2">
      {actions.map((action) => (
        <Button
          key={action.label}
          variant={action.variant}
          type={action.type}
          form={formId}
          leading={action.icon}
          onClick={action.onClick}
          data-testid={action.testId}
        >
          {action.label}
        </Button>
      ))}
    </Flex>
  );
};
