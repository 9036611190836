import { SkeletonConnectedApp } from 'app/Components/Loaders';
import { NotFound } from 'app/Components/NotFound';
import { useDefaultNetworkId } from 'app/services/local/user';
import { FC, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { GroupContactCommentRedirection } from '../comments/GroupContactCommentRedirection';
import { LogasRoute } from './logas/LogasRoute';
import { LogoutRoute } from './logout/LogoutRoute';
import { MaintenanceRoute } from './maintenance/MaintenanceRoute';
import { OAuthRedirection } from './oauth-redirection';
import { OnboardingRoute } from './onboarding/OnboardingRoute';

const LazyUserSettings = lazy(() =>
  import(/* webpackChunkName: "UserSettings" */ 'app/apps/userSettings').then(
    (module) => ({
      default: module.UserSettings,
    }),
  ),
);

const LazyAuthRoute = lazy(() =>
  import(/* webpackChunkName: "AuthRoute" */ './auth/AuthRoute').then(
    (module) => ({
      default: module.AuthRoute,
    }),
  ),
);

const LazyConnectedAppRoute = lazy(() =>
  import(
    /* webpackChunkName: "ConnectedAppRoute", webpackPreload: true */ './connectedApp/ConnectedAppRoute'
  ).then((module) => ({
    default: module.ConnectedAppRoute,
  })),
);

const LazySharedViewRoute = lazy(() =>
  import(
    /* webpackChunkName: "SharedView" */ './sharedView/SharedViewRoute'
  ).then((module) => ({
    default: module.SharedViewRoute,
  })),
);

const AuthRoute = () => {
  return (
    <Suspense fallback={null}>
      <LazyAuthRoute />
    </Suspense>
  );
};

const ConnectedAppRoute = () => (
  <Suspense fallback={<SkeletonConnectedApp />}>
    <LazyConnectedAppRoute />
  </Suspense>
);

const RedirectToDefaultNetwork: FC = () => {
  const { loading, defaultNetworkId } = useDefaultNetworkId();
  if (loading) {
    return null;
  }

  if (defaultNetworkId) {
    return <Redirect to={`/apps/contacts/network/${defaultNetworkId}/all`} />;
  }
  return <Redirect to="/apps/contacts/network" />;
};

export const FolkRoutes = () => {
  return (
    <Switch>
      <Route exact path="/shared/:id">
        <Suspense fallback={null}>
          <LazySharedViewRoute />
        </Suspense>
      </Route>

      <Route exact path="/oauth-redirection/email/:email/token/:token">
        <OAuthRedirection />
      </Route>

      <Route path="/auth">
        <AuthRoute />
      </Route>

      <Route exact path="/comments/:commentId">
        <GroupContactCommentRedirection />
      </Route>

      <Route path="/onboarding">
        {/* do not memoize for now as there are some issues with the webpack CSS extract tool */}
        <OnboardingRoute />
      </Route>

      <Route path="/apps/contacts/network/:networkId?">
        <ConnectedAppRoute />
      </Route>

      <Route path="/settings">
        <Suspense fallback={<SkeletonConnectedApp isLightSidebar />}>
          <LazyUserSettings />
        </Suspense>
      </Route>

      <Route exact path="/logout">
        <LogoutRoute />
      </Route>

      <Route exact path="/logas/:token">
        <LogasRoute />
      </Route>

      <Route exact path="/maintenance">
        <MaintenanceRoute />
      </Route>

      <Route
        exact
        path={['/', '/apps/contacts/all']}
        component={RedirectToDefaultNetwork}
      />

      <Route path="*" component={NotFound} />
    </Switch>
  );
};
