import { gql } from '@apollo/client';

import { GroupFragments } from './group';
import { UserFragments } from './user';

export const ContactMultiValueFragments = {
  multiValue: gql`
    fragment ContactMultiValue on ContactMultiValue {
      id
      value
      isPrimary
    }
  `,
};

export const InteractionFragments = {
  userContactMetadata: gql`
    fragment UserContactInteractionMetadata on UserContactInteractionMetadata {
      contactId
      count
      last
    }
  `,
};

const ContactCustomFieldFragments = {
  contactCustomField: gql`
    fragment ContactCustomField on CustomField {
      field {
        id
        type
        name
      }
      values
    }
  `,
};

export const CompanyFragments = {
  companyPreview: gql`
    fragment CompanyPreview on Company {
      id
      contactType
      cName
      fullname
      picture
    }
  `,
  company: gql`
    fragment Company on Company {
      id
      contactType
      fullname
      picture
      notes
      totalComments
      totalReminders
      addresses {
        ...ContactMultiValue
      }
      phones {
        ...ContactMultiValue
      }
      urls {
        ...ContactMultiValue
      }
      emails {
        ...ContactMultiValue
      }
      cName
      cDomain
      cTotalPeople
      userInteractionMetadata {
        ...UserContactInteractionMetadata
      }
      customFields {
        ...ContactCustomField
      }
      groups {
        ...GroupPreview
      }
    }
    ${ContactMultiValueFragments.multiValue}
    ${GroupFragments.groupPreview}
    ${InteractionFragments.userContactMetadata}
    ${ContactCustomFieldFragments.contactCustomField}
  `,
};

export const PeopleCompanyFragments = {
  peopleCompany: gql`
    fragment PeopleCompany on PeopleCompany {
      personId
      companyId
      isPrimary
      company {
        ...CompanyPreview
      }
    }
    ${CompanyFragments.companyPreview}
  `,
  peopleCompanyPreview: gql`
    fragment PeopleCompanyPreview on PeopleCompany {
      personId
      companyId
      company {
        id
        fullname
        picture
      }
    }
  `,
};

export const PersonFragments = {
  person: gql`
    fragment Person on Person {
      id
      contactType
      fullname
      picture
      notes
      totalComments(groupId: $groupId)
      totalReminders(groupId: $groupId)
      addresses {
        ...ContactMultiValue
      }
      phones {
        ...ContactMultiValue
      }
      urls {
        ...ContactMultiValue
      }
      emails {
        ...ContactMultiValue
      }
      pFirstname
      pGender
      pLastname
      pBirthday
      touchpoint(groupId: $groupId) {
        ...Touchpoint
      }
      pJobTitle
      companies {
        ...PeopleCompany
      }
      userInteractionMetadata {
        ...UserContactInteractionMetadata
      }
      customFields(groupId: $groupId) {
        ...ContactCustomField
      }
      groups {
        ...GroupPreview
      }
    }
    ${ContactMultiValueFragments.multiValue}
    ${UserFragments.touchpoint}
    ${GroupFragments.groupPreview}
    ${CompanyFragments.companyPreview}
    ${InteractionFragments.userContactMetadata}
    ${PeopleCompanyFragments.peopleCompany}
    ${ContactCustomFieldFragments.contactCustomField}
  `,
};

export const ContactFragments = {
  lightPreview: gql`
    fragment LightPreviewContact on Contact {
      id
      fullname
      picture
      contactType
    }
  `,
};

export const PaginatedContacts = {
  contacts: gql`
    fragment PaginatedContacts on PaginatedContacts {
      contacts {
        ... on Company {
          ...Company
        }
        ... on Person {
          ...Person
        }
      }
      total
    }
  `,
};
