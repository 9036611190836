import {
  ContactType,
  SetCompanyPrimaryValueMutation,
  SetCompanyPrimaryValueMutationVariables,
  SetPersonPrimaryValueMutation,
  SetPersonPrimaryValueMutationVariables,
  useSetCompanyPrimaryValueMutation,
  useSetPersonPrimaryValueMutation,
} from '__generated__/graphql';
import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  MutationUpdaterFunction,
} from '@apollo/client';
import { useCallback } from 'react';

import { updateContactNativeFieldModifier } from '../contactNativeFieldModifiers';

const contactPropertyByType = {
  address: 'addresses',
  email: 'emails',
  phone: 'phones',
  url: 'urls',
} as const;

export const useContactSetPrimaryValue = ({
  contactType,
  options,
}: {
  contactType: ContactType;
  options?:
    | MutationHookOptions<
        SetPersonPrimaryValueMutation,
        SetPersonPrimaryValueMutationVariables
      >
    | MutationHookOptions<
        SetCompanyPrimaryValueMutation,
        SetCompanyPrimaryValueMutationVariables
      >;
}) => {
  const update = useCallback<
    MutationUpdaterFunction<
      SetPersonPrimaryValueMutation | SetCompanyPrimaryValueMutation,
      | SetPersonPrimaryValueMutationVariables
      | SetCompanyPrimaryValueMutationVariables,
      DefaultContext,
      ApolloCache<any>
    >
  >(
    (cache, { data }, { variables }) => {
      if (!variables || !data?.multiValue) {
        return;
      }

      const contactProperty = contactPropertyByType[variables.input.type];

      cache.modify({
        id: cache.identify({
          __typename: contactType === ContactType.person ? 'Person' : 'Company',
          id: variables.input.contactId,
        }),
        fields: {
          [contactProperty]: updateContactNativeFieldModifier({
            cache,
            nativeField: data.multiValue,
          }),
        },
      });
    },
    [contactType],
  );

  const setPersonPrimaryValueMutation = useSetPersonPrimaryValueMutation({
    update,
    ...options,
  });
  const setCompanyPrimaryValueMutation = useSetCompanyPrimaryValueMutation({
    update,
    ...options,
  });

  return contactType === ContactType.person
    ? setPersonPrimaryValueMutation
    : setCompanyPrimaryValueMutation;
};
