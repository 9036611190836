import { useLocale } from 'app/@localization';
import { useCallback, useMemo } from 'react';

//  heavily inspired from https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/i18n/src/useFilter.ts
export const useContains = (options?: Intl.CollatorOptions) => {
  const locale = useLocale();

  const collator = useMemo(
    () =>
      new Intl.Collator(locale, {
        usage: 'search',
        ...options,
      }),
    [locale, options],
  );

  return useCallback(
    (string: string, substring: string) => {
      if (substring.length === 0) {
        return true;
      }

      string = string.normalize('NFC');
      substring = substring.normalize('NFC');

      let scan = 0;
      const sliceLen = substring.length;
      for (; scan + sliceLen <= string.length; scan++) {
        const slice = string.slice(scan, scan + sliceLen);
        if (collator.compare(substring, slice) === 0) {
          return true;
        }
      }

      return false;
    },
    [collator],
  );
};
