import { Flex } from '@designSystem';
import { Box, CSS } from '@folkapp/design-system';
import { useKeyboard } from '@react-aria/interactions';
import { useTextField } from '@react-aria/textfield';
import { useFormatters, useLocale } from 'app/@localization';
import { Datepicker, parseIncompleteDate } from 'app/Components/Datepicker';
import { Popover, PopoverElement, PopoverOption } from 'app/Components/Popover';
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

interface DatePopoverProps {
  autoFocus?: boolean;
  value?: Date | null;
  onClose?: VoidFunction;
  onSubmit: (date: Date | undefined) => void;
  id?: string;
  minDate?: Date;
  containerStyle?: CSS;
  placeholder:string
}

export const DatePopoverInput: FC<DatePopoverProps> = ({
  value,
  onClose,
  autoFocus = false,
  onSubmit,
  id,
  minDate,
  containerStyle,
  placeholder
}) => {
  const { formatDate } = useFormatters();
  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<PopoverElement>(null);

  const [inputValue, setInputValue] = useState(value ? formatDate(value) : '');
  useEffect(() => {
    setInputValue(value ? formatDate(value) : '');
  }, [formatDate, value]);

  const handleSubmit = useCallback(
    (newValue: Date | undefined) => {
      setInputValue(newValue ? formatDate(newValue) : '');
      onSubmit(newValue);
    },
    [onSubmit, formatDate],
  );

  // @note: this is used to stop the event propagation and prevent
  // UI update outside the component.
  const { keyboardProps } = useKeyboard({
    onKeyDown: (e) => {
      switch (e.key) {
        case 'Tab':
          e.preventDefault();
          if (e.shiftKey) {
            inputRef.current?.focus();
          }
          return;
        case 'Escape':
          e.continuePropagation();
          onClose?.();
          return;
        case 'Backspace':
          handleClear();
          return;
        default:
          return;
      }
    },
  });

  // there is no official way to focus the calendar content
  // instead of the input (which would be possible with a ref)
  const focusOnCalendar = useCallback(() => {
    const node = document.getElementsByClassName(
      'react-datepicker__day--selected',
    )[0] as HTMLElement | undefined;
    const nodeFallback = document.getElementsByClassName(
      'react-datepicker__day--keyboard-selected',
    )[0] as HTMLElement | undefined;

    if (!node && !nodeFallback) {
      console.warn('no node found to focus on the datepicker');
      return;
    }

    (node || nodeFallback)!.focus();
  }, []);

  // @note: this cannot be handled on `onChange` because
  // this needs to be triggered when the user chooses the selected date
  const handleSelect = useCallback(() => {
    popoverRef.current?.close();
    onClose?.();
  }, [onClose]);

  const handleChange = useCallback(
    (newValue: Date) => {
      handleSubmit(newValue);
    },
    [handleSubmit],
  );

  const handleClear = useCallback(() => {
    handleSubmit(undefined);
    popoverRef.current?.close();
    onClose?.();
  }, [onClose, handleSubmit]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    },
    [],
  );

  const locale = useLocale();

  const { inputProps } = useTextField(
    {
      'aria-label': 'Date',
      id,
      onBlur: () => {
        setTimeout(() => {
          // hack to detect blur when pressing ESC with the input focused
          if (document.activeElement?.tagName === 'BODY') {
            popoverRef.current?.close();
            onClose?.();
          }
        }, 0);

        if (value && !inputValue) {
          handleSubmit(undefined);
        }
      },
      onKeyDown: (e) => {
        switch (e.key) {
          case 'Tab':
            e.preventDefault();
            if (!e.shiftKey) {
              inputRef.current?.blur();
              focusOnCalendar();
            }
            break;
          case 'ArrowDown':
          case 'ArrowUp':
            inputRef.current?.blur();
            focusOnCalendar();
            break;
          case 'Enter':
            popoverRef.current?.close();
            onClose?.();
            const guessedDate = parseIncompleteDate(inputValue, locale);

            if (guessedDate) {
              handleSubmit(guessedDate);
            }
            break;
          case 'Escape':
            popoverRef.current?.close();
            onClose?.();
            break;
          default:
            break;
        }
      },
    },
    inputRef,
  );

  useEffect(() => {
    const _popoverRef = popoverRef;
    if (autoFocus) {
      _popoverRef.current?.open();
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
    return () => {
      _popoverRef.current?.close();
    };
  }, [autoFocus]);

  return (
    <Flex
      align="center"
      css={{
        flexGrow: 1,
        ...containerStyle,
      }}
    >
      <Popover
        autoFocus={false}
        placement="bottom left"
        defaultCursor
        ref={popoverRef}
        variant="datepicker"
        content={
          <Box {...keyboardProps}>
            <Datepicker
              minDate={minDate}
              selected={value}
              onChange={handleChange}
              onSelect={handleSelect}
              inputValue={inputValue}
            />

            <Box
              css={{
                borderTop: '1px solid $grey200',
              }}
            >
              <PopoverOption label="Clear" onAction={handleClear} />
            </Box>
          </Box>
        }
      >
        <Flex
          align="center"
          css={{
            flexGrow: 1,
            height: '100%',
          }}
        >
          <Box
            as="input"
            {...inputProps}
            css={{
              width: '$20',
              minWidth: '100%',
              '&::placeholder': {
                color: '$grey600',
              },
            }}
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            placeholder={placeholder}
          />
        </Flex>
      </Popover>
    </Flex>
  );
};
