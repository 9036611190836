import { Flex } from '@designSystem';
import { StepDot } from '@designSystem/StepDots';
import { Box, Button } from '@folkapp/design-system';
import { range } from 'app/utils/functions';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'static/images/back.svg';
import { ReactComponent as FolkLogo } from 'static/images/logo2.svg';

export const LayoutOnboarding: FC<
  React.PropsWithChildren<{
    numberOfSteps: number;
    currentStep: number;
    backgroundImg: string;
    title: string;
    subTitle: string;
  }>
> = ({
  children,
  numberOfSteps,
  currentStep,
  backgroundImg,
  title,
  subTitle,
}) => {
  return (
    <Flex
      direction="column"
      css={{
        position: 'relative',
        flexGrow: 1,
        padding: '$5',
        background: `url(${backgroundImg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        overflowY: 'overlay',
        width: '100%',
      }}
    >
      <Flex align="center" gap="2">
        <FolkLogo width="132px" height="32px" />
        <Button asChild leading={<BackIcon />} variant="neutral">
          <Link to="/logout" aria-label="Return to login page">
            Back
          </Link>
        </Button>
      </Flex>

      <Flex
        justify="center"
        css={{
          position: 'relative',
          width: '100%',
          flexGrow: 'inherit',
          backgroundColor: 'transparent',
          paddingTop: 'calc(20vh - 150px)',
        }}
      >
        <Flex direction="column" css={{ width: '100%' }}>
          {title && (
            <Box
              as="h1"
              css={{
                fontFamily: 'IBM Plex Sans Bold, sans-serif',
                fontWeight: '$bold',
                color: '$grey900',
                fontSize: '3rem',
                maxWidth: '$3xl',
                marginTop: '$7',
                marginBottom: '$3',
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
                lineHeight: 1.1,
              }}
            >
              {title}
            </Box>
          )}

          {subTitle && (
            <Box
              as="h2"
              css={{
                lineHeight: '$7',
                maxWidth: '$3xl',
                color: '$grey900',
                fontSize: '1.25rem',
                fontWeight: '$regular',
                textAlign: 'center',
                margin: '0 auto $10 auto',
              }}
            >
              {subTitle}
            </Box>
          )}

          <Flex
            justify="center"
            align="stretch"
            css={{ position: 'relative', flex: 1, maxWidth: '100%' }}
          >
            <Flex direction="column" align="center" css={{ width: '100%' }}>
              {children}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <OnboardingFooter
        numberOfSteps={numberOfSteps}
        currentStep={currentStep}
      />
    </Flex>
  );
};

export const OnboardingFooter: FC<{
  numberOfSteps: number;
  currentStep: number;
}> = ({ numberOfSteps, currentStep }) => {
  return (
    <Flex
      align="center"
      css={{
        width: '100%',
        position: 'relative',
        padding: '$6 $6 $4 $6',
      }}
    >
      <Flex justify="center" align="center" css={{ width: '100%' }}>
        {range(numberOfSteps).map((index) => (
          <Bullet
            key={index}
            isActive={currentStep === index}
            currentStep={currentStep}
            isOver={index < currentStep}
          ></Bullet>
        ))}
      </Flex>
    </Flex>
  );
};

const Bullet: FC<{
  isActive: boolean;
  currentStep: number;
  isOver: boolean;
}> = ({ isActive, isOver }) => {
  return <StepDot disabled isActive={isActive} />;
};
