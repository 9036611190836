import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export type UseDisclosure = (initialState?: boolean | (() => boolean)) => {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
};

/**
 * Hook to manage disclosure state for components like modals, drawers, disclosures, etc.
 * Help to handle common behaviors like opening, closing, and toggling.
 *
 * @example ```tsx
 * const { isOpen, onOpen, onClose } = useDisclosure(false);
 * return (
 *   <>
 *     <Modal isOpen={isOpen} onDismiss={onClose} />
 *     <Button onClick={onOpen}>Open Modal</Button>
 *   </>
 * );
 * ```
 */
export const useDisclosure: UseDisclosure = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onToggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []);
  return { isOpen, setIsOpen, onOpen, onClose, onToggle };
};
