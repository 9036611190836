import { styled } from '@folkapp/design-system';
import * as TabsPrimitive from '@radix-ui/react-tabs';

export const Tabs = TabsPrimitive.Root;

export const TabsList = styled(TabsPrimitive.List, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: '$5',
});

export const TabsTrigger = styled(TabsPrimitive.TabsTrigger, {
  outline: 'none',
  boxShadow: 'none',
  display: 'flex',
  fontWeight: '$medium',
  letterSpacing: '$wide',
  whiteSpace: 'nowrap',
  flexShrink: 0,
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$1',
  padding: '$3 0',
  color: '$grey600',
  borderBottom: '2px solid transparent',
  '&:focus': {
    borderBottomColor: '$grey400',
    boxShadow: '0 0 1px $grey500',
  },
  '&:hover': {
    borderBottomColor: '$grey400',
  },
  '&[data-state="active"]': {
    color: '$grey900',
    borderBottomColor: '$grey900',
  },
  transition: 'border-color 200ms',
});
export const TabsContent = TabsPrimitive.TabsContent;
