import { useHover } from '@react-aria/interactions';
import cx from 'classnames';
import { FC, ReactNode, useEffect, useMemo, useRef } from 'react';

import { Popover, PopoverOption } from '../..';
import { PopoverElement, usePopoverContext } from '../../Popover';
import { PopoverOptions, PopoverOptionsSection } from '../PopoverOptions';
import styles from './PopoverNestedOption.module.css';

interface PopoverNestedOptionProps {
  label: string;
  icon: ReactNode;
  sections?: PopoverOptionsSection[];
  content?: JSX.Element;
  hasFocus?: boolean;
  triggerOpen?: boolean;
  onHover?: VoidFunction;
}

export const PopoverNestedOption: FC<PopoverNestedOptionProps> = ({
  label,
  icon,
  sections,
  hasFocus,
  triggerOpen,
  onHover,
  content: contentProps,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<PopoverElement>(null);
  const { close: closeParentPopover } = usePopoverContext();
  const sectionsWithCloseOnAction = useMemo(
    () =>
      sections?.map((section) => ({
        ...section,
        options: section.options.map((option) => ({
          ...option,
          onAction: () => {
            option.onAction?.();
            if (option.closeOnAction) {
              closeParentPopover?.();
            }
          },
        })),
      })) ?? [],
    [sections, closeParentPopover],
  );

  const content =
    sections !== undefined ? (
      <PopoverOptions
        isNested
        sections={sectionsWithCloseOnAction}
        closeOnAction
        onClose={() => {
          containerRef.current?.focus();
          onHover?.();
        }}
      />
    ) : (
      contentProps
    );

  const { hoverProps } = useHover({
    onHoverStart: () => {
      onHover?.();
    },
  });

  useEffect(() => {
    if (triggerOpen) {
      popoverRef.current?.open();
    }
  }, [triggerOpen]);

  return (
    <div
      ref={containerRef}
      {...hoverProps}
      className={cx(styles.root, hasFocus && styles.rootHovered)}
      tabIndex={0}
    >
      <Popover ref={popoverRef} variant="nested" content={content!}>
        <PopoverOption nestedHover icon={icon} label={label} />
      </Popover>
    </div>
  );
};
