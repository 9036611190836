import {
  GroupContactPanelCustomFieldFragment,
  GroupContactPanelNativeFieldFragment,
  GroupContactPanelSettingsFragment,
} from '__generated__/graphql';

export type VisibleFieldsSections = {
  nativeFields: (
    | GroupContactPanelNativeFieldFragment
    | GroupContactPanelCustomFieldFragment
  )[];
  smartFields: (
    | GroupContactPanelNativeFieldFragment
    | GroupContactPanelCustomFieldFragment
  )[];
  activeFields: (
    | GroupContactPanelNativeFieldFragment
    | GroupContactPanelCustomFieldFragment
  )[];
  customFields: (
    | GroupContactPanelNativeFieldFragment
    | GroupContactPanelCustomFieldFragment
  )[];
};

export function swap<T extends {}>(
  array: T[],
  moveIndex: number,
  toIndex: number,
): T[] {
  if (
    array.length < Math.max(moveIndex, toIndex) ||
    moveIndex < 0 ||
    toIndex < 0
  ) {
    console.warn('cannot swap array', { array, moveIndex, toIndex });
    return array;
  }

  const item = array[moveIndex]!;
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  }

  if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }

  return array;
}

// Returns fitting styles for dragged/idle items
export const getFittingStyles =
  (
    order: number[],
    down: boolean = false,
    originalIndex: number = 0,
    curIndex: number = 0,
    y: number = 0,
  ) =>
  (
    index: number,
  ): {
    y: number;
    zIndex: number;
    shadow: number;
    immediate: ((n: string) => boolean) | boolean;
  } =>
    down && index === originalIndex
      ? {
          y: curIndex * 33 + y,
          zIndex: 1,
          shadow: 15,
          immediate: (n: string) => n === 'y' || n === 'zIndex',
        }
      : {
          y: order.indexOf(index) * 33,
          zIndex: 0,
          shadow: 1,
          immediate: false,
        };

function getOrderedFields(order: number[], sections: VisibleFieldsSections) {
  const orderedFields = order
    .map((orderedIndex) => sections.activeFields[orderedIndex])
    .filter<
      | GroupContactPanelNativeFieldFragment
      | GroupContactPanelCustomFieldFragment
    >(
      (
        field,
      ): field is
        | GroupContactPanelNativeFieldFragment
        | GroupContactPanelCustomFieldFragment => field !== undefined,
    );
  return [
    ...orderedFields,
    ...sections.nativeFields,
    ...sections.customFields,
    ...sections.smartFields,
  ];
}

export const getOrderedContactSettings = (
  newOrder: number[],
  baseSettings: GroupContactPanelSettingsFragment,
  isPerson: boolean,
  sections: VisibleFieldsSections,
) => {
  return {
    ...baseSettings,
    person: isPerson
      ? getOrderedFields(newOrder, sections)
      : baseSettings.person,
    company: isPerson
      ? baseSettings.company
      : getOrderedFields(newOrder, sections),
  } as const;
};

export const getToggledSettings = (
  id: string,
  settings: GroupContactPanelSettingsFragment,
  isPerson: boolean,
) => {
  return {
    ...settings,
    person: settings.person.map((field) => ({
      ...field,
      active: field.id === id && isPerson ? !field.active : field.active,
    })),
    company: settings.company.map((field) => ({
      ...field,
      active: field.id === id && !isPerson ? !field.active : field.active,
    })),
  };
};
