import { ClientContact, ClientPerson } from 'app/types';

export function contactIsPerson(
  contact?: ClientContact | null,
): contact is ClientPerson {
  return Boolean(contact?.__typename === 'Person');
}

export function buildContactUrl({
  contactId,
  isPerson,
  networkId,
  groupId,
}: {
  contactId: string;
  isPerson: boolean;
  networkId: string;
  groupId?: string;
}) {
  const search = isPerson ? `personId=${contactId}` : `companyId=${contactId}`;

  if (groupId == null) {
    return `/apps/contacts/network/${networkId}/all#${search}`;
  } else {
    return `/apps/contacts/network/${networkId}/groups/${groupId}#${search}`;
  }
}
