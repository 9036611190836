import {
  FilterAttribute,
  FilterCondition,
  GroupByGroupCustomFieldFragment,
  GroupViewSettingsFragment,
  RankedContactsQueryVariables,
} from '__generated__/graphql';
import { gql } from '@apollo/client';
import {
  CompanyFragments,
  PersonFragments,
} from 'app/operations/remote/fragments/contact';
import { SelectCustomFieldValue } from 'app/services/local/utils';
import { removeTypename } from 'app/utils/functions';

gql`
  query RankedContacts(
    $networkId: ID!
    $groupId: ID!
    $groupViewId: ID!
    $contactType: ContactType!
    $filters: [FilterInput!]
    $query: String
    $offset: Int
    $limit: Int
  ) {
    rankedContacts(
      networkId: $networkId
      groupId: $groupId
      groupViewId: $groupViewId
      contactType: $contactType
      filters: $filters
      query: $query
      offset: $offset
      limit: $limit
    ) {
      totalCount
      items {
        ... on Company {
          ...Company
        }
        ... on Person {
          ...Person
        }
      }
    }
  }

  ${PersonFragments.person}
  ${CompanyFragments.company}
`;

export const getRankedContactsQueryVariables = ({
  groupId,
  networkId,
  viewSettings,
  groupCustomField,
  groupCustomFieldValue,
  query,
}: {
  groupId: string;
  networkId: string;
  viewSettings: GroupViewSettingsFragment;
  groupCustomField: GroupByGroupCustomFieldFragment;
  groupCustomFieldValue?: SelectCustomFieldValue;
  query: string;
}): RankedContactsQueryVariables => {
  const columnFilter = groupCustomFieldValue
    ? {
        attribute: FilterAttribute.customField,
        customFieldId: groupCustomField.id,
        condition: FilterCondition.IS,
        value: '',
        values: [groupCustomFieldValue.id],
      }
    : {
        attribute: FilterAttribute.customField,
        customFieldId: groupCustomField.id,
        condition: FilterCondition.IS_EMPTY,
        value: '',
        values: null,
      };
  return {
    filters: [...viewSettings.filters.map(removeTypename), columnFilter],
    contactType: viewSettings.display,
    groupId,
    groupViewId: viewSettings.id,
    networkId,
    query,
    offset: 0,
    limit: 50,
  };
};
