import {
  GroupContactPanelSettingsQueryVariables,
  useGroupContactPanelSettingsQuery,
} from '__generated__/graphql';
import { useRefetchQueryOnPageVisible } from 'app/hooks/useRefetchQueryOnPageVisible';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

/**
 * Reference counting hook that keep track of the number of times a query is
 * referenced with these exact variables.
 */
const referenceCountByVariables: Record<string, number> = {};
const useQueryReferenceCount = (
  variables: GroupContactPanelSettingsQueryVariables,
) => {
  const referenceCountKey = useMemo(
    () => JSON.stringify(variables),
    [variables],
  );
  const [referenceCount, setRefCount] = useState(
    () => referenceCountByVariables[referenceCountKey] ?? 0,
  );

  useEffect(() => {
    const referenceCount = referenceCountByVariables[referenceCountKey] ?? 0;
    referenceCountByVariables[referenceCountKey] = referenceCount + 1;
    setRefCount(referenceCount);
    return () => {
      referenceCountByVariables[referenceCountKey] -= 1;
    };
  }, [referenceCountKey]);

  return referenceCount;
};

export const useGroupContactPanelSettings = () => {
  const { groupId } = useParams<{ groupId: string }>();

  const variables = useMemo<GroupContactPanelSettingsQueryVariables>(
    () => ({ groupId }),
    [groupId],
  );

  /**
   * Keep track of the number of times this query and variables are referenced.
   *
   * This allow us to change the fetch policy to:
   * - `cache-and-network` when the query is unused (i.e. reference count is 0)
   * - `cache-first` when the query is already used with the same variables (i.e. reference count is > 0)
   *
   * This behavior is different from the `cache-first` fetch policy as it _will_ refetch the query after it has been unmounted.
   * This allows us to refetch the query when the user navigates from one view to another,
   * and keep fetching logic simple in components that use this hook.
   */
  const referenceCount = useQueryReferenceCount(variables);

  const result = useGroupContactPanelSettingsQuery({
    variables,
    skip: !groupId,
    fetchPolicy: referenceCount === 0 ? 'cache-and-network' : 'cache-first',
    nextFetchPolicy: 'cache-first',
  });

  useRefetchQueryOnPageVisible(result);

  return result;
};
