import { gql } from '@apollo/client';

export const UserFragments = {
  user: gql`
    fragment User on User {
      id
      email
      fullname
      firstname
      lastname
      picture
      needOnboarding
      onboardingStep
      acquisitionTags
    }
  `,
  creator: gql`
    fragment Creator on User {
      id
      email
      fullname
      picture
    }
  `,
  touchpoint: gql`
    fragment Touchpoint on User {
      id
      fullname
      picture
    }
  `,
  source: gql`
    fragment UserSource on Source {
      id
      userId
      provider
      name
      isActive
    }
  `,
};
