import {
  AirtableIcon,
  Button,
  CsvIcon,
  NotionIcon,
} from '@folkapp/design-system';
import { useContactImporterButton } from 'app/apps/import/ContactImporter';
import onboardingSourceCsv from 'static/images/onboarding_source_csv.avif';
import onboardingSourceCsvPng from 'static/images/onboarding_source_csv.png';

import { SingleSourceOption } from './SingleSourceOption';

export const ImportFileSourceOption = ({
  networkId,
}: {
  networkId: string;
}) => {
  const { onClick } = useContactImporterButton();

  return (
    <SingleSourceOption
      illustration={
        <picture>
          <source srcSet={onboardingSourceCsv} type="image/avif" />
          <img
            width={288}
            height={209}
            src={onboardingSourceCsvPng}
            title="CSV import"
            alt="CSV import"
          />
        </picture>
      }
      title="Upload a CSV file"
      subtitle="Import your contacts from any tool: Notion, Airtable, Google Sheets, etc..."
      logos={
        <>
          <NotionIcon size={24} />
          <AirtableIcon size={24} />
          <CsvIcon size={24} />
        </>
      }
      action={
        <Button
          onClick={() => {
            onClick({ networkId, groupId: null });
          }}
          fullWidth
          variant="neutral"
        >
          Import file
        </Button>
      }
    />
  );
};
