import {
  ContactMultiValueFragment,
  ContactMultiValueType,
  ContactType,
} from '__generated__/graphql';
import { FetchResult } from '@apollo/client';
import { useContactAddValue } from 'app/services/remote/contact/multiValues/addContactValue';
import { useContactRemoveValue } from 'app/services/remote/contact/multiValues/removeContactValue';
import { useContactSetPrimaryValue } from 'app/services/remote/contact/multiValues/setContactPrimaryValue';
import { useContactUpdateValue } from 'app/services/remote/contact/multiValues/updateContactValue';
import { useCallback } from 'react';

export const useContactMultiValueFormMethods = ({
  networkId,
  contactId,
  contactType,
  type,
}: {
  networkId: string;
  contactId: string;
  contactType: ContactType;
  type: ContactMultiValueType;
}) => {
  const [setPrimaryValue] = useContactSetPrimaryValue({
    contactType,
  });
  const [addContactValue] = useContactAddValue({
    contactType,
  });
  const [updateContactValue] = useContactUpdateValue({
    contactType,
  });
  const [removeContactValue] = useContactRemoveValue({
    contactType,
  });

  const onSetPrimaryValue = useCallback(
    (
      contactValue: ContactMultiValueFragment,
      options:
        | {
            refetchQueries?: (
              result: FetchResult<{ multiValue: ContactMultiValueFragment }>,
            ) => any;
          }
        | undefined = {},
    ) => {
      setPrimaryValue({
        variables: {
          input: { networkId, contactId, type, id: contactValue.id },
        },
        optimisticResponse: {
          multiValue: {
            ...contactValue,
            isPrimary: true,
          },
        },
        refetchQueries: options?.refetchQueries,
      });
    },
    [setPrimaryValue, networkId, contactId, type],
  );

  const onAddValue = useCallback(
    (
      // value can be null, needs to be fixed in DB
      contactValue: ContactMultiValueFragment & { value: string },
      options:
        | {
            refetchQueries?: (
              result: FetchResult<{ multiValue: ContactMultiValueFragment }>,
            ) => any;
          }
        | undefined = {},
    ) => {
      addContactValue({
        variables: {
          input: {
            networkId,
            contactId,
            type,
            id: contactValue.id,
            value: contactValue.value,
          },
        },
        optimisticResponse: { multiValue: contactValue },
        refetchQueries: options.refetchQueries,
      });
    },
    [addContactValue, networkId, contactId, type],
  );

  const onUpdateValue = useCallback(
    (
      // value can be null, needs to be fixed in DB
      contactValue: ContactMultiValueFragment & { value: string },
      options:
        | {
            refetchQueries?: (
              result: FetchResult<{ multiValue: ContactMultiValueFragment }>,
            ) => any;
          }
        | undefined = {},
    ) => {
      updateContactValue({
        variables: {
          input: {
            networkId,
            contactId,
            id: contactValue.id,
            type,
          },
          value: contactValue.value,
        },
        optimisticResponse: { multiValue: contactValue },
        refetchQueries: options.refetchQueries,
      });
    },
    [updateContactValue, networkId, contactId, type],
  );

  const onRemoveValue = useCallback(
    (
      contactValue: ContactMultiValueFragment,
      options:
        | {
            refetchQueries?: (
              result: FetchResult<{ multiValue: ContactMultiValueFragment }>,
            ) => any;
          }
        | undefined = {},
    ) => {
      removeContactValue({
        variables: {
          input: {
            networkId,
            contactId,
            type,
            id: contactValue.id,
          },
        },
        optimisticResponse: { multiValue: contactValue },
        refetchQueries: options.refetchQueries,
      });
    },
    [removeContactValue, networkId, contactId, type],
  );

  return {
    onSetPrimaryValue,
    onAddValue,
    onUpdateValue,
    onRemoveValue,
  };
};

export type MultiValueFormMethods = ReturnType<
  typeof useContactMultiValueFormMethods
>;
