import {
  Button,
  ButtonProps,
  keyframes,
  styled,
  Text,
} from '@folkapp/design-system';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { ReactNode } from 'react';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const AlertDialogOverlay = styled(AlertDialog.Overlay, {
  backgroundColor: 'rgba(49, 53, 57, 0.2)',
  position: 'fixed',
  zIndex: 100000,
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

export const AlertDialogContent = styled(AlertDialog.Content, {
  color: '$grey700',
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow: '$xl',
  position: 'fixed',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  top: '30%',
  left: '50%',
  minWidth: 'min(calc(100% - 20px), 400px)',
  maxWidth: 'min(calc(100% - 20px), 640px)',
  transform: 'translate(-50%, -50%)',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  '&:focus': { outline: 'none' },
});

export const AlertDialogInnerContent = styled('div', {
  padding: '$4',
});

export const AlertDialogTitle = ({ children }: { children: ReactNode }) => {
  return (
    <Text as={AlertDialog.Title} variant="title" truncate>
      {children}
    </Text>
  );
};

export const AlertDialogDescription = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Text as={AlertDialog.Description} css={{ paddingBlockStart: '$3' }}>
      {children}
    </Text>
  );
};

export const AlertDialogActions = styled('footer', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  borderTop: '1px solid $grey200',
  padding: '$3 $4',
  gap: '$3',
});

export interface ConfirmationModalProps {
  onRequestClose: VoidFunction;
  onConfirm: VoidFunction;
  open: boolean;
  title: string;
  description?: ReactNode;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonVariant?: ButtonProps['variant'];
}

export const ConfirmationModal = ({
  description,
  open,
  title,
  onRequestClose,
  onConfirm,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'OK',
  confirmButtonVariant = 'default',
}: ConfirmationModalProps) => {
  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialogOverlay>
          <AlertDialogContent onEscapeKeyDown={() => onRequestClose()}>
            <AlertDialogInnerContent>
              <AlertDialogTitle>{title}</AlertDialogTitle>

              {description && (
                <AlertDialogDescription>{description}</AlertDialogDescription>
              )}
            </AlertDialogInnerContent>

            <AlertDialogActions>
              <AlertDialog.Cancel asChild>
                <Button variant="ghost" onClick={() => onRequestClose()}>
                  {cancelButtonText}
                </Button>
              </AlertDialog.Cancel>

              <AlertDialog.Action asChild>
                <Button
                  variant={confirmButtonVariant}
                  onClick={() => {
                    onRequestClose();
                    onConfirm();
                  }}
                >
                  {confirmButtonText}
                </Button>
              </AlertDialog.Action>
            </AlertDialogActions>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};
