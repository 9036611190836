import { Box } from '@folkapp/design-system';
import { useFocus } from '@react-aria/interactions';
import { AutoSizeInput } from 'app/Components/AutoSizeInput';
import { Popover, PopoverElement, PopoverProps } from 'app/Components/Popover';
import { useContains } from 'app/hooks/useContains';
import { isNotNil } from 'app/utils/functions';
import { useRoveFocus } from 'app/utils/lists';
import * as React from 'react';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ComboBoxPopoverContent } from './components/ComboBoxPopoverContent';
import {
  ComboBoxOption,
  OptionActions,
  OptionDisplay,
  OptionVariant,
} from './components/Option.types';
import { SelectedOptions } from './components/SelectedOptions';

export interface Section {
  title: string;
  id: string;
  variant?: OptionVariant;
  options: ComboBoxOption[];
}

export interface ComboBoxProps {
  actions: OptionActions;
  ariaLabel?: string;
  autoFocus?: boolean;
  clearInput: VoidFunction;
  disableFilterByInput?: boolean; // filtering by input value can be problematic with fuzzy search (think enriched companies)
  wrapping?: OptionDisplay;
  filterOutSelectedOptions?: boolean;
  id?: string;
  inputValue: string;
  isMulti?: boolean;
  loading?: boolean;
  onChange: (value: string) => void;
  onFocus?: VoidFunction;
  onToggle?: (isOpen: boolean) => void;
  openOnChange?: boolean;
  placeholder?: string;
  popoverVariant?: PopoverProps['variant'];
  sections: Section[];
  selectedOptionIds: string[];
  variant?: OptionVariant;
}

let ComboBox = forwardRef<HTMLDivElement, ComboBoxProps>(
  (
    {
      actions,
      ariaLabel = 'Combo box',
      autoFocus,
      clearInput,
      disableFilterByInput = false,
      wrapping = 'flex',
      filterOutSelectedOptions = true,
      id,
      inputValue,
      isMulti = false,
      loading = false,
      onChange,
      onFocus,
      onToggle,
      openOnChange = false,
      placeholder,
      popoverVariant,
      sections,
      selectedOptionIds,
      variant,
    },
    forwardedRef,
  ) => {
    const contains = useContains({
      sensitivity: 'base',
    });
    const popoverRef = useRef<PopoverElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const allOptions = useMemo(
      () => sections.map((section) => section.options).flat(),
      [sections],
    );
    // @note: using the context is not possible here.
    // use the `popoverRef.current.isOpen` brings sync issues
    const [localIsOpen, setLocalIsOpen] = useState(false);
    const handleSelectOption = useCallback(
      (option: ComboBoxOption) => {
        actions.onSelect?.(option);
        // this prevents an issue when the user creates
        // a new option. During a single render, the inputValue value would be "",
        // therefore there would be no create option, with the new option not
        // being present yet so `getSelectedOption` would fail in this case
        setTimeout(clearInput, 0);
        // ... but we still want to clear the input
        setLocalInputValue('');

        if (isMulti) {
          inputRef.current?.focus();

          setTimeout(() => {
            inputRef.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'nearest',
            });
          }, 0);
        } else {
          popoverRef.current?.close();
          inputRef.current?.blur();
          // @note: use useImperativeHandle
          if (forwardedRef && typeof forwardedRef !== 'function') {
            forwardedRef.current?.focus();
          }
        }
      },
      [clearInput, actions, isMulti, forwardedRef],
    );
    const { focusProps } = useFocus({
      onFocus,
      onBlur: () => {
        setCurrentFocus(undefined);

        // @note: use useImperativeHandle
        if (forwardedRef && typeof forwardedRef !== 'function') {
          forwardedRef.current?.focus();
        }
      },
    });
    const handleEnter = useCallback(
      (id: string | undefined) => {
        if (id === undefined) {
          // confirming an existing option in single pick mode
          if (selectedOptionIds.length > 0 && localIsOpen && !isMulti) {
            inputRef.current?.blur();
            popoverRef.current?.close();
          } else {
            inputRef.current?.focus();
            popoverRef.current?.open();
          }
        } else {
          const focusedOption = allOptions.find((o) => o.id === id);

          if (focusedOption !== undefined) {
            handleSelectOption(focusedOption);
          }
        }
      },
      [
        allOptions,
        handleSelectOption,
        isMulti,
        localIsOpen,
        selectedOptionIds.length,
      ],
    );
    const handleEscape = useCallback(() => {
      popoverRef.current?.close();
    }, []);

    // we need a local value for perf. reasons but it requires to
    // sync the value with a `useEffect`...
    const [localInputValue, setLocalInputValue] = useState(inputValue);
    useEffect(() => {
      setLocalInputValue(inputValue);
    }, [inputValue]);

    const handleOnChange = useCallback(
      (value: any) => {
        setLocalInputValue(value);
        onChange(value);
      },
      [onChange],
    );

    /**
     * When component is active but not in edit mode,
     * updates mode and value if the key pressed should
     * print a character.
     */
    const handleContainerKeyPress = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        /**
         * on Safari, contrary to Chrome and Firefox at least, the event is dispatched
         * even when copying (i.e. Cmd + C) so we need to explicitely filter out the event with `metaKey === true`
         */
        if (!localIsOpen && event.key.length === 1 && !event.metaKey) {
          // Firefox wouldn’t not write the key on the input
          // but Chrome would. This prevents all to write.
          event.stopPropagation();
          event.preventDefault();
          // Updates value with the key except if necessary
          if (!(!isMulti && selectedOptionIds.length > 0)) {
            onChange?.(event.key);
          }
          popoverRef.current?.open();
          inputRef.current?.focus();
        }
      },
      [localIsOpen, onChange, isMulti, selectedOptionIds.length],
    );

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && localInputValue === '') {
          const lastOptionId = selectedOptionIds.slice(-1)[0];

          if (lastOptionId) {
            actions.onRemove?.(lastOptionId);
          }
          inputRef.current?.focus();
        } else {
          // prevent writing if single select
          if (!isMulti && selectedOptionIds.length > 0) {
            event.preventDefault();
          }
        }
      },
      [localInputValue, selectedOptionIds, actions, isMulti],
    );

    const handleInputClick = useCallback(() => {
      inputRef.current?.focus();
    }, []);

    useEffect(() => {
      if (autoFocus) {
        setTimeout(() => {
          popoverRef.current?.open();
          inputRef.current?.focus();
        }, 0);
      }
    }, [autoFocus]);

    useEffect(() => {
      if (inputValue && openOnChange) {
        popoverRef.current?.open();
      }
    }, [inputValue, openOnChange]);

    const getSelectedOption = useCallback(
      (selectedOptionId: string) => {
        const selectedOption = allOptions.find(
          (option) => option.id === selectedOptionId,
        );

        return selectedOption;
      },
      [allOptions],
    );
    const selectedOptions = useMemo(
      () =>
        selectedOptionIds
          .map(getSelectedOption)
          // filtering out nil values is normally not necessary - this is a safety measure
          .filter(isNotNil),
      [getSelectedOption, selectedOptionIds],
    );

    const popoverSections = useMemo(() => {
      const filterSelectedOptions = (option: ComboBoxOption): boolean =>
        !filterOutSelectedOptions || !selectedOptionIds.includes(option.id);

      const filterByInputValue = (option: ComboBoxOption) => {
        return (
          disableFilterByInput ||
          !inputValue ||
          contains(option.text, inputValue.trim())
        );
      };

      return sections.map((section) => ({
        ...section,
        options: section.options
          .filter(filterSelectedOptions)
          .filter(filterByInputValue),
      }));
    }, [
      sections,
      filterOutSelectedOptions,
      selectedOptionIds,
      disableFilterByInput,
      inputValue,
      contains,
    ]);

    const popoverOptions = useMemo(
      () => popoverSections.map((section) => section.options).flat(),
      [popoverSections],
    );

    const unselectedOptionIds = useMemo(
      () =>
        popoverOptions
          .map((option) => option.id)
          .filter((optionId) =>
            filterOutSelectedOptions
              ? !selectedOptionIds.includes(optionId)
              : true,
          ),
      [popoverOptions, selectedOptionIds, filterOutSelectedOptions],
    );

    const {
      currentFocus,
      setCurrentFocus,
      keyboardProps: roveFocusKeyboardProps,
    } = useRoveFocus({
      ids: unselectedOptionIds,
      popoverIsOpen: localIsOpen,
      inputValue: localInputValue,
      onEnter: handleEnter,
      onEscape: handleEscape,
    });

    // TODO: fix the following issue:
    // @note: useful when having clicked twice on the input:
    // the input is focused but the popover is closed.
    // useEffect(() => {
    //   if (localInputValue && !localIsOpen) {
    //     popoverRef.current?.open();
    //   }
    //   // CAREFUL: do not include `localIsOpen` in the hook dependencies
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [localInputValue]);
    return (
      <Box
        {...roveFocusKeyboardProps}
        onKeyPress={handleContainerKeyPress}
        tabIndex={0}
        css={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginTop: '$2',
          marginBottom: '$2',
        }}
        ref={forwardedRef}
        aria-label={ariaLabel}
        // @note: the event is stopped to prevent side effects when detecting
        // a table cell becoming inactive
        onBlur={(event) => {
          event.stopPropagation();
        }}
      >
        <Popover
          ref={popoverRef}
          variant={popoverVariant}
          autoFocus={false}
          onOpen={() => {
            setLocalIsOpen(true);
            onToggle?.(true);
          }}
          onClose={() => {
            setLocalIsOpen(false);
            onToggle?.(false);
          }}
          content={
            <ComboBoxPopoverContent
              loading={loading}
              sections={popoverSections}
              onSelect={handleSelectOption}
              currentFocus={currentFocus}
              setCurrentFocus={setCurrentFocus}
              blockingMessage={
                !isMulti &&
                selectedOptionIds.length > 0 &&
                filterOutSelectedOptions
                  ? 'You can only pick one option'
                  : undefined
              }
              actions={actions}
              variant={variant}
              popoverVariant={popoverVariant}
            />
          }
        >
          <SelectedOptions
            isMulti={isMulti}
            onInputClick={handleInputClick}
            actions={actions}
            options={selectedOptions}
            variant={variant}
            wrapping={wrapping}
          >
            <AutoSizeInput
              {...focusProps}
              placeholder={
                selectedOptionIds.length === 0 && inputValue === ''
                  ? placeholder
                  : undefined
              }
              onKeyDown={onKeyDown}
              type="text"
              aria-label="combobox"
              inputRef={(el) => {
                // @ts-expect-error
                inputRef.current = el;
              }}
              onChange={handleOnChange}
              autoComplete="chrome-off"
              value={localInputValue}
              id={id}
            />
          </SelectedOptions>
        </Popover>
      </Box>
    );
  },
);

ComboBox.displayName = 'ComboBox';
ComboBox = memo(ComboBox);

export { ComboBox };
