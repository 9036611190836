import {
  GetNetworkDocument,
  GetNetworkQuery,
  GetNetworkQueryVariables,
  NetworkCreateMutation,
  NetworkCreateMutationVariables,
  NetworkFragment,
  NetworkListDocument,
  NetworkListQuery,
  NetworkListQueryVariables,
  NetworkMemberFragment,
  NetworkMembersDocument,
  NetworkMembersQuery,
  NetworkMembersQueryVariables,
  NetworkPreviewFragment,
  NetworkSearchMembersQuery,
  NetworkSearchMembersQueryVariables,
  useGetNetworkQuery,
  useNetworkCreateMutation,
  useNetworkListQuery,
  useNetworkMembersQuery,
  useNetworkSearchMembersLazyQuery,
} from '__generated__/graphql';
import {
  ApolloClient,
  MutationHookOptions,
  QueryHookOptions,
  useApolloClient,
} from '@apollo/client';

/**
 * fetches a network given its id
 */
export const useNetwork = (
  networkId: string,
  options?: QueryHookOptions<GetNetworkQuery, GetNetworkQueryVariables>,
) => {
  return useGetNetworkQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options,
    variables: { id: networkId },
  });
};

/**
 * get network given its ids from the cache
 */
export function getNetwork(client: ApolloClient<any>, id: string) {
  return client.readQuery<GetNetworkQuery, GetNetworkQueryVariables>({
    query: GetNetworkDocument,
    variables: {
      id,
    },
  });
}

/**
 * update a given network
 */
export function updateNetwork(
  client: ApolloClient<any>,
  id: string,
  modifier: (network: NetworkFragment) => NetworkFragment,
) {
  const network = getNetwork(client, id)?.network;
  if (network) {
    return client.writeQuery<GetNetworkQuery, GetNetworkQueryVariables>({
      query: GetNetworkDocument,
      variables: {
        id,
      },
      data: {
        network: modifier(network),
      },
    });
  }
}

// TODO: write updateNetworkMembers?

/**
 * fetches all attached user networks
 */
export const useNetworkList = (
  options?: QueryHookOptions<NetworkListQuery, NetworkListQueryVariables>,
) => {
  return useNetworkListQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options,
  });
};

/**
 * Gets network list from cache
 */
export function getNetworkList(client: ApolloClient<any>) {
  try {
    return client.readQuery<NetworkListQuery, NetworkListQueryVariables>({
      query: NetworkListDocument,
    });
  } catch (error) {
    console.error(error);
  }
}

/**
 * Updates cached network list
 */
export function updateNetworkList(
  client: ApolloClient<object>,
  modifier: (networks: NetworkPreviewFragment[]) => NetworkPreviewFragment[],
) {
  const networks = getNetworkList(client)?.networks;
  if (networks) {
    client.writeQuery<NetworkListQuery, NetworkListQueryVariables>({
      query: NetworkListDocument,
      data: { networks: modifier(networks) },
    });
  }
}

/**
 * fetches all network members
 */
export const useNetworkMembers = (
  options: QueryHookOptions<NetworkMembersQuery, NetworkMembersQueryVariables>,
) => {
  return useNetworkMembersQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options,
  });
};

export function updateNetworkMembers({
  client,
  networkId,
  modifier,
}: {
  client: ApolloClient<any>;
  networkId: string;
  modifier: (members: NetworkMemberFragment[]) => NetworkMemberFragment[];
}) {
  const existingMembers = client.readQuery<
    NetworkMembersQuery,
    NetworkMembersQueryVariables
  >({
    query: NetworkMembersDocument,
    variables: { id: networkId },
  })?.networkMembers?.members;

  if (existingMembers) {
    client.cache.writeQuery<NetworkMembersQuery, NetworkMembersQueryVariables>({
      query: NetworkMembersDocument,
      data: {
        networkMembers: {
          __typename: 'Network',
          id: networkId,
          members: modifier(existingMembers),
        },
      },
    });
  }
}

/**
 * Get cached network members.
 */
export const getNetworkMembers = (client: ApolloClient<object>, id: string) => {
  return client.readQuery<NetworkMembersQuery, NetworkMembersQueryVariables>({
    query: NetworkMembersDocument,
    variables: { id },
  });
};

export function useLazyNetworkMembers(
  options?: QueryHookOptions<
    NetworkSearchMembersQuery,
    NetworkSearchMembersQueryVariables
  >,
) {
  return useNetworkSearchMembersLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options,
  });
}

/**
 * create a network
 */
export const useCreateNetwork = (
  options?: MutationHookOptions<
    NetworkCreateMutation,
    NetworkCreateMutationVariables
  >,
) => {
  const client = useApolloClient();
  return useNetworkCreateMutation({
    /**
     * Upon network creation, append it into the current network list.
     */
    update(_, response) {
      const network = response.data?.network;
      if (network) {
        updateNetworkList(client, (networks) => [...networks, network]);
      }
    },
    ...options,
  });
};
