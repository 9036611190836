import { Flex } from '@designSystem';
import { Box } from '@folkapp/design-system';
import { generateColor } from 'app/utils/ui';
import { components, OptionProps } from 'react-select';

import { UseCase } from './utils/useCases';

const { Option } = components;

export const UseCaseOption = (props: OptionProps<UseCase, true>) => {
  return (
    <Option {...props}>
      <Flex align="center">
        <Box
          as="p"
          css={{
            padding: '$0_5 $1',
            borderRadius: '$md',
            backgroundColor: `${generateColor(props.data.label)}22`,
          }}
        >
          {props.data.label}
        </Box>
      </Flex>
    </Option>
  );
};
