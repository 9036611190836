import {
  CreateCompanyInput,
  CreatePersonInput,
  CsvImportGroupCustomFieldsDocument,
  CsvImportGroupCustomFieldsQuery,
  CsvImportGroupCustomFieldsQueryVariables,
  CustomFieldType,
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
  useCreatePeopleMutation,
  useTrackCsvImportedMutation,
} from '__generated__/graphql';
import { gql, useApolloClient } from '@apollo/client';
import type {
  default as FlatfileImporter,
  FlatfileResults,
  ISettings,
} from '@flatfile/adapter';
import { IField } from '@flatfile/adapter/build/main/interfaces';
import { useToasts } from '@folkapp/design-system';
import { hasProperty } from 'app/services/local/utils';
import { useCreateGroup } from 'app/services/remote/group';
import { getRandomEmoji } from 'app/utils/emoji';
import { isNotNil } from 'app/utils/functions';
import { dset } from 'dset';
import chunk from 'lodash/chunk';
import { useCallback, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { v4 as uuid } from 'uuid';

import { genderOptions } from '../contactPanel/components/ContactDetails/components/Fields/Gender/Gender';
import { ContactImporterLoader } from './ContactImporterLoader';

const FLATFILE_API_KEY = '38e325c0-31a6-45ce-96a1-da02f0216a20';

gql`
  query CSVImportGroupCustomFields($groupId: ID!) {
    groupCustomFields(
      groupId: $groupId
      filterBy: {
        contactType: person
        types: [multipleSelect, singleSelect, textField, dateField]
      }
    ) {
      id
      name
      type
    }
  }
`;

export type ImporterResolver = (
  settings: ISettings,
) => Promise<FlatfileImporter>;

const defaultImporterResolver = async (settings: ISettings) => {
  const module = await import('@flatfile/adapter');
  const FlatfileImporter = module.default;
  return new FlatfileImporter(FLATFILE_API_KEY, settings);
};

const customFieldDescription: Partial<
  Record<CustomFieldType, string | undefined>
> = {
  [CustomFieldType.multipleSelect]: 'A comma-separated list of values',
  [CustomFieldType.dateField]: 'A date in the format YYYY-MM-DD',
};

export const useLoadImporter = (
  importerResolver: ImporterResolver = defaultImporterResolver,
) => {
  const apolloClient = useApolloClient();

  return useCallback(
    async (groupId: string | null) => {
      const loader = document.createElement('div');
      document.body.appendChild(loader);
      createRoot(loader).render(<ContactImporterLoader />);
      try {
        const userQuery = await apolloClient.query<
          GetMeQuery,
          GetMeQueryVariables
        >({
          query: GetMeDocument,
          fetchPolicy: 'cache-first',
        });

        const importerSettingFields: ISettings['fields'] = [
          { label: 'First name', key: 'firstname' },
          { label: 'Last name', key: 'lastname' },
          { label: 'Notes', key: 'notes' },
          { label: 'Email 1', key: 'emails.0.value' },
          { label: 'Email 2', key: 'emails.1.value' },
          { label: 'URL 1', key: 'urls.0.value' },
          { label: 'URL 2', key: 'urls.1.value' },
          { label: 'Phone 1', key: 'phones.0.value' },
          { label: 'Phone 2', key: 'phones.1.value' },
          { label: 'Address 1', key: 'addresses.0.value' },
          { label: 'Address 2', key: 'addresses.1.value' },
          { label: 'Company', key: 'companies.0.name' },
          { label: 'Company URL 1', key: 'companies.0.urls.0.value' },
          { label: 'Company phone 1', key: 'companies.0.phones.0.value' },
          {
            label: 'Company address 1',
            key: 'companies.0.addresses.0.value',
          },
          { label: 'Company email 1', key: 'companies.0.emails.0.value' },
          { label: 'Job title', key: 'jobTitle' },
          {
            label: 'Birthday',
            key: 'birthday',
            description: 'A date in the format YYYY-MM-DD',
          },
          {
            label: 'Gender',
            key: 'gender',
            type: 'select',
            options: genderOptions.map(({ label, value }) => ({
              label,
              value,
            })),
          },
        ];

        if (groupId) {
          const groupCustomFieldsQuery = await apolloClient.query<
            CsvImportGroupCustomFieldsQuery,
            CsvImportGroupCustomFieldsQueryVariables
          >({
            query: CsvImportGroupCustomFieldsDocument,
            variables: { groupId },
            fetchPolicy: 'network-only',
          });

          importerSettingFields.push(
            ...groupCustomFieldsQuery.data.groupCustomFields.map(
              (groupCustomField): IField => ({
                key: `customFields.${JSON.stringify({
                  id: groupCustomField.id,
                  type: groupCustomField.type,
                })}`,
                label: groupCustomField.name,
                description: customFieldDescription[groupCustomField.type],
              }),
            ),
          );
        }

        const importer = await importerResolver({
          type: 'Contact',
          title: 'Import contacts into folk',
          allowInvalidSubmit: true,
          autoDetectHeaders: true,
          managed: false,
          fields: importerSettingFields,
        });

        importer.setCustomer({ userId: userQuery.data.user.id });
        importer.setLanguage('en');
        await importer.$ready;
        return importer;
      } finally {
        document.body.removeChild(loader);
      }
    },
    [apolloClient, importerResolver],
  );
};

gql`
  mutation CreatePeople($input: CreatePeopleInput!) {
    createPeople(input: $input)
  }
`;

gql`
  mutation TrackCSVImported($input: TrackCSVImportedInput!) {
    trackCSVImported(input: $input)
  }
`;

type CreateContactImportHandlerOptions = {
  onChunk: (chunk: CreatePersonInput[]) => Promise<void>;
  onComplete: (importedContactCount: number) => Promise<void>;
};

type ContactImportHandlerOptions = {
  importer: Pick<
    FlatfileImporter,
    'displayLoader' | 'displaySuccess' | 'displayError'
  >;
  results: Pick<FlatfileResults, 'allData'>;
};

const isCustomFieldKey = (key: string) => key.startsWith('customFields.');

const isCustomFieldConfig = (
  value: unknown,
): value is {
  id: string;
  type:
    | CustomFieldType.multipleSelect
    | CustomFieldType.singleSelect
    | CustomFieldType.textField
    | CustomFieldType.dateField;
} => {
  return (
    typeof value === 'object' &&
    value !== null &&
    hasProperty(value, 'id') &&
    typeof value['id'] === 'string' &&
    hasProperty(value, 'type') &&
    [
      CustomFieldType.multipleSelect,
      CustomFieldType.singleSelect,
      CustomFieldType.textField,
      CustomFieldType.dateField,
    ].includes(value.type)
  );
};

const parseCustomFieldKey = (key: string) => {
  const [, json] = key.split('customFields.');
  if (!json) {
    return null;
  }
  try {
    const parsed = JSON.parse(json);
    return isCustomFieldConfig(parsed) ? parsed : null;
  } catch (e) {
    return null;
  }
};

export const fromFlatFileDataToRows = (allData: any[]): CreatePersonInput[] => {
  return allData
    .map((row) => {
      const entries = Object.entries(row);

      // Only keep non-empty values
      const validEntries = entries.filter(([key, value]) => {
        if (typeof value === 'string') {
          return value.trim() !== '';
        }
        return value !== undefined && value !== null;
      });
      // If the line does not contain any valid values, skip it
      if (validEntries.length === 0) {
        return null;
      }

      return validEntries.reduce<CreatePersonInput>((acc, [key, value]) => {
        if (!isCustomFieldKey(key)) {
          dset(acc, key, value);
          return acc;
        }

        const groupCustomField = parseCustomFieldKey(key);
        if (!groupCustomField || typeof value !== 'string') {
          return acc;
        }

        acc.customFieldValues = acc.customFieldValues ?? [];
        acc.customFieldValues.push(
          groupCustomField.type === CustomFieldType.multipleSelect
            ? {
                customFieldId: groupCustomField.id,
                values: value.split(','),
              }
            : {
                customFieldId: groupCustomField.id,
                value,
              },
        );

        return acc;
      }, {});
    })
    .filter(isNotNil)
    .map((row) => {
      const newRow = { ...row };

      for (const [key, value] of Object.entries(newRow)) {
        if (key === 'companies' && Array.isArray(value)) {
          // Remove companies without name
          newRow.companies = (value as CreateCompanyInput[]).filter(
            (company) => isNotNil(company) && !!company.name,
          );
          continue;
        }
        if (Array.isArray(value)) {
          // @ts-expect-error
          newRow[key] = value.filter(isNotNil);
        }
      }

      return newRow;
    });
};

export const createContactImporterHandler = ({
  onChunk,
  onComplete,
}: CreateContactImportHandlerOptions) => {
  return async ({
    importer,
    results: { allData },
  }: ContactImportHandlerOptions) => {
    const rows = fromFlatFileDataToRows(allData);
    const chunkSize = 100;
    const chunks = chunk(rows, chunkSize);
    let chunkIsTooLarge = false;

    importer.displayLoader(`⏳ Importing your contacts. (0/${allData.length})`);
    let processed = 0;
    for (const chunk of chunks) {
      try {
        await onChunk(chunk);
      } catch (error: any) {
        console.error(error);

        if (error.message === 'Failed to fetch') {
          chunkIsTooLarge = true;
        }
      } finally {
        processed += chunk.length;
        importer.displayLoader(
          `⏳ Importing your contacts. (${processed}/${allData.length})`,
        );
      }
    }

    await onComplete(allData.length);

    if (chunkIsTooLarge) {
      importer.displayError(
        'Whoops! Some contacts were not imported – your file may be too large',
      );
    } else {
      importer.displaySuccess(`🎉 Contacts imported successfully`);
    }
  };
};

const useGetContactImportHandler = () => {
  const apolloClient = useApolloClient();
  const [createPeople] = useCreatePeopleMutation();
  const [trackCSVImported] = useTrackCsvImportedMutation();

  return useCallback(
    ({ groupId, networkId }: { groupId: string; networkId: string }) =>
      createContactImporterHandler({
        onChunk: async (chunk) => {
          await createPeople({
            variables: {
              input: {
                networkId,
                groupId,
                inputs: chunk,
              },
            },
          });
        },
        onComplete: async (importedContactCount) => {
          try {
            trackCSVImported({
              variables: {
                input: {
                  groupId,
                  networkId,
                  importedContactCount,
                },
              },
            });
            await apolloClient.reFetchObservableQueries();
          } catch (error) {
            console.error('[useGetContactImportHandler.onComplete]', error);
          }
        },
      }),
    [createPeople, trackCSVImported, apolloClient],
  );
};

export const useContactImporterButton = (
  importerResolver?: ImporterResolver,
): {
  /** if `groupId` is null, a group will be dynamically created */
  onClick: ({
    groupId,
    networkId,
  }: {
    groupId: string | null;
    networkId: string;
  }) => void;
} => {
  const loadImporter = useLoadImporter(importerResolver);
  const getContactImportHandler = useGetContactImportHandler();
  const [createGroup] = useCreateGroup();
  const { addToast } = useToasts();

  return useMemo(
    () => ({
      onClick: async ({
        groupId,
        networkId,
      }: {
        groupId: string | null;
        networkId: string;
      }) => {
        const importer = await loadImporter(groupId);
        const groupIdWithFallback = groupId ?? uuid();
        const results = await importer.requestDataFromUser();

        if (!groupId) {
          const today = new Date();

          // default name, which is useful when the user manually
          // enters data with the FlatFile UI
          let name = `${today.getFullYear()}_${
            today.getMonth() + 1
          }_${today.getDate()}_csv_import`;

          if (results.fileName) {
            // strip the file extension
            name = results.fileName.substring(
              0,
              results.fileName.lastIndexOf('.csv'),
            );
          }

          try {
            await createGroup({
              variables: {
                payload: {
                  id: groupIdWithFallback,
                  networkId,
                  name,
                  openAccess: false,
                  emoji: getRandomEmoji(),
                },
              },
            });
          } catch (error) {
            console.error(error);
            addToast('Could not create a group', {
              variant: 'error',
            });
            return;
          }
        }

        const onImport = getContactImportHandler({
          groupId: groupIdWithFallback,
          networkId,
        });
        await onImport({ importer, results });
      },
    }),
    [loadImporter, getContactImportHandler, addToast, createGroup],
  );
};
