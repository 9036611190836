import { GetMeQuery } from '__generated__/graphql';
import { ApolloError } from '@apollo/client';
import { useRedirectTo } from 'app/apps/routes/utils';
import { useCallback } from 'react';

import { UserStatus } from '.';

export const useRedirectUpon403 = () => {
  const redirectTo = useRedirectTo();

  return useCallback(
    ({
      error,
      extraAction,
    }: {
      error: ApolloError;
      extraAction?: VoidFunction;
    }) => {
      const graphQLErrors = error?.graphQLErrors || [];
      // TODO: check the condition
      const permissionError = graphQLErrors.find(
        (e) => e.extensions?.code === 403,
      );
      if (permissionError) {
        extraAction?.();
        redirectTo(UserStatus.CONNECTED);
      } else {
        throw error;
      }
    },
    [redirectTo],
  );
};

export const buildAnalyticsUserTraits = (user: GetMeQuery['user']) => {
  return {
    email: user.email,
    first_name: user.firstname,
    last_name: user.lastname,
    onboarding_needed: user.needOnboarding,
    onboarding_step: user.onboardingStep,
    ...buildAnalyticsTraitsFromAcquisitionTags(user.acquisitionTags),
  };
};

const buildAnalyticsTraitsFromAcquisitionTags = (
  acquisitionTags: any | null,
) => {
  if (!acquisitionTags) {
    return undefined;
  }

  const {
    utm_campaign,
    utm_source,
    utm_medium,
    utm_term,
    utm_content,
    referer,
    landing_url,
  } = acquisitionTags;

  if (
    !utm_campaign &&
    !utm_source &&
    !utm_medium &&
    !utm_term &&
    !utm_content &&
    !referer &&
    !landing_url
  ) {
    return;
  }

  return {
    utm_campaign,
    utm_source,
    utm_medium,
    utm_term,
    utm_content,
    referer,
    landing_url,
  };
};
