import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Portal from '@radix-ui/react-portal';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { createContext, useCallback, useContext, useMemo, useState, } from 'react';
import { IconButton } from './Button';
import { MdCancel, MdCheckCircle, MdClose, MdError, MdInfo } from './Icon';
import { keyframes, styled } from './stitches.config';
const fadeOut = keyframes({
    '0%': { opacity: 1 },
    '100%': { opacity: 0 },
});
const slideIn = keyframes({
    from: { transform: 'translateY($space$2)', opacity: 0 },
    to: { transform: 'translateX(0)', opacity: 1 },
});
const swipeOut = keyframes({
    from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
    to: { transform: 'translateX(calc(100% + $$toastViewportPadding))' },
});
const ToastStateContext = createContext({
    addToast: () => -1,
});
let toastIdGenerator = 0;
export const ToastProvider = ({ children, duration = 6000, }) => {
    const [toasts, setToasts] = useState([]);
    const removeToast = useCallback((toastId) => {
        setToasts((toasts) => toasts.filter((toast) => toast.id !== toastId));
    }, []);
    const addToast = useCallback((title, { description, variant = 'success' } = {}) => {
        const toastId = ++toastIdGenerator;
        let toastRef = null;
        setToasts((toasts) => [
            ...toasts,
            {
                id: toastId,
                element: (_jsxs(Toast, { ref: (ref) => (toastRef = ref), onOpenChange: () => {
                        toastRef === null || toastRef === void 0 ? void 0 : toastRef.addEventListener('animationend', () => removeToast(toastId), { once: true });
                    }, forceMount: true, children: [_jsxs(ToastIcon, { variant: variant, children: [variant === 'success' && (_jsx(MdCheckCircle, { "data-testid": "success-icon" })), variant === 'info' && _jsx(MdInfo, { "data-testid": "info-icon" }), variant === 'warning' && (_jsx(MdError, { "data-testid": "warning-icon" })), variant === 'error' && _jsx(MdCancel, { "data-testid": "error-icon" })] }), _jsxs(ToastContent, { children: [_jsx(ToastTitle, { children: title }), description ? (_jsx(ToastDescription, { children: description })) : null] }), _jsx(ToastClose, {})] }, toastId)),
            },
        ]);
        return toastId;
    }, [removeToast]);
    const value = useMemo(() => ({ addToast }), [addToast]);
    return (_jsxs(ToastPrimitive.Provider, { swipeDirection: "right", duration: duration, children: [_jsx(ToastStateContext.Provider, { value: value, children: children }), toasts.map((toast) => toast.element), _jsx(Portal.Root, { children: _jsx(ToastViewport, {}) })] }));
};
const Toast = styled(ToastPrimitive.Root, {
    maxWidth: '$sm',
    flexShrink: 0,
    width: '$full',
    backgroundColor: '$white',
    borderWidth: 1,
    borderColor: '$grey200',
    boxShadow: '0px 9px 24px rgba(24, 26, 27, 0.16), 0px 3px 6px rgba(24, 26, 27, 0.08), 0px 0px 1px rgba(24, 26, 27, 0.04)',
    borderRadius: '$lg',
    pointerEvents: 'auto',
    overflow: 'hidden',
    padding: '$3',
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: '$3',
    '@media (prefers-reduced-motion: no-preference)': {
        '&[data-state="open"]': {
            animation: `${slideIn} 250ms cubic-bezier(0, 0, 0.2, 1)`,
        },
        '&[data-state="closed"]': {
            animation: `${fadeOut} 200ms linear forwards`,
        },
        '&[data-swipe="move"]': {
            transform: 'translateX(var(--radix-toast-swipe-move-x))',
        },
        '&[data-swipe="cancel"]': {
            transform: 'translateX(0)',
            transition: 'transform 200ms linear',
        },
        '&[data-swipe="end"]': {
            animation: `${swipeOut} 200ms linear forwards`,
        },
    },
});
const ToastTitle = styled(ToastPrimitive.Title, {
    fontWeight: '$medium',
    lineHeight: '$5',
});
const ToastDescription = styled(ToastPrimitive.Description, {
    gridArea: 'description',
    marginTop: '$1',
    color: '$grey600',
});
const ToastClose = (props) => (_jsx(ToastPrimitive.Close, { asChild: true, children: _jsx(IconButton, { css: {
            marginBlock: 'calc(-$1_5 + $px)',
            marginInlineEnd: 'calc(-$1_5 + $px)',
            flexShrink: 0,
        }, variant: "ghost", "aria-label": "Close", ...props, children: _jsx(MdClose, { "aria-hidden": "true" }) }) }));
const ToastViewport = styled(ToastPrimitive.Viewport, {
    $$toastViewportPadding: '$space$4',
    pointerEvents: 'none',
    position: 'fixed',
    inset: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: '$$toastViewportPadding',
    gap: '$3',
    overflow: 'hidden',
    zIndex: 100000,
});
const ToastIcon = styled('div', {
    svg: {
        width: '$5',
        height: '$5',
    },
    variants: {
        variant: {
            success: {
                svg: {
                    color: '$green600',
                },
            },
            info: {
                svg: {
                    color: '$blue600',
                },
            },
            warning: {
                svg: {
                    color: '$yellow600',
                },
            },
            error: {
                svg: {
                    color: '$red600',
                },
            },
        },
    },
});
const ToastContent = styled('div', {
    flex: 1,
    minWidth: 0,
});
export const useToasts = () => {
    return useContext(ToastStateContext);
};
