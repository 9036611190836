import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MdWarning } from 'react-icons/md';
import { styled } from './stitches.config';
import { Text } from './Text';
const BannerRoot = styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '$2',
    backgroundColor: '$yellow100',
    color: '$yellow900',
    padding: '$1',
    paddingInlineStart: '$2',
    borderRadius: '$lg',
});
const BannerIcon = styled('div', {
    width: '$4',
    height: '$4',
    flexShrink: 0,
    svg: {
        width: '$full',
        height: '$full',
        color: '$yellow600',
    },
});
const BannerContent = styled('div', {
    flex: 1,
    minWidth: 0,
});
const BannerActions = styled('div', {
    display: 'flex',
    gap: '$2',
    flexShrink: 0,
});
export const Banner = ({ icon = _jsx(MdWarning, {}), content, actions, }) => {
    return (_jsxs(BannerRoot, { role: "alert", children: [_jsx(BannerIcon, { children: icon }), _jsx(BannerContent, { children: _jsx(Text, { variant: "textMedium", children: content }) }), actions && _jsx(BannerActions, { children: actions })] }));
};
